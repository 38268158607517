import classNames from 'classnames';
import { FormikValues, useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Button from 'components/button/Button';
import Input from 'components/form/input/Input';
import MediaTypeDropdown from 'components/media/type-dropdown/MediaTypeDropdown';
import Heading from 'components/typography/heading/Heading';

import { Paths } from 'constants/Routes.enum';
import useDebounce from 'hooks/useDebounce';

import { fetchMediaAction, filterMediaAction } from 'store/media/mediaActions';
import { RootState } from 'store/rootState';

import './MediaFilter.scss';

const MediaFilter = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { folder_id } = useParams<{ folder_id: string }>();
  const matchPoolMedia = useRouteMatch([Paths.POOLS_MEDIA]);

  const { customerOverride } = useSelector((state: RootState) => state.superAdmin);

  const initialValues = {
    name: '',
    filetype: '',
  };

  const [isInitialized, setInitialized] = useState(false);
  const [formKey, setFormKey] = useState(0);

  const filterMedia = useCallback(
    (values: FormikValues) => {
      if (isInitialized) {
        localStorage.setItem('media_filters', JSON.stringify(values));
        if (values.name || values.filetype) {
          dispatch(filterMediaAction(values.name, values.filetype, folder_id));
        } else {
          dispatch(fetchMediaAction({ folder_id }));
        }
      }
    },
    [dispatch, folder_id, isInitialized],
  );

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    onSubmit: filterMedia,
  });

  const resetMedia = useCallback(() => {
    resetForm();
    localStorage.removeItem('media_filters');
    setFormKey(key => key + 1);
  }, [resetForm]);

  useEffect(() => {
    const filterStorage = localStorage.getItem('media_filters');
    if (filterStorage && !isInitialized) {
      const filter = JSON.parse(filterStorage);

      if (filter.name) {
        setFieldValue('name', filter.name);
      }
      if (filter.filetype) {
        setFieldValue('filetype', filter.filetype);
      }
    }
    setInitialized(true);
  }, [setFieldValue, isInitialized]);

  const debouncedValues = useDebounce(values, 250)
  useEffect(() => {
    filterMedia(debouncedValues);
  }, [debouncedValues, filterMedia]);

  useEffect(() => {
    resetMedia()
  }, [customerOverride, resetMedia])

  return (
    <form
      key={formKey}
      onSubmit={handleSubmit}
      onChange={handleChange}
      className={classNames('media-filter', {
        'media-filter--in-pool': matchPoolMedia,
      })}
    >
      <div className="media-filter__bar">
        <div className="media-filter__input">
          <Input
            type="text"
            label={
              <Heading level={3} noMargin>
                Search
              </Heading>
            }
            name="name"
            id="search_name"
            value={values.name}
            scheme="white"
            onChange={handleChange}
            placeholder="What are you looking for?"
          />

          <MediaTypeDropdown
            label="File type"
            name="filetype"
            id="search_filetype"
            onChange={setFieldValue}
            value={values.filetype}
            width={220}
            placeholder="Select a file type"
          />
        </div>

        <div className="media-filter__options">
          <Button
            tag="button"
            text="Reset"
            className="media-filter__option"
            size="medium"
            scheme="link"
            hasShadow={false}
            onClick={resetMedia}
            disabled={values.name === '' && values.filetype === ''}
          />
          <Button tag="button" type="submit" text="Search" size="medium" />
        </div>
      </div>
    </form>
  );
};

export default MediaFilter;
