import { InputDevice, InputDevices } from 'store/rootState';
import { InputDevicesActionType } from './inputDevicesActions.enum';
import { InputDevicesAction } from './inputDevicesActions.types';

export const inputDevicesInitialState = {
  request: false,
  selectedInputDevice: {
    request: false,
    inputDevice: null,
  },
  inputDevices: [],
};

const inputDevicesReducer = (
  state: InputDevices,
  action: InputDevicesAction,
) => {
  switch (action.type) {
    case InputDevicesActionType.INPUT_DEVICES_REQUESTED:
      return { ...state, request: true, selectedInputDevice: null };
    case InputDevicesActionType.INPUT_DEVICES_SUCCEEDED:
      return {
        ...state,
        inputDevices: action.payload,
        request: false,
        selectedInputDevice: {
          ...inputDevicesInitialState.selectedInputDevice,
        },
      };
    case InputDevicesActionType.INPUT_DEVICES_FAILED:
      return {
        ...inputDevicesInitialState,
        request: false,
        error: action.payload,
      };
    case InputDevicesActionType.INPUT_DEVICE_ADDED:
      return {
        ...state,
        inputDevices: [action.payload, ...state.inputDevices],
        selectedInputDevice: {
          ...inputDevicesInitialState.selectedInputDevice,
        },
      };
    case InputDevicesActionType.INPUT_DEVICE_UPDATED:
      return {
        ...state,
        inputDevices: state.inputDevices.map((inputDevice: InputDevice) => {
          if (inputDevice.id === action.payload.id) {
            return {
              ...inputDevice,
              ...action.payload,
            };
          }
          return inputDevice;
        }),
        selectedInputDevice: {
          request: false,
          inputDevice: action.payload,
        },
      };
    case InputDevicesActionType.INPUT_DEVICE_INPUT_SIGNALS_ACTIVATED:
      return {
        ...state,
        selectedInputDevice: {
          request: false,
          inputDevice: action.payload,
        },
      };
    case InputDevicesActionType.INPUT_DEVICE_INPUT_SIGNALS_DEACTIVATED:
      return {
        ...state,
        selectedInputDevice: {
          request: false,
          inputDevice: action.payload,
        },
      };
    case InputDevicesActionType.INPUT_DEVICE_DETAILS_REQUESTED:
      return {
        ...state,
        request: false,
        selectedInputDevice: {
          request: true,
          inputDevice: null,
        },
      };
    case InputDevicesActionType.INPUT_DEVICE_DETAILS_SUCCEEDED:
      return {
        ...state,
        request: false,
        selectedInputDevice: {
          request: false,
          inputDevice: action.payload,
        },
      };
    case InputDevicesActionType.INPUT_DEVICE_DETAILS_FAILED:
      return {
        ...state,
        request: false,
        selectedInputDevice: {
          request: false,
          inputDevice: null,
          error: action.payload,
        },
      };
    case InputDevicesActionType.INPUT_DEVICE_DELETED:
      return {
        ...state,
        inputDevices: state.inputDevices.filter(
          item => item.id !== action.payload.id,
        ),
        selectedInputDevice: {
          ...inputDevicesInitialState.selectedInputDevice,
        },
      };
    default:
      return { ...state };
  }
};

export default inputDevicesReducer;
