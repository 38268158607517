import { UserActionTypes } from './userActions';

export const userInitialState = {
  request: false,
  id: undefined,
  status: null,
  firstname: null,
  lastname: null,
  emailAddress: null,
  roles: [],
  locations: [],
  created_at: null,
};

const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case UserActionTypes.USER_REQUESTED:
      return { ...state, request: true };
    case UserActionTypes.USER_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
        request: false,
      };
    case UserActionTypes.USER_FAILED:
      return {
        ...userInitialState,
        request: false,
      };
    case UserActionTypes.USER_UPDATED:
      return { ...action.payload };
    case UserActionTypes.USER_NOT_FOUND:
      return { ...userInitialState, id: 0 };
    default:
      return state;
  }
};

export default userReducer;
