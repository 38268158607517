import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'store/rootState';

import BlankButton from 'components/button/BlankButton';
import Card from 'components/card/Card';
import Icon from 'components/icon/Icon';
import Heading from 'components/typography/heading/Heading';

import UserRight from 'constants/UserRight.enum';
import { hasUserRights } from 'store/auth/hasUserRights';
import { setInfoBarShow } from 'store/info/infoActions';
import { editMediaFolderAction } from 'store/media/mediaActions';

import './MediaCardFolder.scss';

interface MediaCardFolderProps {
  id: number
  title: string
  link: string
  editable?: boolean
}

const MediaCardFolder = ({ title, id, link, editable }: MediaCardFolderProps) => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const history = useHistory();
  const hasMediaFoldersEditRights = dispatch(
    hasUserRights(UserRight.MEDIA_FOLDERS_EDIT),
  );
  const cardClick = () => {
    const { state } = history.location;

    history.push({ pathname: link, state });
  };

  const cardSettingsClick = (e: Event, id: number) => {
    e.stopPropagation();
    dispatch(editMediaFolderAction(id));
    dispatch(setInfoBarShow({ show: false }));
  };

  return (
    <BlankButton tag="a" handler={cardClick} className="button__interaction">
      <Card className="media-card-folder">
        <div className="media-card-folder__inner">
          <div className="media-card-folder__label">
            <Icon name="folder" className="media-card-folder__icon" />
            <Heading level={3} className="media-card-folder__heading">
              {title}
            </Heading>
          </div>

          {hasMediaFoldersEditRights && editable && (
            <BlankButton handler={e => cardSettingsClick(e, id)}>
              <div className="media-card-folder__hover">
                <Icon name="more_options" />
              </div>
            </BlankButton>
          )}
        </div>
      </Card>
    </BlankButton>
  );
};

export default MediaCardFolder;
