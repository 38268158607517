import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import equal from 'fast-deep-equal';

import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import {
  ButtonScheme,
  ButtonSize,
  ButtonTag,
} from 'components/button/Button.enum';
import Input from 'components/form/input/Input';
import { InputScheme } from 'components/form/input/Input.enum';
import ButtonGroup from 'components/button/ButtonGroup';
import SubmitButton from 'components/form/submit-button/SubmitButton';
import Heading from 'components/typography/heading/Heading';
import {
  PopupStickyFooter,
  PopupStickyForm,
  PopupStickyHeader,
  PopupStickyMain,
} from 'components/popup/Popup';
import LocationDropdown from 'components/location/dropdown/LocationDropdown';
import UserRoleDropdown from 'components/userRoles/dropdown/UserRoleDropdown';

import { RootState } from 'store/rootState';
import { popupActionClear } from 'store/popup/popupActions';

import useAuthLogout from 'hooks/useAuthLogout';

import { setInfoBarShow } from 'store/info/infoActions';
import { addUserAction } from 'store/users/usersActions';

import RegexOptions from 'constants/RegexOptions';

import {
  Location,
  LocationOption,
  UserProps,
  UserRole,
  UserRoleOption,
} from './User.types';

const User: React.FC<UserProps> = ({
  id,
  firstName,
  lastName,
  email,
  locations,
  userRoles,
}) => {
  const dispatch = useDispatch();
  const infoBar = useSelector((state: RootState) => state.info.bar);
  const [isSubmitting, setSubmitting] = useState(false);
  const { customerOverride } = useSelector((state: RootState) => state.superAdmin);
  const [initialForm, setInitialForm] = useState({
    firstName,
    lastName,
    email,
    locations: locations || [],
    userRoles: userRoles || [],
  });
  const [logoutAction] = useAuthLogout(true);
  const loggedInUser = useSelector((state: RootState) => state.auth.user);
  const popupContents = (
    <>
      <h2>Verification needed</h2>
      <p>
        You changed the e-mail for the user you are currently logged in with.
        For security reasons this needs to be verified. <br />
        Click on the link in the verification e-mail we have send to the changed
        e-mail to complete the verification.
        <br />
        Click on the button below to log out. You can login again after you have
        verified your new e-mail.
      </p>
      <Button
        tag={ButtonTag.BUTTON}
        text="Log out"
        size={ButtonSize.MEDIUM}
        handler={logoutAction}
      />
    </>
  );

  useEffect(() => {
    setInitialForm({
      firstName,
      lastName,
      email,
      userRoles: userRoles || [],
      locations: locations || [],
    });
  }, [firstName, lastName, email, userRoles, locations]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is a required field'),
    lastName: Yup.string().required('Last name is a required field'),
    email: Yup.string()
      .required('E-mail is a required field')
      .matches(RegexOptions.EMAIL_VALIDATION, 'Please enter a valid e-mail'),
  });

  const onCancel = () => {
    dispatch(popupActionClear());
    if (infoBar.show) {
      dispatch(setInfoBarShow({ show: false }));
    }
  };

  const onSubmit = (values: UserProps) => {
    if (equal(values, initialForm)) {
      dispatch(popupActionClear());
      dispatch(setInfoBarShow({ show: false }));
      return;
    }

    setSubmitting(true);

    const userData = new FormData();
    userData.append('user[first_name]', values.firstName);
    userData.append('user[last_name]', values.lastName);
    userData.append('user[email]', values.email);
    if (customerOverride) {
      userData.append('user[customer_id]', customerOverride);
    }

    if (values.userRoles.length > 0) {
      values.userRoles.forEach(role => {
        userData.append('user[user_group_ids][]', role.id.toString());
      });
    } else {
      userData.append('user[user_group_ids][]', '');
    }

    if (values.locations.length > 0) {
      values.locations.forEach(location => {
        userData.append('user[location_ids][]', location.id.toString());
      });
    } else {
      userData.append('user[location_ids][]', '');
    }

    dispatch(
      addUserAction(userData, id, setSubmitting, loggedInUser, popupContents),
    );
  };

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
      }) => (
        <PopupStickyForm onSubmit={handleSubmit}>
          <PopupStickyHeader>
            <Heading level={2} noMargin>
              {id ? 'Edit user' : 'New user'}
            </Heading>
          </PopupStickyHeader>
          <PopupStickyMain>
            <fieldset>
              <legend>User information</legend>

              <FormBlock style={{ maxWidth: 580 }}>
                <Input
                  label="First name"
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First name"
                  scheme={InputScheme.WHITE}
                  value={values.firstName}
                  error={touched.firstName && errors.firstName}
                  onChange={handleChange}
                />
                <Input
                  label="Last name"
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last name"
                  scheme={InputScheme.WHITE}
                  value={values.lastName}
                  error={touched.lastName && errors.lastName}
                  onChange={handleChange}
                />
              </FormBlock>

              <FormBlock flatten style={{ maxWidth: 580 }}>
                <Input
                  label="E-mail"
                  type="text"
                  name="email"
                  id="email"
                  placeholder="E-mail"
                  scheme={InputScheme.WHITE}
                  value={values.email}
                  error={touched.email && errors.email}
                  onChange={handleChange}
                />
              </FormBlock>
            </fieldset>
            <fieldset>
              <legend>User roles</legend>
              <p>
                Choose the user roles, you can assign multiple user roles to one
                user.
              </p>
              <FormBlock flatten style={{ maxWidth: 580 }}>
                <UserRoleDropdown
                  id="userRoles"
                  name="userRoles"
                  label="User roles"
                  width={580}
                  value={values.userRoles}
                  placeholder="Select user roles"
                  onChange={(values: UserRole[]) => {
                    setFieldValue(
                      'userRoles',
                      [].slice.call(values).map((option: UserRoleOption) => ({
                        id: option.value,
                        name: option.label,
                      })),
                    );
                  }}
                  error={touched.userRoles && errors.userRoles}
                  listSelected
                  multiple
                />
              </FormBlock>
            </fieldset>
            <fieldset>
              <legend>Locations</legend>
              <p>Select which locations this user has access to.</p>
              <FormBlock flatten style={{ maxWidth: 580 }}>
                <LocationDropdown
                  id="locations"
                  name="locations"
                  label="Locations"
                  width={580}
                  value={values.locations}
                  placeholder="Select locations"
                  onChange={(values: Location[]) => {
                    setFieldValue(
                      'locations',
                      [].slice.call(values).map((option: LocationOption) => ({
                        id: option.value,
                        name: option.label,
                      })),
                    );
                  }}
                  error={touched.locations && errors.locations}
                  listSelected
                  multiple
                  showAllLocations
                />
              </FormBlock>
            </fieldset>
          </PopupStickyMain>

          <PopupStickyFooter>
            <FormBlock hasInlineChildren flatten>
              <Button
                tag={ButtonTag.BUTTON}
                size={ButtonSize.MEDIUM}
                text="Cancel"
                scheme={ButtonScheme.LINK}
                hasShadow={false}
                handler={() => {
                  onCancel();
                }}
                disabled={isSubmitting}
              />
              <ButtonGroup>
                <SubmitButton canSave disabled={isSubmitting} />
              </ButtonGroup>
            </FormBlock>
          </PopupStickyFooter>
        </PopupStickyForm>
      )}
    </Formik>
  );
};

export default User;
