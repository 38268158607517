import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Button from 'components/button/Button';
import Container from 'components/container/Container';
import DeckEmpty from 'components/deck/DeckEmpty';
import MediaButton from 'components/media/button/MediaButton';
import Files from 'components/media/files/Files';
import MediaFilter from 'components/media/filter/MediaFilter';
import Folders from 'components/media/folders/Folders';
import FolderHeader from 'components/media/folders/folderHeader/FolderHeader';
import MediaUpload from 'components/media/upload/Upload';
import PageFilterContainer from 'components/page-filter-container/PageFilterContainer';

import { Paths } from 'constants/Routes.enum';
import UserRight from 'constants/UserRight.enum';

import { hasUserRights } from 'store/auth/hasUserRights';
import { RootState } from 'store/rootState';

import './Overview.scss';

const MediaOverview = () => {
  const history = useHistory();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const filter = useSelector((state: RootState) => state.media.filter);
  const folder_info = useSelector(
    (state: RootState) => state.media.folder_info,
  );
  const { customerOverride } = useSelector((state: RootState) => state.superAdmin)
  const hasMediaViewRights = dispatch(hasUserRights(UserRight.MEDIA_VIEW));
  const hasMediaEditRights = dispatch(hasUserRights(UserRight.MEDIA_EDIT));

  const hasFilter = !(!filter || (!filter.name && !filter.filetype));

  useEffect(() => {
    // return to media overview if already inside a folder when customer override changes
    if (customerOverride && folder_info && folder_info.customer_id !== Number(customerOverride)) {
      history.replace(Paths.MEDIA)
    }
    // TODO: Fix exhaustive-deps warning
    // eslint-disable-next-line
  }, [customerOverride, folder_info])

  if (!hasMediaViewRights) return (
    <DeckEmpty title="No view rights for media">
      <p>
        In case you should have view rights please contact your supervisor or send
        an email to{' '}
        <Button
          hasShadow={false}
          scheme="link"
          link={`mailto:${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
          text={`${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
          size="inline"
        />
      </p>
    </DeckEmpty>
  )

  return (
    <div className="media-page">
      <PageFilterContainer title="Media">
        <MediaFilter />
      </PageFilterContainer>

      <Container className="media-page__content">
        <Breadcrumbs renderCurrentFolder={hasFilter} />
        {(hasFilter || folder_info) && (
          <FolderHeader
            title="All media"
            buttonType="editFolder"
            dynamicHeaderTitle
            showOptions
          />
        )}

        {hasMediaEditRights && !hasFilter && (
          <MediaUpload
            folderId={folder_info?.id}
            size="medium"
          />
        )}

        {!hasFilter && (
          <>
            <FolderHeader title="Folders" buttonType="newFolder" />
            <Folders editable />
          </>
        )}

        <Files />

        {hasMediaEditRights && <MediaButton />}
      </Container>
    </div>
  );
};

export default MediaOverview;
