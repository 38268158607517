import React, { FC } from 'react';
import { ReactComponent as LogoFull } from 'assets/logo/logoFull.svg';
import { ReactComponent as LogoBrandMark } from 'assets/logo/logoBrandMark.svg';

import { LogoProps } from './Logo.types';
import './Logo.scss';

const Logo: FC<LogoProps> = ({ brandMark = false }) => {
  return brandMark ? <LogoBrandMark /> : <LogoFull className="logo-full" />;
};

export default Logo;
