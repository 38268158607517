import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ArrangementMediaItemsProps } from 'constants/types/arrangmentTypes';

import './ArrangementLayoutArea.scss';

const ArrangementLayoutArea = ({
  area,
  gridGap,
  interActive,
  spotRef,
  media,
  borderWidth,
  showNumbers,
  templateHeight,
}) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setActive(true);
        } else {
          setActive(false);
        }
      },
      {
        root: null,
        threshold: 0.8,
      },
    );

    if (spotRef?.current) {
      observer.observe(spotRef.current);
    }

    return () => {
      observer.disconnect()
    }
  }, [spotRef]);

  const areaClicked = (area, spotRef) => {
    if (!spotRef) return null;
    return spotRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const areaHeight =
    area.name === 'Background' ? area.height + borderWidth * 2 : area.height;
  const areaWidth =
    area.name === 'Background' ? area.width + borderWidth * 2 : area.width;
  const x = area.name === 'Background' ? area.x : area.x + gridGap;
  const y = area.name === 'Background' ? area.y : area.y + gridGap;
  const rectWidth =
    area.name === 'Background'
      ? area.width
      : area.width - gridGap - borderWidth * 2;
  const rectHeight =
    area.name === 'Background'
      ? area.height
      : area.height - gridGap - borderWidth * 2;

  return (
    area.height > 0 &&
    area.width > 0 && (
      <>
        <svg
          height={areaHeight}
          width={areaWidth}
          key={`${area.x}x${area.y}-${area.width}x${area.height}-${area.area_number}`}
          x={x}
          y={y}
          className={classNames('arrangement-layout-area__main', {
            'arrangement-layout-area__main--interactive': interActive,
          })}
        >
          {showNumbers && area.name !== 'Background' && (
            <text
              x={areaWidth / 2 - borderWidth}
              y={areaHeight / 2 - borderWidth}
              className={classNames('arrangement-layout-area__text', {
                'arrangement-layout-area__text--interactive': interActive,
                'arrangement-layout-area__text--interactive-active':
                  interActive && active,
                'arrangement-layout-area__text--interactive-inactive':
                  interActive && !active,
              })}
              fontSize={templateHeight / 8}
              fill="currentColor"
              alignmentBaseline="middle"
              textAnchor="middle"
              onClick={() => areaClicked(area, spotRef)}
            >
              {area.area_number}
            </text>
          )}
          <rect
            className={classNames({
              'arrangement-layout-area--background': area.name === 'Background',
              'arrangement-layout-area--background--interactive':
                interActive && area.name === 'Background',
              'arrangement-layout-area--rect': area.name !== 'Background',
              'arrangement-layout-area--empty':
                (interActive && !Array.isArray(media?.items)) ||
                (interActive && media?.items.length === 0),
              'arrangement-layout-area--interactive': interActive,
              'arrangement-layout-area--interactive-active':
                interActive && active,
              'arrangement-layout-area--interactive-inactive':
                interActive && !active,
            })}
            rx={gridGap === 0 ? 0 : 50}
            ry={gridGap === 0 ? 0 : 50}
            x={borderWidth}
            y={borderWidth}
            height={rectHeight < 0 ? 0 : rectHeight}
            width={rectWidth < 0 ? 0 : rectWidth}
            onClick={() => areaClicked(area, spotRef)}
          />
        </svg>
      </>
    )
  );
};

ArrangementLayoutArea.propTypes = {
  area: PropTypes.shape({
    name: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    area_number: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  gridGap: PropTypes.number.isRequired,
  interActive: PropTypes.bool,
  spotRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  media: ArrangementMediaItemsProps,
  borderWidth: PropTypes.number.isRequired,
  showNumbers: PropTypes.bool,
  templateHeight: PropTypes.number.isRequired,
};

ArrangementLayoutArea.defaultProps = {
  interActive: false,
  spotRef: null,
  media: { items: [] },
  showNumbers: false,
};

export default ArrangementLayoutArea;
