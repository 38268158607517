import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/button/Button';
import ButtonGroup from 'components/button/ButtonGroup';
import FormBlock from 'components/form/block/FormBlock';
import LabelValueList from 'components/list/labelValueList/LabelValueList';
import Heading from 'components/typography/heading/Heading';

import UserRight from 'constants/UserRight.enum';

import { hasUserRights } from 'store/auth/hasUserRights';
import {
  openEditDevicesAction,
  unAssignDeviceAction,
} from 'store/devices/devicesActions';
import { infoBarHide, setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import { popupActionClear } from 'store/popup/popupActions';
import { RootState } from 'store/rootState';

import { DeviceDetailProps } from './DeviceDetail.types';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

const DeviceDetail = ({ id, deviceValues, isInPool }: DeviceDetailProps) => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const hasDevicesEditRights = dispatch(hasUserRights(UserRight.SIGNS_EDIT));
  const infoBar = useSelector((state: RootState) => state.info.bar);

  return (
    <>
      <Heading level={2}>Device details</Heading>
      <fieldset>
        <LabelValueList
          values={deviceValues}
          listId={id}
          flatten
          loading={!deviceValues}
          loadingItemLength={5}
        />
      </fieldset>
      <FormBlock hasInlineChildren isEnd>
        {hasDevicesEditRights && (
          <Button
            tag="button"
            type="button"
            size="medium"
            text="Remove device from group"
            scheme="link"
            hasShadow={false}
            disabled={!isInPool || !hasDevicesEditRights}
            handler={() => {
              if (isInPool && hasDevicesEditRights) {
                dispatch(
                  setInfoBar({
                    message:
                      'Are you sure you want to remove the device from this group?',
                    action: [
                      {
                        text: 'Yes, Delete',
                        type: 'button',
                        color: 'blue',
                        handle: () => dispatch(unAssignDeviceAction(id)),
                      },
                      {
                        text: 'Cancel',
                        type: 'link',
                        handle: () => dispatch(infoBarHide()),
                      },
                    ],
                    state: InfoBarState.ERROR,
                  }),
                );
              }
            }}
          />
        )}

        <ButtonGroup>
          {hasDevicesEditRights && (
            <Button
              tag="a"
              size="medium"
              scheme="link"
              text="Edit"
              hasShadow={false}
              handler={() => {
                dispatch(openEditDevicesAction(id));
              }}
            />
          )}
          <Button
            tag="button"
            type="submit"
            size="medium"
            text="Close"
            handler={() => {
              dispatch(popupActionClear());
              if (infoBar.show) {
                dispatch(infoBarHide());
              }
            }}
          />
        </ButtonGroup>
      </FormBlock>
    </>
  );
};

export default DeviceDetail;
