import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import Logo from 'components/logo/Logo';
import FormMessage from 'components/form/formMessage/FormMessage';
import PasswordValidator from 'components/passwordValidator/PasswordValidator';
import { BigForm, BigFormField } from 'components/form/bigForm/BigForm';

import { resetPasswordAction } from 'store/auth/resetPasswordActions';

import RegexOptions from 'constants/RegexOptions';
import { Paths } from 'constants/Routes.enum';
import { InitialValues } from './ResetPassword.types';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();

  const initialValues = {
    password: '',
    passwordConfirm: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is a required field')
      .matches(
        RegexOptions.PASSWORD_VALIDATION,
        'Password does not meet required formatting.',
      ),
    passwordConfirm: Yup.string()
      .required('Password confirmation is a required field')
      .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
  });

  const onSubmit = (values: InitialValues) => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('reset_password_token');

    setSubmitting(true);
    dispatch(
      resetPasswordAction(
        token,
        values.password,
        values.passwordConfirm,
        history,
      ),
    );
  };

  return (
    <>
      <Logo />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, handleSubmit, touched }) => (
          <>
            <BigForm
              handleSubmit={handleSubmit}
              submitButtonText="Submit new password"
              isSubmitting={isSubmitting}
              fields={
                <>
                  <BigFormField>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="New password"
                      size="large"
                      scheme="login"
                      aria-label="New password"
                      value={values.password}
                      error={touched.password && errors.password ? true : null}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                  </BigFormField>
                  <BigFormField>
                    <Input
                      type="password"
                      name="passwordConfirm"
                      id="passwordConfirm"
                      placeholder="Repeat new password"
                      size="large"
                      scheme="login"
                      aria-label="Repeat new password"
                      value={values.passwordConfirm}
                      error={
                        touched.passwordConfirm && errors.passwordConfirm
                          ? true
                          : null
                      }
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                  </BigFormField>
                </>
              }
              aboveSubmitButtonElements={
                <PasswordValidator input={values.password} />
              }
              errorMessages={
                <>
                  <FormMessage name="password" type="formikErrorMessage" />
                  <FormMessage
                    name="passwordConfirm"
                    type="formikErrorMessage"
                  />
                </>
              }
              footerText={
                <Button
                  link={Paths.LOGIN}
                  scheme="link"
                  text="Back to log in"
                  size="inline"
                  hasShadow={false}
                />
              }
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default ResetPassword;
