import React from 'react';

import { SkeletonParagraphProps } from './SkeletonParagraph.types';

const SkeletonParagraph: React.FC<SkeletonParagraphProps> = ({ settings }) => {
  return (
    <div style={{ marginTop: 20, marginBottom: 25 }}>
      <div
        className="skeleton"
        style={{
          ...settings,
          width: '100%',
        }}
      />
      <div
        className="skeleton"
        style={{
          ...settings,
          width: '100%',
        }}
      />
      <div
        className="skeleton"
        style={{
          ...settings,
          width: '50%',
        }}
      />
    </div>
  );
};

export default SkeletonParagraph;
