import { Api } from 'constants/Routes.enum';
import { reverse } from 'named-urls';

import fetchData from 'store/fetchData';
import { setInfoBar } from 'store/info/infoActions';
import { popupActionClear, popupActionSet } from 'store/popup/popupActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import PopupTypes from 'constants/PopupTypes.enum';

export const LocationsActionTypes = {
  LOCATIONS_REQUESTED: '@@locations/requested',
  LOCATIONS_SUCCEEDED: '@@locations/success',
  LOCATIONS_FAILED: '@@locations/error',
  LOCATIONS_CLEARED: '@@locations/cleared',
  LOCATION_ADDED: '@@location/added',
  LOCATION_UPDATED: '@@location/updated',
};

export const locationsRequested = () => ({
  type: LocationsActionTypes.LOCATIONS_REQUESTED,
});

export const locationsSucceeded = locations => ({
  type: LocationsActionTypes.LOCATIONS_SUCCEEDED,
  payload: locations,
});

export const locationsFailed = () => ({
  type: LocationsActionTypes.LOCATIONS_FAILED,
});

export const locationAdded = location => ({
  type: LocationsActionTypes.LOCATION_ADDED,
  payload: location,
});

export const locationsCleared = () => ({
  type: LocationsActionTypes.LOCATIONS_CLEARED,
});

export const locationUpdated = location => ({
  type: LocationsActionTypes.LOCATION_UPDATED,
  payload: location,
});

export const fetchLocationsAction = (showAllLocations = false) => dispatch => {
  dispatch(locationsRequested());

  const promise = dispatch(
    fetchData(showAllLocations ? Api.LOCATIONS_OVERVIEW : Api.LOCATIONS),
  );

  promise
    .then(json => json.json())
    .then(result => {
      if (result.locations) {
        dispatch(locationsSucceeded(result.locations));
      } else {
        dispatch(locationsFailed());
      }
    })
    .catch(() => {
      dispatch(locationsFailed());
    });
};

export const openLocationAction = id => async dispatch => {
  const promise = dispatch(fetchData(reverse(Api.LOCATIONS_DETAIL, { id })));

  promise
    .then(json => json.json())
    .then(result => {
      const { name, timezone_info, users } = result.location;

      dispatch(
        popupActionSet(PopupTypes.MANAGEMENT_LOCATION, {
          id,
          name,
          timezone: timezone_info,
          users: users.map(user => {
            return {
              id: user.id,
              name: `${user.firstname} ${user.lastname}`,
            };
          }),
        }),
      );
    })
    .catch(err => {
      console.error(err);
      dispatch(
        setInfoBar({
          message: 'Failed to find location',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const addLocationAction = ({
  locationData,
  id,
  setSubmitting,
}) => async dispatch => {
  const isEditing = !!id;

  const promise = dispatch(
    fetchData(
      isEditing ? reverse(Api.LOCATIONS_PATCH, { id }) : Api.LOCATIONS_POST,
      {
        method: isEditing ? 'PATCH' : 'POST',
        body: locationData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message, location } = result;

      if (location) {
        dispatch(popupActionClear());
        dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));

        if (isEditing) {
          // update location state
          dispatch(locationUpdated(location));
        } else {
          // add new location to state
          dispatch(locationAdded(location));
        }
      } else {
        dispatch(
          setInfoBar({ message, timeout: 0, state: InfoBarState.ERROR }),
        );
      }
    })
    .catch(err => {
      console.error(err);
      dispatch(
        setInfoBar({
          message: id
            ? 'Failed to update location'
            : 'Failed to add new location',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    })
    .finally(() => {
      setSubmitting(false);
    });
};
