import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import Header from 'components/header/Header';

import { RootState } from 'store/rootState';
import './Private.scss';

const PrivateBody = ({ children }: PropsWithChildren<{}>) => {
  const { error } = useSelector((state: RootState) => state.info);

  return (
    <>
      <Header />
      {!error && <div className="private-body">{children}</div>}
    </>
  );
};

export default PrivateBody;
