import React, { FC } from 'react';

import PopupContext from 'components/popup-context/PopupContext';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';

import './AddButtonWithContextMenu.scss';
import { AddButtonWithContextMenuProps } from './AddButtonWithContextMenu.types';

const AddButtonWithContextMenu: FC<AddButtonWithContextMenuProps> = ({
  children,
  buttonHoverText,
  dataCy,
}) => {
  return (
    <PopupContext
      className="add-button-with-context-menu"
      contentClassName="add-button-with-context-menu__content"
      button={
        <div className="detail-button">
          <Button
            data-cy={dataCy}
            shapeEdges="rounded"
            text={<Icon name="plus" size="medium" />}
            hoverText={buttonHoverText}
            hoverTextInverse
          />
        </div>
      }
    >
      {children}
    </PopupContext>
  );
};

export default AddButtonWithContextMenu;
