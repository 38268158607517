import { reverse } from 'named-urls';

import fetchData from 'store/fetchData';
import { Api } from 'constants/Routes.enum';
import TwoFactorStatus from 'constants/TwoFactorStatus.enum';

export const TwoFactorActionTypes = {
  TWOFACTOR_SET: '@@twofactor/set',
  TWOFACTOR_UNSET: '@@twofactor/unset',
  TWOFACTOR_CHECK: '@@twofactor/check',
  TWOFACTOR_RESEND_EMAIL_PROCESSING: '@@twofactor/resend-email/processing',
  TWOFACTOR_RESEND_EMAIL_SUCCESS: '@@twofactor/resend-email/success',
  TWOFACTOR_RESEND_EMAIL_FAILED: '@@twofactor/resend-email/failed',
};

export const twoFactorResendEmailSuccess = () => ({
  type: TwoFactorActionTypes.TWOFACTOR_RESEND_EMAIL_SUCCESS,
});

export const twoFactorResendEmailFailed = () => ({
  type: TwoFactorActionTypes.TWOFACTOR_RESEND_EMAIL_FAILED,
});

export const twoFactorResendEmailProcessing = () => ({
  type: TwoFactorActionTypes.TWOFACTOR_RESEND_EMAIL_PROCESSING,
});

export const twoFactorResendEmail = () => async (dispatch, getState) => {
  const userId = getState().auth.user.id;
  const authToken = getState().auth.auth_token;

  if (!userId || !authToken) return;

  dispatch(twoFactorResendEmailProcessing());

  const promise = dispatch(
    fetchData(Api.TWOFACTOR_RESEND_EMAIL, {
      method: 'POST',
    }),
  );

  promise
    .then(json => json.json())
    .then(res => {
      if (res && res.status === TwoFactorStatus.NOT_CONFIRMED) {
        dispatch(twoFactorResendEmailSuccess());
      } else {
        dispatch(twoFactorResendEmailFailed());
      }
    });
};

export const twoFactorSet = () => ({
  type: TwoFactorActionTypes.TWOFACTOR_SET,
});

export const twoFactorUnset = () => ({
  type: TwoFactorActionTypes.TWOFACTOR_UNSET,
});

export const twoFactorCheck = sendEmailOnExpire => async (
  dispatch,
  getState,
) => {
  const userId = getState().auth.user.id;
  const authToken = getState().auth.auth_token;

  if (!userId || !authToken) return;

  const response = dispatch(
    fetchData(reverse(Api.TWOFACTOR_STATUS, { id: userId }), {}, false),
  );

  response
    .then(json => json.json())
    .then(res => {
      if (res && res.status === TwoFactorStatus.CONFIRMED) {
        dispatch(twoFactorSet());
      } else if (res && res.status === TwoFactorStatus.EXPIRED) {
        dispatch(twoFactorUnset());

        if (sendEmailOnExpire) {
          dispatch(twoFactorResendEmail());
        }
      }
    });
};
