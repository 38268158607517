import ApprovalStates from 'constants/ApprovalStates.enum';
import { EventsActionTypes } from './eventsActions';

export const eventsInitialState = {
  request: false,
  events: [],
  jumpToDate: null,
};

const eventsReducer = (state = eventsInitialState, action) => {
  switch (action.type) {
    case EventsActionTypes.EVENTS_REQUESTED:
      return { ...eventsInitialState, request: true };
    case EventsActionTypes.EVENTS_SUCCEEDED:
      return { events: action.payload, request: false };
    case EventsActionTypes.EVENTS_FAILED:
    case EventsActionTypes.EVENTS_CLEARED:
      return eventsInitialState;
    case EventsActionTypes.EVENTS_DELETED:
      return {
        ...state,
        events: state.events.filter(event => event.id !== action.payload),
      };
    case EventsActionTypes.EVENTS_ADDED:
      return {
        ...state,
        jumpToDate: action.payload.start_at,
        events: [action.payload, ...state.events],
      };
    case EventsActionTypes.EVENTS_APPROVED:
      return {
        ...state,
        events: state.events.map(event => {
          if (event.id === action.payload.id) {
            return {
              ...event,
              ...action.payload,
              pending_approval:
                action.payload.approval_state !== ApprovalStates.APPROVED,
            };
          }

          return event;
        }),
      };
    case EventsActionTypes.EVENTS_ACTIVATED:
      return {
        ...state,
        events: state.events.map(event => {
          if (event.id === action.payload.id) {
            return {
              ...event,
              active: action.payload.active,
            };
          }

          return event;
        }),
      };
    default:
      return { ...state };
  }
};

export default eventsReducer;
