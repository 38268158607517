import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { reverse } from 'named-urls';

import Heading from 'components/typography/heading/Heading';
import Text from 'components/typography/text/Text';
import TextManipulation from 'components/textManipulation/TextManipulation';
import { TextManipulationTag } from 'components/textManipulation/TextManipulation.enum';

import { popupActionSet } from 'store/popup/popupActions';
import { setInfoBar, setInfoBarShow } from 'store/info/infoActions';
import { InfoBarActionType, InfoBarState } from 'store/info/infoActions.enum';
import fetchData from 'store/fetchData';

import PopupTypes from 'constants/PopupTypes.enum';
import { Api } from 'constants/Routes.enum';

import { NotificationLocation } from './Notifications.enum';
import { Notification, NotificationProps } from './Notifications.types';
import { ButtonColor } from '../button/Button.enum';
import { TextSize, TextTag } from '../typography/text/Text.enum';

const Notifications = ({ location }: NotificationProps) => {
  const dispatch = useDispatch();
  const moreInfoPopup = (data: string) => {
    const children = (
      <TextManipulation tag={TextManipulationTag.DIV} innerHTML={data} />
    );

    dispatch(popupActionSet(PopupTypes.INFO, children));
  };

  const notificationsStorage = localStorage.getItem('notifications');
  const parsedNotificationsStorage =
    notificationsStorage && JSON.parse(notificationsStorage);
  const [showNotification, setShowNotification] = useState(false);

  const closeMoreInfoPopup = (data: Notification) => {
    const parsedNotification =
      parsedNotificationsStorage !== null &&
        Array.isArray(Object.values(parsedNotificationsStorage))
        ? Object.values(parsedNotificationsStorage)
        : parsedNotificationsStorage;

    const notificationsFromStorage = parsedNotification.map(
      (notification: Notification) => {
        if (notification.updated_at === data.updated_at) {
          return {
            ...notification,
            show: false,
          };
        }
        return notification;
      },
    );

    localStorage.setItem(
      'notifications',
      JSON.stringify({ ...notificationsFromStorage }),
    );
    dispatch(setInfoBarShow({ show: false }));
  };

  const notificationMessage = (data: Notification) => {
    const { more_info, event } = data;

    dispatch(
      setInfoBar({
        message: (
          <>
            {data.title && (
              <Heading level={4} stylingLevel={4}>
                {data.title}
              </Heading>
            )}
            <Text size={TextSize.NORMAL} tag={TextTag.P} flatten noTop>
              {data.body}
            </Text>
          </>
        ),
        action: more_info
          ? [
            {
              text: 'Close',
              handle: () => closeMoreInfoPopup(data),
              type: InfoBarActionType.LINK,
            },
            {
              text: 'More information',
              handle: () => moreInfoPopup(more_info),
              color: ButtonColor.BLUE,
            },
          ]
          : [
            {
              text: 'Close',
              handle: () => closeMoreInfoPopup(data),
              type: InfoBarActionType.LINK,
            },
          ],
        state:
          event === 'maintenance'
            ? InfoBarState.MAINTENANCE
            : InfoBarState.PLANNED_MAINTENANCE,
      }),
    );
  };

  useEffect(() => {
    const promise = dispatch(fetchData(reverse(Api.NOTIFICATIONS)));

    promise // @ts-ignore
      .then(json => json.json()) // @ts-ignore
      .then(result => {
        const { notifications } = result;

        const setShowStatus = (
          storageNotification: Notification,
          apiNotification: Notification,
        ) => {
          switch (true) {
            case storageNotification === null ||
              storageNotification === undefined:
              return true;
            case storageNotification &&
              storageNotification.updated_at !== apiNotification.updated_at:
              return true;
            default:
              return storageNotification?.show;
          }
        };

        const compareNotifications = (
          notifications: Notification[],
          parsedNotificationsStorage: Notification[],
        ) => {
          const comparedNotifications = notifications.map(
            (notification, index: number) => {
              const parsedNotification =
                parsedNotificationsStorage !== null &&
                  Array.isArray(Object.values(parsedNotificationsStorage))
                  ? Object.values(parsedNotificationsStorage)[index]
                  : parsedNotificationsStorage;

              return {
                // @ts-ignore
                show: setShowStatus(parsedNotification, notification),
                ...notification,
              };
            },
          );

          // update localstorage
          localStorage.setItem(
            'notifications',
            JSON.stringify({
              ...comparedNotifications,
            }),
          );

          return comparedNotifications;
        };

        const comparedNotifications = compareNotifications(
          notifications,
          parsedNotificationsStorage,
        );

        const getLatestNotification = (
          locationNotifications: Notification[],
          location: NotificationLocation,
        ): Notification | undefined => {
          if (locationNotifications.length === 0) return undefined;

          return locationNotifications
            .filter(
              locationNotification =>
                locationNotification.location === location,
            )
            .reduce((a, b) => {
              return new Date(a.updated_at) > new Date(b.updated_at) ? a : b;
            });
        };

        const latestNotification = getLatestNotification(
          comparedNotifications,
          location,
        );

        setShowNotification(
          latestNotification && latestNotification.show
            ? latestNotification.show
            : false,
        );

        if (showNotification && latestNotification) {
          return notificationMessage(latestNotification);
        }
        return null;
      })
      .catch((error: string) => {
        console.error(error);
      });
    // TODO: fix exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, showNotification]);
};

export default Notifications;
