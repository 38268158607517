import React from 'react';
import classNames from 'classnames';

import { CheckboxProps } from './Checkbox.types';
import { CheckboxScheme, CheckboxLabelScheme } from './Checkbox.enum';

import './Checkbox.scss';

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  id,
  name,
  disabled,
  onChange,
  scheme = CheckboxScheme.BLUE,
  labelScheme = CheckboxLabelScheme.BLUE,
}) => {
  return (
    <label
      htmlFor={id}
      className={classNames('form__checkbox', {
        'form__checkbox--disabled': disabled,
        [`form__checkbox--${scheme}`]: scheme,
      })}
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={() => onChange(name, !checked)}
      />
      <span className="form__checkbox__check" />
      <span
        className={classNames('form__checkbox__label', {
          [`form__checkbox__label--${labelScheme}`]: labelScheme,
        })}
      >
        {label}
      </span>
    </label>
  );
};

export default Checkbox;
