export enum OverviewListItemStatus {
  DEFAULT = 'default',
  PENDING = 'pending',
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export enum OverviewListItemTag {
  BUTTON = 'button',
  DIV = 'div',
}