import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reverse } from 'named-urls';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import Icon from 'components/icon/Icon';
import Button from 'components/button/Button';
import { hasUserRights } from 'store/auth/hasUserRights';
import fetchData from 'store/fetchData';
import UserRights from 'constants/UserRight.enum';
import { Api } from 'constants/Routes.enum';

import { popupArrangementAreaMediaItemDeleted } from 'store/popup/popupActions';

import './ArrangementMediaItem.scss';

const ArrangementMediaItem = ({
  mediaItem,
  index,
  pending_approval,
  spot,
  setMediaItems,
  isDragDisabled,
}) => {
  const dispatch = useDispatch();
  const authToken = useSelector(state => state.auth.auth_token);
  const hasArrangementEditRights = dispatch(
    hasUserRights(UserRights.ARRANGEMENTS_EDIT),
  );
  const [isAdded, setIsAdded] = useState(false);

  const deleteMediaItem = (id, spot) => {
    dispatch(
      fetchData(reverse(Api.ARRANGEMENT_MEDIA_ITEMS_DELETE, { id }), {
        method: 'DELETE',
        headers: {
          Authorization: authToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    );

    setMediaItems(prevItems => {
      dispatch(
        popupArrangementAreaMediaItemDeleted(
          {
            ...prevItems,
            [spot]: {
              ...prevItems[spot],
              items: prevItems[spot].items.filter(i => i.id !== id),
            },
          },
          id,
        ),
      );

      return {
        ...prevItems,
        [spot]: {
          ...prevItems[spot],
          items: prevItems[spot].items.filter(i => i.id !== id),
        },
      };
    });
  };

  useEffect(() => {
    setIsAdded(!mediaItem.active);
  }, [mediaItem]);

  return (
    <Draggable
      draggableId={mediaItem.id.toString()}
      index={index}
      isDragDisabled={isDragDisabled || pending_approval}
    >
      {(provided, snapshot) => (
        <CSSTransition
          in={isAdded}
          timeout={2500}
          classNames="arrangement-media-item--added"
        >
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            data-cy="arrangementMediaItem"
            className={classNames('arrangement-media-item', {
              'arrangement-media-item--disabled':
                (pending_approval &&
                  !mediaItem.pending_approval &&
                  !mediaItem.pending_removal) ||
                !hasArrangementEditRights,
              'arrangement-media-item--changed':
                pending_approval && mediaItem.pending_approval,
              'arrangement-media-item--deleted': mediaItem.pending_removal,
              'arrangement-media-item--is-dragging': snapshot.isDragging,
            })}
          >
            {!isDragDisabled && !pending_approval && (
              <div className="arrangement-media-item__drag-and-drop">
                <Icon name="drag" size="x-small" />
              </div>
            )}
            <div
              data-cy="arrangementMediaItemName"
              className={classNames('arrangement-media-item__name', {
                'arrangement-media-item__name--drag-and-droppable':
                  !isDragDisabled && !pending_approval,
              })}
            >
              {mediaItem.name}
            </div>
            {pending_approval || mediaItem.pending_removal ? (
              <div className="arrangement-media-item__delete">
                <Icon name="cross" size="x-small" />
              </div>
            ) : (
              <Button
                text={<Icon name="cross" size="x-small" />}
                hasShadow={false}
                scheme="link"
                size="inline"
                className="arrangement-media-item__delete"
                onClick={() => deleteMediaItem(mediaItem.id, spot.area_number)}
                disabled={!hasArrangementEditRights}
              />
            )}
          </div>
        </CSSTransition>
      )}
    </Draggable>
  );
};

ArrangementMediaItem.propTypes = {
  mediaItem: PropTypes.shape({
    active: PropTypes.bool,
    approval_state: PropTypes.oneOf([
      'pending_position',
      'pending_approval',
      'pending_removal',
      'approved',
    ]),
    area_number: PropTypes.number,
    arrangement_id: PropTypes.number,
    arrangement_media_item_id: PropTypes.number,
    id: PropTypes.number,
    muted: PropTypes.string,
    pending_approval: PropTypes.bool,
    pending_removal: PropTypes.bool,
    position: PropTypes.number,
  }),
  index: PropTypes.number,
  pending_approval: PropTypes.bool,
  spot: PropTypes.shape({
    area_number: PropTypes.number,
    name: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  setMediaItems: PropTypes.func,
  isDragDisabled: PropTypes.bool,
};

ArrangementMediaItem.defaultProps = {
  mediaItem: null,
  index: null,
  pending_approval: false,
  spot: null,
  setMediaItems: null,
  isDragDisabled: false,
};

export default ArrangementMediaItem;
