import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Api } from 'constants/Routes.enum';
import fetchData from 'store/fetchData';
import Dropdown from 'components/form/dropdown/Dropdown';

const DevicesDropdown = ({ poolId, ...props }) => {
  const dispatch = useDispatch();
  const [devices, setDevices] = useState([]);
  const [loadingDevices, setLoadingDevices] = useState(true);
  const pool = useSelector(state => state.pool);

  useEffect(() => {
    setLoadingDevices(true);

    const promise = dispatch(fetchData(`${Api.DEVICES}?unassigned=true`));

    promise
      .then(json => json.json())
      .then(({ signs }) => {
        const devices = signs.map(sign => {
          return {
            label: sign.name,
            value: sign.id,
          };
        });
        setDevices(devices);
      })
      .catch(err => {
        console.error(err);
        setDevices([]);
      })
      .finally(() => {
        setLoadingDevices(false);
      });

    return () => {
      setLoadingDevices(false);
      setDevices([]);
    };
  }, [dispatch, pool.id, poolId]);

  return (
    <Dropdown
      {...props}
      values={devices}
      hasEmptyOption={false}
      isLoading={loadingDevices}
    />
  );
};

export default DevicesDropdown;
