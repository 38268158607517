import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Logo from 'components/logo/Logo';
import Heading from 'components/typography/heading/Heading';
import Text from 'components/typography/text/Text';
import Icon from 'components/icon/Icon';
import Button from 'components/button/Button';

import PopupTypes from 'constants/PopupTypes.enum';
import { resendEmailValues, RootState } from 'store/rootState';

import { popupActionSet } from 'store/popup/popupActions';
import {
  twoFactorResendEmail,
  twoFactorCheck,
} from 'store/auth/twoFactorActions';

import './TwoFactor.scss';

const TwoFactor = () => {
  const dispatch = useDispatch();
  const email = useSelector((state: RootState) => state.auth.user.email);
  const resendEmail = useSelector(
    (state: RootState) => state.auth.two_factor_resend_email,
  );

  useEffect(() => {
    dispatch(twoFactorCheck(true));

    const interval = setInterval(() => {
      dispatch(twoFactorCheck(false));
    }, 5000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const authenticationDoesNotWork = () => {
    const children = (
      <>
        <h2>Contact your supervisor</h2>
        <p>
          If you are having trouble receiving the two-factor authentication
          email try the following steps.
        </p>
        <ul>
          <li>Wait five minutes and check your email again</li>
          <li>Check your spam folder</li>
        </ul>
        <p>
          If you still have trouble receiving the two-factor authentication
          email or if you are experiencing any other problems send an email to{' '}
          <Button
            hasShadow={false}
            scheme="link"
            link={`mailto:${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
            text={`${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
            size="inline"
          />
        </p>
      </>
    );

    dispatch(popupActionSet(PopupTypes.INFO, children));
  };

  const resendingEmail = (resendEmail: resendEmailValues) => {
    let resendEmailContents;
    switch (resendEmail) {
      case 'processing':
        resendEmailContents = 'Resending e-mail...';
        break;
      case 'success':
        resendEmailContents = 'E-mail has been sent again';
        break;
      case 'failed':
        resendEmailContents = (
          <>
            Resending e-mail failed.
            <Button
              onClick={() => dispatch(twoFactorResendEmail())}
              scheme="link"
              text="Try again"
              size="inline"
              hasShadow={false}
            />
          </>
        );
        break;
      default:
        resendEmailContents = (
          <>
            No e-mail received yet?{' '}
            <Button
              onClick={() => dispatch(twoFactorResendEmail())}
              scheme="link"
              text="Click here to resend confirmation e-mail."
              size="inline"
              hasShadow={false}
            />
          </>
        );
    }

    return (
      <Text size="small" color="grey-dark" flatten>
        {resendEmailContents}
      </Text>
    );
  };

  return (
    <>
      <Logo />
      <div className="two-factor-page__fields-container">
        <Heading level={1} stylingLevel={2}>
          Two step authentication
        </Heading>
        <Icon
          name="twoFactorAuthentication"
          className="two-factor-page__icon"
          block={false}
        />
        <Text size="small">Email sent to: {email}</Text>
        <Text size="small" color="grey-dark" flatten>
          `An e-mail with a confirmation link has been sent. Use it to log in to
          ${process.env.REACT_APP_SITE_TITLE}.`
        </Text>
        {resendingEmail(resendEmail)}
      </div>
      <Button
        tag="button"
        size="large"
        className="two-factor-page__login-button"
        text="Waiting for authentication…"
        block
      />
      <Text size="small" color="grey-dark" flatten>
        Two step authentication doesn’t work.{' '}
        <Button
          onClick={() => authenticationDoesNotWork()}
          scheme="link"
          text="What should I do?"
          size="inline"
          hasShadow={false}
        />
      </Text>
    </>
  );
};

export default TwoFactor;
