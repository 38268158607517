import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import equal from 'fast-deep-equal';

import { RootState } from 'store/rootState';
import { popupActionClear } from 'store/popup/popupActions';
import { infoBarHide, setInfoBar, setInfoBarShow } from 'store/info/infoActions';
import {
  addUserRoleAction,
  deleteUserRoleAction,
} from 'store/userRoles/userRolesActions';
import {
  PopupStickyFooter,
  PopupStickyForm,
  PopupStickyHeader,
  PopupStickyMain,
} from 'components/popup/Popup';
import Icon from 'components/icon/Icon';
import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import { InputFontSize } from 'components/form/input/Input.enum';
import Button from 'components/button/Button';
import Text from 'components/typography/text/Text';
import { TextSize, TextTag } from 'components/typography/text/Text.enum';
import ButtonGroup from 'components/button/ButtonGroup';
import SubmitButton from 'components/form/submit-button/SubmitButton';
import UserDropdown from 'components/user/dropdown/UserDropdown';

import UserRight from 'constants/UserRight.enum';

import { InfoBarState } from 'store/info/infoActions.enum';
import {
  UserRightConnection,
  userRightConnections,
} from './constants/UserRoleRights';
import { User, UserOption, UserRoleProps } from './UserRole.types';
import UserRoleRights from './UserRoleRights';

const UserRole: FC<UserRoleProps> = ({ id, name, rights, isAdmin, users }) => {
  const dispatch = useDispatch();

  const rightConnections: UserRightConnection = useMemo(() => {
    return userRightConnections;
  }, []);

  const infoBar = useSelector((state: RootState) => state.info.bar);
  const [isSubmitting, setSubmitting] = useState(false);
  const { customerOverride } = useSelector((state: RootState) => state.superAdmin);

  const [initialForm, setInitialForm] = useState({
    name: name || '',
    rights: rights || [UserRight.POOLS_VIEW],
    isAdmin,
    users,
  });

  useEffect(() => {
    setInitialForm({
      name,
      rights,
      isAdmin,
      users,
    });
  }, [id, name, rights, isAdmin, users]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is a required field'),
  });

  const onCancel = () => {
    dispatch(popupActionClear());
    if (infoBar.show) {
      dispatch(setInfoBarShow({ show: false }));
    }
  };

  const onSubmit = (values: UserRoleProps) => {
    if (equal(values, initialForm)) {
      dispatch(popupActionClear());
      dispatch(setInfoBarShow({ show: false }));
      return;
    }

    setSubmitting(true);

    const userRoleData = new FormData();
    userRoleData.append('user_group[name]', values.name);

    if (values.rights && values.rights.length > 0) {
      values.rights.forEach(right => {
        userRoleData.append('user_group[rights][]', right.toString());
      });
    } else {
      userRoleData.append('user_group[rights][]', '');
    }

    if (values.rights.includes(UserRight.APPROVAL)) {
      userRoleData.append('user_group[admin]', 'true');
    } else {
      userRoleData.append('user_group[admin]', 'false');
    }

    if (values.users && values.users.length > 0) {
      values.users.forEach(user => {
        userRoleData.append('user_group[user_ids][]', user.id.toString());
      });
    } else {
      userRoleData.append('user_group[user_ids][]', '');
    }

    if (customerOverride) {
      userRoleData.append('user_group[customer_id]', customerOverride);
    }

    dispatch(addUserRoleAction({ userRoleData, id, setSubmitting }));
  };

  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: initialForm,
    validationSchema,
    onSubmit,
  });

  const handleRightsChange = useCallback(
    (value: UserRight) => {
      let hasValue = values.rights.indexOf(value) >= 0;

      if (value === UserRight.APPROVAL) {
        hasValue = values.isAdmin;
        setFieldValue('isAdmin', !values.isAdmin);
      }
      let valuesToChange: UserRight[] = [value];

      const connection = rightConnections[value];
      if (!hasValue) {
        if (connection?.enables) {
          valuesToChange = [value, ...connection.enables];
        }
      } else if (connection?.disables) {
        valuesToChange = [value, ...connection.disables];
      }

      setFieldValue(
        'rights',
        hasValue
          ? values.rights.filter(right => valuesToChange.indexOf(right) < 0)
          : [...values.rights, ...valuesToChange],
      );
    },
    [values, setFieldValue, rightConnections],
  );

  return (
    <PopupStickyForm onSubmit={handleSubmit}>
      <PopupStickyHeader>
        <fieldset className="fieldset--flatten">
          <FormBlock flatten>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Enter role name here"
              value={values.name}
              error={touched.name && errors.name}
              onChange={handleChange}
              fontSize={InputFontSize.LARGE}
              suffix={<Icon name="edit" />}
              disabled={isSubmitting}
            />
          </FormBlock>
        </fieldset>
      </PopupStickyHeader>

      <PopupStickyMain>
        <fieldset>
          <legend>Which rights does this role have</legend>
          <Text size={TextSize.NORMAL} tag={TextTag.P}>
            Mark the rights for this role. If a role is able to edit, viewing is
            automatically enabled as well.
          </Text>
          <UserRoleRights
            handleRightsChange={handleRightsChange}
            isAdmin={values.isAdmin}
            rightsValue={values.rights}
          />
        </fieldset>

        <fieldset>
          <legend>Applying this role to users</legend>

          <FormBlock>
            <UserDropdown
              id="users"
              name="users"
              label="For who is this role"
              value={values.users}
              placeholder="Select users"
              error={touched.users && errors.users}
              width={380}
              onChange={(values: User[]) => {
                setFieldValue(
                  'users',
                  [].slice.call(values).map((option: UserOption) => ({
                    id: option.value,
                    name: option.label,
                  })),
                );
              }}
              listSelected
              multiple
            />
          </FormBlock>
        </fieldset>
      </PopupStickyMain>

      <PopupStickyFooter>
        <FormBlock hasInlineChildren flatten>
          {(id && (
            <Button
              tag="button"
              size="medium"
              text="Delete"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(
                  setInfoBar({
                    message: 'Are you sure you want to delete this user role?',
                    action: [
                      {
                        text: 'Yes, Delete',
                        type: 'button',
                        color: 'blue',
                        handle: () => dispatch(deleteUserRoleAction(id)),
                      },
                      {
                        text: 'Cancel',
                        type: 'link',
                        handle: () => dispatch(infoBarHide()),
                      },
                    ],
                    state: InfoBarState.ERROR,
                  }),
                );
              }}
              disabled={isSubmitting}
            />
          )) || (
            <Button
              tag="button"
              size="medium"
              text="Cancel"
              scheme="link"
              hasShadow={false}
              handler={() => {
                onCancel();
              }}
              disabled={isSubmitting}
            />
          )}
          <ButtonGroup>
            <SubmitButton canSave disabled={isSubmitting} />
          </ButtonGroup>
        </FormBlock>
      </PopupStickyFooter>
    </PopupStickyForm>
  );
};

export default UserRole;
