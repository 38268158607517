import { Paths } from 'constants/Routes.enum';
import { reverse } from 'named-urls';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Deck from 'components/deck/Deck';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import MediaCardFolder from 'components/media/card-folder/MediaCardFolder';

import { hasSuperAdminRights } from 'store/auth/hasUserRights';
import { RootState } from 'store/rootState';

import './Folders.scss';

interface FoldersProps {
  editable: boolean;
}

const Folders = ({ editable }: FoldersProps) => {
  const { request, folders } = useSelector((state: RootState) => state.media);
  const pool = useSelector((state: RootState) => state.pool);
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch()
  const isSuperAdmin = dispatch(hasSuperAdminRights())
  const { customerOverride } = useSelector((state: RootState) => state.superAdmin)

  const folderList = useMemo(() => {
    if (!folders) return []
    if (!isSuperAdmin || !customerOverride) return folders

    const customerId = Number(customerOverride)
    return folders.filter((folder) => folder.customer_id === customerId)

  }, [folders, customerOverride, isSuperAdmin])

  const folderPath = (poolId: number, folderId: number) => {
    const pathName = window.location.pathname;

    if (pathName.startsWith(Paths.MEDIA)) {
      return reverse(Paths.MEDIA_FOLDER, { folder_id: folderId });
    }
    return reverse(Paths.POOLS_MEDIA_FOLDER, {
      id: poolId,
      folder_id: folderId,
    });
  };

  if (request) {
    return <LoadingIndicator title="Loading folders..." withSpacing />;
  }

  return (
    <Deck>
      {folderList.map((folder) => (
        <MediaCardFolder
          title={folder.title}
          id={folder.id}
          key={folder.id}
          link={folderPath(pool.id, folder.id)}
          editable={editable}
        />
      ))}
      {(folderList.length <= 0) && (
        <p className="folders__empty">No folders found</p>
      )}
    </Deck>
  );
};

export default Folders;
