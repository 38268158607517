import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Deck from 'components/deck/Deck';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import MediaCard from 'components/media/card/MediaCard';
import Heading from 'components/typography/heading/Heading';

import { hasSuperAdminRights } from 'store/auth/hasUserRights';
import { RootState } from 'store/rootState';

import { FilesProps } from './Files.types';

const Files = ({
  selectable = false,
  selectedMedia = [],
  setSelected,
  selectedType
}: FilesProps) => {
  const { request, media } = useSelector((state: RootState) => state.media);
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch()
  const isSuperAdmin = dispatch(hasSuperAdminRights())
  const { customerOverride } = useSelector((state: RootState) => state.superAdmin)

  const mediaList = useMemo(() => {
    if (!media) return []
    const mediaFiltered = media.filter(x => x.name)
    if (!isSuperAdmin || !customerOverride) return mediaFiltered

    const customerId = Number(customerOverride)
    return mediaFiltered.filter((item) => item.customer_id === customerId)
  }, [customerOverride, media, isSuperAdmin])

  return (
    <>
      <Heading level={2}>Media items</Heading>
      {request ? (
        <LoadingIndicator title="Loading media items..." />
      ) : (
        <Deck>
          {mediaList?.map(item => (
            <MediaCard
              id={item.id}
              key={item.id}
              title={item.name}
              type={item.filetype || item.source_type}
              itemType={item.media_item_type}
              image={item.media_file_url || item.media_source}
              selectable={selectable}
              selected={selectedMedia.indexOf(item.id) >= 0}
              setSelected={setSelected}
              disabled={
                !!selectedType &&
                ([
                  item.filetype,
                  item.source_type,
                  item.media_item_type,
                ].indexOf(selectedType) < 0 ||
                  (['image', 'video'].indexOf(selectedType) < 0 &&
                    selectedMedia.indexOf(item.id) < 0))
              }
            />
          ))}
          {(!mediaList || mediaList.length <= 0) && (
            <p className="folders__empty">No media found</p>
          )}
        </Deck>
      )}
    </>
  );
};

export default Files;
