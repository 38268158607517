import { logoutAction } from 'store/auth/loginActions';

const fetchData = (url, options, asJson = true, signal) => async (
  dispatch,
  getState,
) => {
  const authToken = getState().auth.auth_token;

  let jsonHeaders = {};
  if (asJson) {
    jsonHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  const response = await fetch(`${process.env.REACT_APP_BASE_API}${url}`, {
    signal,
    method: 'GET',
    headers: {
      Authorization: authToken,
      ...jsonHeaders,
    },
    ...options,
  });

  switch (response.status) {
    case 503:
    case 427:
      dispatch(logoutAction());
      break;
    case 403:
    case 401:
      dispatch(logoutAction("Missing user rights. You've been logged out."));
      break;
    case 201:
    case 200:
      return response;
    default:
      break;
  }

  return response;
};

export default fetchData;
