import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from 'components/typography/text/Text';
import Heading from 'components/typography/heading/Heading';
import Icon from 'components/icon/Icon';
import Button from 'components/button/Button';
import {
  accountSendVerifyEmail,
  accountSettingsVerifyEmailStatus,
  updateEmailSuccess,
} from 'store/account/accountSettingsActions';

import './AccountSettingsTwoFactor.scss';
import { RootState } from 'store/rootState';
import { AccountSettingsVerifyEmailProps } from './AccountSettingsVerifyEmail.types';

const AccountSettingsVerifyEmail: FC<AccountSettingsVerifyEmailProps> = ({
  title,
  text,
  emailData,
  id,
}) => {
  const dispatch = useDispatch();
  const verify_email_email = useSelector(
    (state: RootState) => state.accountSettings.verify_email_email,
  );
  const resendEmail = useSelector(
    (state: RootState) => state.accountSettings.two_factor_resend_email,
  );
  const emailVerified = useSelector(
    (state: RootState) => state.accountSettings.verify_email_is_set,
  );

  useEffect(() => {
    dispatch(accountSendVerifyEmail(emailData, id));

    // TODO: Fix exhaustive-deps warning
    // eslint-disable-next-line
  }, [dispatch]);

  const newEmail = () => {
    const object = {}; // @ts-ignore
    emailData.forEach((value, key) => {
      // @ts-ignore
      object[key.replace(/(^.*\[|].*$)/g, '')] = value;
    }); // @ts-ignore
    return object.email;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(accountSettingsVerifyEmailStatus(emailVerified));
    }, 5000);

    return () => clearInterval(interval);
  }, [dispatch, emailVerified]);

  const continueClick = () => {
    dispatch(updateEmailSuccess(verify_email_email));
  };

  const resendingEmail = (resendEmail: string) => {
    let resendEmailContents;
    switch (resendEmail) {
      case 'processing':
        resendEmailContents = 'Resending e-mail...';
        break;
      case 'success':
        resendEmailContents = 'E-mail has been sent again';
        break;
      case 'failed':
        resendEmailContents = (
          <>
            Resending e-mail failed.
            <Button
              onClick={() => dispatch(accountSendVerifyEmail(emailData, id))}
              scheme="link"
              text="Try again"
              size="inline"
              hasShadow={false}
            />
          </>
        );
        break;
      default:
        resendEmailContents = (
          <>
            No e-mail received yet?{' '}
            <Button
              onClick={() => dispatch(accountSendVerifyEmail(emailData, id))}
              scheme="link"
              text="Click here to resend confirmation e-mail."
              size="inline"
              hasShadow={false}
            />
          </>
        );
    }

    return (
      <Text size="small" color="grey-dark" flatten>
        {resendEmailContents}
      </Text>
    );
  };

  return (
    <>
      <div className="account-settings-two-factor__fields-container">
        <Heading level={1} stylingLevel={2}>
          {title}
        </Heading>
        <Icon
          name="twoFactorAuthentication"
          className="account-settings-two-factor__icon"
          block={false}
        />
        {emailVerified ? (
          <>
            <Icon
              name="check"
              size="small"
              color="green"
              className="account-settings-two-factor__verification-icon"
            />
            <Text tag="span" size="small">
              Verification successful
            </Text>
          </>
        ) : (
          <>
            <Text size="small">Email sent to: {newEmail()}</Text>
            <Text size="small" color="grey-dark" flatten>
              {text}
            </Text>
            {resendingEmail(resendEmail)}
          </>
        )}
      </div>

      {emailVerified ? (
        <Button
          tag="button"
          size="large"
          color="green"
          className="account-settings-two-factor__next-button"
          text="Continue"
          handler={() => continueClick()}
          block
        />
      ) : (
        <Button
          tag="button"
          size="large"
          className="account-settings-two-factor__next-button"
          text="Waiting for authentication…"
          block
        />
      )}
    </>
  );
};

export default AccountSettingsVerifyEmail;
