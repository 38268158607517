import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';

import LabelValueList from 'components/list/labelValueList/LabelValueList';
import Text from 'components/typography/text/Text';

import defaultTimezone from 'constants/DefaultTimezone';

import { RootState } from 'store/rootState';

import { InputSignalLatestDataProps } from './InputSignalLatestData.types';

const InputSignalLatestData: React.FC<InputSignalLatestDataProps> = ({ latest_data }) => {
  const timeZone = useSelector((state: RootState) => state.pool?.location?.timezone_info);

  const timestamp = latest_data?.timestamp;
  const data = latest_data?.data;

  const dataValues = data && Object.entries(data).map(([key, value]) => {
    if (key === 'timestamp') {
      return (
        {
          label: key[0].toUpperCase() + key.slice(1),
          value: moment.unix(value).isValid() ? moment.unix(value).format('DD-MM-YYYY HH:mm') : moment.tz(value, timeZone || defaultTimezone).format('DD-MM-YYYY HH:mm'),
        }
      )
    } else {
      let dataValue = value;

      if (typeof value === 'object' && value !== null) {
        dataValue = Object.entries(value).map(([key, value]) => `${key}:${value}`).join(', ')
      }

      return (
        {
          label: key[0].toUpperCase() + key.slice(1).replace('_', ' '),
          value: dataValue,
        }
      )
    }
  }).sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });


  return <fieldset>
    <legend>Latest data</legend>

    <Text><b>{moment.tz(timestamp, timeZone || defaultTimezone).format('DD-MM-YYYY HH:mm')}</b></Text>
    {data && (
      <LabelValueList
        values={dataValues}
        listId='inputSignalLatestDataList'
        loading={!data}
        loadingItemLength={2}
      />
    )}
  </fieldset>;
};

export default InputSignalLatestData;