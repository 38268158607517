import { Api } from "constants/Routes.enum"
import { Customer } from "./customer.types"
import fetchData from "store/fetchData"
import { AnyAction } from "redux"
import { RootState } from "store/rootState"
import { ThunkAction } from "redux-thunk"

export const CustomersActionTypes = {
  CUSTOMERS_CLEAR: '@@customers/clear',
  CUSTOMERS_SET: '@@customers/set'
}

export const customersActionClear = () => ({
  type: CustomersActionTypes.CUSTOMERS_CLEAR,
  payload: undefined
})

export const customersActionSet = (customers: Customer[]) => ({
  type: CustomersActionTypes.CUSTOMERS_SET,
  payload: customers
})

export const fetchCustomersAction = (): ThunkAction<void, RootState, undefined, AnyAction> => async (dispatch, getState) => {
  const { customers } = getState().customers
  if (customers.length > 0) return

  try {
    const promise = await dispatch(fetchData(Api.CUSTOMERS))
    const result = await promise.json()
    if (result) {
      dispatch(customersActionSet(result.customers))
    }
  }
  catch {
    dispatch(customersActionClear())
  }
}