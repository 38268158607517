import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import equal from 'fast-deep-equal';

import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';

import { popupActionClear } from 'store/popup/popupActions';
import Input from 'components/form/input/Input';
import { InputFontSize } from 'components/form/input/Input.enum';
import Icon from 'components/icon/Icon';
import Dropdown from 'components/form/dropdown/Dropdown';
import Checkbox from 'components/form/checkbox/Checkbox';
import {
  PopupStickyForm,
  PopupStickyHeader,
  PopupStickyMain,
  PopupStickyFooter,
} from 'components/popup/Popup';

import {
  editDeviceAction,
  fetchDeviceResolutionsAction,
} from 'store/devices/devicesActions';
import { setInfoBarShow } from 'store/info/infoActions';
import { RootState } from 'store/rootState';

import { DeviceEditProps, DeviceEditValues } from './DeviceEdit.types';

const DeviceEdit: React.FC<DeviceEditProps> = ({
  id,
  name,
  mute,
  resolution_id,
  rotation
}) => {
  const dispatch = useDispatch();
  const resolutions = useSelector(
    (state: RootState) => state.devices?.resolutions,
  );
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    dispatch(fetchDeviceResolutionsAction());
  }, [dispatch]);

  const [initialValues, setInitialValues] = useState<DeviceEditValues>({
    name,
    mute,
    resolution: resolution_id,
    rotation: rotation ?? 0
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is a required field'),
    mute: Yup.bool(),
    resolution: Yup.number()
      .nullable()
      .required('Resolution is a required field'),
    rotation: Yup.number()
      .required('Rotation is a required field')
  });

  useEffect(() => {
    setInitialValues({
      name,
      mute,
      resolution: resolution_id,
      rotation,
    });
  }, [name, mute, resolution_id, rotation]);

  const onSubmit = (values: DeviceEditValues) => {
    if (equal(values, initialValues)) {
      dispatch(popupActionClear());
      dispatch(setInfoBarShow({ show: false }));
      return;
    }

    setSubmitting(true);
    dispatch(editDeviceAction(id, values, setSubmitting));
  };

  const deviceRotations = [{
    label: '0°', value: 0
  }, {
    label: '90°', value: 90
  }, {
    label: '180°', value: 180
  }, {
    label: '270°', value: 270
  }]

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });

  return (
    <PopupStickyForm onSubmit={handleSubmit}>
      <PopupStickyHeader>
        <fieldset>
          <FormBlock flatten>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Device name"
              onChange={handleChange}
              value={values.name}
              error={touched.name && errors.name}
              fontSize={InputFontSize.LARGE}
              suffix={<Icon name="edit" />}
            />
          </FormBlock>
        </fieldset>
      </PopupStickyHeader>
      <PopupStickyMain>
        <fieldset>
          <FormBlock>
            <Dropdown
              label="Resolution"
              name="resolution"
              value={values.resolution}
              values={resolutions}
              error={touched.resolution && errors.resolution}
              isLoading={!resolutions}
              onChange={setFieldValue}
              hasEmptyOption={false}
              placeholder="Select resolution"
            />
          </FormBlock>
          <FormBlock>
            <Dropdown
              label="Rotation"
              name="rotation"
              value={values.rotation}
              values={deviceRotations}
              error={touched.rotation && errors.rotation}
              onChange={setFieldValue}
              hasEmptyOption={false}
              placeholder="Select rotation"
            />
          </FormBlock>
          <FormBlock flatten>
            <Checkbox
              label="Mute this device"
              name="mute"
              id="mute"
              checked={values.mute}
              onChange={setFieldValue}
            />
          </FormBlock>
        </fieldset>
      </PopupStickyMain>

      <PopupStickyFooter>
        <FormBlock hasInlineChildren flatten>
          <Button
            tag="button"
            size="medium"
            text="Cancel"
            scheme="link"
            hasShadow={false}
            handler={() => {
              dispatch(popupActionClear());
            }}
            disabled={isSubmitting}
          />

          <Button
            tag="button"
            type="submit"
            size="medium"
            text="Save and close"
            disabled={isSubmitting}
          />
        </FormBlock>
      </PopupStickyFooter>
    </PopupStickyForm>
  );
};

export default DeviceEdit;
