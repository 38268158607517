import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import Heading from 'components/typography/heading/Heading';
import OverviewListItem from 'components/overviewList/OverviewListItem';
import { OverviewListItemTag } from 'components/overviewList/OverviewListItem.enum';
import Status from 'components/status/Status';
import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import IconList from 'components/list/iconList/IconList';
import {
  PopupStickyWrapper,
  PopupStickyHeader,
  PopupStickyMain,
  PopupStickyFooter,
} from 'components/popup/Popup';

import { editArrangementAction } from 'store/arrangements/arrangementsActions';
import { editTriggerAction } from 'store/triggers/triggersActions';
import { editEventAction } from 'store/events/eventsActions';
import { popupActionClear } from 'store/popup/popupActions';
import { RootState } from 'store/rootState';

import defaultTimezone from 'constants/DefaultTimezone';

import { LogDetailsProps } from './LogDetails.types';
import { LogDetailsItemType } from './LogDetails.enum';

import './LogDetails.scss';

const LogDetails: React.FC<LogDetailsProps> = ({
  item_id,
  item_name,
  item_type,
  pending_approval,
  changes,
  active,
  deleted,
  deleted_log,
  created_at,
  description,
}) => {
  const dispatch = useDispatch();
  const timeZone = useSelector(
    (state: RootState) => state.pool?.location?.timezone_info,
  );

  const overviewListItem = [
    {
      content: `${item_type}: ${item_name}`,
      key: `${item_id}_name`,
    },
    {
      content: (
        <Status
          isActive={active}
          needsApproval={pending_approval}
          isDeleted={deleted_log !== null}
        />
      ),
      key: `${item_id}_status`,
      width: '25%',
    },
  ];

  const detailedChanges =
    changes &&
    changes.map(change => ({
      content: change,
    }));

  const reviewChangesClick = (id: number, item_type: LogDetailsItemType) => {
    switch (item_type) {
      case LogDetailsItemType.POOL_TRIGGER:
        dispatch(editTriggerAction(id));
        break;
      case LogDetailsItemType.SCHEDULED_EVENT:
        dispatch(editEventAction(id));
        break;
      default:
        dispatch(editArrangementAction(id, 1));
    }
  };

  const emptyListTexts = (
    changes: string[],
    created_at: string | undefined,
    description: string,
    item_name: string,
  ) => {
    switch (true) {
      case (changes === undefined || changes.length === 0) &&
        created_at &&
        !description.includes('rejected'): {
        return `${item_type} "${item_name}" has been created at ${moment
          .tz(created_at, timeZone || defaultTimezone)
          .format('DD-MM-YYYY hh:mm')}`;
      }
      case description.includes('rejected'):
        return `Changes to "${item_name}" have been rejected`;
      default:
        return 'No detailed changes have been made';
    }
  };

  return (
    <PopupStickyWrapper>
      <PopupStickyHeader>
        <Heading level={2}>Changes made to</Heading>
        <OverviewListItem
          id={item_id.toString()}
          columns={overviewListItem}
          tag={OverviewListItemTag.DIV}
          cardProps={{ shadow: true }}
          className="log-details__log-item"
          loading={!item_id}
        />
      </PopupStickyHeader>
      <PopupStickyMain>
        <Heading level={2}>Detailed changes</Heading>
        <IconList
          icon="circle"
          items={detailedChanges}
          loading={!detailedChanges}
          loadingItemLength={5}
          iconBackgroundColor="grey"
          emptyListText={emptyListTexts(
            changes,
            created_at,
            description,
            item_name,
          )}
        />
      </PopupStickyMain>
      <PopupStickyFooter>
        <FormBlock hasInlineChildren flatten>
          <Button
            tag="button"
            size="medium"
            text="Cancel"
            scheme="link"
            hasShadow={false}
            handler={() => {
              dispatch(popupActionClear());
            }}
          />
          {!deleted && (
            <Button
              tag="button"
              size="medium"
              text={`View ${item_type.toLowerCase()}`}
              handler={() => {
                reviewChangesClick(item_id, item_type);
              }}
            />
          )}
        </FormBlock>
      </PopupStickyFooter>
    </PopupStickyWrapper>
  );
};

export default LogDetails;
