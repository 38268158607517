import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logoutAction } from 'store/auth/loginActions';
import { popupActionClear } from 'store/popup/popupActions';

const useAuthLogout = (clearPopup?: boolean) => {
  const dispatch = useDispatch();

  const logoutCallback = useCallback(() => {
    dispatch(logoutAction());

    if (clearPopup) {
      dispatch(popupActionClear());
    }
  }, [clearPopup, dispatch]);

  return [logoutCallback];
};

export default useAuthLogout;
