import { DevicesActionTypes } from './devicesActions';

export const devicesInitialState = {
  request: false,
  signs: [],
  error: null,
  filterOptions: null,
  resolutions: null,
};

const devicesReducer = (state = devicesInitialState, action) => {
  switch (action.type) {
    case DevicesActionTypes.DEVICES_REQUESTED:
      return { ...state, request: true };
    case DevicesActionTypes.DEVICES_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
        request: false,
        error: null,
      };
    case DevicesActionTypes.DEVICES_FAILED:
      return { ...devicesInitialState, request: false, error: action.payload };
    case DevicesActionTypes.DEVICE_ADDED:
      return {
        ...state,
        signs: [action.payload, ...state.signs],
      };
    case DevicesActionTypes.DEVICES_CLEARED:
      return devicesInitialState;
    case DevicesActionTypes.DEVICES_UPDATED:
      return {
        ...state,
        signs: [
          action.payload,
          ...state.signs.filter(sign => sign.id !== action.payload.id),
        ],
      };
    case DevicesActionTypes.DEVICE_UNASSIGNED_IN_GROUP:
      return {
        ...state,
        signs: state.signs.filter(item => item.id !== action.payload),
      };
    case DevicesActionTypes.DEVICE_UNASSIGNED:
      return {
        ...state,
        signs: state.signs.map(sign => {
          if (sign.id === action.payload.id) {
            return {
              ...sign,
              location_name: action.payload.location_name,
              pool_name: action.payload.pool_name,
            };
          }
          return sign;
        }),
      };
    case DevicesActionTypes.DEVICES_FETCH_RESOLUTIONS_SUCCESS:
      return {
        ...state,
        resolutions: action.payload,
      };
    case DevicesActionTypes.DEVICES_FETCH_RESOLUTIONS_FAILED:
      return {
        ...state,
        resolutions: null,
      };

    case DevicesActionTypes.DEVICES_FETCH_FILTERS_SUCCESS:
      return {
        ...state,
        filterOptions: action.payload,
      };
    case DevicesActionTypes.DEVICES_FETCH_FILTERS_FAILED:
      return {
        ...state,
        filterOptions: null,
      };
    default:
      return state;
  }
};

export default devicesReducer;
