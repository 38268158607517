import React from 'react';
import classNames from 'classnames';

import './LoadingIndicator.scss';

interface LoadingIndicatorProps {
  title: string;
  withSpacing?: boolean;
}

const LoadingIndicator = ({
  title,
  withSpacing = false
}: LoadingIndicatorProps) => {
  return (
    <div
      className={classNames('loading-indicator', {
        'loading-indicator--spacing': withSpacing,
      })}
    >
      {title}
    </div>
  );
};

export default LoadingIndicator;
