import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Paths } from 'constants/Routes.enum';
import UserRights from 'constants/UserRight.enum';

import AccountMenu from 'components/accountMenu/AccountMenu';
import CustomerDropdown from 'components/customer-dropdown/CustomerDropdown';
import Logo from 'components/logo/Logo';

import { hasSuperAdminRights, hasUserRights } from 'store/auth/hasUserRights';
import { RootState } from 'store/rootState';

import './Header.scss';

const Header = () => {
  const location = useLocation();
  const dispatch: ThunkDispatch<undefined, any, AnyAction> = useDispatch();

  const hasMediaViewRights = dispatch(hasUserRights(UserRights.MEDIA_VIEW));
  const hasDeviceViewRights = dispatch(hasUserRights(UserRights.SIGNS_VIEW));
  const hasManagementRights = dispatch(hasUserRights(UserRights.MANAGEMENT));
  const hasInputRights = dispatch(hasUserRights(UserRights.INPUT_VIEW));
  const isSuperAdmin = dispatch(hasSuperAdminRights());

  const mediaSelectable = useSelector((state: RootState) => state.media.selectable);
  const arrangementsSelectable = useSelector((state: RootState) => state.arrangements.selectable);

  const routes = useMemo(() => [
    { Key: 'groups', Path: Paths.POOLS, Name: 'Groups', ViewRights: true },
    {
      Key: 'media',
      Path: Paths.MEDIA,
      Name: 'Media',
      ViewRights: hasMediaViewRights,
    },
    {
      Key: 'devices',
      Path: Paths.DEVICES,
      Name: 'Devices',
      ViewRights: hasDeviceViewRights,
    },
    {
      Key: 'inputs',
      Path: Paths.INPUTS,
      Name: 'Inputs',
      ViewRights: hasInputRights,
    },
    {
      Key: 'management',
      Path: Paths.MANAGEMENT,
      Name: 'Management',
      ViewRights: hasManagementRights,
    },
  ].map(route => {
    return {
      ...route,
      Active: !!matchPath(location.pathname, route.Path),
    };
  }), [location.pathname, hasMediaViewRights, hasDeviceViewRights, hasInputRights, hasManagementRights]);

  const customerDropdown = useMemo(() => {
    if (!isSuperAdmin) return null

    return (
      <CustomerDropdown
        width={200}
        disabled={mediaSelectable || arrangementsSelectable}
      />
    )
  }, [isSuperAdmin, mediaSelectable, arrangementsSelectable])

  const profileElement = useMemo(() => {
    return <div className="page-header__profile">
      {customerDropdown}
      <AccountMenu
        disabled={mediaSelectable || arrangementsSelectable}
      />
    </div>
  }, [mediaSelectable, arrangementsSelectable, customerDropdown])

  return (
    <header className="page-header">
      <nav className="page-header__inner">
        {mediaSelectable || arrangementsSelectable ? (
          <span className="page-header__logo page-header__logo--disabled">
            <Logo brandMark />
          </span>
        ) : (
          <Link to={Paths.HOME} className="page-header__logo">
            <Logo brandMark />
          </Link>
        )}

        <div className="page-header__links">
          {routes.map(
            route =>
              route.ViewRights && (
                <React.Fragment key={`${route.Key}-${route.Path}`}>
                  {mediaSelectable || arrangementsSelectable ? (
                    <span
                      className={classNames(
                        'page-header__link page-header__link--disabled',
                        {
                          'page-header__link--active': route.Active,
                        },
                      )}
                    >
                      {route.Name}
                    </span>
                  ) : (
                    <Link
                      key={route.Key}
                      to={route.Path}
                      className={classNames('page-header__link', {
                        'page-header__link--active': route.Active,
                      })}
                    >
                      {route.Name}
                    </Link>
                  )}
                </React.Fragment>
              ),
          )}
        </div>

        {profileElement}
      </nav>
    </header>
  );
};

export default Header;
