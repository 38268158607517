export const AssignedOptions = {
  assigned: [
    {
      label: 'All options',
      value: 0,
    },
    {
      label: 'Assigned',
      value: 1,
    },
    {
      label: 'Unassigned',
      value: 2,
    },
  ],
};

export const StatusOptions = {
  status: [
    {
      label: 'All options',
      value: '',
    },
    {
      label: 'Online',
      value: 'online',
    },
    {
      label: 'Offline',
      value: 'offline',
    },
  ],
};
