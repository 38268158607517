import moment from 'moment-timezone';
import { useMemo } from 'react';

type UseCalendarDates = {
  startDate?: string | null;
  endDate?: string | null;
  roundTime: boolean;
  timeZone?: string;
}

const useCalendarDates = ({
  startDate = null,
  endDate = null,
  roundTime = true,
  timeZone,
}: UseCalendarDates) => {
  return useMemo(() => {
    let start = timeZone ? moment.tz(startDate || new Date(), timeZone) : null;
    let end = timeZone && endDate ? moment.tz(endDate, timeZone) : null;

    const getRoundedTime = (date: moment.Moment | null ) => {
      const newDate = date && date.clone();
      const minutes = newDate && newDate.minute();

      if (newDate && minutes && minutes > 30) {
        // round to next hour
        newDate.set({ hour: newDate.hour() + 1, minute: 0 });
      } else if (newDate && minutes && minutes > 0) {
        // round to next half hour
        newDate.set({ minute: 30 });
      }

      newDate?.set({ second: 0 })

      return newDate;
    };

    if (!endDate) {
      end = start && start.clone();
      end && end.add(1, 'hours');
    }

    if (roundTime) {
      start = getRoundedTime(start);
      end = getRoundedTime(end);
    }

    return [start, end];
  }, [startDate, endDate, timeZone, roundTime])
};

export default useCalendarDates;
