import PropTypes from 'prop-types';
import React from 'react';

import FormBlock from 'components/form/block/FormBlock';
import MediaFolderDropdown from 'components/media/folder-dropdown/MediaFolderDropdown';

const MediaPlacementFieldset = ({
  name,
  setFieldValue,
  folder_id,
  disabled,
}) => {
  return (
    <fieldset>
      <legend>Placement of media item</legend>
      <FormBlock flatten>
        <MediaFolderDropdown
          name={name}
          label="Where should the media item be located"
          onChange={setFieldValue}
          initialValue={folder_id}
          disabled={disabled}
        />
      </FormBlock>
    </fieldset>
  );
};

MediaPlacementFieldset.propTypes = {
  name: PropTypes.string,
  setFieldValue: PropTypes.func,
  folder_id: PropTypes.number,
  disabled: PropTypes.bool,
};

MediaPlacementFieldset.defaultProps = {
  name: '',
  setFieldValue: '',
  folder_id: null,
  disabled: false,
};

export default MediaPlacementFieldset;
