import React, { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';

import Icon from 'components/icon/Icon';

import { MediaItemType } from 'store/media/Media.types';

import './MediaCardImage.scss';

interface MediaCardImageProps {
  type?: MediaItemType | string
  itemType?: MediaItemType | string
  title: string
  image?: string
  setImageNotLoaded: (state: boolean) => void
}

const MediaCardImage = ({
  type = 'other',
  itemType,
  title,
  image,
  setImageNotLoaded,
}: MediaCardImageProps) => {
  const [imageIsIcon, setImageIsIcon] = useState(true);
  const [imageContent, setImageContent] = useState<ReactNode | undefined>(undefined);

  const setEmptyImage = () => {
    setImageContent(<Icon color="grey" name="image" />);
    setImageIsIcon(true);
  };

  useEffect(() => {
    switch (true) {
      // @ts-ignore webpackages to be supported later
      case itemType === 'webpackage':
      case itemType === MediaItemType.JACKPOT:
        setImageContent(<Icon color="grey" name="jackpot" />);
        break;
      case itemType === MediaItemType.TICKER_TAPE:
        setImageContent(<Icon color="grey" name="tickerTape" />);
        break;
      case type === MediaItemType.VIDEO:
      case type === MediaItemType.HTML:
        // @ts-ignore name of type should always match
        setImageContent(<Icon color="grey" name={type} />);
        break;
      case type === MediaItemType.RICH_TEXT:
        setImageContent(<Icon color="grey" name="rte" />);
        break;
      case type === MediaItemType.IMAGE:
        setImageContent(
          <img
            src={image}
            alt={title}
            onError={(e) => {
              setEmptyImage();
              setImageNotLoaded(false);
              // e.onerror = null;
            }}
          />,
        );
        setImageIsIcon(false);
        break;
      default:
        setImageContent(<Icon color="grey" name="url" />);
        break;
    }
  }, [type, itemType, image, title, setImageNotLoaded]);

  return (
    <div
      className={classNames('media-card__image', {
        'media-card__image--icon': imageIsIcon,
      })}
    >
      {imageContent && <>{imageContent}</>}
    </div>
  );
};

export default MediaCardImage;
