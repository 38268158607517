import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import OverviewList from 'components/overviewList/OverviewList';
import { OverviewListHeader } from 'components/overviewList/OverviewList.types';
import { OverviewListItemItem } from 'components/overviewList/OverviewListItem.types';
import Ellipsis from 'components/typography/ellipsis/Ellipsis';

import { hasSuperAdminRights } from 'store/auth/hasUserRights';
import { setInfoBarShow } from 'store/info/infoActions';
import { popupActionSet } from 'store/popup/popupActions';
import { RootState, UserRole } from 'store/rootState';
import {
  fetchUserRolesAction,
  openUserRoleAction,
} from 'store/userRoles/userRolesActions';

import PopupTypes from 'constants/PopupTypes.enum';


const UserRolesOverview = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { request, userRoles } = useSelector(
    (state: RootState) => state.userRoles,
  );
  const { customerOverride } = useSelector((state: RootState) => state.superAdmin)
  const isSuperAdmin = dispatch(hasSuperAdminRights())

  useEffect(() => {
    dispatch(fetchUserRolesAction());
  }, [dispatch]);

  const mappedUserRoles = useMemo(() => {
    const userRoleList = isSuperAdmin && customerOverride
      ? userRoles.filter(userRole => userRole.customer_id === Number(customerOverride))
      : userRoles

    return userRoleList.map((userRole: UserRole) => {
      const { id, name, users, customer_name } = userRole;
      return {
        key: id.toString(),
        id: id.toString(),
        items: [
          {
            key: `${id}_name`,
            width: '50%',
            content: name,
          },
          isSuperAdmin ? {
            key: `${id}_customer`,
            width: '250px',
            content: (
              <Ellipsis text={customer_name} />
            )
          } : undefined,
          {
            key: `${id}_users`,
            content: users,
          },
        ].filter(Boolean) as OverviewListItemItem[],
        onClick: () => {
          dispatch(openUserRoleAction(id));
        },
      };
    });

  }, [userRoles, dispatch, customerOverride, isSuperAdmin]);

  const overviewHeaders = [
    { key: 'Name', content: 'Name', width: '50%' },
    isSuperAdmin ? { key: 'Customer', content: 'Customer', width: '250px' } : undefined,
    { key: 'Users', content: 'Users' },
  ].filter(Boolean) as OverviewListHeader[];

  return (
    <>
      <OverviewList
        headers={overviewHeaders}
        content={mappedUserRoles}
        cardProps={{ highlightOnHover: true, interactive: true }}
        loading={request}
      />
      <div className="detail-button">
        <Button
          text={<Icon name="plus" size="medium" />}
          hoverText="Add user role"
          hoverTextInverse
          handler={() => {
            dispatch(popupActionSet(PopupTypes.MANAGEMENT_USER_ROLE, {}));
            dispatch(setInfoBarShow({ show: false }));
          }}
          shapeEdges="rounded"
        />
      </div>
    </>
  );
};

export default UserRolesOverview;
