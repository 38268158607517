import React, { PropsWithChildren } from 'react';

import './Public.scss';

const Public = ({ children }: PropsWithChildren<{}>) => {
  return (
    <div className="public-body">
      <div className="public-body__container">{children}</div>
    </div>
  );
};

export default Public;
