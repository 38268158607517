import React, {ReactElement, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import {useHistory, useLocation} from 'react-router-dom';

import { setInfoBar, setInfoBarShow } from 'store/info/infoActions';
import { InfoBarMessageType } from 'store/info/infoActions.types';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import { IconName } from 'components/icon/Icon.enum';

import HistoryLocationState from 'constants/HistoryState';

import { InfoBarState } from 'store/info/infoActions.enum';
import { RootState } from 'store/rootState';

import { InfoBarSize } from './InfoBar.enum';
import './InfoBar.scss';

const InfoBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [timer, setTimer] = useState(0);
  const [time, setTime] = useState(0);
  const { show, message, state, action, timeout } = useSelector(
    (state: RootState) => state.info.bar,
  );

  const infoBarSize = (message: InfoBarMessageType) => {
    const messageTest = message;

    if (!messageTest) return InfoBarSize.NORMAL;

    const maxTextLength = 100;
    const isReactElement = React.isValidElement(message);

    const isString = typeof message === 'string' || message instanceof String;

    switch (true) {
      case isReactElement: {
        // @ts-ignore
        const reactNodeMessage: ReactElement = messageTest.props.children;
        const messageNodes = Array.isArray(reactNodeMessage) ? reactNodeMessage.map(
          messageNode => messageNode.props.children,
        ) : reactNodeMessage;
        const messageText = messageNodes.toString().replace(',', ' ');
        return messageText.length > maxTextLength
          ? InfoBarSize.WIDE
          : InfoBarSize.NORMAL;
      }
      case isString: {
        const messageText = messageTest.toString().replace(/(<([^>]+)>)/gi, '');
        return messageText.length > maxTextLength
          ? InfoBarSize.WIDE
          : InfoBarSize.NORMAL;
      }
      default:
        return InfoBarSize.NORMAL;
    }
  };
  const icons = {
    check: IconName.CHECK,
    warning: IconName.WARNING,
    error: IconName.CROSS,
    maintenance: IconName.TOOL,
    planned_maintenance: IconName.TOOL,
    info: IconName.INFO,
    undefined: IconName.CHECK,
    select:  IconName.DOWNLOAD,
  };

  const historyLocation = useLocation<HistoryLocationState>();

  useEffect(() => {
    if (historyLocation.state?.infoMessage) {
      setTimeout(() => {
        dispatch(
          setInfoBar({
            message: historyLocation.state.infoMessage,
            state: InfoBarState.INFO,
          }),
        );
      }, 0);

      history.replace({ state: null });
    }

    // only hide infoBar on route change when it is still visible and there is no sign of a group being added or deleted.
    // TODO: Refactor if statement, because there are a lot of exceptional situations right now.
    if (
      show &&
      historyLocation.state &&
      !historyLocation.state.groupAdded &&
      !historyLocation.state.groupDeleted &&
      !historyLocation.state.infoMessage &&
      !historyLocation.state.passwordReset &&
      !historyLocation.state.unprocessableMediaItems &&
      !historyLocation.state.fromArrangementFormFormValues
    ) {
      dispatch(setInfoBarShow({ show: false }));
    }

    // TODO: Fix exhaustive-deps warning
    // eslint-disable-next-line
  }, [dispatch, history, historyLocation]);

  useEffect(() => {
    if (show && timeout && timeout > 0) {
      // clear the infoBar of the message
      clearTimeout(timer);

      setTimer(
        window.setTimeout(() => {
          setTime(time + 1);
          dispatch(setInfoBarShow({ show: false }));
        }, timeout),
      );
    } else if (timeout === 0) {
      clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, timeout, time]);

  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames="fixed-bottom"
      unmountOnExit
    >
      <div
        className={classNames('info-bar', `info-bar--${infoBarSize(message)}`)}
        data-cy={`info-bar-${icons[state || '']}`}
      >
        <span
          className={classNames('info-bar__icon', {
            'info-bar__icon--info': state === InfoBarState.SELECT,
            'info-bar__icon--error':
              state === InfoBarState.ERROR ||
              state === InfoBarState.MAINTENANCE,
            'info-bar__icon--warning':
              state === 'warning' || state === InfoBarState.PLANNED_MAINTENANCE,
          })}
        >
          {state && (
            <Icon name={icons[state]} size="small" />
          )}
        </span>
        <span className="info-bar__text" data-cy="info-bar-text">
          {/* @ts-ignore */}
          {message}
        </span>
        {(action &&
          Array.isArray(action) &&
          action.length > 1 &&
          action.map(ac => (
            <Button
              tag={ac.type === 'button' ? 'button': 'a'}
              key={`info-bar_button_${ac.text}`}
              handler={ac.handle}
              className={`info-bar__action ${ac.className}`}
              size="medium-small"
              hasShadow={false}
              color={ac.color}
              scheme={ac.type === 'link' ? 'link' : undefined}
              text={ac.text}
              disabled={ac.disabled}
            />
          ))) ||
          (action &&
            Array.isArray(action) &&
            action.length === 1 &&
            action.map(ac => (
              <Button
                tag={ac.type === 'button' ? 'button': 'a'}
                handler={ac.handle}
                className="info-bar__action"
                size="medium-small"
                hasShadow={false}
                scheme="link"
                text={ac.text}
              />
            )))}
      </div>
    </CSSTransition>
  );
};

export default InfoBar;
