import {InputDeviceInputSignal} from "store/rootState";

export const attachedInputSignalsAreAllOnline = (
  input_signals: InputDeviceInputSignal[] | undefined,
) => {
  if (!input_signals) return false;
  const inputSignalLength = input_signals.length;
  const activeInputSignalLength = input_signals.filter(
    input_signal => input_signal.active,
  ).length;
  return inputSignalLength === activeInputSignalLength;
};

export default attachedInputSignalsAreAllOnline;
