export enum InfoBarState {
  WARNING = 'warning',
  ERROR = 'error',
  MAINTENANCE = 'maintenance',
  PLANNED_MAINTENANCE =  'planned_maintenance',
  CHECK = 'check',
  INFO = 'info',
  SELECT = 'select',
}

export enum InfoBarActionType {
  BUTTON = 'button',
  LINK = 'link',
}
