import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import Logo from 'components/logo/Logo';
import FormMessage from 'components/form/formMessage/FormMessage';
import { BigForm, BigFormField } from 'components/form/bigForm/BigForm';

import { requestChangePasswordAction } from 'store/auth/resetPasswordActions';
import RegexOptions from 'constants/RegexOptions';
import { Paths } from 'constants/Routes.enum';
import { InitialValues } from './RequestPassword.types';

const RequestPassword = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('E-mail is a required field')
      .matches(RegexOptions.EMAIL_VALIDATION, 'Please enter a valid e-mail'),
  });

  const onSubmit = (values: InitialValues) => {
    setSubmitting(true);
    dispatch(
      requestChangePasswordAction(
        values.email,
        setSubmitting,
        setSubmittedSuccessfully,
      ),
    );
  };

  return (
    <>
      <Logo />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, handleSubmit, touched }) => (
          <>
            <BigForm
              handleSubmit={handleSubmit}
              submitButtonText={
                submittedSuccessfully ? 'Request send' : 'Request password link'
              }
              isSubmitting={isSubmitting}
              title="Request password"
              fields={
                <BigFormField>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="E-mail"
                    size="large"
                    scheme="login"
                    aria-label="Email"
                    value={values.email}
                    error={touched.email && errors.email ? true : null}
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                </BigFormField>
              }
              errorMessages={
                <>
                  <FormMessage name="email" type="formikErrorMessage" />
                </>
              }
              footerText={
                <Button
                  link={Paths.LOGIN}
                  scheme="link"
                  text="Back to log in"
                  size="inline"
                  hasShadow={false}
                />
              }
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default RequestPassword;
