import React, { FC } from 'react';
import Heading from 'components/typography/heading/Heading';

import { DeckEmptyProps } from './DeckEmpty.types';
import './DeckEmpty.scss';

const DeckEmpty: FC<DeckEmptyProps> = ({ title, height, children }) => {
  return (
    <div className="deck-empty" style={{ height }}>
      {title && (
        <Heading className="deck-empty__title" level={2}>
          {title}
        </Heading>
      )}
      {children && <div className="deck-empty__content">{children}</div>}
    </div>
  );
};

export default DeckEmpty;
