import React, { FC } from 'react';
import classNames from 'classnames';

import RegexOptions from 'constants/RegexOptions';

import { PasswordValidatorProps } from './PasswordValidator.types';
import './PasswordValidator.scss';

const PasswordValidator: FC<PasswordValidatorProps> = ({ input }) => {
  const validators = [
    {
      id: 0,
      value: '8 characters',
      regex: RegexOptions.MINIMAL_EIGHT_CHARS,
    },
    {
      id: 1,
      value: '1 capital',
      regex: RegexOptions.MINIMAL_ONE_CAPITAL,
    },
    {
      id: 2,
      value: '1 number',
      regex: RegexOptions.MINIMAL_ONE_NUMBER,
    },
  ];

  return (
    <div className="password-validator">
      <div className="password-validator__text">A password requires</div>
      <div className="password-validator__validators">
        {validators.map(validator => (
          <div
            key={validator.id}
            className={classNames('password-validator__validator', {
              'password-validator__validator--valid': validator.regex.test(
                input,
              ),
            })}
          >
            {validator.value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PasswordValidator;
