import React from 'react';

import SkeletonParagraph from 'components/skeleton/views/SkeletonParagraph';
import { SkeletonProps } from 'components/skeleton/Skeleton.types';
import { SkeletonTag, SkeletonType } from 'components/skeleton/Skeleton.enum';

import './Skeleton.scss';

const Skeleton: React.FC<SkeletonProps> = ({ type, tag, styling }) => {
  const defaultLineHeight = 25;
  const defaultLineSpacing = 5;

  const defaultStyleSettings = {
    width: '100%',
    height: defaultLineHeight - defaultLineSpacing,
    marginBottom: defaultLineSpacing,
  };

  const styleSettings = {
    ...defaultStyleSettings,
    ...styling,
  };

  const elementHeight = (elTag: SkeletonTag | undefined) => {
    switch (elTag) {
      case SkeletonTag.H1:
        return 54;
      case SkeletonTag.H2:
        return 30;
      case SkeletonTag.H3:
        return 24;
      case SkeletonTag.H4:
        return 18;
      case SkeletonTag.H5:
        return 15;
      case SkeletonTag.H6:
        return 12;
      default:
        return undefined;
    }
  };

  const headingStyles = {
    ...styleSettings,
    height: elementHeight(tag),
    marginBottom: 0,
  };

  const buttonSquareStyles = {
    ...styleSettings,
    height: 'unset',
    paddingTop: '100%',
    marginBottom: 0,
  };

  const textStyles = {
    ...styleSettings,
    marginBottom: 0,
  };

  switch (type) {
    case SkeletonType.TEXT:
      return <div className="skeleton" style={{ ...textStyles }} />;
    case SkeletonType.HEADING:
      return <div className="skeleton" style={{ ...headingStyles }} />;
    case SkeletonType.BUTTON_SQUARE:
      return <div className="skeleton" style={{ ...buttonSquareStyles }} />;
    case SkeletonType.PARAGRAPH:
    default:
      return SkeletonParagraph({ settings: styleSettings });
  }
};

export default Skeleton;
