import React, { useEffect, createRef } from 'react';
import classNames from 'classnames';
import Label from 'components/form/label/Label';

import './Input.scss';
import { InputProps } from './Input.types';

const Input: React.FC<InputProps> = ({
  label,
  type,
  value,
  placeholder,
  id,
  name,
  disabled,
  className,
  containerClassName,
  size,
  fontSize,
  scheme,
  onChange,
  suffix,
  prefix,
  width,
  error,
  isChanged,
  children,
  readOnly,
  dataCy,
  ...props
}) => {
  const suffixRef = createRef<HTMLDivElement>();
  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (suffix && suffixRef.current) {
      const suffixEl = suffixRef.current;
      const inputEl = inputRef.current;
      const width = suffixEl.offsetWidth;

      if (inputEl) {
        inputEl.style.paddingRight = `${width + 4}px`;
      }
    }
  }, [suffix, suffixRef, inputRef]);

  return (
    <Label idFor={id} label={label}>
      <div
        className={classNames('form__input__container', containerClassName)}
        style={{ width }}
      >
        <input
          type={type}
          id={id}
          value={value}
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          onChange={onChange}
          className={classNames('form__input', className, {
            'form__input--prefix': prefix,
            'form__input--error': !!error,
            'form__input--font-large': fontSize === 'large',
            'form__input--large': size === 'large',
            'form__input--changed': isChanged,
            [`form__input--${scheme}`]: scheme,
          })}
          ref={inputRef}
          readOnly={readOnly}
          {...props}
        />
        {prefix && <div className="form__input__prefix">{prefix}</div>}
        {suffix && (
          <div
            ref={suffixRef}
            className={classNames('form__input__suffix', {
              'form__input__suffix--disabled': disabled,
            })}
          >
            {suffix}
          </div>
        )}
        {children}
        {error && scheme && <span className="form__container-error" />}
      </div>
      {error && error !== true && (
        <p
          className={classNames('form__error', {
            'form__error--wide': !scheme,
          })}
        >
          {error}
        </p>
      )}
    </Label>
  );
};

export default Input;
