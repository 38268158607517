import React, { FC } from 'react';
import classNames from 'classnames';

import { LayoutBlockProps } from './LayoutBlock.types';
import './LayoutBlock.scss';

const LayoutBlock: FC<LayoutBlockProps> = ({
  hasInlineChildren = false,
  ignoreSplit = false,
  noSpacing = false,
  isEnd = false,
  halfWidth = false,
  children,
}) => {
  const hasMultipleChildren =
    React.Children.count(children) > 1 && !hasInlineChildren;

  return (
    <div
      className={classNames('layout-block', {
        'layout-block--split':
          (hasMultipleChildren && !ignoreSplit) || halfWidth,
        'layout-block--split-inline': hasInlineChildren,
        'layout-block--inline': ignoreSplit,
        'layout-block--no-spacing': noSpacing,
        'layout-block--end': isEnd,
      })}
    >
      {children}
    </div>
  );
};

export default LayoutBlock;
