import { reverse } from 'named-urls';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Button from 'components/button/Button';
import DeckEmpty from 'components/deck/DeckEmpty';
import MediaButton from 'components/media/button/MediaButton';
import Files from 'components/media/files/Files';
import MediaFilter from 'components/media/filter/MediaFilter';
import Folders from 'components/media/folders/Folders';
import FolderHeader from 'components/media/folders/folderHeader/FolderHeader';
import MediaUpload from 'components/media/upload/Upload';
import Ellipsis from 'components/typography/ellipsis/Ellipsis';
import Text from 'components/typography/text/Text';

import HistoryLocationState from 'constants/HistoryState';
import { Api, Paths } from 'constants/Routes.enum';
import UserRight from 'constants/UserRight.enum';

import { hasUserRights } from 'store/auth/hasUserRights';
import fetchData from 'store/fetchData';
import { infoBarHide, setInfoBar } from 'store/info/infoActions';
import { InfoBarActionType, InfoBarState } from 'store/info/infoActions.enum';
import { MediaItemType } from 'store/media/Media.types';
import { selectMediaItems } from 'store/media/mediaActions';
import { RootState } from 'store/rootState';

const PoolsMedia = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const history = useHistory<HistoryLocationState>();

  const { folder_info } = useSelector((state: RootState) => state.media);
  const filter = useSelector((state: RootState) => state.media.filter);
  const selectable = useSelector((state: RootState) => state.media.selectable);
  const pool = useSelector((state: RootState) => state.pool);
  const hasMediaViewRights = dispatch(hasUserRights(UserRight.MEDIA_VIEW));
  const hasMediaEditRights = dispatch(hasUserRights(UserRight.MEDIA_EDIT));
  const hasFilter = !(!filter || (!filter.name && !filter.filetype));

  const [selectedMedia, setSelected] = useState<number[]>([]);
  const [selectedType, setSelectedType] = useState<MediaItemType | undefined>(undefined);

  const noMediaViewRightsSubTitle = (
    <p>
      In case you should have view rights please contact your supervisor or send
      an email to{' '}
      <Button
        hasShadow={false}
        scheme="link"
        link={`mailto:${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
        text={`${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
        size="inline"
      />
    </p>
  );

  useEffect(() => {
    const { state } = history.location;
    if (state) {
      if (state.fromArrangement && !!state.fromSpot && state.fromSpot?.id >= 0) {
        dispatch(selectMediaItems(true));
      }
      if (state.fromMediaType) {
        setSelectedType(state.fromMediaType);
      }
    }
  }, [dispatch, history.location]);

  const toggleSelectedMedia = (id: number, type: MediaItemType) => {
    setSelected(prevSelected => {
      let newMedia = prevSelected;
      if (prevSelected.indexOf(id) >= 0) {
        newMedia = prevSelected.filter(s => s !== id);
      } else {
        newMedia = [...prevSelected, id];
      }

      const { state } = history.location;
      if (!state?.fromMediaType) {
        setSelectedType(newMedia.length > 0 ? type : undefined);
      }
      return newMedia;
    });
  };

  const addMediaToArrangement = useCallback(() => {
    const { state } = history.location;
    const arrangementId = state.fromArrangement;
    const spotId = state.fromSpot?.id;
    const mediaItems = state.fromSpot?.mediaItems;
    const formValues = state.fromArrangementFormFormValues;
    const { newArrangement } = state;
    const currentMediaItemLength = (mediaItems && mediaItems.length) || 0;

    const keyValues = selectedMedia.map((id, index) => {
      const newIndex = index + currentMediaItemLength;

      return [newIndex, id];
    });

    const keyValuesObject = Object.fromEntries(keyValues);

    const promise = dispatch(
      fetchData(
        Api.ARRANGEMENT_MEDIA_ITEMS_POST,
        {
          method: 'POST',
          body: JSON.stringify({
            area_number: spotId,
            media_item_ids: keyValuesObject,
            arrangement_id: arrangementId,
          }),
        },
        true,
      ),
    );

    promise
      .then(json => json.json())
      .then((result: { status: number }) => {
        dispatch(selectMediaItems(false));

        const { status } = result;

        const unprocessableMediaItems = status === 422;

        const location = {
          pathname: reverse(Paths.POOLS_ARRANGEMENTS, { id: pool.id }),
          state: {
            fromArrangement: arrangementId,
            fromArrangementFormFormValues: formValues,
            unprocessableMediaItems,
            newArrangement,
          },
        };
        history.push(location);
      });
  }, [dispatch, history, pool.id, selectedMedia]);

  const returnToArrangement = useCallback(() => {
    dispatch(selectMediaItems(false));

    const { state } = history.location;
    const arrangementId = state.fromArrangement;
    const formValues = state.fromArrangementFormFormValues;
    const { newArrangement } = state;

    const location = {
      pathname: reverse(Paths.POOLS_ARRANGEMENTS, { id: pool.id }),
      state: {
        fromArrangement: arrangementId,
        fromArrangementFormFormValues: formValues,
        newArrangement,
      },
    };
    history.push(location);
  }, [dispatch, history, pool.id]);

  useEffect(() => {
    const { state } = history.location;
    const showName = state?.fromArrangementFormFormValues?.name;
    const itemCount = selectedMedia.length;

    if (selectable) {
      dispatch(
        setInfoBar({
          message: (
            <Text flatten>
              <>
                {showName === '' || !showName ? (
                  'Select the media item(s) you want to use for your show.'
                ) : (
                  <>
                    Select the media item(s) you want to use for{' '}
                    <span style={{ whiteSpace: 'nowrap' }}>
                      &apos;
                      <Ellipsis text={showName} maxWidth="200px" />
                      &apos;
                    </span>{' '}
                    show.
                  </>
                )}

                {itemCount > 0 && (
                  <>
                    <br /> {`${itemCount} media item(s) selected`}
                  </>
                )}
              </>
            </Text>
          ),
          action: [
            {
              text: 'Cancel',
              handle: () => {
                dispatch(infoBarHide());
                returnToArrangement();
              },
              type: InfoBarActionType.LINK,
            },
            {
              text: 'Use selection',
              handle: () => {
                dispatch(infoBarHide());
                addMediaToArrangement();
              },
              type: InfoBarActionType.BUTTON,
              disabled: itemCount === 0,
            },
          ],
          state: InfoBarState.SELECT,
        }),
      );
    }
  }, [
    addMediaToArrangement,
    dispatch,
    history.location,
    returnToArrangement,
    selectable,
    selectedMedia.length,
  ]);

  if (!hasMediaViewRights) {
    return (
      <DeckEmpty title="No view rights for media">
        {noMediaViewRightsSubTitle}
      </DeckEmpty>
    );
  }

  return (
    <>
      <MediaFilter />

      {hasMediaViewRights ? (
        <>
          {!hasFilter && <Breadcrumbs />}
          {(!selectable || !!folder_info) && (
            <FolderHeader
              title="All media"
              buttonType="editFolder"
              editable={!selectable}
              dynamicHeaderTitle
              showOptions
            />
          )}

          {!selectable && hasMediaEditRights && !hasFilter && (
            // TODO: design check margin bottom when enable MediaUpload
            <div className="folder-header--margin">
              <MediaUpload
                poolId={pool.id}
                folderId={folder_info && folder_info.id}
              />
            </div>
          )}

          {!hasFilter && (
            <>
              <FolderHeader
                title="Folders"
                buttonType="newFolder"
                editable={!selectable}
              />
              <Folders editable={!selectable} />
            </>
          )}

          <Files
            selectable={selectable}
            selectedMedia={selectedMedia}
            setSelected={toggleSelectedMedia}
            selectedType={selectedType}
          />
          {!selectable && hasMediaEditRights && <MediaButton />}
        </>
      ) : (
        <DeckEmpty title="No view rights for media">
          {noMediaViewRightsSubTitle}
        </DeckEmpty>
      )}
    </>
  );
};

export default PoolsMedia;
