import equal from 'fast-deep-equal';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import Button from 'components/button/Button';
import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import { InputFontSize } from 'components/form/input/Input.enum';
import Icon from 'components/icon/Icon';
import LabelValueList from 'components/list/labelValueList/LabelValueList';
import LocationDropdown from 'components/location/dropdown/LocationDropdown';
import {
  PopupStickyFooter,
  PopupStickyForm,
  PopupStickyHeader,
  PopupStickyMain,
} from 'components/popup/Popup';
import {
  Location,
  LocationOption,
} from 'components/popup/management/User.types';
import Heading from 'components/typography/heading/Heading';

import UserRight from 'constants/UserRight.enum';

import { hasUserRights } from 'store/auth/hasUserRights';
import {
  infoBarHide,
  setInfoBar,
  setInfoBarShow,
} from 'store/info/infoActions';
import { InfoBarActionType, InfoBarState } from 'store/info/infoActions.enum';
import {
  deleteInputDeviceAction,
  editInputDeviceAction,
} from 'store/inputSignals/inputDevices/inputDevicesActions';
import { popupActionClear } from 'store/popup/popupActions';
import {
  InputDevice,
  RootState,
} from 'store/rootState';

import InputSignalsOverviewFieldset from './InputSignalsOverviewFieldset';
import attachedInputSignalsAreAllOnline from "./helpers";

import './inputDeviceEdit.scss';

const InputDeviceEdit: React.FC<InputDevice> = ({
  id,
  in_use,
  name,
  locations,
  input_signals,
  serial_number,
  version,
  connection,
}) => {
  const dispatch = useDispatch();
  const { request, inputDevice } = useSelector(
    (state: RootState) => state.inputs.inputDevices.selectedInputDevice,
  );

  const hasInputEditRights = dispatch(hasUserRights(UserRight.INPUT_EDIT));
  const [isSubmitting, setSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState({
    id,
    in_use,
    name,
    locations,
    input_signals,
    serial_number,
    version,
    connection,
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is a required field'),
  });

  useEffect(() => {
    setInitialValues({
      id,
      in_use,
      name,
      locations,
      input_signals,
      serial_number,
      version,
      connection,
    });
  }, [
    id,
    in_use,
    name,
    locations,
    input_signals,
    serial_number,
    version,
    connection,
  ]);

  const onSubmit = (values: InputDevice) => {
    if (equal(values, initialValues)) {
      dispatch(popupActionClear());
      dispatch(setInfoBarShow({ show: false }));
      return;
    }

    const allValues = {
      ...values,
      id,
      serial_number,
    };

    setSubmitting(true);
    dispatch(editInputDeviceAction(allValues, setSubmitting));
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });

  const inputDeviceDetails = [
    {
      label: 'Serial',
      value: serial_number,
    },
    {
      label: 'Software version',
      value: version,
    },
    {
      label: 'Connection',
      value: connection,
    },
  ];

  useEffect(() => {
  }, [inputDevice])

  return (
    <PopupStickyForm onSubmit={handleSubmit}>
      <PopupStickyHeader>
        <fieldset>
          <FormBlock flatten>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Input device name"
              onChange={handleChange}
              value={values.name}
              error={touched.name && errors.name}
              fontSize={InputFontSize.LARGE}
              suffix={!hasInputEditRights ? null : <Icon name="edit" />}
              disabled={!hasInputEditRights || isSubmitting}
            />
          </FormBlock>
        </fieldset>
      </PopupStickyHeader>
      <PopupStickyMain>
        <Heading level={2}>Input device details</Heading>
        <LabelValueList
          values={inputDeviceDetails}
          listId={1}
          loading={!serial_number}
          loadingItemLength={3}
        />
        <fieldset>
          <FormBlock flatten>
            <LocationDropdown
              id="locations"
              name="locations"
              label="This input device is available in:"
              width={580}
              value={values.locations}
              placeholder="Select locations"
              onChange={(values: Location[]) => {
                setFieldValue(
                  'locations',
                  [].slice.call(values).map((option: LocationOption) => ({
                    id: option.value,
                    name: option.label,
                  })),
                );
              }}
              error={touched.locations && errors.locations}
              disabled={!hasInputEditRights || isSubmitting}
              listSelected
              multiple
              showAllLocations
            />
          </FormBlock>
        </fieldset>
        <fieldset>
          <InputSignalsOverviewFieldset
            isLoading={request}
            id={id}
            input_signals={inputDevice?.input_signals || []}
            active={attachedInputSignalsAreAllOnline(
              inputDevice?.input_signals
            )}
            isDisabled={isSubmitting}
            setSubmitting={setSubmitting}
          />
        </fieldset>
      </PopupStickyMain>

      <PopupStickyFooter>
        <FormBlock hasInlineChildren flatten>
          {(hasInputEditRights && (
            <Button
              tag="a"
              size="medium"
              text="Delete"
              scheme="link"
              disabled={!hasInputEditRights || isSubmitting}
              hasShadow={false}
              handler={() => {
                if (isSubmitting) return;
                if (inputDevice && inputDevice.in_use) {
                  dispatch(
                    setInfoBar({
                      message:
                        'This input device is in use and can for that reason not be deleted',
                      action: [
                        {
                          text: 'Close',
                          handle: () => dispatch(infoBarHide()),
                          type: InfoBarActionType.LINK,
                        },
                      ],
                      state: InfoBarState.ERROR,
                    }),
                  );
                } else {
                  dispatch(
                    setInfoBar({
                      message:
                        'Are you sure you want to delete this input device?',
                      action: [
                        {
                          text: 'Yes, Delete',
                          handle: () =>
                            dispatch(deleteInputDeviceAction(id, true)),
                          type: InfoBarActionType.BUTTON,
                          color: 'blue',
                        },
                        {
                          text: 'Cancel',
                          handle: () => dispatch(infoBarHide()),
                          type: InfoBarActionType.LINK,
                        },
                      ],
                      state: InfoBarState.ERROR,
                    }),
                  );
                }
              }}
            />
          )) || (
              <Button
                tag="button"
                size="medium"
                text="Cancel"
                scheme="link"
                hasShadow={false}
                handler={() => {
                  dispatch(popupActionClear());
                }}
                disabled={isSubmitting}
              />
            )}

          {/* TODO: fix dispatch typing in middleware */}
          {/* @ts-ignore */}
          {hasInputEditRights && (
            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Save and close"
              disabled={isSubmitting}
            />
          )}
        </FormBlock>
      </PopupStickyFooter>
    </PopupStickyForm>
  );
};

export default InputDeviceEdit;
