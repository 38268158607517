// @ts-nocheck

import { createTheme } from '@material-ui/core';

const Theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        color: '#323333',
      },
      h2: {
        fontWeight: 500,
      },
      h4: {
        fontWeight: 500,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'rgba(203, 213, 214, .3)',
        justifyContent: 'unset',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#646666',
      },
      toolbarBtnSelected: {
        color: '#323333',
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        justifyContent: 'center',
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: '230px',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        marginBottom: '30px',
        marginTop: '20px',
        padding: '0 20px',
      },
      transitionContainer: {
        order: '1',
        '&>*': {
          color: '#646666',
          fontWeight: 500,
          left: 0,
          textAlign: 'left',
        },
      },
      dayLabel: {
        color: '#323333',
      },
      iconButton: {
        color: '#0095DA',
        marginLeft: '4px',
        order: '2',
        padding: '5px',
      },
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        '&>*': {
          left: '20px',
          right: '20px',
        },
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0)',
        },
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: '#0095DA',
      },
      root: {
        color: '#323333',
        '&:selected': {
          color: '#323333',
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        background: 'linear-gradient(90deg, #0095DA, #0087C9)',
        backgroundColor: '#0095DA',
        '&:hover': {
          background: 'linear-gradient(90deg, #0095DA, #0087C9)',
          backgroundColor: '#0095DA',
        },
      },
      day: {
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, .1)',
        },
      },
      current: {
        color: '#323333',
      },
      hidden: {
        opacity: '.3',
      },
    },
    MuiPickersTimePickerToolbar: {
      hourMinuteLabel: {
        marginLeft: '-4px',
        fontWeight: 500,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: '#0095DA',
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#0095DA',
      },
      noPoint: {
        backgroundColor: '#0095DA',
      },
      thumb: {
        border: '14px solid #0095DA',
      },
    },
    MuiTouchRipple: {
      root: {
        display: 'none',
      },
    },
  },
});

export default Theme;
