import { UserRolesActionTypes } from './userRolesActions';

export const userRolesInitialState = {
  request: false,
  userRoles: [],
};

const userRolesReducer = (state = userRolesInitialState, action) => {
  switch (action.type) {
    case UserRolesActionTypes.USER_ROLES_REQUESTED:
      return { ...state, request: true };
    case UserRolesActionTypes.USER_ROLES_SUCCEEDED:
      return {
        ...state,
        userRoles: action.payload,
        request: false,
      };
    case UserRolesActionTypes.USER_ROLES_FAILED:
      return {
        ...userRolesInitialState,
        request: false,
      };
    case UserRolesActionTypes.USER_ROLES_ADDED:
      return {
        ...state,
        userRoles: [action.payload, ...state.userRoles],
      };
    case UserRolesActionTypes.USER_ROLES_UPDATED:
      return {
        ...state,
        userRoles: state.userRoles.map(userRole => {
          if (userRole.id === action.payload.id) {
            return {
              ...action.payload,
            };
          }
          return userRole;
        }),
      };
    case UserRolesActionTypes.USER_ROLES_DELETED:
      return {
        ...state,
        userRoles: state.userRoles.filter(role => role.id !== action.payload),
      };
    default:
      return state;
  }
};

export default userRolesReducer;
