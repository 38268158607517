import { AccountSettingsActionTypes } from './accountSettingsActions';

export const accountSettingsInitialState = {
  two_factor_popup_is_active: false,
  verify_email_popup_is_active: false,
  verify_email_is_set: false,
  verify_email_email: '',
  two_factor_is_set: false,
  two_factor_resend_email: 'unsent',
};

const accountSettingsReducer = (
  state = accountSettingsInitialState,
  action,
) => {
  switch (action.type) {
    case AccountSettingsActionTypes.ACCOUNT_SETTINGS_VERIFY_EMAIL_POPUP_ACTIVE:
      return { ...state, verify_email_popup_is_active: true };
    case AccountSettingsActionTypes.ACCOUNT_SETTINGS_VERIFY_EMAIL_POPUP_INACTIVE:
      return { ...state, verify_email_popup_is_active: false };
    case AccountSettingsActionTypes.ACCOUNT_SETTINGS_VERIFY_EMAIL_SET:
      return {
        ...state,
        verify_email_is_set: true,
        verify_email_email: action.payload,
      };
    case AccountSettingsActionTypes.ACCOUNT_SETTINGS_VERIFY_EMAIL_UNSET:
      return { ...state, verify_email_is_set: false };
    case AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_SET:
      return { ...state, two_factor_is_set: true };
    case AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_UNSET:
      return { ...state, two_factor_is_set: false };
    case AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_POPUP_ACTIVE:
      return { ...state, two_factor_popup_is_active: true };
    case AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_POPUP_INACTIVE:
      return { ...state, two_factor_popup_is_active: false };
    case AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_RESEND_EMAIL_INITIAL:
      return { ...state, two_factor_resend_email: 'unsent' };
    case AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_RESEND_EMAIL_PROCESSING:
      return { ...state, two_factor_resend_email: 'processing' };
    case AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_RESEND_EMAIL_SUCCESS:
      return { ...state, two_factor_resend_email: 'success' };
    case AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_RESEND_EMAIL_FAILED:
      return { ...state, two_factor_resend_email: 'failed' };
    default:
      return state;
  }
};

export default accountSettingsReducer;
