import { Api } from 'constants/Routes.enum';
import UserRight from 'constants/UserRight.enum';
import PopupTypes from 'constants/PopupTypes.enum';

import fetchData from 'store/fetchData';
import { setInfoBar } from 'store/info/infoActions';
import { popupActionClear, popupActionSet } from 'store/popup/popupActions';
import { InfoBarState } from 'store/info/infoActions.enum';

import { reverse } from 'named-urls';

export const UserRolesActionTypes = {
  USER_ROLES_REQUESTED: '@@userRoles/requested',
  USER_ROLES_SUCCEEDED: '@@userRoles/success',
  USER_ROLES_FAILED: '@@userRoles/error',
  USER_ROLES_ADDED: '@@userRoles/added',
  USER_ROLES_UPDATED: '@@userRoles/updated',
  USER_ROLES_DELETED: '@@userRoles/deleted',
};

export const userRolesRequested = () => ({
  type: UserRolesActionTypes.USER_ROLES_REQUESTED,
});

export const userRolesSucceeded = users => ({
  type: UserRolesActionTypes.USER_ROLES_SUCCEEDED,
  payload: users,
});

export const userRolesFailed = () => ({
  type: UserRolesActionTypes.USER_ROLES_FAILED,
});

export const userRolesAdded = userRole => ({
  type: UserRolesActionTypes.USER_ROLES_ADDED,
  payload: userRole,
});

export const userRolesUpdated = userRole => ({
  type: UserRolesActionTypes.USER_ROLES_UPDATED,
  payload: userRole,
});

export const userRolesDeleted = id => ({
  type: UserRolesActionTypes.USER_ROLES_DELETED,
  payload: id,
});

export const fetchUserRolesAction = () => dispatch => {
  dispatch(userRolesRequested());

  const promise = dispatch(fetchData(Api.USER_ROLES));

  promise
    .then(json => json.json())
    .then(result => {
      const { user_roles } = result;
      if (user_roles) {
        dispatch(userRolesSucceeded(user_roles));
      } else {
        dispatch(userRolesFailed());
      }
    })
    .catch(() => {
      dispatch(userRolesFailed());
    });
};

export const addUserRoleAction = ({
  userRoleData,
  id,
  setSubmitting,
}) => async dispatch => {
  const isEditing = !!id;

  // create or update user role
  const promise = dispatch(
    fetchData(
      isEditing ? reverse(Api.USER_ROLES_PATCH, { id }) : Api.USER_ROLES_POST,
      {
        method: isEditing ? 'PATCH' : 'POST',
        body: userRoleData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message, user_group } = result;

      if (user_group) {
        if (isEditing) {
          // update user role state
          dispatch(userRolesUpdated(user_group));
        } else {
          // add new user role to state
          dispatch(userRolesAdded(user_group));
        }

        dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
        dispatch(popupActionClear());
      } else {
        dispatch(
          setInfoBar({ message, timeout: 0, state: InfoBarState.ERROR }),
        );
      }

      setSubmitting(false);
    })
    .catch(err => {
      console.error(err);
      setSubmitting(false);
      dispatch(
        setInfoBar({
          message: 'Failed to add new user role',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const openUserRoleAction = id => async dispatch => {
  const promise = dispatch(fetchData(reverse(Api.USER_ROLES_DETAIL, { id })));

  promise
    .then(json => json.json())
    .then(result => {
      const { admin, name, rights, users } = result.user_group;

      rights.push(UserRight.POOLS_VIEW);
      if (rights.includes(UserRight.MEDIA_VIEW)) {
        rights.push(UserRight.MEDIA_FOLDERS_VIEW);
      }

      dispatch(
        popupActionSet(PopupTypes.MANAGEMENT_USER_ROLE, {
          id,
          name,
          rights,
          isAdmin: admin,
          users: users.map(user => {
            return {
              id: user.id,
              name: `${user.firstname} ${user.lastname}`,
            };
          }),
        }),
      );
    })
    .catch(err => {
      console.error(err);
      dispatch(
        setInfoBar({
          message: 'Failed to find user role',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const deleteUserRoleAction = id => dispatch => {
  const promise = dispatch(
    fetchData(reverse(Api.USER_ROLES_DELETE, { id }), {
      method: 'DELETE',
    }),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message } = result;

      dispatch(userRolesDeleted(id));
      dispatch(popupActionClear());
      dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
    })
    .catch(error => {
      console.error(error);
      dispatch(
        setInfoBar({
          message: error,
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};
