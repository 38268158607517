import React from 'react';
import classNames from 'classnames';

import './Card.scss';
import { CardProps } from './Card.types';
import { CardStyle } from './Card.enum';

const Card: React.FC<CardProps> = ({
  className,
  zoomable,
  children,
  shadow = false,
  highlightOnHover = true,
  interactive = true,
  styling = CardStyle.NORMAL,
}) => {
  return (
    <div
      className={classNames('card', className, `card--style-${styling}`, {
        'card--interactive': interactive,
        'card--zoom': zoomable,
        'card--shadow': shadow,
        'card--highlight-on-hover': highlightOnHover,
      })}
    >
      {children}
    </div>
  );
};

export default Card;
