export enum ArrangementsItemSortAttribute {
  UPDATED_AT = 'updated_at',
  NAME = 'name',
  CREATED_AT = 'created_at'
}

export enum ArrangementsItemSortOrder {
  ASCENDING = 'asc',
  DESCENDING = 'desc'
}

export interface ArrangementsItemSort {
  attribute: ArrangementsItemSortAttribute;
  order: ArrangementsItemSortOrder;
}