import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reverse } from 'named-urls';
import { useHistory } from 'react-router-dom';

import OverviewList from 'components/overviewList/OverviewList';
import { OverviewListItemStatus } from 'components/overviewList/OverviewListItem.enum';
import Status from 'components/status/Status';
import Ellipsis from 'components/typography/ellipsis/Ellipsis';
import DeckEmpty from 'components/deck/DeckEmpty';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';

import { fetchUsersAction } from 'store/users/usersActions';
import { popupActionSet } from 'store/popup/popupActions';
import { setInfoBarShow } from 'store/info/infoActions';
import { RootState } from 'store/rootState';

import { Paths } from 'constants/Routes.enum';
import PopupTypes from 'constants/PopupTypes.enum';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { hasSuperAdminRights } from 'store/auth/hasUserRights';
import { OverviewListHeader } from 'components/overviewList/OverviewList.types';
import { OverviewListItemItem } from 'components/overviewList/OverviewListItem.types';

const UsersOverview: React.FC = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const history = useHistory();
  const { request, users } = useSelector((state: RootState) => state.users);
  const isSuperAdmin = dispatch(hasSuperAdminRights())
  const { customerOverride } = useSelector((state: RootState) => state.superAdmin);

  useEffect(() => {
    dispatch(fetchUsersAction());
  }, [dispatch]);

  const mappedUsers = useMemo(() => {
    const userList = isSuperAdmin && customerOverride
      ? users.filter(user => user.customer_id === Number(customerOverride))
      : users

    return userList.map(
        ({ id, status, firstname, lastname, email, roles, locations, customer_name }) => {
          return {
            key: id.toString(),
            id: id.toString(),
            items: [
              {
                key: `${id}_status`,
                width: '25px',
                content: (
                  <Status
                    isActive={status === 'activated'}
                    expired={status === 'expired'}
                    hasText={false}
                  />
                ),
              },
              {
                key: `${id}_name`,
                content: `${firstname} ${lastname}`,
              },
              {
                key: `${id}_email`,
                width: '25%',
                content: <Ellipsis text={email} />,
              },
              isSuperAdmin ? {
                key: `${id}_customer`,
                width: '120px',
                content: (
                  <Ellipsis text={customer_name} />
                )
              } : undefined,
              {
                key: `${id}_roles`,
                width: '25%',
                content:
                  roles.length > 0 ? (
                    <Ellipsis
                      text={roles
                        .map(role => role.name)
                        .sort()
                        .join(', ')}
                    />
                  ) : (
                    'Unassigned'
                  ),
                status:
                  roles.length > 0
                    ? OverviewListItemStatus.ACTIVE
                    : OverviewListItemStatus.DISABLED,
              },
              {
                key: `${id}_locations`,
                content: locations,
                width: '100px'
              },
            ].filter(Boolean) as OverviewListItemItem[],
            onClick: () => {
              history.push({
                pathname: reverse(Paths.MANAGEMENT_USER_DETAILS, { id }),
              });
            },
          };
        },
    );
  }, [users, history, isSuperAdmin, customerOverride]);

  const overviewHeaders = [
    { key: 'Status', content: '', width: '25px' },
    { key: 'Name', content: 'Name' },
    { key: 'E-mail', content: 'E-mail', width: '25%' },
    isSuperAdmin ? { key: 'Customer', content: 'Customer', width: '120px' } : undefined,
    { key: 'Roles', content: 'Roles', width: '25%' },
    { key: 'Locations', content: 'Locations', width: '100px' },
  ].filter(Boolean) as OverviewListHeader[];

  if (!request && mappedUsers.length === 0) return (
    <DeckEmpty title="No users found" />
  )

  return (
    <>
      <OverviewList
        headers={overviewHeaders}
        content={mappedUsers}
        cardProps={{ highlightOnHover: true, interactive: true }}
        loading={request}
      />

      <div className="detail-button">
        <Button
          text={<Icon name="plus" size="medium" />}
          hoverText="Add user"
          hoverTextInverse
          handler={() => {
            dispatch(popupActionSet(PopupTypes.MANAGEMENT_USER, {}));
            dispatch(setInfoBarShow({ show: false }));
          }}
          shapeEdges="rounded"
        />
      </div>
    </>
  );
};

export default UsersOverview;
