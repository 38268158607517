import PropTypes from 'prop-types';
import React from 'react';

import OverviewList from 'components/overviewList/OverviewList';
import Status from 'components/status/Status';

const MediaShowsOverviewFieldset = ({ shows }) => {
  const overviewHeaders = [
    {
      key: 'name',
      content: 'Name',
      width: null,
      align: 'left',
    },
    {
      key: 'status',
      content: 'Status',
      width: '130px',
      align: 'left',
    },
  ];
  const overviewData = shows.map(show => ({
    id: show.id,
    items: [
      {
        width: null,
        align: 'left',
        content: show.name,
      },
      {
        width: '130px',
        align: 'left',
        content: (
          <Status
            isActive={show.active}
            needsApproval={show.pending_approval}
          />
        ),
      },
    ],
    tag: 'div',
  }));

  return (
    <fieldset>
      <legend>
        Used in {shows.length} show{shows.length > 1 && 's'}
      </legend>
      <OverviewList headers={overviewHeaders} content={overviewData} />
    </fieldset>
  );
};

MediaShowsOverviewFieldset.propTypes = {
  shows: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      id: PropTypes.number,
      name: PropTypes.string,
      pending_approval: PropTypes.bool,
    }),
  ).isRequired,
};

export default MediaShowsOverviewFieldset;
