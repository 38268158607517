import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import equal from 'fast-deep-equal';

import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import { ButtonColor } from 'components/button/Button.enum';
import Icon from 'components/icon/Icon';
import Text from 'components/typography/text/Text';
import SubmitButton from 'components/form/submit-button/SubmitButton';
import {
  PopupStickyForm,
  PopupStickyHeader,
  PopupStickyMain,
  PopupStickyFooter,
} from 'components/popup/Popup';

import { deletePoolAction, editPoolAction } from 'store/pool/poolActions';
import { infoBarHide, setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import { popupActionClear } from 'store/popup/popupActions';

const Pool = ({ name, id }) => {
  const dispatch = useDispatch();
  const infoBar = useSelector(state => state.info.bar);
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();
  const initialForm = {
    id,
    name,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is a required field'),
  });

  const onSubmit = values => {
    if (equal(values, initialForm)) {
      dispatch(popupActionClear());
      return;
    }
    setSubmitting(true);
    dispatch(editPoolAction(values, setSubmitting));
  };

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        initialTouched,
      }) => (
        <PopupStickyForm onSubmit={handleSubmit}>
          <PopupStickyHeader>
            <fieldset>
              <FormBlock flatten>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="New group"
                  value={values.name}
                  onChange={handleChange}
                  fontSize="large"
                  suffix={<Icon name="edit" />}
                  error={touched.name && errors.name}
                />
              </FormBlock>
            </fieldset>
          </PopupStickyHeader>
          <PopupStickyMain>
            <fieldset>
              <legend>Deleting this group</legend>
              <Text color="grey-black">
                If you delete this group all existing events, shows, triggers
                and devices appointed to this group will be deleted with it.
              </Text>
              <Button
                tag="a"
                size="small"
                scheme="link"
                text="Delete this group"
                hasShadow={false}
                handler={() => {
                  dispatch(
                    setInfoBar({
                      message:
                        'Are you sure you want to continue deleting this group?',
                      action: [
                        {
                          text: 'Delete',
                          handle: () => {
                            dispatch(deletePoolAction(id, history));
                          },
                          color: ButtonColor.RED,
                        },
                        {
                          text: 'Cancel',
                          handle: () => {
                            dispatch(infoBarHide());
                          },
                          color: ButtonColor.GREEN,
                        },
                      ],
                      state: InfoBarState.WARNING,
                    }),
                  );
                }}
                disabled={isSubmitting}
              />
            </fieldset>
          </PopupStickyMain>
          <PopupStickyFooter>
            <FormBlock hasInlineChildren flatten>
              <Button
                tag="button"
                type="button"
                size="medium"
                text="Cancel"
                scheme="link"
                hasShadow={false}
                handler={() => {
                  dispatch(popupActionClear());
                  if (infoBar.show) {
                    dispatch(infoBarHide());
                  }
                }}
                disabled={isSubmitting}
              />
              <SubmitButton canSave={initialTouched} disabled={isSubmitting} />
            </FormBlock>
          </PopupStickyFooter>
        </PopupStickyForm>
      )}
    </Formik>
  );
};

export default Pool;
