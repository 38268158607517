import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Button from 'components/button/Button';
import DeckEmpty from 'components/deck/DeckEmpty';
import Icon from 'components/icon/Icon';
import OverviewList from 'components/overviewList/OverviewList';
import { OverviewListContent, OverviewListHeader } from 'components/overviewList/OverviewList.types';

import PopupTypes from 'constants/PopupTypes.enum';

import {
  fetchLocationsAction,
  openLocationAction,
} from 'store/locations/locationsActions';
import { hasAdminRights, hasSuperAdminRights } from 'store/auth/hasUserRights';
import { setInfoBarShow } from 'store/info/infoActions';
import { popupActionSet } from 'store/popup/popupActions';
import { RootState } from 'store/rootState';
import { OverviewListItemItem } from 'components/overviewList/OverviewListItem.types';
import Ellipsis from 'components/typography/ellipsis/Ellipsis';

const LocationsOverview = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const isAdmin = dispatch(hasAdminRights())
  const isSuperAdmin = dispatch(hasSuperAdminRights())
  const { customerOverride } = useSelector((state: RootState) => state.superAdmin)
  const { request, locations } = useSelector(
    (state: RootState) => state.locations,
  );

  const overviewHeaders = [
    { key: 'Name', content: 'Name', width: '35%' },
    { key: 'Groups', content: 'Groups', width: '100px' },
    { key: 'Users', content: 'Users', width: '80px' },
    isSuperAdmin ? { key: 'Customer', content: 'Customer', width: '120px' } : undefined,
    { key: 'Notifications', content: 'Notifications', width: '' },
  ].filter(Boolean) as OverviewListHeader[];

  useEffect(() => {
    dispatch(fetchLocationsAction(true));
  }, [dispatch]);

  const mappedLocations = useMemo(() => {
    const locs = isSuperAdmin && customerOverride
      ? locations.filter(loc => loc.customer_id === Number(customerOverride))
      : locations

    const mapped = locs.map<OverviewListContent>(
      ({ id, name, groups, customer_name, users }) => {
        return {
          key: id,
          id,
          items: [
            {
              key: `${id}_name`,
              width: '35%',
              content: name,
            },
            {
              key: `${id}_groups`,
              width: '100px',
              content: groups,
            },
            {
              key: `${id}_users`,
              width: '80px',
              content: users,
            },
            isSuperAdmin ? {
              key: `${id}_customer`,
              width: '120px',
              content: (
                <Ellipsis text={customer_name} />
              )
            } : undefined,
            {
              key: `${id}_notifications`,
              content: '',
            },
          ].filter(Boolean) as OverviewListItemItem[],
          onClick: () => {
            dispatch(openLocationAction(id));
          },
        };
      },
    );
    return mapped
  }, [dispatch, locations, isSuperAdmin, customerOverride]);

  return (
    <>
      {!request && locations.length === 0 ? (
        <DeckEmpty title="No locations found" />
      ) : (
        <OverviewList
          headers={overviewHeaders}
          content={mappedLocations}
          cardProps={{ highlightOnHover: true, interactive: true }}
          loading={request}
        />
      )}
      {isAdmin && (
        <div className="detail-button">
          <Button
            text={<Icon name="plus" size="medium" />}
            hoverText="Add location"
            hoverTextInverse
            handler={() => {
              dispatch(popupActionSet(PopupTypes.MANAGEMENT_LOCATION, {}));
              dispatch(setInfoBarShow({ show: false }));
            }}
            shapeEdges="rounded"
          />
        </div>
      )}
    </>
  );
};

export default LocationsOverview;
