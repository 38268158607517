import React from 'react';
import DOMPurify from 'dompurify';
import { TextManipulationProps } from './TextManipulation.types';

const TextManipulation: React.FC<TextManipulationProps> = ({
  isXSSsafe = false,
  innerHTML,
  tag: Element,
  dataCy,
  ...props
}) => {
  let html = innerHTML;
  html = isXSSsafe ? html : DOMPurify.sanitize(html);

  return (
    <Element
      data-cy={dataCy}
      dangerouslySetInnerHTML={{ __html: html }}
      {...props}
    />
  );
};

export default TextManipulation;
