import React from 'react';
import classNames from 'classnames';

import { FormBlockProps } from './FormBlock.types';
import './FormBlock.scss';

const FormBlock: React.FC<FormBlockProps> = ({
  hasInlineChildren = false,
  ignoreSplit = false,
  noSpacing = false,
  spacing,
  isEnd = false,
  isStart = false,
  flatten = false,
  error = false,
  halfWidth = false,
  children,
  style,
  className,
}) => {
  const hasMultipleChildren =
    React.Children.count(children) > 1 && !hasInlineChildren;

  return (
    <div
      style={style}
      className={classNames('form__block', className, {
        'form__block--split':
          (hasMultipleChildren && !ignoreSplit) || halfWidth,
        'form__block--split-inline': hasInlineChildren,
        'form__block--inline': ignoreSplit,
        'form__block--no-spacing': noSpacing,
        'form__block--end': isEnd,
        'form__block--start': isStart,
        'form__block--flatten': flatten,
        'form__block--error': error,
        [`form__block--spacing-${spacing}`]: spacing,
      })}
    >
      {error && <span>{error}</span>}

      {children}
    </div>
  );
};

export default FormBlock;
