import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import { popupActionClear } from 'store/popup/popupActions';
import { addDeviceAction } from 'store/devices/devicesActions';
import DevicesDropdown from 'components/devices/dropdown/DevicesDropdown';

const Device = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pool = useSelector(state => state.pool);
  const [isSubmitting, setSubmitting] = useState(false);
  const initialForm = {
    id: '',
    pool_id: pool.id,
  };

  const validationSchema = Yup.object().shape({
    id: Yup.number().required('Device is required'),
  });

  const onSubmit = values => {
    setSubmitting(true);
    dispatch(addDeviceAction(values, history, setSubmitting));
  };

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        submitCount,
        values,
        errors,
        touched,
        setFieldValue,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <fieldset>
            <legend>Add device to group</legend>
            <FormBlock flatten>
              <DevicesDropdown
                id="id"
                name="id"
                label="Select device"
                value={values.id}
                error={(touched.id || submitCount > 0) && errors.id}
                onChange={setFieldValue}
                placeholder="Select device"
              />
            </FormBlock>
          </fieldset>

          <FormBlock hasInlineChildren isEnd>
            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Cancel"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(popupActionClear());
              }}
              disabled={isSubmitting}
            />

            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Add and close"
              disabled={isSubmitting}
            />
          </FormBlock>
        </form>
      )}
    </Formik>
  );
};

export default Device;
