import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Card from 'components/card/Card';
import Button from 'components/button/Button';
import {
  ButtonScheme,
  ButtonShapeEdges,
  ButtonSize,
  ButtonTag,
} from 'components/button/Button.enum';
import Icon from 'components/icon/Icon';
import { IconName } from 'components/icon/Icon.enum';
import Status from 'components/status/Status';
import Heading from 'components/typography/heading/Heading';
import { editTriggerAction } from 'store/triggers/triggersActions';
import IconList from 'components/list/iconList/IconList';
import { TextManipulationTag } from 'components/textManipulation/TextManipulation.enum';
import TextManipulation from 'components/textManipulation/TextManipulation';

import { TriggerCardProps } from './TriggerCard.types';
import './TriggerCard.scss';

const TriggerCard: React.FC<TriggerCardProps> = ({
  id,
  name,
  when,
  show,
  until,
  active,
  pending,
  loading = false,
}) => {
  const dispatch = useDispatch();

  const cardClick = () => {
    dispatch(editTriggerAction(id));
  };

  const listItems = [
    {
      icon: IconName.TARGET,
      content: when ? (
        <TextManipulation
          tag={TextManipulationTag.SPAN}
          innerHTML={when.toString()}
        />
      ) : (
        ''
      ),
    },
    {
      icon: IconName.IMAGE_SMALL,
      content: show ? (
        <TextManipulation
          tag={TextManipulationTag.SPAN}
          innerHTML={show.toString()}
        />
      ) : (
        ''
      ),
    },
    {
      icon: IconName.STOP,
      content: until ? (
        <TextManipulation
          tag={TextManipulationTag.SPAN}
          innerHTML={until.toString()}
        />
      ) : (
        ''
      ),
    },
  ];

  return (
    <button
      type="button"
      tabIndex={0}
      onClick={cardClick}
      onKeyDown={e => e.key === '13' && cardClick}
      className={classNames('trigger-card__button', {
        button__interaction: !loading,
      })}
    >
      <Card
        className="trigger-card"
        zoomable={!loading}
        highlightOnHover={!loading}
      >
        <Heading level={2} className="trigger-card__title" title={name} loading={loading}>
          {name}
        </Heading>
        <IconList
          items={listItems}
          iconSize="small"
          loading={loading}
          singleLine
        />
        <div>
          <div className="card__state">
            <Status
              className="trigger-card__status"
              isActive={active}
              inactiveText="Disabled"
              needsApproval={pending}
              loading={loading}
            />
          </div>
          <Button
            tag={ButtonTag.SPAN}
            text={<Icon name="arrow-right" />}
            size={ButtonSize.SMALL}
            hasShadow={false}
            className="card__button"
            scheme={ButtonScheme.CARD}
            shapeEdges={ButtonShapeEdges.ROUNDED}
            loading={loading}
          />
        </div>
      </Card>
    </button>
  );
};

export default TriggerCard;
