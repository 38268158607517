import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import equal from 'fast-deep-equal';

import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import { popupActionClear } from 'store/popup/popupActions';
import { editPasswordAction } from 'store/account/accountSettingsActions';
import Text from 'components/typography/text/Text';
import RegexOptions from 'constants/RegexOptions';
import { RootState } from 'store/rootState';
import { AccountSettingsPasswordValues } from './AccountSettingsPassword.types';

const AccountSettingsPassword = () => {
  const dispatch = useDispatch();
  const id = useSelector((state: RootState) => state.auth?.user?.id);
  const [isSubmitting, setSubmitting] = useState(false);
  const initialForm = {
    password: '',
    passwordConfirm: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is a required field')
      .matches(
        RegexOptions.PASSWORD_VALIDATION,
        'Password does not meet required formatting.',
      ),
    passwordConfirm: Yup.string()
      .required('Password confirmation is a required field')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  });
  const onSubmit = (values: AccountSettingsPasswordValues) => {
    setSubmitting(true);

    const passwordData = new FormData();
    passwordData.append('user[password]', values.password);
    passwordData.append('user[password_confirmation]', values.passwordConfirm);

    if (equal(values, initialForm)) {
      setSubmitting(false);
      dispatch(popupActionClear());
      return;
    }
    dispatch(editPasswordAction(passwordData, id, setSubmitting));
  };

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <fieldset>
            <FormBlock>
              <Input
                type="password"
                label="Password"
                name="password"
                id="password"
                scheme="white"
                value={values.password}
                placeholder="Password"
                onChange={handleChange}
                error={touched.password && errors.password}
              />
            </FormBlock>
            <FormBlock>
              <Input
                type="password"
                label="Password confirmation"
                name="passwordConfirm"
                id="passwordConfirm"
                scheme="white"
                value={values.passwordConfirm}
                placeholder="Retype your new password"
                onChange={handleChange}
                error={touched.passwordConfirm && errors.passwordConfirm}
              />
            </FormBlock>
            <Text color="grey-dark" flatten>
              A password requires 8 characters, 1 capital and 1 number
            </Text>
          </fieldset>

          <FormBlock hasInlineChildren isEnd>
            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Cancel"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(popupActionClear());
              }}
              disabled={isSubmitting}
            />
            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Save and close"
              disabled={isSubmitting}
            />
          </FormBlock>
        </form>
      )}
    </Formik>
  );
};

export default AccountSettingsPassword;
