import { Customer } from "./customer.types"
import { CustomersActionTypes } from "./customerActions"



export const customersInitialState = {
  customers: []
}

const customersReducer = (state = customersInitialState, action: {
  type: string,
  payload: Customer[]
}) => {
  switch (action.type) {
    case CustomersActionTypes.CUSTOMERS_CLEAR:
      return customersInitialState
    case CustomersActionTypes.CUSTOMERS_SET:
      return {
        ...state,
        customers: action.payload
      }
    default:
      return state
  }
}

export default customersReducer