import React from 'react';
import PropTypes from 'prop-types';

import { ArrangementMediaProps } from 'constants/types/arrangmentTypes';

import FormBlock from 'components/form/block/FormBlock';
import ArrangementMediaBlock from 'components/arrangement/media/ArrangementMediaBlock';

import './ArrangementMedia.scss';

const ArrangementMedia = ({
  templateId,
  arrangementId,
  arrangementType,
  media,
  formValues,
  pending_approval,
  touched,
  errors,
  handleChange,
  loading,
  dimension,
  spots,
  spotRefs,
}) => {
  return (
    <>
      {templateId && (
        <fieldset>
          <legend>Media</legend>
          {loading && <div>Loading media areas</div>}
          {spots &&
            spots.length > 0 &&
            spots.map((spot, index) => (
              <FormBlock
                flatten={index === spots.length - 1}
                key={`${spot.name}_${spot.area_number}`}
                className="arrangement-media__list"
              >
                <ArrangementMediaBlock
                  pending_approval={pending_approval}
                  arrangementId={arrangementId}
                  arrangementType={arrangementType}
                  dimension={dimension}
                  formValues={formValues}
                  media={media}
                  spot={spot}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  spotRefs={spotRefs}
                />
              </FormBlock>
            ))}
        </fieldset>
      )}
    </>
  );
};

ArrangementMedia.propTypes = {
  templateId: PropTypes.number,
  arrangementId: PropTypes.number,
  arrangementType: PropTypes.oneOf(['RegularArrangement', 'TriggerArrangement'])
    .isRequired,
  media: ArrangementMediaProps,
  formValues: PropTypes.shape({
    isDefault: PropTypes.bool,
    name: PropTypes.string,
    template: PropTypes.number,
  }),
  pending_approval: PropTypes.bool,
  spotRefs: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

ArrangementMedia.defaultProps = {
  templateId: null,
  arrangementId: null,
  media: null,
  formValues: null,
  pending_approval: false,
  spotRefs: null,
};

export default ArrangementMedia;
