import classNames from 'classnames';
import React from 'react';

import Skeleton from 'components/skeleton/Skeleton';
import { SkeletonTag, SkeletonType } from 'components/skeleton/Skeleton.enum';

import './Heading.scss';
import { HeadingProps } from './Heading.types';

const Heading: React.FC<HeadingProps> = ({
  children,
  level = 1,
  stylingLevel,
  noMargin = false,
  className,
  dataCy,
  loading = false,
  title,
}) => {
  const tag = (level: 1 | 2 | 3 | 4 | 5 | 6) => {
    switch (level) {
      case 2:
        return SkeletonTag.H2;
      case 3:
        return SkeletonTag.H3;
      case 4:
        return SkeletonTag.H4;
      case 5:
        return SkeletonTag.H5;
      case 6:
        return SkeletonTag.H6;
      default:
        return SkeletonTag.H1;
    }
  };

  return React.createElement(
    tag(level),
    {
      'data-cy': dataCy,
      className: classNames(
        {
          [`heading--h${stylingLevel}`]: stylingLevel,
          'heading--no-margin': noMargin,
        },
        className,
      ),
      title,
    },
    loading ? (
      <Skeleton type={SkeletonType.HEADING} tag={tag(level)} />
    ) : (
      children
    ),
  );
};

export default Heading;
