import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { ThemeProvider } from '@material-ui/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import Label from 'components/form/label/Label';
import Icon from 'components/icon/Icon';
import defaultTimezone from 'constants/DefaultTimezone';
import Theme from './Theme';

import 'react-datepicker/dist/react-datepicker.css';
import './DateTime.scss';
import { RootState } from 'store/rootState';
import { DateTimeInputProps } from './DateTime.types';
import MomentUtils from '@date-io/moment';

const DateInput = ({
  label,
  value = new Date(),
  placeholder,
  id,
  name,
  onChange,
  error,
  isChanged = false,
  disabled = false,
  minDate = moment(0),
}: DateTimeInputProps) => {
  const timeZone = useSelector((state: RootState) => state.pool?.location?.timezone_info);

  return (
    <Label idFor={id} label={label}>
      <div className="form__datetime__container">
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <ThemeProvider theme={Theme}>
            <KeyboardDatePicker
              autoOk
              placeholder={placeholder}
              variant="inline"
              value={value}
              onChange={date =>
                onChange(name, moment.tz(date, timeZone || defaultTimezone))
              }
              disabled={disabled}
              format="DD-MM-yyyy"
              className={classNames('form__datetime', {
                'form__datetime--changed': isChanged,
                'form__datetime--disabled': disabled,
              })}
              helperText={null}
              keyboardIcon={
                <Icon
                  name="calendar"
                  className={classNames('form__datetime__icon', {
                    'form__datetime__icon--disabled': disabled,
                  })}
                />
              }
              error={!!error}
              minDate={minDate}
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>


        {error && <span className="form__container-error" />}
      </div>
    </Label>
  );
};

export default DateInput;
