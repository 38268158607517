import React, { FC } from 'react';
import classNames from 'classnames';

import Skeleton from 'components/skeleton/Skeleton';
import { SkeletonType } from 'components/skeleton/Skeleton.enum';
import { TextProps } from './Text.types';

import './Text.scss';

const Text: FC<TextProps> = ({
  children,
  className,
  color,
  flatten,
  noTop,
  size = 'normal',
  tag = 'p',
  loading,
  loadingStyles,
  style,
  dataCy,
}) => {
  return React.createElement(
    tag,
    {
      className: classNames(
        'text',
        {
          [`text--${size}`]: size,
          [`text--color-${color}`]: color,
          'text--flatten': flatten,
          'text--no-top': noTop,
        },
        className,
      ),
      style,
      'data-cy': dataCy,
    },
    loading ? (
      <Skeleton type={SkeletonType.TEXT} styling={loadingStyles} />
    ) : (
      children
    ),
  );
};

export default Text;
