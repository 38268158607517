import React, {FC} from 'react';
import classNames from 'classnames';

import Button from 'components/button/Button';
import ButtonGroup from 'components/button/ButtonGroup';
import Label from 'components/form/label/Label';
import Icon from 'components/icon/Icon';

import PriorityOptions from './Priority.enum';
import PriorityProps, { PriorityToggleProps } from "./Priority.types";
import './Priority.scss';

const PriorityToggle: FC<PriorityToggleProps> = ({
  value,
  label,
  icon,
  iconColor,
  active,
  onClick,
  className,
  disabled = false,
}) => {
  return (
    <Button
      size="medium"
      tag="button"
      color="white"
      className={classNames('priority__toggle', className, {
        'priority__toggle--active': active,
      })}
      text={
        <>
          <Icon name={icon} color={iconColor} />
          <span className="priority__toggle__name">{label}</span>
        </>
      }
      handler={() => onClick(value)}
      disabled={disabled}
    />
  );
};

const Priority: FC<PriorityProps> = ({
  id,
  name,
  label,
  value,
  onChange,
  disabled = false,
  pendingChange,
  pendingApproval = false,
}) => {
  const onClick = (val: PriorityOptions) => {
    if (!disabled) {
      onChange(name, val);
    }
  };

  return (
    <Label
      idFor={id}
      label={label}
      className={classNames('priority', {
        'priority--disabled': disabled,
        'priority--pending': !!pendingChange || pendingApproval,
      })}
    >
      <ButtonGroup id={id} name={name}>
        <PriorityToggle
          value={PriorityOptions.PRIORITY_LOW}
          label="Low (default)"
          icon="priorityLow"
          active={
            pendingChange
              // @ts-ignore
              ? pendingChange === PriorityOptions.PRIORITY_LOW
              : value === PriorityOptions.PRIORITY_LOW
          }
          onClick={onClick}
          className="priority__toggle--low"
        />

        <PriorityToggle
          value={PriorityOptions.PRIORITY_MEDIUM}
          label="Medium"
          icon="priorityMedium"
          iconColor="orange"
          active={
            pendingChange
              ? pendingChange === PriorityOptions.PRIORITY_MEDIUM
              : value === PriorityOptions.PRIORITY_MEDIUM
          }
          onClick={onClick}
          className="priority__toggle--medium"
        />

        <PriorityToggle
          value={PriorityOptions.PRIORITY_HIGH}
          label="High"
          icon="priorityHigh"
          iconColor="red"
          active={
            pendingChange
              ? pendingChange === PriorityOptions.PRIORITY_HIGH
              : value === PriorityOptions.PRIORITY_HIGH
          }
          onClick={onClick}
          className="priority__toggle--high"
        />
      </ButtonGroup>
    </Label>
  );
};

export default Priority;
