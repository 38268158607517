import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import Label from 'components/form/label/Label';
import fetchData from 'store/fetchData';
import { Api } from 'constants/Routes.enum';
import BlankButton from 'components/button/BlankButton';

import './MediaFolderDropdown.scss';

const MediaFolderItem = ({ folder, currentId, setValue }) => {
  const [isOpen, setOpen] = useState(false);

  const subFolder = folder.sub_folders.sort((a, b) => {
    return a.media_folder.title.localeCompare(b.media_folder.title, 'en', {
      numeric: true,
      sensitivity: 'base',
    });
  });

  return (
    <div
      className={classNames('folder-dropdown__item', {
        'folder-dropdown__item--disabled': currentId === folder.media_folder.id,
      })}
    >
      <div className="folder-dropdown__item__info">
        {(folder.sub_folders && folder.sub_folders.length > 0 && (
          <Button
            onClick={() => setOpen(!isOpen)}
            text={isOpen ? '–' : '+'}
            scheme="link"
            size="small"
            hasShadow={false}
            className="folder-dropdown__item__toggle"
          />
        )) || <span className="folder-dropdown__item__toggle" />}

        <Button
          onClick={() => setValue(folder.media_folder)}
          className="folder-dropdown__item__name"
          scheme="link"
          size="small"
          hasShadow={false}
          text={folder.media_folder.title}
        />
      </div>

      <>
        {subFolder &&
          isOpen &&
          subFolder.map(child => (
            <MediaFolderItem
              key={child.media_folder.id}
              folder={child}
              setValue={setValue}
              currentId={currentId}
            />
          ))}
      </>
    </div>
  );
};

const MediaFolderDropdown = ({
  label,
  name,
  folderId,
  initialValue,
  onChange,
  disabled,
}) => {
  const dispatch = useDispatch();

  const [isOpen, setOpen] = useState(false);
  const [currentValue, setValue] = useState();
  const [menuWidth, setWidth] = useState(0);
  const [folders, setFolders] = useState([]);
  const [isDisabled, setDisabled] = useState(disabled);

  const dropdownRef = useRef();

  const rootFolder = { title: 'Home', id: 0 };

  useEffect(() => {
    const promise = dispatch(fetchData(Api.MEDIA_FOLDER));

    promise
      .then(json => json.json())
      .then(({ media_folders }) => {
        const flatten = folders => {
          return [].concat(
            ...folders.flatMap(x => [
              x.media_folder,
              x.sub_folders && flatten(x.sub_folders),
            ]),
          );
        };
        const flattenedFolders = flatten(media_folders);

        const sortedFolders = media_folders.sort((a, b) => {
          return a.media_folder.title.localeCompare(
            b.media_folder.title,
            'en',
            {
              numeric: true,
              sensitivity: 'base',
            },
          );
        });

        setFolders(sortedFolders);

        const value = flattenedFolders.find(f => f.id === initialValue);
        if (value) {
          setValue(value);
        } else {
          setValue(rootFolder);
        }
      })
      .catch(err => {
        setDisabled(true);
        console.error(err);
      });

    // TODO: Fix exhaustive-deps warning
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (isOpen) {
      const element = dropdownRef.current;
      if (element) {
        setWidth(element.offsetWidth);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    setOpen(false);

    if (currentValue) {
      onChange(name, currentValue.id);
    }
  }, [currentValue, name, onChange]);

  return (
    <Label label={label} idFor={name}>
      <div
        className={classNames('folder-dropdown', {
          'folder-dropdown--open': isOpen,
          'folder-dropdown--disabled': isDisabled,
        })}
      >
        <BlankButton
          tag="div"
          handler={() => !isDisabled && setOpen(!isOpen)}
          className={classNames('folder-dropdown__selected', {
            'folder-dropdown__selected--disabled': isDisabled,
          })}
          elementRef={dropdownRef}
        >
          {currentValue ? currentValue.title : 'Select a folder'}
          <Icon
            name="chevron"
            className={classNames('folder-dropdown__selected__toggle', {
              'folder-dropdown__selected__toggle--disabled': isDisabled,
            })}
          />
        </BlankButton>

        {isOpen && (
          <div
            className="folder-dropdown__selection"
            style={{ width: menuWidth }}
          >
            <div className="folder-dropdown__item">
              <div className="folder-dropdown__info">
                <Button
                  onClick={() => setValue(rootFolder)}
                  className="folder-dropdown__item__name"
                  scheme="link"
                  size="small"
                  hasShadow={false}
                  text={rootFolder.title}
                />
              </div>
            </div>
            {folders.map(
              folder =>
                folder &&
                !folder.media_folder.media_folder_id && (
                  <MediaFolderItem
                    key={folder.media_folder.id}
                    folder={folder}
                    setValue={setValue}
                    currentId={folderId}
                  />
                ),
            )}
          </div>
        )}
      </div>
    </Label>
  );
};

export default MediaFolderDropdown;
