import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Dropdown from 'components/form/dropdown/Dropdown';
import DropdownMultiple from 'components/form/dropdown-multiple/DropdownMultiple';
import { fetchLocationsAction } from 'store/locations/locationsActions';
import { hasSuperAdminRights } from 'store/auth/hasUserRights';

const useLocationDropdown = (showAllLocations, disabled, value) => {
  const dispatch = useDispatch();
  const { request, locations } = useSelector(state => state.locations);
  const { customerOverride } = useSelector(state => state.superAdmin);
  const isSuperAdmin = dispatch(hasSuperAdminRights());

  useEffect(() => {
    dispatch(fetchLocationsAction(showAllLocations));
  }, [dispatch, showAllLocations, value, disabled]);

  const locationList = useMemo(() => {
    if (disabled) return value;
    if (!locations) return [];

    return locations
      .filter(location => {
        if (isSuperAdmin && customerOverride) {
          return location.customer_id === customerOverride;
        }
        return true;
      })
      .sort((a, b) => {
        if (a.customer_id < b.customer_id) {
          return -1;
        }
        if (a.customer_id > b.customer_id) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      })
      .map(location => ({
        label: location.name,
        value: location.id,
      }));
  }, [locations, isSuperAdmin, customerOverride, disabled, value]);

  return {
    request,
    locationList,
  };
};

const LocationDropdown = ({ multiple, showAllLocations, ...props }) => {
  const { value, disabled } = props;
  const { locationList, loadingLocations } = useLocationDropdown(
    showAllLocations,
    disabled,
    value,
  );
  let selectedValuesAsValues = [];

  if (disabled) {
    selectedValuesAsValues = value.map(location => {
      return {
        label: location.name,
        value: location.id,
      };
    });
  }

  const selectedValues = () => {
    if (disabled) {
      return selectedValuesAsValues;
    }
    return locationList;
  };

  return (
    <>
      {multiple ? (
        <DropdownMultiple
          {...props}
          values={selectedValues()}
          isLoading={loadingLocations}
          sortOptions
          selectedItemsLabel={
            selectedValues().length > 1 ? 'locations' : 'location'
          }
        />
      ) : (
        <Dropdown
          {...props}
          values={selectedValues()}
          hasEmptyOption={false}
          placeholder="Select location"
          isLoading={loadingLocations}
        />
      )}
    </>
  );
};

LocationDropdown.propTypes = {
  multiple: PropTypes.bool,
  showAllLocations: PropTypes.bool,
  onChange: PropTypes.func,
};

LocationDropdown.defaultProps = {
  multiple: false,
  showAllLocations: false,
  onChange: () => {},
};

export default LocationDropdown;
