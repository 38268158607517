import React, { FC } from 'react';
import classNames from 'classnames';
import Skeleton from 'components/skeleton/Skeleton';
import { SkeletonType } from 'components/skeleton/Skeleton.enum';
import { LabelProps } from './Label.types';

import './Label.scss';

const Label: FC<LabelProps> = ({
  label,
  idFor,
  className,
  children = [],
  color = 'black',
  loading = false,
  loadingWidth,
}) => {
  return (
    <label htmlFor={idFor} className={className}>
      {label && label !== '' && (
        <span
          className={classNames('label', className, {
            [`label--${color}`]: color,
          })}
        >
          {loading ? (
            <Skeleton
              type={SkeletonType.TEXT}
              styling={{ width: loadingWidth }}
            />
          ) : (
            label
          )}
        </span>
      )}
      {children}
    </label>
  );
};

export default Label;
