import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import Icon from 'components/icon/Icon';
import UserRight from 'constants/UserRight.enum';
import { hasUserRights } from 'store/auth/hasUserRights';
import { infoBarSet } from 'store/info/infoActions';

import { UploadMultipleProps, UploadUploadedFile } from './Upload.types';
import UploadMultipleList from './UploadMultipleList';

import './Upload.scss';

const UploadMultiple = ({ id, name, text, oldFiles = [], onChange, error }: UploadMultipleProps) => {
  const dispatch = useDispatch();
  const hasMediaEditRights = dispatch(hasUserRights(UserRight.MEDIA_EDIT));

  const [uploadedFiles, setUploadedFiles] = useState(oldFiles);
  const [readFiles, setReadFiles] = useState(oldFiles.map(f => f.file_name));

  const onDrop = useCallback(
    (files: File[]) => {
      const filesToUpload = files.filter((file) => {
        return !uploadedFiles.some(
          prevFile =>
            prevFile.name === file.name || prevFile.file_name === file.name,
        );
      });

      if (filesToUpload.length !== files.length) {
        dispatch(
          infoBarSet({
            message: 'A file with the same name already exists',
            state: 'error',
            timeout: 3000,
            show: true,
          }),
        );
      }

      setUploadedFiles((prevFiles) => {
        const newFiles = [...prevFiles, ...filesToUpload] as UploadUploadedFile[];

        onChange(name, newFiles);
        return newFiles;
      });

      filesToUpload.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          setReadFiles(prevFiles => [...prevFiles, file.name]);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [dispatch, uploadedFiles, onChange, name],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
  });

  const removeFile = (fileNameToDelete: string) => {
    setUploadedFiles(prevFiles => {
      const newFiles = prevFiles.filter(
        file =>
          file.name !== fileNameToDelete && file.file_name !== fileNameToDelete,
      );

      onChange(name, newFiles);
      return newFiles;
    });
  };

  return (
    <div className={classNames('form__upload__container')}>
      {error && (
        <div className="form__upload__error">
          <Icon name="warning" />
          <span>{error}</span>
        </div>
      )}

      {uploadedFiles && uploadedFiles.length > 0 && (
        <UploadMultipleList
          readFiles={readFiles}
          uploadedFiles={uploadedFiles}
          removeFile={removeFile}
        />
      )}

      {/* TODO: fix dispatch typing */}
      {/* @ts-ignore */}
      {hasMediaEditRights && (
        <div
          className={classNames('form__upload', 'form__upload--multi', {
            'form__upload--empty': !uploadedFiles || uploadedFiles.length === 0,
          })}
          {...getRootProps()}
        >
          <p className="form__upload__text">
            {text || (
              <>
                Drag and drop your files here or <br />
                <span>browse your local files</span>
              </>
            )}
          </p>
          <input id={id} name={name} type="upload" {...getInputProps()} />
        </div>
      )}
    </div>
  );
};

export default UploadMultiple;
