import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { reverse } from 'named-urls';
import { useHistory } from 'react-router-dom';

import { ArrangementMediaProps } from 'constants/types/arrangmentTypes';
import UserRights from 'constants/UserRight.enum';
import { Paths, Api } from 'constants/Routes.enum';
import { hasUserRights } from 'store/auth/hasUserRights';
import { upsertArrangementAction } from 'store/arrangements/arrangementsActions';
import { popupActionClear } from 'store/popup/popupActions';
import fetchData from 'store/fetchData';

import Card from 'components/card/Card';
import Button from 'components/button/Button';
import Input from 'components/form/input/Input';
import ArrangementMediaItem from 'components/arrangement/media/ArrangementMediaItem';

import './ArrangementMediaBlock.scss';

const ArrangementMediaBlock = ({
  dimension,
  pending_approval,
  arrangementId,
  formValues,
  media,
  spot,
  touched,
  errors,
  handleChange,
  arrangementType,
  spotRefs,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const hasArrangementEditRights = dispatch(
    hasUserRights(UserRights.ARRANGEMENTS_EDIT),
  );
  const pool = useSelector(state => state.pool);
  const [mediaItems, setMediaItems] = useState(media);
  const [showMediaLink, setShowMediaLink] = useState(true);

  useEffect(() => {
    setMediaItems(media);
  }, [media, spot]);

  useEffect(() => {
    if (mediaItems?.[spot.area_number]?.items?.length > 0) {
      const firstItem = mediaItems[spot.area_number].items.filter(
        item => !item.pending_removal,
      )[0];
      const allowedTypes = ['image', 'video'];
      if (
        firstItem &&
        allowedTypes.indexOf(firstItem.filetype) < 0 &&
        allowedTypes.indexOf(firstItem.media_item_type) < 0
      ) {
        setShowMediaLink(false);
      } else {
        setShowMediaLink(true);
      }
    } else {
      setShowMediaLink(true);
    }
  }, [mediaItems, spot]);

  /**
   * Only allow adding media items when spot has at least one item and spot contains an image item.
   * @param {Array<Object>} items - Media items
   * @param {Object} items.item - Media item
   * @param {Boolean} items.item.active
   * @param {String} items.item.created_at
   * @param {Number} items.item.file_size
   * @param {Boolean} items.item.pending_removal
   * @param {String} items.item.filename
   * @param {String} items.item.filetype
   * @param {Number} items.item.id
   * @param {String} items.item.media_file_url
   * @param {String} items.item.name
   * @param {String} items.item.updated_at
   * @param {Number} items.item.used_count
   * @param {Object} spot
   * @param {Number} spot.area_number
   * @param {Number} spot.height
   * @param {String} spot.name
   * @param {Number} spot.width
   * @param {Number} spot.x
   * @param {Number} spot.y
   * @returns {boolean}
   */

  const showSpotTimeBetweenItemsInput = (items, spot) => {
    const spotHasItems = items && items[spot.area_number]?.items;
    const spotItemLength =
      spotHasItems &&
      items[spot.area_number]?.items.filter(item => !item.pending_removal)
        .length;
    const spotImageItemLength =
      spotHasItems &&
      items[spot.area_number]?.items.filter(
        item =>
          (item.filetype === 'image' || item.media_item_type === 'image') &&
          !item.pending_removal,
      ).length;

    return spotItemLength === spotImageItemLength && spotImageItemLength > 1;
  };

  const openMediaLibrary = useCallback(
    (spotId, spotMediaItems) => {
      if (spotId >= 0) {
        const firstMediaItem = spotMediaItems?.items?.filter(
          item => !item.pending_removal,
        )[0];
        const location = (arrangementId, newArrangement) => {
          return {
            pathname: reverse(Paths.POOLS_MEDIA, { id: pool.id }),
            state: {
              ...history?.location?.state,
              fromArrangement: arrangementId,
              fromSpot: {
                id: spotId,
                mediaItems: spotMediaItems || null,
              },
              fromMediaType: firstMediaItem
                ? firstMediaItem.filetype || firstMediaItem.media_item_type
                : null,
              fromArrangementFormFormValues: formValues,
              newArrangement:
                newArrangement || history?.location?.state?.newArrangement,
            },
          };
        };

        if (arrangementId === undefined || arrangementId === null) {
          const promise = dispatch(
            upsertArrangementAction(
              formValues,
              arrangementType,
              arrangementId,
              pool,
              null,
            ),
          );

          promise
            .then(json => json.json())
            .then(result => {
              const arrId = result.arrangement.id;
              dispatch(popupActionClear());
              history.push(location(arrId, true));
            });
        } else {
          dispatch(popupActionClear());
          history.push(location(arrangementId, undefined));
        }
      }
    },
    [dispatch, history, arrangementId, arrangementType, pool, formValues],
  );

  const updateMediaItemOrder = mediaItems => {
    const mediaItemIds = mediaItems.map(mediaItem => mediaItem.id);
    const promise = dispatch(
      fetchData(
        reverse(Api.ARRANGEMENT_MEDIA_ITEMS_UPDATE_POSITION),
        {
          method: 'PATCH',
          body: JSON.stringify({
            ids: mediaItemIds,
          }),
        },
        true,
      ),
    );

    promise
      .then(jsons => jsons.json())
      .then(result => {
        console.log('result', result);
      });
  };

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;

    // If there is no destination do nothing
    if (!destination) {
      return;
    }

    // Do nothing if the location of the draggable is not changed
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const area = mediaItems[source.droppableId];

    const newAreaMediaItems = Array.from(area.items);
    // Remove moved media item from newAreaMediaItems
    newAreaMediaItems.splice(source.index, 1);
    // Add moved media item back to newAreaMediaItems with updated index
    newAreaMediaItems.splice(
      destination.index,
      0,
      media[source.droppableId].items.filter(
        mediaItem => mediaItem.id.toString() === draggableId,
      )[0],
    );

    updateMediaItemOrder(newAreaMediaItems);

    // Update area object
    const newArea = {
      ...mediaItems,
      [source.droppableId]: {
        ...mediaItems[source.droppableId],
        items: newAreaMediaItems,
      },
    };

    // Update state for media items within area;
    setMediaItems(newArea);
  };

  return (
    <div
      ref={spotRefs[spot.area_number]}
      className="arrangement-media-block"
      data-cy="arrangementMediaBlock"
    >
      <Card highlightOnHover={false} shadow interactive={false}>
        <h3 className="arrangement-media-block__title">
          {`${spot.area_number}. `}
          {spot.name} ({spot.width || dimension.width}px-
          {spot.height || dimension.height}px)
        </h3>

        {mediaItems?.[spot.area_number]?.items && (
          <div className="arrangement-media-block__order">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={spot.area_number.toString()}>
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {mediaItems[spot.area_number].items.map(
                      (mediaItem, index) => (
                        <ArrangementMediaItem
                          key={mediaItem.id}
                          setMediaItems={setMediaItems}
                          pending_approval={pending_approval}
                          index={index}
                          mediaItem={mediaItem}
                          spot={spot}
                          isDragDisabled={
                            !hasArrangementEditRights ||
                            mediaItems[spot.area_number].items.length === 1
                          }
                        />
                      ),
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}

        {showMediaLink && (
          <div className="form__upload form__upload--small">
            <div className="form__upload__text">
              {false && <>Drag and drop your media here or </>}
              <Button
                data-cy="browseMediaLibraryButton"
                tag="span"
                role="button"
                scheme="link"
                hasShadow={false}
                size="medium"
                handler={() =>
                  !pending_approval && hasArrangementEditRights
                    ? openMediaLibrary(
                        spot.area_number,
                        (mediaItems && mediaItems[spot.area_number]) || null,
                      )
                    : null
                }
                text="browse the media library"
                disabled={pending_approval || !hasArrangementEditRights}
              />
            </div>
          </div>
        )}

        {showSpotTimeBetweenItemsInput(mediaItems, spot) && (
          <div className="arrangement-media-block__time-between-images">
            <Input
              data-cy="timeBetweenItems"
              label="Time between items"
              type="number"
              name={`duration${spot.area_number}`}
              id={`duration${spot.area_number}`}
              scheme="white"
              placeholder="0"
              suffix="seconds"
              value={formValues[`duration${spot.area_number}`] || ''}
              onChange={handleChange}
              isChanged={
                pending_approval &&
                media[spot.area_number]?.duration?.pending_approval
              }
              error={
                touched[`duration${spot.area_number}`] &&
                errors[`duration${spot.area_number}`]
              }
              disabled={pending_approval || !hasArrangementEditRights}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

ArrangementMediaBlock.propTypes = {
  dimension: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  pending_approval: PropTypes.bool,
  arrangementId: PropTypes.number,
  arrangementType: PropTypes.oneOf(['RegularArrangement', 'TriggerArrangement'])
    .isRequired,
  formValues: PropTypes.shape({
    isDefault: PropTypes.bool,
    name: PropTypes.string,
    template: PropTypes.number,
  }),
  media: ArrangementMediaProps,
  spot: PropTypes.shape({
    area_number: PropTypes.number,
    name: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  spotRefs: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

ArrangementMediaBlock.defaultProps = {
  arrangementId: null,
  dimension: null,
  pending_approval: false,
  formValues: null,
  media: null,
  spot: null,
  spotRefs: null,
};

export default ArrangementMediaBlock;
