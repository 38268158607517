import React from 'react';
import classNames from 'classnames';

import Text from 'components/typography/text/Text';
import { TextTag } from 'components/typography/text/Text.enum';
import Skeleton from 'components/skeleton/Skeleton';
import { SkeletonType } from 'components/skeleton/Skeleton.enum';

import { StatusProps } from './Status.types';
import './Status.scss';

const Status: React.FC<StatusProps> = ({
  isActive = true,
  isDeleted = false,
  needsApproval = false,
  activeText = 'Active',
  inactiveText = 'Inactive',
  expired = false,
  deletedText = 'Deleted',
  dataCy,
  loading = false,
  hasText = true,
  textColor,
  className,
  title,
}) => {
  return (
    <div className={classNames('status', className)}>
      {loading ? (
        <span className="status__light">
          <Skeleton type={SkeletonType.BUTTON_SQUARE} />
        </span>
      ) : (
        <span
          title={title}
          className={classNames('status__light', {
            'status__light--active': isActive && !isDeleted && !needsApproval,
            'status__light--pending': needsApproval,
            'status__light--expired': expired,
            'status__light--deleted': isDeleted,
          })}
        />
      )}

      {hasText && (
        <Text
          loading={loading}
          tag={TextTag.SPAN}
          dataCy={dataCy}
          className={classNames('status__text', {
            [`status__text--${textColor}`]: textColor,
          })}
        >
          {needsApproval && 'Needs approval'}
          {!needsApproval && !isDeleted && isActive && activeText}
          {!needsApproval && !isDeleted && !isActive && inactiveText}
          {isDeleted && deletedText}
        </Text>
      )}
    </div>
  );
};

export default Status;
