import equal from 'fast-deep-equal';
import { useFormik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as Yup from 'yup';
import { reverse } from 'named-urls';

import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import Icon from 'components/icon/Icon';
import { PopupStickyFooter, PopupStickyForm, PopupStickyHeader, PopupStickyMain } from 'components/popup/Popup';

import Button from 'components/button/Button';
import UserRight from 'constants/UserRight.enum';
import { hasUserRights } from 'store/auth/hasUserRights';
import { infoBarHide, setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import { addMediaAction, mediaDeleted, deleteMediaAction } from 'store/media/mediaActions';
import { popupActionClear } from 'store/popup/popupActions';
import { RootState } from 'store/rootState';
import { MediaItemType } from 'store/media/Media.types';
import MediaItemTypes from 'constants/MediaItemTypes.enum';
import fetchData from 'store/fetchData';
import { Api } from 'constants/Routes.enum';

interface RTSPFormType {
  name: string;
  username: string
  password: string
  domain: string
  port: string
  path: string
}

const MediaRTSP = ({ id, name, rtsp, shows }: any) => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch()
  const authToken = useSelector((state: RootState) => state.auth.auth_token);
  const infoBar = useSelector((state: RootState) => state.info.bar)
  const hasMediaEditRights = dispatch(hasUserRights(UserRight.MEDIA_EDIT));

  const [isSubmitting, setSubmitting] = useState(false)

  const initialValues: RTSPFormType = {
    name: name || '',
    username: rtsp?.username || '',
    password: rtsp?.password || '',
    domain: rtsp?.domain || '',
    port: rtsp?.port || '',
    path: rtsp?.path || '/',
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    domain: Yup.string().required('Domain is a required field'),
    username: Yup.string().when('password', {
      is: val => val !== '',
      then: Yup.string().required('Username is a required field if password is given')
    }),
    port: Yup.string().matches(/^[0-9]*$/, 'Port must be number')
  })

  const getUrlFromValues = useCallback((v: RTSPFormType, hidePassword: boolean) => {
    let userAndPass = v.username
    if (v.password !== '') {
      userAndPass += hidePassword ? `:${'*'.repeat(v.password.length)}` : `:${encodeURIComponent(v.password)}`
    }
    userAndPass += '@'
    const port = v.port ? `:${v.port}` : ''

    return `rtsp://${userAndPass}${v.domain}${port}${v.path}`
  }, [])

  const onSubmit = (values: RTSPFormType) => {
    if (equal(values, initialValues)) {
      dispatch(popupActionClear())
      setSubmitting(false)
      return
    }

    const urlFromValues = getUrlFromValues(values, false)
    const mediaData = new FormData();
    mediaData.append('media[type]', MediaItemTypes.RTSP_MEDIA_ITEM)
    mediaData.append('media[media_item_type_id]', '9')
    mediaData.append('media[name]', values.name)
    mediaData.append('media[source_url]', urlFromValues)

    const promise = dispatch(
      fetchData(id ? reverse(Api.MEDIA_DETAIL, { id }) : Api.MEDIA_POST, {
        method: id ? 'PATCH' : 'POST',
        headers: {
          Authorization: authToken,
        },
        body: mediaData,
      }),
    );

    promise
      .then(json => json.json())
      .then(result => {
        setSubmitting(false);
        dispatch(mediaDeleted(id));
        if (result.media) {
          dispatch(addMediaAction(result));
        }
      })
      .catch(() => {
        setSubmitting(false);
        dispatch(
          setInfoBar({
            message: 'Could not add media item',
            state: InfoBarState.ERROR,
            timeout: 5000,
          }),
        );
      });
  }

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const url = useMemo(() => getUrlFromValues(values, true), [values])

  return (
    <PopupStickyForm onSubmit={handleSubmit}>
      <PopupStickyHeader>
        <fieldset className="fieldset--flatten">
          <FormBlock flatten>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Enter a name for your RTSP stream"
              value={values.name}
              error={touched.name && errors.name ? errors.name : undefined}
              onChange={handleChange}
              fontSize="large"
              suffix={<Icon name="edit" />}
              disabled={!hasMediaEditRights}
            />
          </FormBlock>
        </fieldset>
      </PopupStickyHeader>

      <PopupStickyMain>
        <fieldset>
          <FormBlock halfWidth>
            <Input
              label="Username"
              type="text"
              id="username"
              name="username"
              scheme="white"
              value={values.username}
              error={touched.username && errors.username ? errors.username : undefined}
              onChange={handleChange}
              disabled={!hasMediaEditRights}
            />
            <Input
              label="Password"
              type="password"
              id="password"
              name="password"
              scheme="white"
              value={values.password}
              error={touched.password && errors.password ? errors.password : undefined}
              onChange={handleChange}
              disabled={!hasMediaEditRights}
            />
          </FormBlock>
          <FormBlock>
            <Input
              label="Domain"
              type="text"
              id="domain"
              name="domain"
              scheme="white"
              value={values.domain}
              error={touched.domain && errors.domain ? errors.domain : undefined}
              onChange={handleChange}
              disabled={!hasMediaEditRights}
            />
            <Input
              label="Port"
              type="text"
              id="port"
              name="port"
              scheme="white"
              value={values.port}
              error={touched.port && errors.port ? errors.port : undefined}
              onChange={handleChange}
              disabled={!hasMediaEditRights}
            />
          </FormBlock>
          <FormBlock>
            <Input
              label="Path"
              type="text"
              id="path"
              name="path"
              scheme="white"
              value={values.path}
              error={touched.path && errors.path ? errors.path : undefined}
              onChange={handleChange}
              disabled={!hasMediaEditRights}
            />
          </FormBlock>
          <FormBlock isEnd>
            {url}
          </FormBlock>
        </fieldset>
      </PopupStickyMain>

      <PopupStickyFooter>
        <FormBlock hasInlineChildren flatten>
          {(id && hasMediaEditRights && (
            <Button
              tag="button"
              type="button"
              size="medium"
              text="Delete"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(
                  setInfoBar({
                    message:
                      'Are you sure you want to delete this media item?',
                    action: [
                      {
                        text: 'Yes, Delete',
                        type: 'button',
                        color: 'blue',
                        handle: () =>
                          dispatch(deleteMediaAction({ id, shows })),
                      },
                      {
                        text: 'Cancel',
                        type: 'link',
                        handle: () => dispatch(infoBarHide()),
                      },
                    ],
                    state: InfoBarState.ERROR,
                  }),
                );
              }}
              disabled={isSubmitting}
            />
          )) || (
              <Button
                tag="button"
                type="submit"
                size="medium"
                text="Cancel"
                scheme="link"
                hasShadow={false}
                handler={() => {
                  dispatch(popupActionClear());
                  if (infoBar.show) {
                    dispatch(infoBarHide());
                  }
                }}
                disabled={isSubmitting}
              />
            )}

          <Button
            tag="button"
            type="submit"
            size="medium"
            text="Save and close"
            disabled={!hasMediaEditRights || isSubmitting}
          />
        </FormBlock>
      </PopupStickyFooter>
    </PopupStickyForm>
  )
}

export default MediaRTSP
