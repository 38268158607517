import classNames from 'classnames';
import React, { useRef } from 'react';
import { ChromePicker } from 'react-color';
import styled from 'react-css-styled';

import { ColorPickerProps } from './ColorPicker.types';

import './ColorPicker.scss';

const ColorPicker = ({
  className,
  popoverClassName,
  buttonClassName,
  handleColorChange,
  handleClose,
  handleClick,
  color,
  disabled = false,
  displayColorPicker = false,
}: ColorPickerProps) => {
  const element = useRef<HTMLDivElement | null>(null);

  const PickedColor = styled(
    'div',
    `{
        background: ${color}
    }`,
  );

  return (
    <div ref={element} className={classNames('color-picker', className)}>
      <div
        role="button"
        tabIndex={0}
        className={classNames('color-picker__button', buttonClassName, {
          'color-picker__button--disabled': disabled,
        })}
        onClick={!disabled ? handleClick : undefined}
        onKeyUp={!disabled ? handleClick : undefined}
      >
        <PickedColor className="color-picker__picked-color" />
      </div>
      {displayColorPicker && element.current && (
        <div className={classNames('color-picker__popover', popoverClassName)}>
          <div
            className="color-picker__popover-inner"
            style={{
              top: element.current.getBoundingClientRect().bottom,
              left: element.current.getBoundingClientRect().left,
            }}
          >
            <div
              className="color-picker__cover"
              role="presentation"
              onClick={handleClose}
            />
            <ChromePicker
              color={color}
              disableAlpha
              className="color-picker__context"
              onChange={handleColorChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
