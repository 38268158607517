import React, { useRef, useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';

import './DragSwitch.scss';
import { DragSwitchProps } from './DragSwitch.types';

const DragSwitch: React.FC<DragSwitchProps> = ({
  id,
  name,
  type = 'on-off-toggle',
  labelOn = 'on',
  labelOff = 'off',
  isChecked = false,
  isDisabled = false,
  onChange,
  isChanged = false,
  className,
}) => {
  const [checked, setChecked] = useState(isChecked);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const switchRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLDivElement>(null);
  const offButtonRef = useRef<HTMLButtonElement>(null);
  const onButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const animateToggle = () => {
      if (onButtonRef.current && offButtonRef.current && toggleRef.current) {
        const toggleWidth = checked
          ? onButtonRef.current.offsetWidth
          : offButtonRef.current.offsetWidth;

        toggleRef.current.style.width = `${toggleWidth}px`;
      }

      if (switchRef.current && offButtonRef.current && toggleRef.current) {
        const toggleMarginLeft = checked
          ? offButtonRef.current.offsetWidth + 10
          : 0;

        toggleRef.current.style.marginLeft = `${toggleMarginLeft}px`;
      }
    };

    animateToggle();
  }, [checked]);

  const handleChange = useCallback(
    (value: boolean) => {
      if (!isDisabled && checked !== value) {
        setChecked(value);
        onChange('', value);
      }
    },
    [setChecked, onChange, isDisabled, checked],
  );

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div
      ref={switchRef}
      className={classNames('drag-switch', className, {
        'drag-switch--disabled': isDisabled,
        'drag-switch--checked': checked,
        [`drag-switch--${type}`]: type,
        'drag-switch--changed': isChanged,
      })}
    >
      <div
        ref={toggleRef}
        className={classNames('drag-switch__toggle', {
          'drag-switch__toggle--no-animation': isDisabled,
          'drag-switch__toggle--active': checked,
          'drag-switch__toggle--inactive': !checked,
        })}
      />
      <button
        aria-label="Switch off"
        type="button"
        ref={offButtonRef}
        className="drag-switch__label drag-switch__label--off"
        dangerouslySetInnerHTML={{ __html: labelOff }}
        onClick={() => handleChange(false)}
      />
      <button
        aria-label="Switch on"
        type="button"
        ref={onButtonRef}
        className="drag-switch__label drag-switch__label--on"
        dangerouslySetInnerHTML={{ __html: labelOn }}
        onClick={() => handleChange(true)}
      />
      <input
        type="checkbox"
        className="drag-switch__input"
        id={id}
        name={name}
        disabled={isDisabled}
        ref={checkboxRef}
        tabIndex={-1}
      />
    </div>
  );
};

export default DragSwitch;
