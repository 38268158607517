import PopupTypes from 'constants/PopupTypes.enum';
import { Api } from 'constants/Routes.enum';
import TwoFactorStatus from 'constants/TwoFactorStatus.enum';
import AccountSettingsEmailVerifyStatus from 'constants/AccountSettingsEmailVerifyStatus.enum';
import fetchData from 'store/fetchData';
import { popupActionClear, popupActionSet } from 'store/popup/popupActions';
import { reverse } from 'named-urls';
import { setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';

export const AccountSettingsActionTypes = {
  ACCOUNT_SETTINGS_VERIFY_EMAIL_POPUP_ACTIVE:
    '@@account-settings/verify-email/popup-active',
  ACCOUNT_SETTINGS_VERIFY_EMAIL_POPUP_INACTIVE:
    '@@account-settings/verify-email/popup-inactive',
  ACCOUNT_SETTINGS_VERIFY_EMAIL_SET: '@@account-settings/verify-email/set',
  ACCOUNT_SETTINGS_VERIFY_EMAIL_UNSET: '@@account-settings/verify-email/unset',
  ACCOUNT_SETTINGS_VERIFY_EMAIL_UPDATE:
    '@@account-settings/verify-email/update',
  ACCOUNT_SETTINGS_TWOFACTOR_POPUP_ACTIVE:
    '@@account-settings/two-factor/popup-active',
  ACCOUNT_SETTINGS_TWOFACTOR_POPUP_INACTIVE:
    '@@account-settings/two-factor/popup-inactive',
  ACCOUNT_SETTINGS_TWOFACTOR_SET: '@@account-settings/two-factor/set',
  ACCOUNT_SETTINGS_TWOFACTOR_UNSET: '@@account-settings/two-factor/unset',
  ACCOUNT_SETTINGS_TWOFACTOR_CHECK: '@@account-settings/two-factor/check',
  ACCOUNT_SETTINGS_TWOFACTOR_RESEND_EMAIL_INITIAL:
    '@account-settings/@two-factor/resend-email/initial',
  ACCOUNT_SETTINGS_TWOFACTOR_RESEND_EMAIL_PROCESSING:
    '@account-settings/@two-factor/resend-email/processing',
  ACCOUNT_SETTINGS_TWOFACTOR_RESEND_EMAIL_SUCCESS:
    '@@account-settings/two-factor/resend-email/success',
  ACCOUNT_SETTINGS_TWOFACTOR_RESEND_EMAIL_FAILED:
    '@@account-settings/two-factor/resend-email/failed',
};

export const accountSettingsVerifyEmailPopupActive = () => ({
  type: AccountSettingsActionTypes.ACCOUNT_SETTINGS_VERIFY_EMAIL_POPUP_ACTIVE,
});

export const accountSettingsVerifyEmailPopupInactive = () => ({
  type: AccountSettingsActionTypes.ACCOUNT_SETTINGS_VERIFY_EMAIL_POPUP_INACTIVE,
});

export const updateUserEmail = email => ({
  type: AccountSettingsActionTypes.ACCOUNT_SETTINGS_VERIFY_EMAIL_UPDATE,
  payload: email,
});

export const accountSettingsVerifyEmailSet = email => ({
  type: AccountSettingsActionTypes.ACCOUNT_SETTINGS_VERIFY_EMAIL_SET,
  payload: email,
});

export const accountSettingsVerifyEmailUnset = () => ({
  type: AccountSettingsActionTypes.ACCOUNT_SETTINGS_VERIFY_EMAIL_UNSET,
});

export const accountSettingsTwoFactorPopupActive = () => ({
  type: AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_POPUP_ACTIVE,
});

export const accountSettingsTwoFactorPopupInactive = () => ({
  type: AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_POPUP_INACTIVE,
});

export const accountSettingsTwoFactorSet = () => ({
  type: AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_SET,
});

export const accountSettingsTwoFactorUnset = () => ({
  type: AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_UNSET,
});

export const accountSettingsTwoFactorResendEmailInitial = () => ({
  type:
    AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_RESEND_EMAIL_INITIAL,
});

export const accountSettingsTwoFactorResendEmailSuccess = () => ({
  type:
    AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_RESEND_EMAIL_SUCCESS,
});

export const accountSettingsTwoFactorResendEmailFailed = () => ({
  type:
    AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_RESEND_EMAIL_FAILED,
});

export const accountSettingsTwoFactorResendEmailProcessing = () => ({
  type:
    AccountSettingsActionTypes.ACCOUNT_SETTINGS_TWOFACTOR_RESEND_EMAIL_PROCESSING,
});

export const verifyEmailAction = (emailData, id) => async dispatch => {
  dispatch(accountSettingsVerifyEmailPopupActive());
  dispatch(
    popupActionSet(PopupTypes.ACCOUNT_SETTINGS_VERIFY_EMAIL, {
      title: 'Changing your e-mail address needs to be verified',
      text: `An e-mail with a confirmation link has been sent. Use it to change your e-mail address for ${process.env.REACT_APP_SITE_TITLE}.`,
      type: 'password',
      emailData,
      id,
    }),
  );
};

export const openEditPasswordFormAction = () => async dispatch => {
  dispatch(accountSettingsTwoFactorPopupInactive());
  dispatch(popupActionSet(PopupTypes.ACCOUNT_SETTINGS_PASSWORD));
};

export const editPasswordTwoFactorAction = accountSettingsTwoFactorIsSet => async dispatch => {
  if (accountSettingsTwoFactorIsSet) {
    dispatch(openEditPasswordFormAction());
  } else {
    dispatch(accountSettingsTwoFactorPopupActive());
    dispatch(
      popupActionSet(PopupTypes.ACCOUNT_SETTINGS_TWO_FACTOR, {
        title: 'Changing your password requires two step authentication',
        text: `An e-mail with a confirmation link has been sent. Use it to change your password for ${process.env.REACT_APP_SITE_TITLE}.`,
        type: 'password',
      }),
    );
  }
};

export const editPasswordAction = (
  passwordData,
  id,
  setSubmitting,
) => async dispatch => {
  const promise = dispatch(
    fetchData(
      reverse(Api.USER_PASSWORD, { id }),
      {
        method: 'PATCH',
        body: passwordData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(() => {
      dispatch(popupActionClear());
      setSubmitting(false);
      dispatch(
        setInfoBar({
          message: 'You successfully changed your password',
          timeout: 5000,
          state: 'check',
        }),
      );
    })
    .catch(err => {
      console.error(err);
      setSubmitting(false);
      dispatch(
        setInfoBar({
          message: 'Failed to edit password',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const openEditEmailFormAction = () => async dispatch => {
  dispatch(accountSettingsTwoFactorPopupInactive());
  dispatch(popupActionSet(PopupTypes.ACCOUNT_SETTINGS_EMAIL));
};

export const editEmailTwoFactorAction = accountSettingsTwoFactorIsSet => async dispatch => {
  if (accountSettingsTwoFactorIsSet) {
    dispatch(openEditEmailFormAction());
  } else {
    dispatch(accountSettingsTwoFactorPopupActive());
    dispatch(
      popupActionSet(PopupTypes.ACCOUNT_SETTINGS_TWO_FACTOR, {
        title: 'Changing your e-mail address requires two step authentication',
        text: `An e-mail with a confirmation link has been sent. Use it to change your e-mail address for ${process.env.REACT_APP_SITE_TITLE}.`,
        type: 'email',
      }),
    );
  }
};

export const editEmailAction = (
  emailData,
  id,
  setSubmitting,
) => async dispatch => {
  setSubmitting(false);
  dispatch(popupActionClear());
  dispatch(verifyEmailAction(emailData, id));
};

export const accountSettingsTwoFactorResendEmail = () => async (
  dispatch,
  getState,
) => {
  const userId = getState().auth.user.id;
  const authToken = getState().auth.auth_token;

  if (!userId || !authToken) return;

  dispatch(accountSettingsTwoFactorResendEmailProcessing());

  const promise = dispatch(
    fetchData(Api.ACCOUNT_SETTINGS_TWOFACTOR_RESEND_EMAIL, {
      method: 'POST',
    }),
  );

  promise
    .then(json => json.json())
    .then(res => {
      if (res && res.status === TwoFactorStatus.NOT_CONFIRMED) {
        dispatch(accountSettingsTwoFactorResendEmailSuccess());
      } else {
        dispatch(accountSettingsTwoFactorResendEmailFailed());
      }
    });
};

export const accountSettingsVerifyEmailStatus = emailVerified => async dispatch => {
  if (!emailVerified) {
    const promise = dispatch(
      fetchData(reverse(Api.ACCOUNT_SETTINGS_EMAIL_STATUS)),
    );

    promise
      .then(json => json.json())
      .then(res => {
        const { status, email } = res;
        if (status === AccountSettingsEmailVerifyStatus.CONFIRMED) {
          dispatch(accountSettingsVerifyEmailSet(email));
        } else if (status === AccountSettingsEmailVerifyStatus.NOT_CONFIRMED) {
          dispatch(accountSettingsVerifyEmailUnset());
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
};

export const updateEmailSuccess = email => async dispatch => {
  dispatch(accountSettingsVerifyEmailPopupInactive());
  dispatch(accountSettingsVerifyEmailUnset());
  dispatch(popupActionClear());
  dispatch(updateUserEmail(email));
  dispatch(
    setInfoBar({
      message: 'You successfully changed your e-mail address',
      timeout: 5000,
      state: 'check',
    }),
  );
};
export const accountSendVerifyEmail = (emailData, id) => async dispatch => {
  const promise = dispatch(
    fetchData(
      reverse(Api.USER_EMAIL, { id }),
      {
        method: 'PATCH',
        body: emailData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(() => {
      dispatch(accountSettingsVerifyEmailStatus());
    })
    .catch(err => {
      console.error(err);
    });
};

export const accountSettingsTwoFactorCheck = sendEmailOnExpire => async dispatch => {
  const response = dispatch(
    fetchData(
      Api.TWOFACTOR_SEND,
      {
        method: 'POST',
        body: JSON.stringify({
          type: 'settings',
          only_when_expired: 'true',
        }),
      },
      true,
    ),
  );

  response
    .then(json => json.json())
    .then(res => {
      const { status } = res;
      if (status === TwoFactorStatus.CONFIRMED) {
        dispatch(accountSettingsTwoFactorSet());
      } else if (status === TwoFactorStatus.EXPIRED) {
        dispatch(accountSettingsTwoFactorUnset());

        if (sendEmailOnExpire) {
          dispatch(accountSettingsTwoFactorResendEmail());
        }
      }
    });
};
