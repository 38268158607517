import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { hasUserRights } from 'store/auth/hasUserRights';
import { setInfoBarShow } from 'store/info/infoActions';
import {
  MediaItemSortAttribute,
  MediaItemSortOrder,
} from 'store/media/Media.enum';
import {
  editMediaFolderAction,
  sortMediaItemsAction,
} from 'store/media/mediaActions';
import { popupActionSet } from 'store/popup/popupActions';

import Dropdown from 'components/form/dropdown/Dropdown';
import Heading from 'components/typography/heading/Heading';
import PopupTypes from 'constants/PopupTypes.enum';
import UserRight from 'constants/UserRight.enum';

import { MediaFolderInfo } from 'store/media/Media.types';
import { RootState } from 'store/rootState';

import { FolderHeaderButtonTypes } from './FolderHeader.enum';
import { FolderHeaderOrder, FolderHeaderProps } from './FolderHeader.types';

import './FolderHeader.scss';

const FolderHeader: React.FC<FolderHeaderProps> = ({
  title,
  buttonType,
  editable = true,
  dynamicHeaderTitle = false,
  showOptions = false,
}) => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { folder_id } = useParams<{ folder_id: string }>();
  const hasMediaFoldersEditRights = dispatch(
    hasUserRights(UserRight.MEDIA_FOLDERS_EDIT),
  );

  // Media order
  const mediaOrderOptions = [
    {
      label: 'Date last edited',
      value: {
        attribute: MediaItemSortAttribute.UPDATED_AT,
        order: MediaItemSortOrder.DESCENDING,
      },
    },
    {
      label: 'Media item name A-Z',
      value: {
        attribute: MediaItemSortAttribute.NAME,
        order: MediaItemSortOrder.ASCENDING,
      },
    },
    {
      label: 'Media item name Z-A',
      value: {
        attribute: MediaItemSortAttribute.NAME,
        order: MediaItemSortOrder.DESCENDING,
      },
    },
    {
      label: 'Creation date new to old',
      value: {
        attribute: MediaItemSortAttribute.CREATED_AT,
        order: MediaItemSortOrder.DESCENDING,
      },
    },
    {
      label: 'Creation date old to new',
      value: {
        attribute: MediaItemSortAttribute.CREATED_AT,
        order: MediaItemSortOrder.ASCENDING,
      },
    },
  ];
  const [mediaOrder, setMediaOrder] = useState<FolderHeaderOrder | null>();

  useEffect(() => {
    if (!mediaOrder) {
      const mediaOrderStorage = localStorage.getItem('media_order');
      if (mediaOrderStorage) {
        setMediaOrder(JSON.parse(mediaOrderStorage));
      } else {
        setMediaOrder({
          attribute: MediaItemSortAttribute.UPDATED_AT,
          order: MediaItemSortOrder.DESCENDING,
        });
      }
    }
  }, [mediaOrder]);

  const changeMediaOrder = useCallback(
    (order: FolderHeaderOrder) => {
      localStorage.setItem('media_order', JSON.stringify(order));
      setMediaOrder(order);
      dispatch(sortMediaItemsAction(order, folder_id));
    },
    [dispatch, folder_id],
  );

  const { folder_info } = useSelector((state: RootState) => state.media);
  const folderName = folder_info ? folder_info.title : title;

  const filter = useSelector((state: RootState) => state.media.filter);
  const hasFilter = !(!filter || (!filter.name && !filter.filetype));

  const editFolderClick = (folderInfo: MediaFolderInfo) => {
    dispatch(editMediaFolderAction(folderInfo.id));
    dispatch(setInfoBarShow({ show: false }));
  };

  const newFolderClick = () => {
    const folderId = folder_info ? folder_info.id : null;
    dispatch(popupActionSet(PopupTypes.MEDIA_FOLDER, { parent_id: folderId }));
    dispatch(setInfoBarShow({ show: false }));
  };

  return (
    <div className="folder-header">
      {hasFilter && (
        <>
          <p className="media-page__search-label">
            {!!filter.name || !filter.filetype
              ? 'Search results for'
              : 'Search results by'}
          </p>
          <Heading level={2} className="media-page__search-title">
            {filter.name ? `'${filter.name}'` : 'File type'}
          </Heading>
        </>
      )}

      {!hasFilter && (
        <>
          <Heading level={2} className="folder-header__folder-name">
            {dynamicHeaderTitle ? folderName : title}
          </Heading>
          {folder_info &&
            hasMediaFoldersEditRights &&
            buttonType === FolderHeaderButtonTypes.EDIT_FOLDER &&
            editable &&
            !hasFilter && (
              <button
                type="button"
                onClick={() => editFolderClick(folder_info)}
                onKeyDown={e =>
                  e.key === 'Enter' && editFolderClick(folder_info)
                }
                className="folder-header__folder-button"
              >
                Edit
              </button>
            )}
          {hasMediaFoldersEditRights &&
            buttonType === FolderHeaderButtonTypes.NEW_FOLDER &&
            editable &&
            !hasFilter && (
              <button
                type="button"
                onClick={newFolderClick}
                onKeyDown={e => e.key === 'Enter' && newFolderClick}
                className="folder-header__folder-button"
              >
                Create new folder
              </button>
            )}
        </>
      )}

      {showOptions && (
        <div className="folder-header__options">
          <Dropdown
            name="media-order"
            width={230}
            values={mediaOrderOptions}
            value={mediaOrder}
            onChange={(_, value) => changeMediaOrder(value)}
            purpose="navigate"
            hasEmptyOption={false}
          />
        </div>
      )}
    </div>
  );
};

export default FolderHeader;
