import React from 'react';

import { EllipsisProps } from './Ellipsis.types';

import './Ellipsis.scss';

const Ellipsis: React.FC<EllipsisProps> = ({ text, maxWidth}) => {
  let style;

  if (maxWidth) {
    style = {maxWidth: maxWidth, display: 'inline-block', verticalAlign: 'bottom'}
  }

  return (
    <span className="ellipsis" title={text} style={style}>
      {text}
    </span>
  );
};

export default Ellipsis;
