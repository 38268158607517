export enum InputSignalsActionType {
  INPUT_SIGNALS_REQUESTED= '@@input-signals/requested',
  INPUT_SIGNALS_SUCCEEDED = '@@input-signals/success',
  INPUT_SIGNALS_FAILED = '@@input-signals/failed',
  INPUT_SIGNALS_CLEARED = '@@input-signals/cleared',
  INPUT_SIGNALS_DETAILS_REQUESTED = '@@input-signals/details-requested',
  INPUT_SIGNALS_DETAILS_SUCCEEDED = '@@input-signals/details-success',
  INPUT_SIGNALS_DETAILS_FAILED = '@@input-signals/details-failed',
  INPUT_SIGNALS_UPDATED = '@@input-signals/updated',
  INPUT_SIGNALS_ACTIVATED =
    '@@input-signals/activated',
  INPUT_SIGNALS_DEACTIVATED =
    '@@input-signals/deactivated',
}