import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { reverse } from "named-urls";

import ArrangementCard from 'components/arrangement/card/ArrangementCard';
import Deck from 'components/deck/Deck';
import DeckEmpty from 'components/deck/DeckEmpty';
import Button from 'components/button/Button';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import LayoutBlock from 'components/layout/layoutBlock/LayoutBlock';
import Heading from 'components/typography/heading/Heading';
import Dropdown from 'components/form/dropdown/Dropdown';
import ArrangementAddButton from 'components/arrangement/addButton/ArrangementAddButton';
import Input from 'components/form/input/Input';
import Text from 'components/typography/text/Text';
import Ellipsis from "components/typography/ellipsis/Ellipsis";

import { Paths } from 'constants/Routes.enum';
import UserRight from 'constants/UserRight.enum';
import HistoryLocationState from 'constants/HistoryState';

import {
  fetchArrangementsAction,
  editArrangementAction, selectArrangement,
} from 'store/arrangements/arrangementsActions';
import { hasUserRights } from 'store/auth/hasUserRights';
import { RootState } from 'store/rootState';
import {
  ArrangementsItemSortOrder,
  ArrangementsItemSortAttribute,
} from 'store/arrangements/Arrangements.enum';
import { setInfoBar, infoBarHide } from 'store/info/infoActions';
import { InfoBarState, InfoBarActionType } from 'store/info/infoActions.enum';

import { SelectedArrangement } from "./Arrangements.types";
import ArrangementFilterTypeOptions from './Arrangements.enum';

// TODO: move filter elements to its own component when restructuring filter options
import 'components/arrangement/filter/ArrangementFilter.scss';

const PoolsArrangements = () => {

  // Get filter options from local storage
  const arrangementFilterTypeStorage = localStorage.getItem('arrangement_filters_type') || ArrangementFilterTypeOptions.ALL_ARRANGEMENTS;
  const arrangementFilterKeywordStorage = localStorage.getItem('arrangement_filters_keyword') || '';

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const pool = useSelector((state: RootState) => state.pool);
  const { default_arrangement, default_arrangement_pending_approval } = pool;
  const { arrangements, request, selectable } = useSelector(
    (state: RootState) => state.arrangements
  );
  const [selectedArrangement, setSelectedArrangement] = useState<SelectedArrangement | undefined>(undefined);

  // State for filter value using the locale storage value as default value
  const [arrangementFilterType, setArrangementFilterType] = useState(arrangementFilterTypeStorage);
  const [arrangementFilterKeyword, setArrangementFilterKeyword] = useState<string>(arrangementFilterKeywordStorage);

  // List of filteredArrangements
  const filteredArrangements = useMemo(() => {
    let list = arrangements;

    // Remove default arrangement from list if not filtering
    if (!arrangementFilterKeyword && default_arrangement) {
      list = list.filter(arrangement => arrangement.id !== default_arrangement?.id);
    }

    // Filter arrangement list by type
    if (arrangementFilterType && arrangementFilterType !== ArrangementFilterTypeOptions.ALL_ARRANGEMENTS) {
      list = list.filter(arrangement => arrangement.type === arrangementFilterType);
    }
    // Filter arrangement list by keyword
    if (arrangementFilterKeyword) {
      list = list.filter(arrangement => {
        const name = arrangement.name.toLowerCase();
        return name.indexOf(arrangementFilterKeyword.toLowerCase()) >= 0;
      })
    }

    return list;
  }, [default_arrangement, arrangements, arrangementFilterKeyword, arrangementFilterType]);

  const resetFilters = useCallback(() => {
    setArrangementFilterType(ArrangementFilterTypeOptions.ALL_ARRANGEMENTS);
    setArrangementFilterKeyword('');
    localStorage.setItem('arrangement_filters_type', ArrangementFilterTypeOptions.ALL_ARRANGEMENTS);
    localStorage.setItem('arrangement_filters_keyword', '');
  }, [setArrangementFilterType, setArrangementFilterKeyword]);

  const arrangementTypes = [
    {
      label: 'All shows',
      value: ArrangementFilterTypeOptions.ALL_ARRANGEMENTS,
    },
    {
      label: 'Regular shows',
      value: ArrangementFilterTypeOptions.REGULAR_ARRANGEMENTS,
    },
    {
      label: 'Trigger shows',
      value: ArrangementFilterTypeOptions.TRIGGER_ARRANGEMENTS,
    },
  ];

  const arrangementsOrderOptions = [
    {
      label: 'Date last edited',
      value: {
        attribute: ArrangementsItemSortAttribute.UPDATED_AT,
        order: ArrangementsItemSortOrder.DESCENDING,
      },
    },
    {
      label: 'Show item name A-Z',
      value: {
        attribute: ArrangementsItemSortAttribute.NAME,
        order: ArrangementsItemSortOrder.ASCENDING,
      },
    },
    {
      label: 'Show item name Z-A',
      value: {
        attribute: ArrangementsItemSortAttribute.NAME,
        order: ArrangementsItemSortOrder.DESCENDING,
      },
    },
    {
      label: 'Creation date new to old',
      value: {
        attribute: ArrangementsItemSortAttribute.CREATED_AT,
        order: ArrangementsItemSortOrder.DESCENDING,
      },
    },
    {
      label: 'Creation date old to new',
      value: {
        attribute: ArrangementsItemSortAttribute.CREATED_AT,
        order: ArrangementsItemSortOrder.ASCENDING,
      },
    },
  ];

  const arrangementsOrderStorage = localStorage.getItem(
    'arrangement_order',
  );
  const [arrangementsOrder, setArrangementsOrder] = useState<{
    attribute: string;
    order: string;
  }>((arrangementsOrderStorage && JSON.parse(arrangementsOrderStorage)) || { attribute: 'updated_at', order: 'desc' });

  const changeArrangementsOrder = useCallback(
    (order: {
      attribute: string;
      order: string;
    }) => {
      localStorage.setItem('arrangement_order', JSON.stringify(order));
      setArrangementsOrder(order);
    },
    [],
  );

  const hasArrangementsViewRights = dispatch(
    hasUserRights(UserRight.ARRANGEMENTS_VIEW),
  );
  const hasArrangementsEditRights = dispatch(
    hasUserRights(UserRight.ARRANGEMENTS_EDIT),
  );
  const onlyOneHiddenArrangement = useMemo(() => {
    return filteredArrangements &&
      filteredArrangements.length === 1 &&
      filteredArrangements.filter(arrangement => arrangement.hidden).length === 1;
  }, [filteredArrangements]);

  const showsLabel = useMemo(() => {
    const showLength = filteredArrangements.filter(
      arrangement => !arrangement.hidden,
    ).length;

    let label;
    switch (arrangementFilterType) {
      case ArrangementFilterTypeOptions.REGULAR_ARRANGEMENTS:
        label = 'Regular shows';
        break;
      case ArrangementFilterTypeOptions.TRIGGER_ARRANGEMENTS:
        label = 'Trigger shows';
        break;
      default:
        label = 'All shows';
    }

    return `${label} (${showLength})`;
  }, [filteredArrangements, arrangementFilterType]);


  const noArrangementsViewRightsSubTitle = (
    <p>
      In case you should have view rights please contact your supervisor or send
      an email to{' '}
      <Button
        hasShadow={false}
        scheme="link"
        link={`mailto:${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
        text={`${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
        size="inline"
      />
    </p>
  );

  const { state } = useLocation<HistoryLocationState>();

  useEffect(() => {
    dispatch(
      fetchArrangementsAction({
        pool_id: Number(id),
        unprocessableMediaItems: state?.unprocessableMediaItems,
        history,
        sort: arrangementsOrder
      }),
    );

    if (state && state.fromArrangement) {
      dispatch(
        editArrangementAction(
          state.fromArrangement,
          pool.default_arrangement?.id,
        ),
      );
    }
  }, [dispatch, id, history, pool.default_arrangement, state, arrangementsOrder]);

  useEffect(() => {
    if (state && (state?.fromTrigger || state?.newTrigger || state?.fromEvent || state?.newEvent)) {
      dispatch(selectArrangement(true));
    }
  }, [dispatch, history.location, state]);

  const addArrangementToDetailsPopup = useCallback((state: HistoryLocationState) => {
    dispatch(selectArrangement(false));

    // send back to event page
    if (state?.fromEventFormFormValues) {
      history.push({
        pathname: reverse(Paths.POOLS_SCHEDULES, { id: pool.id }),
        state: {
          ...state,
          fromEventFormFormValues: {
            ...state.fromEventFormFormValues,
            arrangementId: selectedArrangement?.id?.toString(),
          },
          arrangement: selectedArrangement
        },
      })
    }

    // send back to trigger page
    if (state?.fromTriggerFormFormValues) {
      history.push({
        pathname: reverse(Paths.POOLS_TRIGGERS, { id: pool.id }),
        state: {
          ...state,
          fromTriggerFormFormValues: {
            ...state.fromTriggerFormFormValues,
            arrangementId: selectedArrangement?.id?.toString(),
          },
          arrangement: selectedArrangement
        },
      })
    }
  }, [dispatch, history, pool.id, selectedArrangement]);

  const returnToDetailsPopup = useCallback((state: HistoryLocationState) => {
    dispatch(selectArrangement(false));

    const location = {
      pathname: state?.fromEventFormFormValues ? reverse(Paths.POOLS_SCHEDULES, { id: pool.id }) : reverse(Paths.POOLS_TRIGGERS, { id: pool.id }),
      state
    };

    history.push(location);
  }, [dispatch, pool.id, history]);

  const toggleSelectedArrangement = ({id, name, template, templateId, active, pendingApproval}:SelectedArrangement) => {
    const props = {
      id, name, template, templateId, active, pendingApproval
    };
    // @ts-ignore
    setSelectedArrangement((prevProps) => prevProps?.id !== props.id && props);
  }

  useEffect(() => {
    const triggerName = state?.fromTriggerFormFormValues?.name;
    const eventName = state?.fromEventFormFormValues?.name;

    if (selectable) {
      dispatch(
        setInfoBar({
          message: (
            <Text flatten>
              <>
                {state?.fromTriggerFormFormValues ? (
                  <>
                    {triggerName === '' || !triggerName ? (
                      'Select the show you want to use for your trigger.'
                    ) : (
                      <>
                        Select the show you want to use for <span style={{whiteSpace: 'nowrap'}}>&apos;
                        <Ellipsis text={triggerName} maxWidth="200px" />
                        &apos;</span> trigger.
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {eventName === '' || !eventName ? (
                      'Select the show you want to use for your event.'
                    ) : (
                      <>
                        Select the show you want to use for <span style={{whiteSpace: 'nowrap'}}>&apos;
                        <Ellipsis text={eventName} maxWidth="200px" />
                        &apos;</span> event.
                      </>
                    )}
                  </>
                )}
              </>
            </Text>
          ),
          action: [
            {
              text: 'Cancel',
              handle: () => {
                dispatch(infoBarHide());
                returnToDetailsPopup(state)
              },
              type: InfoBarActionType.LINK,
            },
            {
              text: 'Use selection',
              handle: () => {
                dispatch(infoBarHide());
                addArrangementToDetailsPopup(state);
              },
              type: InfoBarActionType.BUTTON,
              disabled: !selectedArrangement,
            },
          ],
          state: InfoBarState.SELECT,
        }),
      );
    }
  }, [
    dispatch,
    history.location,
    selectable,
    selectedArrangement,
    addArrangementToDetailsPopup,
    returnToDetailsPopup,
    state,
  ]);

  if (!hasArrangementsViewRights) {
    return (
      <DeckEmpty title="No view rights for shows">
        {noArrangementsViewRightsSubTitle}
      </DeckEmpty>
    );
  }

  if (request) {
    return <LoadingIndicator title="Loading shows..." />;
  }

  return (
    <>
      <div className="arrangement-filter">
        <div className="arrangement-filter__bar">
          <div className="arrangement-filter__input">
            <Input
              type="text"
              label={
                <Heading level={3} noMargin>
                  Search
                </Heading>
              }
              name="keyword"
              id="search_keyword"
              value={arrangementFilterKeyword}
              scheme="white"
              onChange={(e) => {
                setArrangementFilterKeyword(e.target.value);
                localStorage.setItem('arrangement_filters_keyword', e.target.value);
              }}
              placeholder="What are you looking for?"
            />
          </div>

          <div className="arrangement-filter__options">
            <Button
              tag="button"
              text="Reset"
              size="medium"
              scheme="link"
              className="arrangement-filter__option"
              hasShadow={false}
              onClick={resetFilters}
              disabled={
                arrangementFilterKeyword === '' && arrangementFilterType === ArrangementFilterTypeOptions.ALL_ARRANGEMENTS
              }
            />
            <Button tag="button" type="submit" text="Search" size="medium" />
          </div>
        </div>
      </div>

      {default_arrangement && !arrangementFilterKeyword && (
        <>
          <h2>Default for this group</h2>
          <Deck>
            <ArrangementCard
              key={default_arrangement.id}
              id={default_arrangement.id}
              name={default_arrangement.name}
              type={default_arrangement.type}
              template={default_arrangement.template}
              templateId={default_arrangement.template_id}
              active={default_arrangement.active}
              pendingApproval={default_arrangement_pending_approval}
              selected={selectedArrangement && selectedArrangement.id === default_arrangement.id}
              selectable={selectable}
              setSelected={toggleSelectedArrangement}
              disabled={selectable && !default_arrangement.active}
            />
          </Deck>
        </>
      )}

      {arrangements && arrangements.length > 0 && !onlyOneHiddenArrangement && (
        <>
          <LayoutBlock hasInlineChildren>
            <Heading level={2} noMargin>
              {showsLabel}
            </Heading>
            <div className="form__dropdown__menu-block">
              <Dropdown
                name="media-order"
                width={230}
                values={arrangementsOrderOptions}
                value={arrangementsOrder}
                // @ts-ignore
                onChange={(_, value) => changeArrangementsOrder(value)}
                hasEmptyOption={false}
                purpose="navigate"
              />
              <Dropdown
                values={arrangementTypes}
                value={arrangementFilterType}
                width={280}
                name="arrangementTypes"
                id="arrangementTypes"
                // @ts-ignore
                onChange={(func, value) => {
                  setArrangementFilterType(value);
                  localStorage.setItem('arrangement_filters_type', value);
                }}
                hasEmptyOption={false}
                purpose="navigate"
              />
            </div>
          </LayoutBlock>

          <Deck dataCy="arrangementsList">
            {filteredArrangements.map(arrangement => {
              return (
                !arrangement.hidden && (
                  <ArrangementCard
                    key={arrangement.id}
                    id={arrangement.id}
                    name={arrangement.name}
                    type={arrangement.type}
                    template={arrangement.template}
                    templateId={arrangement.template?.id}
                    active={arrangement.active}
                    pendingApproval={arrangement.pending_approval}
                    selected={selectedArrangement && selectedArrangement.id === arrangement.id}
                    selectable={selectable}
                    setSelected={toggleSelectedArrangement}
                    disabled={selectable && !arrangement.active}
                  />
                )
              );
            })}
          </Deck>

          {filteredArrangements && filteredArrangements.length === 0 && (
            <DeckEmpty title="No shows found" />
          )}
        </>
      )}
      {arrangements && arrangements.length === 0 && (
        <DeckEmpty title="No shows added">
          {// @ts-ignore
            hasArrangementsEditRights && (
              <p>Click on the blue + to start adding shows to the group</p>
            )
          }
        </DeckEmpty>
      )}
      {/* @ts-ignore */}
      {!selectable && hasArrangementsEditRights && <ArrangementAddButton />}
    </>
  );
};

export default PoolsArrangements;
