const RegexOptions = {
  URL: /^.{1,}:\/\/.{1,}$/,
  PASSWORD_VALIDATION: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(.{8,})$/,
  MINIMAL_EIGHT_CHARS: /.{8,}/,
  MINIMAL_ONE_NUMBER: /.*\d/,
  MINIMAL_ONE_CAPITAL: /.*[A-Z]/,
  EMAIL_VALIDATION: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
  HEX_COLOR: /^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$/,
  ZERO_TO_HUNDRED_PERCENT: /^[1-9][0-9]?$|^100$/,
  MAC_ADDRESS: /^([0-9a-fA-F]{2}[:-]){5}([0-9a-fA-F]{2})$/,
  NON_NEGATIVE_NUMBER: /^\d+$/,
};

export default RegexOptions;
