import React from 'react';
import { useDispatch } from 'react-redux';

import Calendar from 'components/calendar/Calendar';
import PoolButton from 'components/pool/button/PoolButton';
import PopupTypes from 'constants/PopupTypes.enum';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRight from 'constants/UserRight.enum';
import DeckEmpty from 'components/deck/DeckEmpty';
import Button from 'components/button/Button';

const PoolsDetail = () => {
  const dispatch = useDispatch();
  const hasEventsEditRights = dispatch(hasUserRights(UserRight.EVENTS_EDIT));
  const hasEventsViewRights = dispatch(hasUserRights(UserRight.EVENTS_VIEW));

  const noEventsViewRightsSubTitle = (
    <p>
      In case you should have view rights please contact your supervisor or send
      an email to{' '}
      <Button
        hasShadow={false}
        scheme="link"
        link={`mailto:${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
        text={`${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
        size="inline"
      />
    </p>
  );

  return (
    <>
      {// @ts-ignore
      hasEventsViewRights ? (
        <>
          <Calendar />
          {// @ts-ignore
          hasEventsEditRights && (
            <PoolButton text="Add event" popupType={PopupTypes.EVENT} />
          )}
        </>
      ) : (
        <DeckEmpty title="No view rights for events">
          {noEventsViewRightsSubTitle}
        </DeckEmpty>
      )}
    </>
  );
};

export default PoolsDetail;
