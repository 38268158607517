import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import AnimateHeight from 'react-animate-height';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

import Input from 'components/form/input/Input';
import Heading from 'components/typography/heading/Heading';
import Button from 'components/button/Button';
import Dropdown from 'components/form/dropdown/Dropdown';
import DropdownMultiple from 'components/form/dropdown-multiple/DropdownMultiple';
import LocationDropdown from 'components/location/dropdown/LocationDropdown';

import { Paths } from 'constants/Routes.enum';

import {
  fetchDevicesAction,
  fetchDeviceFiltersAction,
} from 'store/devices/devicesActions';
import { DevicesFilterOptions, DevicesSigns, RootState } from 'store/rootState';

import { AssignedOptions, StatusOptions } from './DeviceFilter.options';
import {
  DeviceFilterProps,
  DeviceFilterStorage,
  FilterDeviceList,
  Filters,
  InitialValues,
} from './DeviceFilter.types';

import './DeviceFilter.scss';

const DeviceFilter = ({
  localStorageItem,
  poolId,
  historyLocation,
}: DeviceFilterProps) => {
  const dispatch = useDispatch();
  const { customerOverride } = useSelector((state: RootState) => state.superAdmin)
  const matchPoolDevices = useRouteMatch([Paths.POOLS_DEVICES]);
  const filterStorage = JSON.parse(
    localStorage.getItem(localStorageItem) || '{}',
  );
  const filterOptions = useSelector(
    (state: RootState) => state.devices.filterOptions,
  );
  const [openFilters, setOpenFilters] = useState(false);
  const [isInitialized, setInitialized] = useState(false);
  const [formKey, setFormKey] = useState(0);

  let initialValues: InitialValues = {
    keyword: '',
    status: '',
  };

  if (!matchPoolDevices) {
    initialValues = {
      ...initialValues,
      assigned: 0,
      locations: undefined,
      groups: undefined,
    };
  }

  const getAllFilterOptions = (filterOptions: DevicesFilterOptions) => {
    const filterOptionsLocations = filterOptions?.locations;
    const filterOptionsGroups = filterOptions?.groups;
    const locations = filterOptionsLocations
      ? filterOptionsLocations.map(location => ({
          label: location.name,
          value: location.id,
        }))
      : null;

    const groups = filterOptionsGroups
      ? filterOptionsGroups.map(group => ({
          label: group.name,
          value: group.id,
          location: group.location,
        }))
      : null;

    return {
      locations,
      groups,
      ...AssignedOptions,
      ...StatusOptions,
    };
  };

  const allFilterOptions = getAllFilterOptions(filterOptions);

  const filterDevices = useCallback(
    (values: InitialValues) => {
      if (isInitialized) {
        localStorage.setItem(localStorageItem, JSON.stringify(values));

        if (matchPoolDevices) {
          dispatch(fetchDevicesAction({ pool_id: poolId, historyLocation }));
        } else {
          dispatch(fetchDevicesAction({
            pool_id: undefined,
            historyLocation: undefined
          }));
        }
      }
    },
    [
      isInitialized,
      localStorageItem,
      matchPoolDevices,
      dispatch,
      poolId,
      historyLocation,
    ],
  );

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({ initialValues, onSubmit: filterDevices });

  const resetFilter = () => {
    resetForm();
    localStorage.removeItem(localStorageItem);

    if (matchPoolDevices) {
      dispatch(fetchDevicesAction({ pool_id: poolId, historyLocation }));
    } else {
      dispatch(fetchDevicesAction({
        pool_id: undefined,
        historyLocation: undefined
      }));
    }

    setFormKey(formKey + 1);
  };

  useEffect(() => {
    dispatch(fetchDeviceFiltersAction());
  }, [dispatch]);

  useEffect(() => {
    if (filterStorage && !isInitialized) {
      if (filterStorage.keyword) {
        setFieldValue('keyword', filterStorage.keyword);
      }
      if (filterStorage.locations) {
        setFieldValue('locations', filterStorage.locations);
      }
      if (filterStorage.groups) {
        setFieldValue('groups', filterStorage.groups);
      }
      if (filterStorage.assigned) {
        setFieldValue('assigned', filterStorage.assigned);
      }
      if (filterStorage.status) {
        setFieldValue('status', filterStorage.status);
      }

      setOpenFilters(
        (filterStorage.status && filterStorage.status !== '') ||
          (filterStorage.assigned && filterStorage.assigned !== 0) ||
          (filterStorage.groups && filterStorage.groups !== 0) ||
          (filterStorage.locations && filterStorage.locations.length !== 0),
      );
    }
    setInitialized(true);
  }, [dispatch, setFieldValue, isInitialized, filterStorage]);

  useEffect(() => {
    if (customerOverride) {
      setFieldValue('locations', [])
      handleSubmit()
    }
  }, [customerOverride, setFieldValue, handleSubmit])

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames('device-filter', {
        'device-filter--in-pool': matchPoolDevices,
      })}
    >
      <div className="device-filter__bar">
        <div className="device-filter__input">
          <Input
            type="text"
            label={
              <Heading level={3} noMargin>
                Search
              </Heading>
            }
            name="keyword"
            id="search_keyword"
            value={values.keyword}
            scheme="white"
            onChange={handleChange}
            placeholder="What are you looking for?"
          />
        </div>

        <div className="device-filter__options">
          <Button
            text={openFilters ? 'Less filters' : 'More filters'}
            size="medium"
            scheme="link"
            className="pool-filter__option"
            hasShadow={false}
            onClick={() => setOpenFilters((prevOpen) => !prevOpen)}
          />
          <Button
            tag="button"
            text="Reset"
            size="medium"
            scheme="link"
            className="device-filter__option"
            hasShadow={false}
            onClick={resetFilter}
            disabled={
              matchPoolDevices
                ? values.keyword === '' && values.status === ''
                : values.keyword === '' &&
                  values.assigned === 0 &&
                  (!values.locations || values.locations?.length === 0) &&
                  (!values.groups || values.groups?.length === 0) &&
                  values.status === ''
            }
          />
          <Button tag="button" type="submit" text="Search" size="medium" />
        </div>
      </div>

      <AnimateHeight duration={300} height={openFilters ? 'auto' : 0}>
        <div className="device-filter__advanced">
          <Heading level={3}>Filter options</Heading>
          {!matchPoolDevices && (
            <>
              {allFilterOptions.locations && (
                <LocationDropdown
                  multiple
                  showAllLocations
                  id="filter_locations"
                  className="device-filter__advanced__option"
                  name="locations"
                  label="Locations"
                  width={220}
                  value={values.assigned !== 0 ? 0 : values.locations}
                  values={allFilterOptions.locations}
                  placeholder="All locations"
                  isLoading={!allFilterOptions.locations}
                  onChange={(values: Array<{ value: string; label: string }>) => {
                    setFieldValue(
                      'locations',
                      [].slice
                        .call(values)
                        .map((option: { value: string; label: string }) => ({
                          id: option.value,
                          name: option.label,
                        })),
                    );
                    handleSubmit();
                  }}
                  disabled={values.assigned !== 0}
                />
              )}
              {allFilterOptions.groups && (
                <DropdownMultiple
                  id="filter_groups"
                  className="device-filter__advanced__option"
                  name="groups"
                  label="Groups"
                  width={220}
                  value={values.assigned !== 0 ? 0 : values.groups}
                  values={allFilterOptions.groups}
                  placeholder="All groups"
                  isLoading={!allFilterOptions.groups}
                  onChange={values => {
                    setFieldValue(
                      'groups',
                      [].slice
                        .call(values)
                        .map((option: { value: string; label: string }) => ({
                          id: option.value,
                          name: option.label,
                        })),
                    );
                    handleSubmit();
                  }}
                  disabled={values.assigned !== 0}
                />
              )}
              {allFilterOptions.assigned && (
                <Dropdown
                  label="Assigned"
                  name="assigned"
                  id="assigned"
                  className="device-filter__advanced__option"
                  width={200}
                  value={
                    (values.groups && values.groups.length) ||
                    (values.locations && values.locations.length)
                      ? 1
                      : values.assigned
                  }
                  values={allFilterOptions.assigned}
                  hasEmptyOption={false}
                  isLoading={!allFilterOptions.assigned}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                    handleSubmit();
                  }}
                  disabled={
                    (values.groups && values.groups.length > 0) ||
                    (values.locations && values.locations.length > 0)
                  }
                />
              )}
            </>
          )}
          {allFilterOptions.status && (
            <Dropdown
              label="Status"
              name="status"
              id="status"
              className="device-filter__advanced__option"
              values={allFilterOptions.status}
              width={200}
              value={values.status}
              hasEmptyOption={false}
              isLoading={!allFilterOptions.status}
              onChange={(name, value) => {
                setFieldValue(name, value);
                handleSubmit();
              }}
            />
          )}
        </div>
      </AnimateHeight>
    </form>
  );
};

export const filterDeviceList = ({
  signs,
  localStorageItem,
  filterOptions,
  customer
}: FilterDeviceList) => {
  if (!filterOptions) return signs;

  const deviceFilters: DeviceFilterStorage = JSON.parse(
    localStorage.getItem(localStorageItem) || '{}',
  );

  const filters: Filters = {};

  if (deviceFilters?.locations) {
    filters.location_name = deviceFilters?.locations.map(l => l.name)
  }

  if (deviceFilters?.groups) {
    const groups = filterOptions?.groups?.map(group => ({
      label: group.name,
      value: group.id,
    }));

    filters.pool_name = groups
      ?.filter(group =>
        deviceFilters?.groups.some((g: any) => g.id === group.value),
      )
      ?.map(group => group.label)
  }

  const multiPropsFilter = (devices: DevicesSigns[], filters: Filters) => {
    const filterKeys = Object.keys(filters);

    return devices.filter(device => {
      // every selected must match
      return filterKeys.every(key => {
        const typedKey = key as keyof Filters

        const filterByKey = filters[typedKey]
        if (filterByKey === undefined) return true
        const deviceByKey = device[typedKey]

        if (Array.isArray(deviceByKey)) {
          return deviceByKey.some(keyEle => {
            // device and filter are both arrays
            if (Array.isArray(filterByKey)) {
              if (filterByKey.length === 0) return true

              return filterByKey?.includes(keyEle)
            }

            // device is array - filter is single value
            return keyEle === filterByKey
          });
        }

        if (Array.isArray(filterByKey)) {
          if (filterByKey.length === 0) return true

          // filter is array - device is single value
          return filterByKey.includes(deviceByKey)
        }

        // both are single value
        return filterByKey === deviceByKey;
      });
    });
  };

  const filterByStatus = (devices: DevicesSigns[], filterValue: string) => {
    if (!filterValue) return devices
    return devices.filter((device) => device.online_status === filterValue)
  }

  const filterByAssigned = (devices: DevicesSigns[], filterValue: number) => {
    if (!filterValue) return devices

    // only show unassigned devices
    if (filterValue === 2) {
      return devices.filter((device) => device.location_name === null)
    }
    // only show assigned devices
    if (filterValue === 1) {
      return devices.filter((device) => device.location_name !== null)
    }

    return devices
  }

  const filterByKeyword = (devices: DevicesSigns[], filterValue: string) => {
    if (!filterValue) return devices
    const normalizedKeyword = filterValue.toLowerCase()

    return devices.filter((device) => {
      const normalizedName = device.name.toLowerCase()
      const normalizedSerial = device.serial_number.toLowerCase()
      return normalizedName.includes(normalizedKeyword)
        || normalizedSerial.includes(normalizedKeyword)
    })
  }

  const searchDevices = (filters: Filters) => {
    let filteredDevices = multiPropsFilter(signs, filters);

    filteredDevices = filterByStatus(filteredDevices, deviceFilters?.status as string)
    filteredDevices = filterByAssigned(filteredDevices, deviceFilters?.assigned)
    filteredDevices = filterByKeyword(filteredDevices, deviceFilters?.keyword)

    if (customer) {
      filteredDevices = filteredDevices.filter((device) => device.customer_id === Number(customer))
    }

    return filteredDevices
  };

  return searchDevices(filters);
};

export default DeviceFilter;
