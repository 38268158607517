import UserRight from "constants/UserRight.enum";
import { DispatchState } from "types/dispatchState";

export const hasUserRights = (userRight: UserRight): DispatchState<boolean> => (_, getState) => {
  const { user } = getState().auth;

  if (user.super_admin) {
    return true;
  }

  if (user.rights) {
    return user.rights.indexOf(userRight) >= 0;
  }
  return false;
};

export const hasAdminRights = (): DispatchState<boolean> => (_, getState) => {
  const { user } = getState().auth;

  if (user.super_admin) {
    return true;
  }
  return user.admin ?? false;
};

export const hasSuperAdminRights = (): DispatchState<boolean> => (_, getState) => {
  const { user } = getState().auth;
  return user.super_admin ?? false;
}
