import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import PoolButton from 'components/pool/button/PoolButton';
import Deck from 'components/deck/Deck';
import DeckEmpty from 'components/deck/DeckEmpty';
import DevicesCard from 'components/devices/card/DevicesCard';
import Button from 'components/button/Button';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import DeviceFilter, {
  filterDeviceList,
} from 'components/devices/filter/DeviceFilter';
import Heading from 'components/typography/heading/Heading';

import { fetchDevicesAction } from 'store/devices/devicesActions';
import { hasUserRights } from 'store/auth/hasUserRights';
import { RootState } from 'store/rootState';

import PopupTypes from 'constants/PopupTypes.enum';
import UserRight from 'constants/UserRight.enum';

const PoolsDevices = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const devices = useSelector((state: RootState) => state.devices);
  const hasDevicesEditRights = dispatch(hasUserRights(UserRight.SIGNS_EDIT));
  const hasDevicesViewRights = dispatch(hasUserRights(UserRight.SIGNS_VIEW));
  const filterOptions = useSelector((state: RootState) => state.devices.filterOptions);
  const filteredDevices = filterDeviceList({
    signs: devices.signs,
    localStorageItem: 'device_filters_pool',
    filterOptions,
  });

  useEffect(() => {
    dispatch(fetchDevicesAction({ pool_id: Number(id), historyLocation: history.location }));
  }, [dispatch, history, id]);

  const noDevicesViewRightsSubTitle = (
    <p>
      In case you should have view rights please contact your supervisor or send
      an email to{' '}
      <Button
        hasShadow={false}
        scheme="link"
        link={`mailto:${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
        text={`${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
        size="inline"
      />
    </p>
  );

  if (!hasDevicesViewRights) {
    return (
      <DeckEmpty title="No view rights for devices">
        {noDevicesViewRightsSubTitle}
      </DeckEmpty>
    );
  }

  return (
    <>
      <DeviceFilter
        localStorageItem="device_filters_pool"
        historyLocation={history.location}
        poolId={Number(id)}
      />
      <Heading level={2}>Devices</Heading>

      {devices.request && devices.signs?.length === 0 && (
        <LoadingIndicator title="Loading devices..." />
      )}

      {devices.signs.length > 0 && (
        <Deck>
          {filteredDevices.map(device => {
            return (
              <DevicesCard
                key={device.id}
                id={device.id}
                name={device.name}
                serialNumber={device.serial_number}
                active={device.online_status}
              />
            );
          })}
        </Deck>
      )}
      {devices.signs.length === 0 && (
        <DeckEmpty title="No devices added">
          {/* @ts-ignore */}
          {hasDevicesEditRights ? (
            <p>Click on the blue + to start adding devices to the group</p>
          ) : (
            ''
          )}
        </DeckEmpty>
      )}
      {/* @ts-ignore */}
      {hasDevicesEditRights && (
        <PoolButton text="Add device" popupType={PopupTypes.DEVICE} />
      )}
      {!Array.isArray(filteredDevices) ||
        (!filteredDevices.length && (
          <DeckEmpty title="No devices found">
            <p>
              There are no devices that match your search criteria. Try to
              change the search criteria or reset all filters.
            </p>
          </DeckEmpty>
        ))}
    </>
  );
};

export default PoolsDevices;
