import React from 'react';
import { useDispatch } from 'react-redux';

import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import Text from 'components/typography/text/Text';
import { TextTag } from 'components/typography/text/Text.enum';

import { hasUserRights } from 'store/auth/hasUserRights';

import UserRight from 'constants/UserRight.enum';

import {
  UploadMultipleListProps,
  UploadUploadedFile
} from './Upload.types';

const UploadMultipleList: React.FC<UploadMultipleListProps> = ({
  uploadedFiles,
  readFiles,
  removeFile,
}) => {
  const dispatch = useDispatch();
  const hasMediaEditRights = dispatch(hasUserRights(UserRight.MEDIA_EDIT));

  const listItem = (file: UploadUploadedFile) => {
    return (
      <div key={file.name || file.file_name} className="form__upload__file">
        <Text
          tag={TextTag.SPAN}
          loading={readFiles.indexOf(file.name || file.file_name) < 0}
          loadingStyles={{ width: 200 }}
          className="form__upload__file-text"
        >
          {file.name || file.file_name}
        </Text>

        {/* TODO: fix dispatch typing */}
        {/* @ts-ignore */}
        {hasMediaEditRights && (
          <Button
            text={<Icon name="cross" size="x-small" />}
            hasShadow={false}
            scheme="link"
            size="inline"
            className="form__upload__delete"
            handler={() => removeFile(file.name || file.file_name)}
            loading={readFiles.indexOf(file.name || file.file_name) < 0}
          />
        )}
      </div>
    );
  };

  return (
    <div className="form__upload__files">
      {uploadedFiles
        .sort(e =>
          (e.name || e.file_name).toLowerCase() === 'index.html' ? -1 : 0,
        )
        .map(file => listItem(file))}
    </div>
  );
};

export default UploadMultipleList;
