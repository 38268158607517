import { reverse } from 'named-urls';

import { Api } from 'constants/Routes.enum';
import { popupActionClear, popupActionSet } from 'store/popup/popupActions';
import { setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import fetchData from 'store/fetchData';
import PopupTypes from 'constants/PopupTypes.enum';
import { userUpdated } from 'store/user/userActions';

export const UsersActionTypes = {
  USERS_REQUESTED: '@@users/requested',
  USERS_SUCCEEDED: '@@users/success',
  USERS_FAILED: '@@users/error',
  USERS_CLEARED: '@@users/cleared',
  USER_ADDED: '@@user/added',
  USERS_UPDATED: '@@users/updated',
};

export const usersRequested = () => ({
  type: UsersActionTypes.USERS_REQUESTED,
});

export const usersSucceeded = users => ({
  type: UsersActionTypes.USERS_SUCCEEDED,
  payload: users,
});

export const usersFailed = () => ({
  type: UsersActionTypes.USERS_FAILED,
});

export const userAdded = user => ({
  type: UsersActionTypes.USER_ADDED,
  payload: user,
});

export const usersCleared = () => ({
  type: UsersActionTypes.USERS_CLEARED,
});

export const usersUpdated = user => ({
  type: UsersActionTypes.USERS_UPDATED,
  payload: user,
});

export const fetchUsersAction = () => dispatch => {
  dispatch(usersRequested());

  const promise = dispatch(fetchData(Api.USERS));

  promise
    .then(json => json.json())
    .then(result => {
      if (result.users) {
        dispatch(usersSucceeded(result.users));
      } else {
        dispatch(usersFailed());
      }
    })
    .catch(() => {
      dispatch(usersFailed());
    });
};

export const addUserAction = (
  userData,
  id,
  setSubmitting,
  loggedInUser,
  popupContents,
) => async dispatch => {
  const isEditing = !!id;

  // create or update user
  const promise = dispatch(
    fetchData(
      isEditing ? reverse(Api.USERS_PATCH, { id }) : Api.USERS_POST,
      {
        method: isEditing ? 'PATCH' : 'POST',
        body: userData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message, user } = result;

      if (user) {
        if (isEditing) {
          // update user state
          dispatch(userUpdated(user));
          dispatch(usersUpdated(user));
        } else {
          // add new user to state

          const updatedUser = {
            ...user,
            locations: user.locations.length,
          };
          dispatch(userAdded(updatedUser));
        }

        dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
        dispatch(popupActionClear());
      } else {
        dispatch(
          setInfoBar({ message, timeout: 0, state: InfoBarState.ERROR }),
        );
      }

      setSubmitting(false);

      // When logged in user is changing its own e-mail show popup wih message that verification is needed + button to log out.
      if (user.id === loggedInUser.id && user.email !== loggedInUser.username) {
        dispatch(popupActionSet(PopupTypes.NOTIFICATION, popupContents));
      }
    })
    .catch(err => {
      console.error(err);
      setSubmitting(false);
      dispatch(
        setInfoBar({
          message: 'Failed to add new user',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const openUserAction = id => async dispatch => {
  const promise = dispatch(fetchData(reverse(Api.USERS_DETAIL, { id })));

  promise
    .then(json => json.json())
    .then(result => {
      const { firstname, lastname, email, roles, locations } = result.user;

      dispatch(
        popupActionSet(PopupTypes.MANAGEMENT_USER, {
          id,
          firstName: firstname,
          lastName: lastname,
          email,
          userRoles: roles,
          locations,
        }),
      );
    })
    .catch(err => {
      console.error(err);
      dispatch(
        setInfoBar({
          message: 'Failed to find user',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};
