import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import RegexOptions from 'constants/RegexOptions';

import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import { InputScheme } from 'components/form/input/Input.enum';
import Button from 'components/button/Button';
import ButtonGroup from 'components/button/ButtonGroup';
import SubmitButton from 'components/form/submit-button/SubmitButton';
import LocationDropdown from 'components/location/dropdown/LocationDropdown';
import {
  Location,
  LocationOption,
} from 'components/popup/management/User.types';

import { RootState } from 'store/rootState';
import { popupActionClear } from 'store/popup/popupActions';
import { setInfoBarShow } from 'store/info/infoActions';
import { createInputDeviceAction } from 'store/inputSignals/inputDevices/inputDevicesActions';

import { InputDeviceAddProps } from './inputDeviceAdd.types';

const InputDeviceAdd: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const infoBar = useSelector((state: RootState) => state.info.bar);
  const [isSubmitting, setSubmitting] = useState(false);

  const initialValues = {
    name: '',
    locations: [],
    serial_number: '',
  };

  const validationSchema = Yup.object().shape({
    serial_number: Yup.string()
      .required('Serial number is required')
      .matches(RegexOptions.MAC_ADDRESS, 'Serial number is not valid'),
  });

  const onCancel = () => {
    dispatch(popupActionClear());
    if (infoBar.show) {
      dispatch(setInfoBarShow({ show: false }));
    }
  };

  const onSubmit = (values: InputDeviceAddProps) => {
    setSubmitting(true);
    dispatch(createInputDeviceAction(values, setSubmitting));
  };

  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <legend>Add an input device</legend>
        <FormBlock>
          <Input
            type="text"
            label="Enter the serial number of the device you want to add"
            name="serial_number"
            id="serial_number"
            scheme={InputScheme.WHITE}
            value={values.serial_number}
            placeholder="Enter the serial number"
            onChange={handleChange}
            error={touched.serial_number && errors.serial_number}
          />
        </FormBlock>
        <FormBlock>
          <LocationDropdown
            id="locations"
            name="locations"
            label="This device is located in"
            value={values.locations}
            placeholder="Select locations"
            onChange={(values: Location[]) => {
              setFieldValue(
                'locations',
                [].slice.call(values).map((option: LocationOption) => ({
                  id: option.value,
                  name: option.label,
                })),
              );
            }}
            error={touched.locations && errors.locations}
            listSelected
            showAllLocations
            multiple
          />
        </FormBlock>
        <FormBlock flatten>
          <Input
            type="text"
            label="Choose a name for this device"
            name="name"
            id="name"
            scheme={InputScheme.WHITE}
            value={values.name}
            placeholder="Define a name"
            onChange={handleChange}
            error={touched.name && errors.name}
          />
        </FormBlock>
      </fieldset>

      <FormBlock hasInlineChildren isEnd>
        <Button
          tag="button"
          size="medium"
          text="Cancel"
          scheme="link"
          hasShadow={false}
          handler={() => {
            onCancel();
          }}
          disabled={isSubmitting}
        />
        <ButtonGroup>
          <SubmitButton canSave disabled={isSubmitting} />
        </ButtonGroup>
      </FormBlock>
    </form>
  );
};

export default InputDeviceAdd;
