import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Dropdown from 'components/form/dropdown/Dropdown';
import DropdownMultiple from 'components/form/dropdown-multiple/DropdownMultiple';
import { fetchUsersAction } from 'store/users/usersActions';

const useUserDropdown = () => {
  const dispatch = useDispatch();
  const { request, users } = useSelector(state => state.users);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    dispatch(fetchUsersAction());

    return () => {
      setUserList([]);
    };
  }, [dispatch]);

  useEffect(() => {
    setUserList(
      users
        .map(user => {
          return {
            label: `${user.firstname} ${user.lastname}`,
            value: user.id,
          };
        })
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }),
    );
  }, [users]);

  return useMemo(() => ({ request, userList }), [request, userList]);
};

const UserDropdown = ({ multiple, ...props }) => {
  const { userList, request } = useUserDropdown();
  return (
    <>
      {multiple ? (
        <DropdownMultiple
          {...props}
          values={userList}
          isLoading={request}
          sortOptions
        />
      ) : (
        <Dropdown {...props} values={userList} isLoading={request} />
      )}
    </>
  );
};

UserDropdown.propTypes = {
  multiple: PropTypes.bool,
};

UserDropdown.defaultProps = {
  multiple: false,
};

export default UserDropdown;
