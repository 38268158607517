import classNames from 'classnames';
import Container from 'components/container/Container';
import Heading from 'components/typography/heading/Heading';
import React, { FC } from 'react';
import './PageFilterContainer.scss';
import { PageFilterContainerProps } from './PageFilterContainer.types';

const PageFilterContainer: FC<PageFilterContainerProps> = ({
  title,
  children,
  noPadding = false,
}) => {
  return (
    <div
      className={classNames('page-filter-container', {
        'page-filter-container--no-padding': noPadding,
      })}
    >
      <Container>
        {title && <Heading level={1}>{title}</Heading>}
        {children}
      </Container>
    </div>
  );
};

export default PageFilterContainer;
