import React, { useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from 'components/form/label/Label';
import Icon from 'components/icon/Icon';
import IconButton from 'components/button/IconButton';

import './DropdownMultiple.scss';

const DropdownMultiple = ({
  label,
  selectedLabel,
  name,
  values,
  value,
  onChange,
  id,
  width,
  disabled,
  isLoading,
  isChanged,
  error,
  className,
  placeholder,
  purpose,
  listSelected,
  sortOptions,
  sortByValue,
  selectedItemsLabel,
}) => {
  const dropdownStyling = {
    container: provided => ({
      ...provided,
      width,
    }),
    indicatorSeparator: () => ({
      border: 0,
    }),
    dropdownIndicator: provided => ({
      ...provided,
      display: 'none',
    }),
    multiValueLabel: provided => ({
      ...provided,
      fontSize: '100%',
    }),
  };

  const getSelectedValues = useCallback((values, value) => {
    if (!values || !value) {
      return [];
    }
    const valueIdArr = value.map(v => v.id);

    return values.filter(v => valueIdArr.includes(v.value));
  }, []);

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions(getSelectedValues(values, value));
    // TODO: fix exhaustive dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, getSelectedValues]);

  const handleChange = selectedOptions => {
    if (sortOptions) {
      selectedOptions.sort((a, b) => {
        const sortProp = sortByValue ? 'value' : 'label';
        if (a[sortProp] < b[sortProp]) {
          return -1;
        }
        if (a[sortProp] > b[sortProp]) {
          return 1;
        }
        return 0;
      });
    }

    onChange(selectedOptions);
    setSelectedOptions(selectedOptions);
  };

  const removeOption = useCallback(
    removedValue => {
      onChange(value.filter(v => v !== removedValue));
      const newSelection = selectedOptions.filter(
        item => item.value !== removedValue.value,
      );
      onChange(newSelection);
      setSelectedOptions(newSelection);
    },
    [onChange, value, selectedOptions],
  );

  const multiSelectField = () => (
    <Label
      label={label}
      idFor={id}
      className={classNames('form__dropdown__label', className, {
        'form__dropdown__label--block': !width,
      })}
    >
      <div
        style={{ width }}
        className={classNames('form__dropdown__container', {
          'form__dropdown__container--disabled': disabled,
        })}
      >
        <Select
          placeholder={placeholder}
          styles={dropdownStyling}
          name={name}
          id={id}
          className={classNames('form__dropdown', 'form__dropdown--add', {
            'form__dropdown--changed': isChanged,
          })}
          classNamePrefix="form__dropdown"
          options={values}
          value={selectedOptions}
          isDisabled={disabled}
          isLoading={isLoading}
          onChange={e => handleChange(e)}
          menuPosition="fixed"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
          isMulti
        />

        <Icon
          name="chevron"
          className={classNames('', {
            'form__dropdown__icon--disabled': disabled,
            [`form__dropdown__icon--${purpose}`]: purpose,
          })}
        />
      </div>

      {error && (
        <p className="form__error" style={{ width }}>
          {error}
        </p>
      )}
    </Label>
  );

  return (
    <>
      {listSelected ? (
        <div>
          {multiSelectField()}
          {Array.isArray(selectedOptions) && selectedOptions.length > 0 && (
            <div className="form__dropdown__selected-values__container">
              <p className="form__dropdown__selected-values__label">
                {selectedLabel ||
                  `Selected ${selectedItemsLabel || label.toLowerCase()}:`}
              </p>
              <div className="form__dropdown__selected-values">
                {selectedOptions.map(selectedValue => {
                  return (
                    <IconButton
                      key={selectedValue.value}
                      className="form__dropdown__selected-value"
                      tag="button"
                      iconName="cross"
                      iconSize="small"
                      text={selectedValue.label}
                      size="small"
                      color="white"
                      iconColor="blue"
                      iconPosition="after-text"
                      scheme=""
                      handler={() => removeOption(selectedValue)}
                      disabled={disabled}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      ) : (
        multiSelectField()
      )}
    </>
  );
};

DropdownMultiple.propTypes = {
  label: PropTypes.string,
  selectedLabel: PropTypes.string,
  name: PropTypes.string,
  // eslint-disable-next-line
  values: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line
  value: PropTypes.any,
  onChange: PropTypes.func,
  width: PropTypes.number,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isChanged: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  purpose: PropTypes.oneOf(['add', 'navigate']),
  listSelected: PropTypes.bool,
  sortOptions: PropTypes.bool,
  sortByValue: PropTypes.bool,
  selectedItemsLabel: PropTypes.string,
};

DropdownMultiple.defaultProps = {
  label: '',
  selectedLabel: '',
  name: '',
  values: [],
  value: '',
  onChange: () => {},
  width: null,
  disabled: false,
  isLoading: false,
  error: false,
  isChanged: false,
  className: null,
  placeholder: 'Select...',
  purpose: 'add',
  listSelected: false,
  sortOptions: false,
  sortByValue: false,
  selectedItemsLabel: null,
};

export default DropdownMultiple;
