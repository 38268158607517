import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import Card from 'components/card/Card';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import { openDetailDevicesAction } from 'store/devices/devicesActions';
import Status from 'components/status/Status';
import Heading from 'components/typography/heading/Heading';
import Text from 'components/typography/text/Text';
import {
  TextTag,
  TextSize,
  TextColor,
} from 'components/typography/text/Text.enum';
import { DeviceCardProps } from './DevicesCard.types';
import './DevicesCard.scss';

const DevicesCard: FC<DeviceCardProps> = ({
  id,
  name,
  serialNumber,
  active,
}) => {
  const dispatch = useDispatch();

  const cardClick = () => {
    dispatch(openDetailDevicesAction(id, true));
  };

  return (
    <button
      type="button"
      tabIndex={0}
      onClick={cardClick}
      onKeyDown={e => e.key === '13' && cardClick}
      className="button__interaction"
    >
      <Card className="devices-card" zoomable>
        <Heading className="devices-card__title" level={3} noMargin>
          {name}
        </Heading>
        <Text tag={TextTag.P} color={TextColor.GREYBLACK} size={TextSize.SMALL}>
          {serialNumber}
        </Text>

        <div className="devices-card__state">
          <Status
            isActive={active !== 'offline'}
            activeText="Online"
            inactiveText="Offline"
          />
        </div>

        <Button
          tag="span"
          text={<Icon name="arrow-right" />}
          size="small"
          hasShadow={false}
          className="devices-card__button"
          scheme="card"
          shapeEdges="rounded"
        />
      </Card>
    </button>
  );
};

export default DevicesCard;
