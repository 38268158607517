import { InputSignals, InputSignal } from 'store/rootState';

import { InputSignalsAction } from './inputSignalsActions.types';
import { InputSignalsActionType } from './inputSignalsActions.enum';

export const inputSignalsInitialState = {
  request: false,
  selectedInputSignal: {
    request: false,
    inputSignal: null,
  },
  inputSignals: [],
};

const inputSignalsReducer = (
  state: InputSignals,
  action: InputSignalsAction,
) => {
  switch (action.type) {
    case InputSignalsActionType.INPUT_SIGNALS_REQUESTED:
      return { ...state, request: true, selectedInputSignal: null };
    case InputSignalsActionType.INPUT_SIGNALS_SUCCEEDED:
      return {
        ...state,
        inputSignals: action.payload,
        request: false,
        selectedInputSignal: {
          ...inputSignalsInitialState.selectedInputSignal,
        },
      };
    case InputSignalsActionType.INPUT_SIGNALS_FAILED:
      return {
        ...inputSignalsInitialState,
      };
    case InputSignalsActionType.INPUT_SIGNALS_UPDATED:
      return {
        ...state,
        inputSignals: state.inputSignals.map((inputSignal: InputSignal) => {
          if (inputSignal.id === action.payload.id) {
            return {
              ...inputSignal,
              ...action.payload,
            };
          }
          return inputSignal;
        }),
        selectedInputSignal: {
          request: false,
          inputSignal: action.payload,
        },
      };
    case InputSignalsActionType.INPUT_SIGNALS_DETAILS_REQUESTED:
      return {
        ...state,
        request: false,
        selectedInputSignal: {
          request: true,
          inputSignal: null,
        },
      };
    case InputSignalsActionType.INPUT_SIGNALS_DETAILS_SUCCEEDED:
      return {
        ...state,
        request: false,
        selectedInputSignal: {
          request: false,
          inputSignal: action.payload,
        },
      };
    case InputSignalsActionType.INPUT_SIGNALS_DETAILS_FAILED:
      return {
        ...state,
        request: false,
        selectedInputSignal: {
          request: false,
          inputSignal: null,
          error: action.payload,
        },
      };
    case InputSignalsActionType.INPUT_SIGNALS_ACTIVATED:
      return {
        ...state,
        inputSignals: state.inputSignals.map((inputSignal: InputSignal) => {
          if (inputSignal.id === action.payload.id) {
            return {
              ...inputSignal,
              ...action.payload,
            };
          }
          return inputSignal;
        }),
        selectedInputSignal: {
          inputSignal: action.payload,
        },
      };
    case InputSignalsActionType.INPUT_SIGNALS_DEACTIVATED:
      return {
        ...state,
        inputSignals: state.inputSignals.map((inputSignal: InputSignal) => {
          if (inputSignal.id === action.payload.id) {
            return {
              ...inputSignal,
              ...action.payload,
            };
          }
          return inputSignal;
        }),
        selectedInputSignal: {
          inputSignal: action.payload,
        },
      };
    default:
      return { ...state };
  }
};

export default inputSignalsReducer;
