export enum ButtonTag {
  A = 'a',
  BUTTON = 'button',
  SPAN = 'span',
}

export enum ButtonSize {
  SMALL = 'small',
  X_SMALL = 'extra-small',
  MEDIUM_SMALL = 'medium-small',
  MEDIUM = 'medium',
  LARGE = 'large',
  INLINE = 'inline',
}

export enum ButtonColor {
  RED = 'red',
  GREEN = 'green',
  BLUE = 'blue',
  WHITE = 'white',
}

export enum ButtonScheme {
  DEFAULT = 'default',
  CARD = 'card',
  LINK = 'link',
  DELETE_LINK = 'delete-link',
}

export enum ButtonShapeEdges {
  ROUNDED = 'rounded',
  SQUARE = 'square',
}
