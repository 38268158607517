import React, { useCallback } from 'react';

import OverviewList from 'components/overviewList/OverviewList';
import Status from 'components/status/Status';
import { OverviewListItemTag } from 'components/overviewList/OverviewListItem.enum';
import DeckEmpty from 'components/deck/DeckEmpty';

import { Trigger, TriggerStatus } from 'components/popup/inputs/inputSignals/InputSignalEdit.types';

import { InputSignalAttachedTriggersOverviewProps } from './InputSignalAttachedTriggersOverview.types';
import { OverviewListItem } from 'components/overviewList/OverviewListItem.types';
import { OverviewListHeader } from 'components/overviewList/OverviewList.types';
import { useDispatch } from 'react-redux';
import { editTriggerAction } from 'store/triggers/triggersActions';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRight from 'constants/UserRight.enum';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'store/rootState';
import { AnyAction } from 'redux';

const InputSignalAttachedTriggersOverview: React.FC<InputSignalAttachedTriggersOverviewProps> = ({
  isLoading,
  triggers = [],
  pool_ids = [],
}) => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch()
  const hasTriggerViewRights = dispatch(hasUserRights(UserRight.TRIGGERS_VIEW));
  const overviewHeaders: OverviewListHeader[] = [
    {
      key: 'Status',
      content: '',
      width: '25px',
    },
    {
      key: 'Name',
      content: 'Name',
    },
  ];

  const handleTriggerClick = useCallback((trigger: Trigger, poolId: number) => {
    if (hasTriggerViewRights) {
      dispatch(editTriggerAction(trigger.id, poolId))
    }
  }, [dispatch, hasTriggerViewRights])

  const overviewData: OverviewListItem[] = triggers.map((trigger, index) => ({
    id: `${trigger.id}`,
    key: `${trigger.id}`,
    items: [
      {
        key: '1',
        width: '25px',
        content: (
          <Status
            isActive={trigger.status === TriggerStatus.ONLINE}
            hasText={false}
            title={
              trigger.status === TriggerStatus.ONLINE
                ? TriggerStatus.ONLINE
                : TriggerStatus.OFFLINE
            }
          />
        ),
      },
      {
        key: '2',
        content: trigger.name,
      },
    ],
    onClick: () => handleTriggerClick(trigger, pool_ids[index]),
    tag: OverviewListItemTag.BUTTON,
  }));

  return (
    <fieldset>
      <div>
        <legend>Used in</legend>
        <p>This input signal is used in:</p>
      </div>

      {isLoading || triggers?.length > 0 ? (
        <OverviewList
          headers={overviewHeaders}
          hideHeaders
          content={overviewData}
          loading={isLoading}
          cardProps={{
            highlightOnHover: hasTriggerViewRights,
            interactive: hasTriggerViewRights,
          }}
        />
      ) : (
        <DeckEmpty title="No triggers found" height={150} />
      )}
    </fieldset>
  );
};

export default InputSignalAttachedTriggersOverview;
