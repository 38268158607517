import { reverse } from 'named-urls';
import moment from 'moment-timezone';

import { Paths, Api } from 'constants/Routes.enum';
import UserRights from 'constants/UserRight.enum';
import PopupTypes from 'constants/PopupTypes.enum';
import fetchData from 'store/fetchData';
import { infoBarHide, setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import { popupActionClear, popupActionSet } from 'store/popup/popupActions';
import { hasUserRights } from 'store/auth/hasUserRights';

export const PoolActionTypes = {
  POOL_REQUESTED: '@@pool/requested',
  POOL_SUCCEEDED: '@@pool/success',
  POOL_FAILED: '@@pool/error',
  POOL_ADDED: '@@pool/added',
  POOL_UPDATED: '@@pool/updated',
  POOL_DELETED: '@@pool/deleted',
  POOL_CLEARED: '@@pool/cleared',
  POOL_NOT_FOUND: '@@pool/not-found',
  POOL_DEFAULT: '@@pool/default',
};

export const poolRequested = () => ({
  type: PoolActionTypes.POOL_REQUESTED,
});

export const poolSucceeded = pool => ({
  type: PoolActionTypes.POOL_SUCCEEDED,
  payload: pool,
});

export const poolFailed = () => ({
  type: PoolActionTypes.POOL_FAILED,
});

export const poolAdded = pool => ({
  type: PoolActionTypes.POOL_ADDED,
  payload: pool,
});

export const poolUpdated = pool => ({
  type: PoolActionTypes.POOL_UPDATED,
  payload: pool,
});

export const poolDeleted = id => ({
  type: PoolActionTypes.POOL_DELETED,
  payload: id,
});

export const poolCleared = () => ({
  type: PoolActionTypes.POOL_CLEARED,
});

export const poolNotFound = () => ({
  type: PoolActionTypes.POOL_NOT_FOUND,
});

export const poolDefaultArrangement = arrangement => ({
  type: PoolActionTypes.POOL_DEFAULT,
  payload: arrangement,
});

export const deletePoolAction = (id, history) => async dispatch => {
  const promise = dispatch(
    fetchData(reverse(Api.POOLS_DELETE, { id }), { method: 'DELETE' }),
  );
  promise
    .then(result => {
      if (result) {
        dispatch(poolDeleted(id));
        dispatch(infoBarHide());
        dispatch(popupActionClear());

        const location = {
          pathname: Paths.POOLS,
          state: {
            groupDeleted: true,
          },
        };
        history.push(location);
      }
    })
    .catch(error => {
      console.error(error);
      dispatch(
        setInfoBar({
          message: error,
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const editPoolAction = (values, setSubmitting) => dispatch => {
  const groupData = new FormData();
  groupData.append('pool[name]', values.name);
  groupData.append('pool[id]', values.id);

  const { id } = values;

  const promise = dispatch(
    fetchData(
      reverse(Api.POOLS_PATCH, { id }),
      {
        method: 'PATCH',
        body: groupData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { pool, message } = result;

      dispatch(popupActionClear());
      setSubmitting(false);
      dispatch(poolUpdated(pool));
      dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
    })
    .catch(err => {
      console.error(err);
      setSubmitting(false);
      dispatch(
        setInfoBar({
          message: 'Failed to edit group',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const detailsPoolAction = id => async dispatch => {
  const hasPoolEditRights = dispatch(hasUserRights(UserRights.POOLS_EDIT));
  const promise = dispatch(fetchData(reverse(Api.POOLS_DETAIL, { id })));
  promise
    .then(json => json.json())
    .then(result => {
      const { pool } = result;

      dispatch(
        popupActionSet(PopupTypes.POOL_DETAIL, {
          name: pool.name,
          id: pool.id,
        }),
      );

      if (!hasPoolEditRights) {
        dispatch(
          setInfoBar({
            message: 'You only have the rights to view this pool',
            state: InfoBarState.WARNING,
          }),
        );
      }
    })
    .catch(error => {
      console.error(error);
      dispatch(
        setInfoBar({
          message: error,
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const addPoolAction = (values, history, setSubmitting) => dispatch => {
  const groupData = new FormData();
  groupData.append('pool[name]', values.name);
  groupData.append('pool[location_id]', values.location);

  const promise = dispatch(
    fetchData(
      Api.POOLS_POST,
      {
        method: 'POST',
        body: groupData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(({ pool }) => {
      dispatch(popupActionClear());
      setSubmitting(false);
      const location = {
        pathname: reverse(Paths.POOLS_DEVICES, { id: pool.id }),
        state: {
          groupAdded: true,
        },
      };
      history.push(location);
      dispatch(poolAdded(pool));
    })
    .catch(err => {
      console.error(err);
      setSubmitting(false);
      dispatch(
        setInfoBar({
          message: 'Failed to add new group',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const fetchPool = id => async dispatch => {
  dispatch(poolRequested());

  const promise = dispatch(fetchData(reverse(Api.POOLS_DETAIL, { id })));
  if (promise) {
    promise
      .then(json => json.json())
      .then(result => {
        const { error, message } = result;

        if (!error && !message) {
          if (result?.location?.timezone_info) {
            moment.tz.setDefault(result.location.timezone_info);
          }

          dispatch(poolSucceeded(result));
        } else {
          dispatch(poolNotFound());
        }
      })
      .catch(() => {
        dispatch(poolFailed());
        dispatch(
          setInfoBar({
            message: 'Could not fetch pool',
            state: InfoBarState.ERROR,
            timeout: 5000,
          }),
        );
      });
  }
};

export const setPoolDefaultArrangement = (poolId, arrangement) => dispatch => {
  const poolData = new FormData();
  poolData.append('pool[arrangement_id]', arrangement.id);

  const promise = dispatch(
    fetchData(
      reverse(Api.POOLS_PATCH, { id: poolId }),
      {
        method: 'PATCH',
        body: poolData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(() => {
      dispatch(poolDefaultArrangement(arrangement));
      dispatch(popupActionClear());
      dispatch(
        setInfoBar({
          message: 'Show has been updated',
          timeout: 5000,
          state: 'check',
        }),
      );
    })
    .catch(err => {
      console.error(err);
    });
};

export const setPoolActivityLogDetailsAction = (
  version_id,
  item_id,
  item_name,
  item_type,
  description,
) => async dispatch => {
  const promise = dispatch(fetchData(reverse(Api.LOGGING, { id: version_id })));
  promise
    .then(json => json.json())
    .then(result => {
      const {
        changes,
        pending_approval,
        active,
        deleted,
        deleted_log,
        created_at,
      } = result;
      dispatch(
        popupActionSet(PopupTypes.LOG_DETAILS, {
          item_id,
          item_name,
          item_type,
          pending_approval,
          created_at,
          active,
          changes,
          deleted,
          deleted_log,
          description,
        }),
      );
    })
    .catch(err => {
      dispatch(
        setInfoBar({
          message: 'Could not return log details',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
      console.error(err);
    });
};
