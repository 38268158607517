import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import './Button.scss';
import Skeleton from 'components/skeleton/Skeleton';
import { SkeletonType } from 'components/skeleton/Skeleton.enum';
import { ButtonProps } from './Button.types';
import { ButtonScheme, ButtonShapeEdges } from './Button.enum';

const Button: React.FC<ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>> = ({
  tag = 'a',
  link,
  text,
  hoverText,
  hoverTextInverse,
  size,
  hasShadow = true,
  scheme = ButtonScheme.DEFAULT,
  className,
  handler,
  block,
  color,
  disabled,
  children,
  dataCy,
  loading = false,
  shapeEdges = ButtonShapeEdges.SQUARE,
  ...props
}) => {
  const loadingState = () => {
    switch (true) {
      case scheme === 'link' || scheme === 'delete-link':
        return (
          <>
            {children}
            <span className="button__text">
              <Skeleton type={SkeletonType.TEXT} styling={{ width: 150 }} />
            </span>
          </>
        );
      case !text:
        return (
          <div className={classNames(className, { [`button--${size}`]: size })}>
            <Skeleton type={SkeletonType.BUTTON_SQUARE} />
          </div>
        );
      default:
        return (
          <div className={classNames(className, { [`button--${size}`]: size })}>
            <Skeleton type={SkeletonType.TEXT} />
          </div>
        );
    }
  };

  return (
    <>
      {loading
        ? loadingState()
        : React.createElement(
            tag,
            {
              className: classNames('button', className, {
                [`button--size-${size}`]: size,
                [`button--shape-edges-${shapeEdges}`]: shapeEdges,
                [`button--scheme-${scheme}`]: scheme,
                'button--no-shadow': !hasShadow,
                'button--text-inverse': hoverTextInverse,
                'button--block': block,
                [`button--color-${color}`]: color,
                'button--scheme-link--disabled': scheme === 'link' && disabled,
              }),
              disabled,
              href: link || null,
              onClick: handler || null,
              onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
                (e.key === '13' && handler) || null,
              type: !!handler || !link ? 'button' : null,
              'data-cy': dataCy,
              ...props,
            },
            <>
              {children}
              {text && <span className="button__text">{text}</span>}
              {hoverText && (
                <>
                  <span className="button__hover-spacing" />
                  <span className="button__hover-text">{hoverText}</span>
                </>
              )}
            </>,
          )}
    </>
  );
};

export default Button;
