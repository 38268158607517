import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { Api } from 'constants/Routes.enum';
import UserRights from 'constants/UserRight.enum';
import MediaItemTypes from 'constants/MediaItemTypes.enum';

import fetchData from 'store/fetchData';
import { popupActionClear } from 'store/popup/popupActions';
import { addMediaAction } from 'store/media/mediaActions';
import { infoBarHide, setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import { hasUserRights } from 'store/auth/hasUserRights';

import Input from 'components/form/input/Input';
import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import Upload from 'components/form/upload/Upload';

const MediaUpload = ({ title }) => {
  const dispatch = useDispatch();
  const authToken = useSelector(state => state.auth.auth_token);
  const { folder_info } = useSelector(state => state.media);

  const infoBar = useSelector(state => state.info.bar);
  const hasMediaEditRights = dispatch(hasUserRights(UserRights.MEDIA_EDIT));
  const [isValidFile, setIsValidFile] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const initialForm = {
    name: title || '',
    file: null,
  };

  const validationScheme = Yup.object().shape({
    name: Yup.string(),
    file: Yup.mixed().required('A file is required'),
  });

  const onSubmit = (values, { resetForm }) => {
    setSubmitting(true);

    const mediaData = new FormData();

    mediaData.append('media[type]', MediaItemTypes.UPLOAD_MEDIA_ITEM);
    mediaData.append(
      'media[name]',
      values.name || (values.file && values.file.name),
    );

    // Media should no longer belong to a pool
    // mediaData.append('media[pool_ids]', [pool.id]);

    if (values.file) {
      mediaData.append('media[media_file]', values.file);
    }

    if (folder_info) {
      mediaData.append('media[media_folder_id]', folder_info.id);
    }

    const promise = dispatch(
      fetchData(Api.MEDIA_POST, {
        method: 'POST',
        headers: {
          Authorization: authToken,
        },
        body: mediaData,
      }),
    );

    promise
      .then(json => json.json())
      .then(result => {
        const { media, message } = result;

        setSubmitting(false);
        if (media) {
          dispatch(addMediaAction(result));
          return;
        }
        if (message) {
          if (values.file?.type === 'text/html') {
            setIsValidFile(false);
            resetForm({ values: { ...values, file: null } });

            // TODO: When back-end returns correct message return message from back-end instead of hardcoded via the front-end
            dispatch(
              setInfoBar({
                message:
                  'Media item contains invalid content. Correct the contents and try uploading again.',
                state: InfoBarState.ERROR,
                timeout: 5000,
              }),
            );
          } else {
            dispatch(
              setInfoBar({ message, state: InfoBarState.ERROR, timeout: 5000 }),
            );
          }

          setTimeout(() => {
            dispatch(infoBarHide());
          }, 5000);
        }
      })
      .catch(() => {
        setSubmitting(false);
        dispatch(
          setInfoBar({
            message: 'Could not add media item',
            state: InfoBarState.ERROR,
            timeout: 5000,
          }),
        );
      });
  };

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationScheme}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        initialTouched,
        errors,
        touched,
      }) => (
        <form onSubmit={handleSubmit} className="form">
          <fieldset>
            <legend>Upload file</legend>
            <FormBlock flatten>
              <Upload
                id="file"
                name="file"
                file={values.file}
                onChange={setFieldValue}
                validFile={isValidFile}
                disabled={!hasMediaEditRights}
              />
            </FormBlock>
            {touched.file && errors.file && (
              <FormBlock error={touched.file && errors.file} />
            )}
          </fieldset>

          <fieldset>
            <FormBlock flatten>
              <Input
                type="text"
                name="name"
                id="name"
                label="Name your file"
                placeholder="Write the file name here"
                scheme="white"
                value={values.name}
                error={touched.name && errors.name}
                onChange={handleChange}
                disabled={!hasMediaEditRights}
              />
            </FormBlock>
          </fieldset>

          <FormBlock hasInlineChildren isEnd>
            <Button
              tag="button"
              type="button"
              size="medium"
              text="Cancel"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(popupActionClear());
                if (infoBar.show) {
                  dispatch(infoBarHide());
                }
              }}
              disabled={isSubmitting}
            />
            <Button
              tag="button"
              type="submit"
              size="medium"
              text={initialTouched ? 'Save and close' : 'Close'}
              disabled={!hasMediaEditRights || isSubmitting}
            />
          </FormBlock>
        </form>
      )}
    </Formik>
  );
};

MediaUpload.propTypes = {
  title: PropTypes.string,
};

MediaUpload.defaultProps = {
  title: '',
};

export default MediaUpload;
