import React, { FC } from 'react';
import './ButtonGroup.scss';
import { ButtonGroupProps } from './ButtonGroup.types';

const ButtonGroup: FC<ButtonGroupProps> = ({ children, ...rest }) => {
  return (
    <div className="button-group" {...rest}>
      {children}
    </div>
  );
};

export default ButtonGroup;
