import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import equal from 'fast-deep-equal';
import { reverse } from 'named-urls';
import PropTypes from 'prop-types';

import UserRight from 'constants/UserRight.enum';
import RegexOptions from 'constants/RegexOptions';
import { Paths, Api } from 'constants/Routes.enum';
import MediaItemTypes from 'constants/MediaItemTypes.enum';

import {
  infoBarHide,
  setInfoBar,
  setInfoBarShow,
} from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import { popupActionClear } from 'store/popup/popupActions';
import { hasUserRights } from 'store/auth/hasUserRights';
import {
  addMediaAction,
  deleteMediaAction,
  fetchMediaValueTypesAction,
  mediaDeleted,
} from 'store/media/mediaActions';
import fetchData from 'store/fetchData';

import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import Input from 'components/form/input/Input';
import Dropdown from 'components/form/dropdown/Dropdown';
import FormMessage from 'components/form/formMessage/FormMessage';
import ColorPickerInput from 'components/form/colorPicker/ColorPickerInput';
import Icon from 'components/icon/Icon';
import MediaPlacementFieldset from 'components/popup/media-preview/MediaPlacementFieldset';
import MediaShowsOverviewFieldset from 'components/popup/media-preview/MediaShowsOverviewFieldset';
import MediaPreviewDetails from 'components/popup/media-preview/MediaPreviewDetails';
import {
  PopupStickyHeader,
  PopupStickyMain,
  PopupStickyFooter,
  PopupStickyForm,
} from 'components/popup/Popup';

const MediaJackpotValue = ({
  id,
  name,
  jackpot,
  folder_id,
  folder_breadcrumbs,
  shows,
  created_at,
  file_size,
  filename,
}) => {
  const dispatch = useDispatch();
  const infoBar = useSelector(state => state.info.bar);
  const { folder_info } = useSelector(state => state.media);
  const jackpotValueTypes = useSelector(
    state => state.media?.value_types?.jackpot,
  );
  const authToken = useSelector(state => state.auth.auth_token);
  const hasMediaEditRights = dispatch(hasUserRights(UserRight.MEDIA_EDIT));
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();

  const [initialForm, setInitialForm] = useState({
    name: name || '',
    topic: jackpot?.topic_id || null,
    keyword: jackpot?.keyword || '',
    currency: jackpot?.currency || jackpotValueTypes?.currencies[0].value,
    separatorAndDecimals:
      jackpot?.separator_and_decimals ||
      jackpotValueTypes?.separatorAndDecimals[0].value,
    font: jackpot?.font || jackpotValueTypes?.fonts[0].value,
    formatting:
      jackpot?.formatting || jackpotValueTypes?.formattingOptions[0].value,
    color: jackpot?.color || '#000000',
    mediaFolderId: folder_id,
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    topic: Yup.number()
      .nullable()
      .required('Jackpot value is required'),
    keyword: Yup.string().required('Jackpot level is required'),
    color: Yup.string()
      .matches(
        RegexOptions.HEX_COLOR,
        'Color must be a valid hexadecimal color',
      )
      .required('Color is a required field'),
  });

  const onSubmit = values => {
    setSubmitting(true);

    if (equal(values, initialForm)) {
      dispatch(popupActionClear());
      setSubmitting(false);
      return;
    }

    const mediaData = new FormData();

    mediaData.append('media[type]', MediaItemTypes.JACKPOT_MEDIA_ITEM);
    mediaData.append('media[name]', values.name);
    mediaData.append('media[topic]', values.topic);
    mediaData.append('media[keyword]', values.keyword);
    mediaData.append('media[currency]', values.currency);
    mediaData.append(
      'media[separator_and_decimals]',
      values.separatorAndDecimals,
    );
    mediaData.append('media[font]', values.font);
    mediaData.append('media[formatting]', values.formatting);
    mediaData.append('media[color]', values.color);

    if (id && values.mediaFolderId >= 0) {
      mediaData.append(
        'media[media_folder_id]',
        values.mediaFolderId === 0 ? '' : values.mediaFolderId,
      );
    } else if (folder_info) {
      mediaData.append('media[media_folder_id]', folder_info.id);
    }

    const promise = dispatch(
      fetchData(id ? reverse(Api.MEDIA_DETAIL, { id }) : Api.MEDIA_POST, {
        method: id ? 'PATCH' : 'POST',
        headers: {
          Authorization: authToken,
        },
        body: mediaData,
      }),
    );

    promise
      .then(json => json.json())
      .then(result => {
        setSubmitting(false);
        dispatch(mediaDeleted(id));
        if (result.media) {
          if (
            (!values.mediaFolderId && values.mediaFolderId !== 0) ||
            values.mediaFolderId === folder_id
          ) {
            dispatch(addMediaAction(result));
          }
        }

        if (values.mediaFolderId >= 0 && values.mediaFolderId !== folder_id) {
          dispatch(
            setInfoBar({
              message: 'The media item has been repositioned',
              action: [
                {
                  handle: () => {
                    dispatch(setInfoBarShow({ show: false }));
                    history.push({
                      pathname:
                        values.mediaFolderId === 0
                          ? Paths.MEDIA
                          : reverse(Paths.MEDIA_FOLDER, {
                              folder_id: values.mediaFolderId,
                            }),
                    });
                  },
                  text: 'Go to folder',
                },
              ],
              timeOut: 5000,
              state: 'check',
            }),
          );
        } else {
          dispatch(
            setInfoBar({
              message: result.message,
              timeout: 5000,
              state: 'check',
            }),
          );
        }
      })
      .catch(() => {
        setSubmitting(false);
        if (id) {
          dispatch(
            setInfoBar({
              message: 'Could not update media item',
              state: InfoBarState.ERROR,
              timeout: 5000,
            }),
          );
        } else {
          dispatch(
            setInfoBar({
              message: 'Could not add media item',
              state: InfoBarState.ERROR,
              timeout: 5000,
            }),
          );
        }
      });

    dispatch(popupActionClear());
  };

  useEffect(() => {
    dispatch(fetchMediaValueTypesAction('jackpot'));
  }, [dispatch]);

  useEffect(() => {
    setInitialForm({
      name: name || '',
      topic: jackpot?.topic_id || null,
      keyword: jackpot?.keyword || '',
      currency: jackpot?.currency || jackpotValueTypes?.currencies[0].value,
      separatorAndDecimals:
        jackpot?.separator_and_decimals ||
        jackpotValueTypes?.separatorAndDecimals[0].value,
      font: jackpot?.font || jackpotValueTypes?.fonts[0].value,
      formatting:
        jackpot?.formatting || jackpotValueTypes?.formattingOptions[0].value,
      color: jackpot?.color || '#000000',
      mediaFolderId: folder_id,
    });
  }, [jackpotValueTypes, jackpot, name, folder_id]);

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        handleChange,
        values,
        errors,
        setFieldValue,
        handleSubmit,
        touched,
      }) => (
        <PopupStickyForm onSubmit={handleSubmit}>
          <PopupStickyHeader>
            <fieldset className="fieldset--flatten">
              <FormBlock flatten>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="New jackpot value"
                  value={values.name}
                  error={touched.name && errors.name}
                  onChange={handleChange}
                  fontSize="large"
                  suffix={<Icon name="edit" />}
                  disabled={!hasMediaEditRights}
                />
              </FormBlock>
            </fieldset>
          </PopupStickyHeader>

          <PopupStickyMain>
            <fieldset>
              <legend>Content</legend>
              <FormBlock flatten>
                <Dropdown
                  label="Jackpot value"
                  name="topic"
                  id="topic"
                  value={values.topic}
                  onChange={setFieldValue}
                  placeholder="Select a jackpot value"
                  hasEmptyOption={false}
                  values={jackpotValueTypes?.topics}
                  isLoading={!jackpotValueTypes?.topics}
                  disabled={!hasMediaEditRights}
                />
                <Input
                  label="Jackpot level"
                  type="text"
                  name="keyword"
                  id="keyword"
                  scheme="white"
                  placeholder="New jackpot level"
                  value={values.keyword}
                  onChange={handleChange}
                  disabled={!hasMediaEditRights}
                />
              </FormBlock>
              <FormBlock flatten>
                <FormMessage
                  errorMessage={touched.topic && errors.topic}
                  belowField
                />
                <FormMessage
                  errorMessage={touched.keyword && errors.keyword}
                  belowField
                />
              </FormBlock>
            </fieldset>

            <fieldset>
              <legend>Styling and format</legend>
              <FormBlock>
                <Dropdown
                  label="Currency"
                  name="currency"
                  id="currency"
                  value={values.currency}
                  onChange={setFieldValue}
                  error={touched.currency && errors.currency}
                  placeholder="Select a currency"
                  hasEmptyOption={false}
                  values={jackpotValueTypes?.currencies}
                  loading={!jackpotValueTypes?.currencies}
                  disabled={!hasMediaEditRights}
                />
                <Dropdown
                  label="Separators and decimals"
                  name="separatorAndDecimals"
                  id="separatorAndDecimals"
                  value={values.separatorAndDecimals}
                  onChange={setFieldValue}
                  error={
                    touched.separatorAndDecimals && errors.separatorAndDecimals
                  }
                  placeholder="Select a separator type"
                  hasEmptyOption={false}
                  values={jackpotValueTypes?.separatorAndDecimals}
                  loading={!jackpotValueTypes?.separatorAndDecimals}
                  disabled={!hasMediaEditRights}
                />
              </FormBlock>

              <FormBlock>
                <Dropdown
                  label="Font"
                  name="font"
                  id="font"
                  value={values.font}
                  onChange={setFieldValue}
                  error={touched.font && errors.font}
                  placeholder="Select a font"
                  hasEmptyOption={false}
                  values={jackpotValueTypes?.fonts}
                  loading={!jackpotValueTypes?.fonts}
                  disabled={!hasMediaEditRights}
                  sortOptions
                />
                <Dropdown
                  label="Formatting"
                  name="formatting"
                  id="formatting"
                  value={values.formatting}
                  onChange={setFieldValue}
                  error={touched.formatting && errors.formatting}
                  placeholder="Select a formatting type"
                  hasEmptyOption={false}
                  values={jackpotValueTypes?.formattingOptions}
                  loading={!jackpotValueTypes?.formattingOptions}
                  disabled={!hasMediaEditRights}
                />
              </FormBlock>

              <FormBlock halfWidth flatten>
                <ColorPickerInput
                  label="Color"
                  name="color"
                  id="color"
                  placeholder="Color"
                  value={values.color}
                  onChange={setFieldValue}
                  error={errors.color}
                  disabled={!hasMediaEditRights}
                />
              </FormBlock>
            </fieldset>

            {id && (
              <>
                <MediaPreviewDetails
                  id={id}
                  created_at={created_at}
                  file_size={file_size}
                  filename={filename}
                  breadcrumb={folder_breadcrumbs}
                />
                <MediaPlacementFieldset
                  name="mediaFolderId"
                  setFieldValue={setFieldValue}
                  folder_id={folder_id}
                  disabled={!hasMediaEditRights}
                />
              </>
            )}

            {shows && shows.length > 0 && (
              <MediaShowsOverviewFieldset shows={shows} />
            )}
          </PopupStickyMain>

          <PopupStickyFooter>
            <FormBlock hasInlineChildren flatten>
              {(id && hasMediaEditRights && (
                <Button
                  tag="button"
                  type="button"
                  size="medium"
                  text="Delete"
                  scheme="link"
                  hasShadow={false}
                  handler={() => {
                    dispatch(
                      setInfoBar({
                        message:
                          'Are you sure you want to delete this media item?',
                        action: [
                          {
                            text: 'Yes, Delete',
                            type: 'button',
                            color: 'blue',
                            handle: () =>
                              dispatch(deleteMediaAction({ id, shows })),
                          },
                          {
                            text: 'Cancel',
                            type: 'link',
                            handle: () => dispatch(infoBarHide()),
                          },
                        ],
                        state: InfoBarState.ERROR,
                      }),
                    );
                  }}
                  disabled={isSubmitting}
                />
              )) || (
                <Button
                  tag="button"
                  type="submit"
                  size="medium"
                  text="Cancel"
                  scheme="link"
                  hasShadow={false}
                  handler={() => {
                    dispatch(popupActionClear());
                    if (infoBar.show) {
                      dispatch(infoBarHide());
                    }
                  }}
                  disabled={isSubmitting}
                />
              )}

              <Button
                tag="button"
                type="submit"
                size="medium"
                text="Save and close"
                disabled={!hasMediaEditRights || isSubmitting}
              />
            </FormBlock>
          </PopupStickyFooter>
        </PopupStickyForm>
      )}
    </Formik>
  );
};

MediaJackpotValue.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  jackpot: PropTypes.shape({
    topic_id: PropTypes.number,
    keyword: PropTypes.string,
    currency: PropTypes.string,
    separator_and_decimals: PropTypes.string,
    font: PropTypes.string,
    formatting: PropTypes.string,
    color: PropTypes.string,
  }),
  folder_id: PropTypes.number,
  folder_breadcrumbs: PropTypes.string,
  shows: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      id: PropTypes.number,
      name: PropTypes.string,
      pending_approval: PropTypes.bool,
    }),
  ),
};

MediaJackpotValue.defaultProps = {
  id: null,
  name: '',
  jackpot: null,
  folder_id: null,
  shows: null,
  folder_breadcrumbs: '',
};

export default MediaJackpotValue;
