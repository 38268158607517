import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import { reverse } from 'named-urls';

import {
  PopupStickyForm,
  PopupStickyHeader,
  PopupStickyMain,
  PopupStickyFooter,
} from 'components/popup/Popup';
import Input from 'components/form/input/Input';
import FormBlock from 'components/form/block/FormBlock';
import { popupActionClear } from 'store/popup/popupActions';
import {
  infoBarHide,
  setInfoBar,
  setInfoBarShow,
} from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import Button from 'components/button/Button';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRight from 'constants/UserRight.enum';
import Icon from 'components/icon/Icon';
import Text from 'components/typography/text/Text';
import UploadMultiple from 'components/form/upload/UploadMultiple';
import MediaItemTypes from 'constants/MediaItemTypes.enum';
import fetchData from 'store/fetchData';
import { Paths, Api } from 'constants/Routes.enum';
import {
  mediaAdded,
  mediaDeleted,
  deleteMediaAction,
} from 'store/media/mediaActions';
import MediaPlacementFieldset from 'components/popup/media-preview/MediaPlacementFieldset';
import MediaShowsOverviewFieldset from 'components/popup/media-preview/MediaShowsOverviewFieldset';

const MediaWebpackage = ({ id, name, media_files, folder_id, shows }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const infoBar = useSelector(state => state.info.bar);
  const { folder_info } = useSelector(state => state.media);

  const hasMediaEditRights = dispatch(hasUserRights(UserRight.MEDIA_EDIT));

  const initialValues = {
    name,
    files: media_files,
    mediaFolderId: folder_id,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    files: Yup.array()
      .min(
        1,
        'In order for webpackages to work, the package must contain an index.html. Please add the index.html to continue',
      )
      .test(
        'includes-index',
        'In order for webpackages to work, the package must contain an index.html. Please add the index.html to continue.',
        files =>
          files.some(file => {
            const fileName = (file.name || file.file_name).toLowerCase();
            return fileName === 'index.html' || fileName === 'index.htm';
          }),
      ),
  });

  const onSubmit = values => {
    if (equal(values, initialValues)) {
      dispatch(popupActionClear());
      return;
    }

    setSubmitting(true);

    const mediaData = new FormData();
    mediaData.append('media[type]', MediaItemTypes.WEBPACKAGE_ITEM);
    mediaData.append('media[name]', values.name);

    for (let i = 0; i < values.files.length; i += 1) {
      const file = values.files[i];
      mediaData.append(
        'media[media_files][]',
        file.id ? JSON.stringify(file) : file,
      );
    }

    // TODO: Rewrite setting the media folder id as a more generic function
    // (duplicate code)
    if (id && values.mediaFolderId >= 0) {
      mediaData.append(
        'media[media_folder_id]',
        values.mediaFolderId === 0 ? '' : values.mediaFolderId,
      );
    } else if (folder_info) {
      mediaData.append('media[media_folder_id]', folder_info.id);
    }

    // TODO: Rewrite the media item promise as a more generic function
    // (duplicate code)
    const promise = dispatch(
      fetchData(
        id ? reverse(Api.MEDIA_DETAIL, { id }) : Api.MEDIA_POST,
        {
          method: id ? 'PATCH' : 'POST',
          body: mediaData,
        },
        false,
      ),
    );

    promise
      .then(json => json.json())
      .then(result => {
        dispatch(mediaDeleted(id));

        if (result.media) {
          if (
            (!values.mediaFolderId && values.mediaFolderId !== 0) ||
            values.mediaFolderId === folder_id
          ) {
            dispatch(mediaAdded(result.media));
          }
          dispatch(popupActionClear());
        } else if (result.message) {
          dispatch(
            setInfoBar({
              message:
                'Media item contains invalid content. Correct the contents and try uploading again.',
              state: InfoBarState.ERROR,
              timeout: 5000,
            }),
          );
        }

        // TODO: move setting the infobar to a more generic location
        // (duplicate code)
        if (values.mediaFolderId >= 0 && values.mediaFolderId !== folder_id) {
          dispatch(
            setInfoBar({
              message: 'The media item has been repositioned',
              action: [
                {
                  handle: () => {
                    dispatch(setInfoBarShow(false));
                    history.push({
                      pathname:
                        values.mediaFolderId === 0
                          ? Paths.MEDIA
                          : reverse(Paths.MEDIA_FOLDER, {
                              folder_id: values.mediaFolderId,
                            }),
                    });
                  },
                  text: 'Go to folder',
                },
              ],
              timeout: 5000,
              state: 'check',
            }),
          );
        }
      })
      .catch(() => {
        if (id) {
          dispatch(
            setInfoBar({
              message: 'Could not update media item',
              state: InfoBarState.ERROR,
              timeout: 5000,
            }),
          );
        } else {
          dispatch(
            setInfoBar({
              message: 'Could not add media item',
              state: InfoBarState.ERROR,
              timeout: 5000,
            }),
          );
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  return (
    <PopupStickyForm onSubmit={handleSubmit}>
      <PopupStickyHeader>
        <fieldset className="fieldset--flatten">
          <FormBlock flatten>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Enter a name for your webpackage"
              value={values.name}
              error={touched.name && errors.name}
              onChange={handleChange}
              fontSize="large"
              suffix={<Icon name="edit" />}
              disabled={!hasMediaEditRights}
            />
          </FormBlock>
        </fieldset>
      </PopupStickyHeader>
      <PopupStickyMain>
        <fieldset>
          <FormBlock>
            <div>
              <legend>Webpackage content</legend>
              <Text tag="p" flatten>
                Webpackages are a group of code based files. In order to display
                webpackages in a show, the webpackage must contain a
                ”index.html” file.
              </Text>
            </div>
          </FormBlock>

          <FormBlock flatten>
            <UploadMultiple
              id="files"
              name="files"
              onChange={setFieldValue}
              error={
                (touched.files && errors.files) ||
                (values.files.length >= 1 && errors.files)
              }
              oldFiles={values.files}
            />
          </FormBlock>
        </fieldset>

        {hasMediaEditRights && id && (
          <MediaPlacementFieldset
            name="mediaFolderId"
            setFieldValue={setFieldValue}
            folder_id={folder_id}
          />
        )}

        {shows && shows.length > 0 && (
          <MediaShowsOverviewFieldset shows={shows} />
        )}
      </PopupStickyMain>
      <PopupStickyFooter>
        <FormBlock hasInlineChildren flatten>
          {(hasMediaEditRights && id && (
            <Button
              tag="button"
              type="button"
              size="medium"
              text="Delete"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(
                  setInfoBar({
                    message: 'Are you sure you want to delete this media item?',
                    action: [
                      {
                        text: 'Yes, Delete',
                        handle: () =>
                          dispatch(deleteMediaAction({ id, shows })),
                      },
                    ],
                    state: InfoBarState.WARNING,
                  }),
                );
              }}
              disabled={isSubmitting}
            />
          )) || (
            <Button
              tag="button"
              size="medium"
              text="Cancel"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(popupActionClear());
                if (infoBar.show) {
                  dispatch(infoBarHide());
                }
              }}
              disabled={isSubmitting}
            />
          )}

          <Button
            tag="button"
            type="submit"
            size="medium"
            text="Save and close"
            disabled={!hasMediaEditRights || isSubmitting}
          />
        </FormBlock>
      </PopupStickyFooter>
    </PopupStickyForm>
  );
};

MediaWebpackage.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  media_files: PropTypes.array,
};

MediaWebpackage.defaultProps = {
  id: null,
  name: '',
  media_files: [],
};

export default MediaWebpackage;
