export enum TextTag {
  P = 'p',
  DIV = 'div',
  SPAN = 'span',
}

export enum TextSize {
  SMALL = 'small',
  NORMAL = 'normal',
  XLARGE = 'x-large',
}

export enum TextColor {
  GREYBLACK = 'grey-black',
  GREYDARK = 'grey-dark',
}
