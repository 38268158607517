import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import { popupActionClear } from 'store/popup/popupActions';
import { createDeviceAction } from 'store/devices/devicesActions';
import Input from 'components/form/input/Input';
import RegexOptions from 'constants/RegexOptions';
import { RootState } from 'store/rootState';

const DeviceAdd = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);

  const customerOverride = useSelector(state => state.superAdmin.customerOverride);

  const initialValues = {
    serial: '',
    name: '',
    ...(customerOverride && { customer_id: customerOverride }),
  };

  const validationSchema = Yup.object().shape({
    serial: Yup.string()
      .required('Serial number is required')
      .matches(RegexOptions.MAC_ADDRESS, 'Serial number is not valid'),
  });

  const onSubmit = values => {
    setSubmitting(true);
    dispatch(createDeviceAction(values, setSubmitting));
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });


  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <legend>Add a new device</legend>
        <FormBlock>
          <Input
            label="Serial number"
            name="serial"
            id="serial"
            scheme="white"
            placeholder="DC:A1:B2:C3:D4:E5"
            onChange={handleChange}
            value={values.serial}
            error={touched.serial && errors.serial}
          />
        </FormBlock>
        <FormBlock>
          <Input
            label="Name"
            name="name"
            id="name"
            scheme="white"
            onChange={handleChange}
            value={values.name}
            error={touched.name && errors.name}
          />
        </FormBlock>
      </fieldset>

      <FormBlock hasInlineChildren isEnd>
        <Button
          tag="button"
          size="medium"
          text="Cancel"
          scheme="link"
          hasShadow={false}
          handler={() => {
            dispatch(popupActionClear());
          }}
          disabled={isSubmitting}
        />

        <Button
          tag="button"
          type="submit"
          size="medium"
          text="Add and close"
          disabled={isSubmitting}
        />
      </FormBlock>
    </form>
  );
};

export default DeviceAdd;
