import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ActivityList from 'components/activity/ActivityList';
import Button from 'components/button/Button';
import LabelValueList from 'components/list/labelValueList/LabelValueList';
import Heading from 'components/typography/heading/Heading';

import UserRight from 'constants/UserRight.enum';

import { hasUserRights } from 'store/auth/hasUserRights';
import { detailsPoolAction } from 'store/pool/poolActions';
import { RootState } from 'store/rootState';

const PoolsInfo = () => {
  const dispatch = useDispatch();
  const pool = useSelector((state: RootState) => state.pool);
  const hasPoolsEditRights = dispatch(hasUserRights(UserRight.POOLS_EDIT));

  const groupDetails = [
    {
      label: 'ID',
      value: pool.id,
    },
    {
      label: 'Created on',
      value: pool.created_at,
    },
  ];

  return (
    <>
      <Heading level={2}>Group details</Heading>
      <LabelValueList
        values={groupDetails}
        listId={pool.id}
        loading={!pool}
        loadingItemLength={2}
      />

      {/* TODO: fix dispatch typing in middleware */}
      {/* @ts-ignore */}
      {hasPoolsEditRights && (
        <Button
          tag="a"
          size="medium"
          scheme="link"
          text="Edit this group"
          hasShadow={false}
          handler={() => {
            dispatch(detailsPoolAction(pool.id));
          }}
        />
      )}

      <ActivityList />
    </>
  );
};

export default PoolsInfo;
