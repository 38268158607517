import moment from 'moment-timezone';
import { reverse } from 'named-urls';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import OverviewList from 'components/overviewList/OverviewList';
import { OverviewListItem } from 'components/overviewList/OverviewListItem.types';
import Heading from 'components/typography/heading/Heading';
import Text from 'components/typography/text/Text';

import defaultTimezone from 'constants/DefaultTimezone';
import { Api } from 'constants/Routes.enum';

import fetchData from 'store/fetchData';
import { setPoolActivityLogDetailsAction } from 'store/pool/poolActions';
import { RootState } from 'store/rootState';

import './ActivityList.scss';

const ActivityList = () => {
  const dispatch = useDispatch();
  const [loadingActivities, setLoadingActivities] = useState(true);
  const [activities, setActivities] = useState<OverviewListItem[]>([]);
  const timeZone = useSelector((state: RootState) => state.pool?.location?.timezone_info);

  const { id } = useParams<{ id?: string }>();

  const overviewHeaders = [
    { key: 'Date and time', content: 'Date and time', width: '25%' },
    { key: 'User', content: 'User', width: '25%' },
    { key: 'Action', content: 'Action' },
  ];

  const activityClick = useCallback(
    ({ version_id, item_id, item_name, item_type, description }: {
      version_id: string
      item_id: string
      item_name: string
      item_type: string
      description: string
    }) => {
      dispatch(
        setPoolActivityLogDetailsAction(
          version_id,
          item_id,
          item_name,
          item_type,
          description,
        ),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (!id) return

    const abortController = new AbortController()
    const promise = dispatch(
      fetchData(
        reverse(Api.LOGS, { id }),
        {},
        true,
        abortController.signal
      )
    );

    promise
      // @ts-ignore
      .then(json => json.json())
      .then((res: { logs?: {
        version_id: string;
        item_id: string;
        item_name: string;
        item_type: string;
        pending_approval: string;
        created_at: string;
        user_first_name: string;
        user_last_name: string;
        description: string;
      }[]}) => {
        const { logs } = res;

        if (logs) {
          setActivities(
            logs.map(
              ({
                version_id,
                item_id,
                item_name,
                item_type,
                pending_approval,
                created_at,
                user_first_name,
                user_last_name,
                description,
              }) => {
                return {
                  key: `activity_${item_id}_${created_at}`,
                  id: item_id,
                  items: [
                    {
                      content: `${moment
                        .tz(created_at, timeZone || defaultTimezone)
                        .format('DD-MM-YYYY hh:mm')}`,
                      key: `${item_id}_created_at`,
                      width: '25%',
                    },
                    {
                      content: `${user_first_name} ${user_last_name}`,
                      key: `${item_id}_name`,
                      width: '25%',
                    },
                    {
                      content: description,
                      key: `${item_id}_description`,
                    },
                  ],
                  status: pending_approval ? 'pending' : 'default',
                  onClick: () => {
                    activityClick({
                      version_id,
                      item_id,
                      item_name,
                      item_type,
                      description,
                    });
                  },
                };
              },
            ),
          );
        }
      })
      .finally(() => {
        setLoadingActivities(false);
      });

    return () => {
      abortController.abort('Unmounting component: activityList')
    }
  }, [dispatch, id, activityClick, timeZone]);

  return (
    <>
      <Heading level={2} className="activity-list__title">
        Activity log
      </Heading>

      {(!loadingActivities && activities.length === 0 && (
        <Text color='grey-dark'>No activity found</Text>
      )) || (
        <OverviewList
          headers={overviewHeaders}
          content={activities}
          loading={loadingActivities}
          cardProps={{
            highlightOnHover: true,
            interactive: true,
          }}
        />
      )}
    </>
  );
};

export default ActivityList;
