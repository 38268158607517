import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import Logo from 'components/logo/Logo';
import FormMessage from 'components/form/formMessage/FormMessage';
import { BigForm, BigFormField } from 'components/form/bigForm/BigForm';

import { Paths } from 'constants/Routes.enum';
import PopupTypes from 'constants/PopupTypes.enum';

import { submitLoginAction } from 'store/auth/loginActions';
import { popupActionSet } from 'store/popup/popupActions';
import { RootState } from 'store/rootState';

import './Login.scss';

const Login = () => {
  const dispatch = useDispatch();
  const authError = useSelector((state: RootState) => state.auth?.error);
  const [isSubmitting, setSubmitting] = useState(false);

  const initialValues = {
    username: '',
    password: '',
  };

  const loginSchema = Yup.object().shape({
    username: Yup.string().required(),
    password: Yup.string().required(),
  });

  const showLostAccountPopup = () => {
    const children = (
      <>
        <h2>Contact us to change your e-mail address</h2>
        <p>
          In case you lost or can&apos;t access the e-mail address you use to
          login to Grep-Cat, we can manually transfer your account to another
          email-address. Send the following data to{' '}
          <Button
            hasShadow={false}
            scheme="link"
            link={`mailto:${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
            text={`${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
            size="inline"
          />
          : <br />
          <ol>
            <li>Your full name</li>
            <li>The company you work for</li>
            <li>Your phone number</li>
            <li>
              The new e-mail address you want your Grep-Cat account to be
              attached to
            </li>
          </ol>
        </p>
        <p>We ask above data for security reasons.</p>
      </>
    );

    dispatch(popupActionSet(PopupTypes.INFO, children));
  };

  return (
    <>
      <Logo />
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={async values => {
          setSubmitting(true);
          dispatch(submitLoginAction(values.password, values.username));
        }}
      >
        {({ values, errors, handleChange, handleSubmit, touched }) => (
          <>
            <BigForm
              handleSubmit={handleSubmit}
              submitButtonText="Log in"
              isSubmitting={isSubmitting}
              dataCy="loginForm"
              fields={
                <>
                  <BigFormField>
                    <Input
                      type="text"
                      name="username"
                      id="username"
                      placeholder="Username"
                      size="large"
                      scheme="login"
                      aria-label="Username"
                      value={values.username}
                      error={touched.username && errors.username ? true : null}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      data-cy="username"
                    />
                  </BigFormField>
                  <BigFormField>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                      size="large"
                      scheme="login"
                      aria-label="Password"
                      value={values.password}
                      error={touched.password && errors.password ? true : null}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      data-cy="password"
                    />
                  </BigFormField>
                </>
              }
              errorMessages={
                <>
                  <FormMessage
                    name="username"
                    type="formikErrorMessage"
                    dataCy="errorUsername"
                  />
                  <FormMessage
                    name="password"
                    type="formikErrorMessage"
                    dataCy="errorPassword"
                  />
                  <FormMessage errorMessage={authError} dataCy="errorAuth" />
                </>
              }
              footerText={
                <>
                  Lost your credentials?
                  <div className="login__lost-credentials-links">
                    <Button
                      className="login__lost-credentials-link"
                      link={Paths.REQUEST_PASSWORD}
                      scheme="link"
                      text="I lost my password"
                      size="inline"
                      hasShadow={false}
                    />
                    <Button
                      className="login__lost-credentials-link"
                      onClick={() => showLostAccountPopup()}
                      scheme="link"
                      text="I lost my account"
                      size="inline"
                      hasShadow={false}
                    />
                  </div>
                </>
              }
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default Login;
