import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import DeckEmpty from 'components/deck/DeckEmpty';
import DragSwitch from 'components/form/dragSwitch/DragSwitch';
import OverviewList from 'components/overviewList/OverviewList';
import { OverviewListItemTag } from 'components/overviewList/OverviewListItem.enum';
import Status from 'components/status/Status';

import UserRight from 'constants/UserRight.enum';

import { hasUserRights } from 'store/auth/hasUserRights';
import { toggleActivateAttachedInputSignalsAction } from 'store/inputSignals/inputDevices/inputDevicesActions';

import { OverviewListHeader } from 'components/overviewList/OverviewList.types';
import { OverviewListItem } from 'components/overviewList/OverviewListItem.types';
import { openInputSignalDetailsAction } from 'store/inputSignals/inputSignals/inputSignalsActions';
import { InputSignalsOverviewFieldsetProps, InputSignal } from './InputSignalsOverviewFieldset.types';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'store/rootState';
import { AnyAction } from 'redux';

const InputSignalsOverviewFieldset: React.FC<InputSignalsOverviewFieldsetProps> = ({
  isLoading,
  id,
  input_signals = [],
  active,
  isDisabled,
  setSubmitting,
}) => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const hasInputEditRights = dispatch(hasUserRights(UserRight.INPUT_EDIT));
  const overviewHeaders: OverviewListHeader[] = [
    {
      key: 'Status',
      content: '',
      width: '25px',
    },
    {
      key: 'Name',
      content: 'Name',
    },
  ];

  const handleSignalClick = useCallback((inputSignal: InputSignal) => {
    if (hasInputEditRights) {
      dispatch(openInputSignalDetailsAction(inputSignal.id))
    }
  }, [dispatch, hasInputEditRights])

  const overviewData: OverviewListItem[] = input_signals.map(input_signal => ({
    id: `${input_signal.id}`,
    key: `${input_signal.id}`,
    items: [
      {
        key: '1',
        width: '25px',
        content: (
          <Status
            isActive={input_signal.active}
            hasText={false}
            title={input_signal.active ? 'Online' : 'Offline'}
          />
        ),
      },
      {
        key: '2',
        content: input_signal.name,
      },
    ],
    onClick: () => handleSignalClick(input_signal),
    tag: OverviewListItemTag.BUTTON,
  }));

  return (
    <fieldset>
      {isLoading || input_signals?.length > 0 ? (
        <>
          <div className="input-device-edit__input-signals-header-layout">
            <legend className="input-device-edit__input-signals-header-layout__header">
              Attached input signals
            </legend>
            <p className="input-device-edit__input-signals-header-layout__paragraph">
              This device contains the following input signals:
            </p>
            {id && (
              <DragSwitch
                className="input-device-edit__input-signals-header-layout__drag-switch"
                id="activateInputSignals"
                name="activateInputSignals"
                labelOn="Active"
                labelOff="Disabled"
                isDisabled={
                  (!isLoading && input_signals?.length === 0) || !hasInputEditRights || isDisabled
                }
                isChecked={active}
                onChange={() => {
                  dispatch(
                    toggleActivateAttachedInputSignalsAction(
                      id,
                      active,
                      setSubmitting,
                    ),
                  );
                }}
              />
            )}
          </div>
          <OverviewList
            headers={overviewHeaders}
            content={overviewData}
            loading={isLoading}
            cardProps={{
              highlightOnHover: true,
              interactive: true,
            }}
          />
        </>
      ) : (
        <>
          <legend className="input-device-edit__input-signals-header-layout__header">
            Attached input signals
          </legend>
          <DeckEmpty title="No input signals found" />
        </>
      )}
    </fieldset>
  );
};

export default InputSignalsOverviewFieldset;
