export enum MediaActionTypes {
  MEDIA_REQUESTED = '@@media/requested',
  MEDIA_SUCCEEDED = '@@media/success',
  MEDIA_FAILED = '@@media/error',
  MEDIA_ADDED = '@@media/added',
  MEDIA_DELETED = '@@media/deleted',
  MEDIA_CLEARED = '@@media/cleared',
  MEDIA_FOLDER_SUCCEEDED = '@@media/folder_succeeded',
  MEDIA_SELECTABLE = '@@media/selectable',
  MEDIA_FOLDER_UPDATED = '@@media/folder_updated',
  MEDIA_FOLDER_ADDED = '@@media/folder_added',
  MEDIA_FOLDER_DELETED = '@@media/folder_deleted',
  MEDIA_FILTER = '@@media/filter',
  MEDIA_SORT = '@@media/sort',
  MEDIA_TYPE_VALUES_SUCCEEDED = '@@media/type_values_succeeded',
  MEDIA_TYPE_VALUES_FAILED = '@@media/type_values_failed',
}

export enum MediaValueType {
  JACKPOT = 'jackpot',
  TICKER_TAPE = 'ticker_tape'
}

export enum MediaItemSortAttribute {
  UPDATED_AT = 'updated_at',
  NAME = 'name',
  CREATED_AT = 'created_at'
}

export enum MediaItemSortOrder {
  ASCENDING = 'asc',
  DESCENDING = 'desc'
}

export enum MediaValueTypeTickerTapeScrollDirectionValue {
  LEFT_TO_RIGHT = 'left-to-right',
  RIGHT_TO_LEFT = 'right-to-left',
}

export enum MediaValueTypeFontValue {
  ARIAL = 'Arial',
  ARIAL_BLACK = 'Arial Black',
  BOOKMAN = 'Bookman',
  COMIC_SANS_MS = 'Comic Sans MS',
  TIMES = "Times",
  TIMES_NEW_ROMAN = "Times New Roman",
  COURIER = "Courier",
  COURIER_NEW = "Courier New",
  VERDANA = "Verdana",
  GEORGIA = "Georgia",
  PALATINO = "Palatino",
  GARAMOND = "Garamond",
  TAHOMA = "Tahoma",
  TREBUCHET_MS = "Trebuchet MS",
  IMPACT = "Impact",
}

export default MediaActionTypes;
