import { ReactNode } from "react";

export interface LabelValueListProps {
  listId?: string | number;
  styling?: LabelValueListStyling;
  flatten?: boolean;
  values?: Array<LabelValueListItem>
  loading?: boolean;
  loadingItemLength?: number;
}

export interface LabelValueListItem {
  id?: number
  value?: string | number | ReactNode;
  label?: string;
  loading?: boolean;
}

export enum LabelValueListStyling {
  NONE = 'none',
  BULLETS = 'bullets'
}