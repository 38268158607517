import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router';

import Heading from 'components/typography/heading/Heading';
import Container from 'components/container/Container';
import Text from 'components/typography/text/Text';
import { TextColor, TextTag } from 'components/typography/text/Text.enum';
import SubNavigation from 'components/navigation/SubNavigation';
import { Paths } from 'constants/Routes.enum';

import { RootState } from 'store/rootState';

import InputDevicesOverview from './inputDevices/InputDevicesOverview';
import InputSignalsOverview from './inputSignals/InputSignalsOverview';

const Inputs = () => {
  const { customer } = useSelector((state: RootState) => state.auth);

  const routes = [
    {
      Key: 'input devices',
      Path: Paths.INPUTS_DEVICES,
      Name: 'Input devices',
      ViewRights: true,
    },
    {
      Key: 'input signals',
      Path: Paths.INPUTS_SIGNALS,
      Name: 'Input signals',
      ViewRights: true,
    },
  ];

  return (
    <div className="detail-page">
      <Container>
        {customer && (
          <Text tag={TextTag.P} color={TextColor.GREYBLACK}>
            {customer}
          </Text>
        )}
        <Heading level={1}>Inputs</Heading>
      </Container>

      <SubNavigation routes={routes} />

      <Container className="detail-page__content">
        <Switch>
          <Route path={Paths.INPUTS_DEVICES} component={InputDevicesOverview} />
          <Route path={Paths.INPUTS_SIGNALS} component={InputSignalsOverview} />
        </Switch>
      </Container>
    </div>
  );
};

export default Inputs;
