export enum UserRight {
  POOLS_VIEW = 'pools',
  POOLS_EDIT = 'pools_edit',
  SIGNS_VIEW = 'signs',
  SIGNS_EDIT = 'signs_edit',
  EVENTS_VIEW = 'scheduled_events',
  EVENTS_EDIT = 'scheduled_events_edit',
  ARRANGEMENTS_VIEW = 'arrangements',
  ARRANGEMENTS_EDIT = 'arrangements_edit',
  MEDIA_VIEW = 'media',
  MEDIA_EDIT = 'media_edit',
  TRIGGERS_VIEW = 'triggers',
  TRIGGERS_EDIT = 'triggers_edit',
  MEDIA_FOLDERS_VIEW = 'media_folders',
  MEDIA_FOLDERS_EDIT = 'media_folders_edit',
  INPUT_VIEW = 'input',
  INPUT_EDIT = 'input_edit',
  MANAGEMENT = 'management',
  APPROVAL = 'approval',
}

export default UserRight;
