import React from 'react';
import Checkbox from 'components/form/checkbox/Checkbox';
import Heading from 'components/typography/heading/Heading';

import './UserRoleRights.scss';
import {
  CheckboxLabelScheme,
  CheckboxScheme,
} from 'components/form/checkbox/Checkbox.enum';
import { UserRoleRight, UserRoleRightsProps } from './UserRoleRights.types';

import { userRoleRights } from './constants/UserRoleRights';
import UserRight from 'constants/UserRight.enum';

const UserRoleRights: React.FC<UserRoleRightsProps> = ({
  rightsValue,
  isAdmin,
  handleRightsChange,
}) => {
  const mappedUserRoleRights = userRoleRights.map(
    (userRight: UserRoleRight) => {
      const { sections } = userRight;

      const rightsGroupSection = sections.map(section => {
        const { rights } = section;

        const sectionRights = rights.map(right => {
          return (
            <div
              key={right.id}
              className="user-role-rights__groups__section-checkbox"
            >
              <Checkbox
                label={right.name}
                name={right.value}
                id={right.value}
                checked={rightsValue.indexOf(right.value) >= 0 || (right.value === UserRight.APPROVAL && isAdmin)}
                onChange={value => {
                  handleRightsChange(value as UserRight);
                }}
                disabled={right.disabled}
                scheme={CheckboxScheme.GREEN}
                labelScheme={CheckboxLabelScheme.BLACK}
              />
            </div>
          );
        });

        return (
          <div key={section.id} className="user-role-rights__groups__section">
            <div className="user-role-rights__groups__section-name">
              {section.name}
            </div>
            <div className="user-role-rights__groups__section-checkboxes">
              {sectionRights}
            </div>
          </div>
        );
      });

      return (
        <div key={userRight.id} className="user-role-rights__groups">
          <Heading level={3} className="user-role-rights__groups-heading">
            {userRight.name}
          </Heading>
          {rightsGroupSection}
        </div>
      );
    },
  );

  return <>{mappedUserRoleRights}</>;
};

export default UserRoleRights;
