import MediaActionTypes, {
  MediaItemSortAttribute,
  MediaItemSortOrder,
} from './Media.enum';
import { Media } from './Media.types';

export const mediaInitialState: Media = {
  request: false,
  media: [],
  value_types: undefined,
  folders: [],
  breadcrumbs: [],
  folder_info: undefined,
  filter: {
    name: '',
    filetype: '',
  },
  sort: {
    attribute: MediaItemSortAttribute.UPDATED_AT,
    order: MediaItemSortOrder.DESCENDING,
  },
  selectable: false,
};

export interface MediaReducerAction {
  type: string;
  payload: Media;
}
const mediaReducer = (
  state = mediaInitialState,
  action: MediaReducerAction,
) => {
  switch (action.type) {
    case MediaActionTypes.MEDIA_SELECTABLE:
      return { ...state, selectable: action.payload };
    case MediaActionTypes.MEDIA_REQUESTED:
      return { ...state, request: true };
    case MediaActionTypes.MEDIA_SUCCEEDED:
      return { ...state, ...action.payload, request: false };
    case MediaActionTypes.MEDIA_FAILED:
      return { ...mediaInitialState, request: false };
    case MediaActionTypes.MEDIA_FOLDER_UPDATED:
      return {
        ...state,
        folders: [
          action.payload.folder_info,
          ...state.folders.filter(
            folder => folder?.id !== action.payload.folder_info?.id,
          ),
        ],
        folder_info:
          action.payload.folder_info?.id === state.folder_info?.id
            ? action.payload.folder_info
            : state.folder_info,
      };
    case MediaActionTypes.MEDIA_FOLDER_ADDED:
      return { ...state, folders: [action.payload, ...state.folders] };
    case MediaActionTypes.MEDIA_FOLDER_DELETED:
      return {
        ...state,
        folders: state.folders.filter(
          item => item.id !== action.payload.folder_info?.id,
        ),
      };
    case MediaActionTypes.MEDIA_ADDED:
      return { ...state, media: [action.payload, ...state.media] };
    case MediaActionTypes.MEDIA_DELETED:
      return {
        ...state,
        media: state.media.filter(
          item => item.id !== action.payload.media[0].id,
        ),
      };
    case MediaActionTypes.MEDIA_CLEARED:
      return mediaInitialState;
    case MediaActionTypes.MEDIA_FILTER:
      return { ...state, filter: action.payload };
    case MediaActionTypes.MEDIA_SORT:
      return { ...state, sort: action.payload };
    case MediaActionTypes.MEDIA_TYPE_VALUES_SUCCEEDED:
      return { ...state, value_types: action.payload };
    case MediaActionTypes.MEDIA_TYPE_VALUES_FAILED:
      return { ...state, value_types: null };
    default:
      return state;
  }
};

export default mediaReducer;
