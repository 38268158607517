import React, { useState } from 'react';
import { ColorResult } from 'react-color';

import ColorPicker from '../colorPicker/ColorPicker';
import { RichTextColorPickerProps } from './RichTextEditor.types';

import './RichTextEditorColorPicker.scss';

const RichTextEditorColorPicker = ({ onChange, currentState }: RichTextColorPickerProps) => {
  const [state, setState] = useState({
    displayColorPicker: false,
    color: currentState.color || '#000000',
  });

  const handleColorChange = (color: ColorResult) => {
    let hexColor: ColorResult | string = color.hex;

    if (!hexColor) {
      hexColor = color;
    }
    setState({ ...state, color: hexColor, displayColorPicker: true });
  };

  const handleClick = () => {
    setState(prevState => {
      return { ...state, displayColorPicker: !prevState.displayColorPicker };
    });
  };

  const handleClose = () => {
    setState({ ...state, displayColorPicker: false });
    onChange('color', state.color);
  };

  return (
    <ColorPicker
      className="rich-text-editor-color-picker"
      buttonClassName="rich-text-editor-color-picker__button"
      handleColorChange={handleColorChange}
      handleClose={handleClose}
      handleClick={handleClick}
      color={state.color}
      displayColorPicker={state.displayColorPicker}
    />
  );
};

export default RichTextEditorColorPicker;
