import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useLocation} from 'react-router-dom';

import PoolButton from 'components/pool/button/PoolButton';
import Deck from 'components/deck/Deck';
import TriggerCard from 'components/trigger/card/TriggerCard';
import UserRight from 'constants/UserRight.enum';
import DeckEmpty from 'components/deck/DeckEmpty';
import Button from 'components/button/Button';
import { Trigger } from 'components/popup/inputs/inputSignals/InputSignalEdit.types';

import HistoryLocationState from 'constants/HistoryState';

import { hasUserRights } from 'store/auth/hasUserRights';
import {editTriggerAction, fetchTriggersAction} from 'store/triggers/triggersActions';
import {popupActionSet} from 'store/popup/popupActions';
import { RootState } from 'store/rootState';

import './Triggers.scss';

const PoolsDetail = () => {
  const dispatch = useDispatch();
  const { state } = useLocation<HistoryLocationState>();
  const pool = useSelector((state: RootState) => state?.pool);
  const triggers = useSelector((state: RootState) => state?.triggers);

  const hasTriggersEditRights = dispatch(
    hasUserRights(UserRight.TRIGGERS_EDIT),
  );
  const hasTriggersViewRights = dispatch(
    hasUserRights(UserRight.TRIGGERS_VIEW),
  );

  useEffect(() => {
    // @ts-ignore
    if (pool.id && hasTriggersViewRights) {
      dispatch(fetchTriggersAction(pool.id));
    }
  }, [hasTriggersViewRights, pool, dispatch]);

  useEffect(() => {
    if (state && state.fromTrigger !== undefined) {
      dispatch(editTriggerAction(state.fromTrigger));
    }
    if (state && state.newTrigger !== undefined) {
      dispatch(popupActionSet('trigger', {}));
    }
  }, [state, dispatch]);

  const noTriggersViewRightsSubTitle = (
    <p>
      In case you should have view rights please contact your supervisor or send
      an email to{' '}
      <Button
        hasShadow={false}
        scheme="link"
        link={`mailto:${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
        text={`${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
        size="inline"
      />
    </p>
  );

  const setTriggerUntilValue = (trigger: Trigger) => {
    const untilValue = Number(trigger.until_value);
    const untilType = trigger.until_type;
    const untilTopicName = trigger?.until_topic?.name;

    if (untilType === 'timer') {
      return untilValue
        ? `Timer of ${untilValue} minute${untilValue === 1 ? '' : 's'}`
        : '';
    }

    return `Until <span class="triggers__topic-name" title="${untilTopicName}">${untilTopicName}</span> is activated`;
  };

  if (!hasTriggersViewRights) {
    return (
      <DeckEmpty title="No view rights for triggers">
        {noTriggersViewRightsSubTitle}
      </DeckEmpty>
    );
  }

  if (triggers.request) {
    const loadingCards = 6;

    return (
      <Deck>
        {[...Array(loadingCards)].map((_, v) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={v}>
              <TriggerCard loading />
            </Fragment>
          );
        })}
      </Deck>
    );
  }

  return (
    <>
      {triggers.triggers.length > 0 && (
        <Deck>
          {triggers.triggers.map(trigger => {
            return (
              <Fragment key={trigger.id}>
                <TriggerCard
                  id={trigger.id}
                  name={trigger.name || trigger.public_name}
                  when={`<span title="${trigger.trigger_public_name}">${trigger.trigger_public_name}</span>`}
                  show={`<span title="${trigger.arrangement_name || trigger.arrangement?.name}">${trigger.arrangement_name || trigger.arrangement?.name}</span>`}
                  until={setTriggerUntilValue(trigger)}
                  active={trigger.active}
                  pending={trigger.pending_approval}
                />
              </Fragment>
            );
          })}
        </Deck>
      )}
      {triggers.triggers.length === 0 && (
        <DeckEmpty title="No triggers added">
          {/* @ts-ignore */}
          {hasTriggersEditRights && (
            <p>Click on the blue + to start adding triggers to the group</p>
          )}
        </DeckEmpty>
      )}
      {/* @ts-ignore */}
      {hasTriggersEditRights && (
        <PoolButton text="Add trigger" popupType="trigger" />
      )}
    </>
  );
};

export default PoolsDetail;
