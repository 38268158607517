import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Dropdown from 'components/form/dropdown/Dropdown';
import fetchData from 'store/fetchData';
import { Api } from 'constants/Routes.enum';

const MediaTypeDropdown = ({ ...props }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const promise = dispatch(fetchData(Api.MEDIA_TYPES));

    promise
      .then(json => json.json())
      .then(({ media_item_types }) => {
        setValues(
          media_item_types.map(item => ({
            value: item.id,
            label: item.name,
          })),
        );
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <Dropdown
      {...props}
      values={values}
      hasEmptyOption={false}
      isLoading={isLoading}
    />
  );
};

export default MediaTypeDropdown;
