import fetchData from 'store/fetchData';
import { Api } from 'constants/Routes.enum';
import { reverse } from 'named-urls';

import { infoBarText } from 'components/infoBar/InfoBar.json';
import { ButtonColor } from 'components/button/Button.enum';

import PopupTypes from 'constants/PopupTypes.enum';
import ApprovalStates from 'constants/ApprovalStates.enum';
import UserRight from 'constants/UserRight.enum';

import { setInfoBarShow, setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import {
  popupActionClear,
  popupActionSet,
  popupActionUpdate,
} from 'store/popup/popupActions';
import { hasAdminRights, hasUserRights } from 'store/auth/hasUserRights';

export const ArrangementsActionTypes = {
  ARRANGEMENT_SELECTABLE: '@@arrangement/selectable',
  ARRANGEMENT_REQUESTED: '@@arrangement/requested',
  ARRANGEMENT_SUCCEEDED: '@@arrangement/success',
  ARRANGEMENT_FAILED: '@@arrangement/error',
  ARRANGEMENT_ADDED: '@@arrangement/added',
  ARRANGEMENT_DELETED: '@@arrangement/deleted',
  ARRANGEMENT_CLEARED: '@@arrangement/cleared',
  ARRANGEMENT_ACTIVATED: '@@arrangement/activated',
  ARRANGEMENT_DEACTIVATED: '@@arrangement/deactivated',
  ARRANGEMENT_APPROVED: '@@arrangement/approved',
};

export const arrangementRequested = () => ({
  type: ArrangementsActionTypes.ARRANGEMENT_REQUESTED,
});

export const arrangementSucceeded = arrangements => ({
  type: ArrangementsActionTypes.ARRANGEMENT_SUCCEEDED,
  payload: { ...arrangements },
});

export const arrangementFailed = () => ({
  type: ArrangementsActionTypes.ARRANGEMENT_FAILED,
});

export const arrangementAdded = arrangement => ({
  type: ArrangementsActionTypes.ARRANGEMENT_ADDED,
  payload: arrangement,
});

export const arrangementDeleted = id => ({
  type: ArrangementsActionTypes.ARRANGEMENT_DELETED,
  payload: id,
});

export const arrangementActivated = arrangement => ({
  type: ArrangementsActionTypes.ARRANGEMENT_ACTIVATED,
  payload: arrangement,
});

export const arrangementDeactivated = arrangement => ({
  type: ArrangementsActionTypes.ARRANGEMENT_DEACTIVATED,
  payload: arrangement,
});

export const arrangementCleared = () => ({
  type: ArrangementsActionTypes.ARRANGEMENT_CLEARED,
});

export const arrangementApproved = arrangement => ({
  type: ArrangementsActionTypes.ARRANGEMENT_APPROVED,
  payload: arrangement,
});

export const arrangementSelectable = selectable => ({
  type: ArrangementsActionTypes.ARRANGEMENT_SELECTABLE,
  payload: selectable,
});

/**
 * Fetch arrangements and update store state accordingly
 * @param {Object} fetchArrangementsAction
 * @param {Number} fetchArrangementsAction.pool_id
 * @param {Boolean=} fetchArrangementsAction.unprocessableMediaItems - Media items that cannot be processed because it is not allowed (for instance adding image to spot that already contains a video
 * @param {Object=} fetchArrangementsAction.history - History state object
 * @param {Object=} fetchArrangementsAction.sort - Sort object
 */
export const fetchArrangementsAction = ({
  pool_id,
  unprocessableMediaItems,
  history,
  sort,
}) => async dispatch => {
  const params = new URLSearchParams();
  let apiUrl = reverse(Api.ARRANGEMENTS_BY_POOL, { pool_id });
  if (sort) {
    params.set('sort_attribute', sort.attribute);
    params.set('sort_order', sort.order);
    apiUrl += `?${params.toString()}`;
  }

  dispatch(arrangementRequested());
  const promise = dispatch(fetchData(apiUrl));
  const historyLocationState = history?.location?.state;

  promise
    .then(json => json.json())
    .then(result => {
      let { arrangements } = result;

      // hide new arrangement from overview
      if (historyLocationState?.newArrangement) {
        arrangements = arrangements.map(arrangement => {
          if (arrangement.id === historyLocationState?.fromArrangement) {
            return {
              hidden: true,
              ...arrangement,
            };
          }
          return arrangement;
        });
      }

      dispatch(arrangementSucceeded({ arrangements: arrangements || [] }));

      if (unprocessableMediaItems) {
        const clearHistoryUnprocessableMediaItemsState = {
          location: {
            state: {
              unprocessableMediaItems: undefined,
            },
          },
        };
        history.replace(clearHistoryUnprocessableMediaItemsState);
        dispatch(
          setInfoBar({
            message:
              'You tried adding media to a spot that cannot be added, because only one item of that type is allowed within the spot',
            state: InfoBarState.ERROR,
            timeout: 5000,
          }),
        );
      }
    })
    .catch(() => {
      dispatch(arrangementFailed());
      dispatch(
        setInfoBar({
          message: 'Could not fetch arrangements',
          action: [
            {
              text: 'retry',
              handle: () =>
                dispatch(fetchArrangementsAction({ pool_id, sort })),
            },
          ],
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

// No longer used?
// export const addArrangementAction = (
//   { message, arrangement },
//   isDefaultArrangement,
// ) => dispatch => {
//   // remove existing arrangement from state to avoid duplicates
//   dispatch(arrangementDeleted(arrangement.id));

//   if (message && !isDefaultArrangement) {
//     dispatch(
//       fetchArrangementsAction({
//         pool_id: arrangement.pool_id,
//         sort: JSON.parse(localStorage.getItem('arrangement_order') || ''),
//       }),
//     );
//     dispatch(popupActionClear());
//     dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
//   }
// };

export const approvalArrangementAction = (id, defaultId, url) => dispatch => {
  const promise = dispatch(
    fetchData(reverse(url, { id }), {
      method: 'POST',
    }),
  );

  promise
    .then(json => json.json())
    .then(result => {
      if (result.deleted) {
        dispatch(arrangementDeleted(id));
        dispatch(popupActionClear());
        dispatch(setInfoBarShow({ show: false }));
        return;
      }

      const { arrangement, message } = result;
      if (arrangement) {
        dispatch(arrangementApproved(arrangement));
      }

      const updateArrangement = {
        ...arrangement,
        pending_changes: null,
        pending_approval:
          arrangement.approval_state !== ApprovalStates.APPROVED &&
          arrangement.approval_state !== ApprovalStates.REJECTED,
      };

      dispatch(popupActionUpdate(updateArrangement));
      dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
    });
};

/**
 * Delete arrangement and remove it from store state
 * @param {Number} id - ArrangementId
 * @param {Boolean} showNotification - Show/hide item deleted notification
 * @param {Object=} history - History state object
 */
export const deleteArrangementAction = (
  id,
  showNotification,
  history,
) => dispatch => {
  // call delete endpoint
  const promise = dispatch(
    fetchData(reverse(Api.ARRANGEMENTS_DELETE, { id }), {
      method: 'DELETE',
    }),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message } = result;

      // arrangement is deleted successfully - clear popup and show message
      dispatch(arrangementDeleted(id));
      dispatch(popupActionClear());
      if (showNotification) {
        dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
      }

      if (history) {
        history.push({
          state: {
            fromArrangement: undefined,
            newArrangement: undefined,
          },
        });
      }
    })
    .catch(err => {
      // arrangement could not be deleted - show error
      console.error(err);
      dispatch(
        setInfoBar({ message: err, state: InfoBarState.ERROR, timeout: 5000 }),
      );
    });
};

/**
 * Edit arrangement and update store state accordingly
 * @param {Number} id - ArrangementId
 * @param {Number} defaultId - Default arrangementId
 */
export const editArrangementAction = (id, defaultId) => dispatch => {
  const isAdmin = dispatch(hasAdminRights());
  const hasArrangementEditRights = dispatch(
    hasUserRights(UserRight.ARRANGEMENTS_EDIT),
  );
  const promise = dispatch(fetchData(reverse(Api.ARRANGEMENTS_DETAIL, { id })));

  promise
    .then(json => json.json())
    .then(arrangement => {
      dispatch(
        popupActionSet(PopupTypes.ARRANGEMENT, {
          ...arrangement,
          isDefault: arrangement.id === defaultId,
        }),
      );

      if (arrangement.pending_approval) {
        if (isAdmin) {
          dispatch(
            setInfoBar({
              message: infoBarText.approval.message,
              state: InfoBarState.WARNING,
              action: [
                {
                  text: infoBarText.approval.buttons.approve,
                  handle: () => {
                    dispatch(
                      approvalArrangementAction(
                        id,
                        defaultId,
                        Api.ARRANGEMENTS_APPROVE,
                      ),
                    );
                  },
                  color: ButtonColor.GREEN,
                },
                {
                  text: infoBarText.approval.buttons.reject,
                  handle: () => {
                    dispatch(
                      approvalArrangementAction(
                        id,
                        defaultId,
                        Api.ARRANGEMENTS_REJECT,
                      ),
                    );
                  },
                  color: ButtonColor.RED,
                },
              ],
            }),
          );
        } else {
          dispatch(
            setInfoBar({
              message: 'The show is waiting for approval',
              state: InfoBarState.WARNING,
            }),
          );
        }
      } else if (!hasArrangementEditRights) {
        dispatch(
          setInfoBar({
            message: 'You only have the rights to view this show',
            state: InfoBarState.WARNING,
          }),
        );
      }
    })
    .catch(err => {
      dispatch(
        setInfoBar({ message: err, state: InfoBarState.ERROR, timeout: 5000 }),
      );
    });
};

/**
 * PATCH arrangement in case it already exist or POST arrangement in case it does not exist yet.
 * @param {Object} values - Values returned from form
 * @param {String} type - ArrangementType RegularArrangement or TriggerArrangement
 * @param {Number} id - ArrangementId
 * @param {Object} pool
 * @param {Object} media
 */
export const upsertArrangementAction = (
  values,
  type,
  id,
  pool,
  media,
  customerOverride,
) => dispatch => {
  // filter areaDurations attributes from values
  const areaDurationsObject = Object.keys(values)
    .filter(key => {
      return key.indexOf('duration') === 0;
    })
    .reduce((newData, k) => {
      // @ts-ignore
      newData[k] = values[k];
      return newData;
    }, {});

  const areaDurationsObjectArray = [];

  Object.entries(areaDurationsObject).forEach(([key, value]) => {
    const areaNumber = parseInt(key.replace('duration', ''), 0);
    areaDurationsObjectArray[areaNumber] = { duration: value };
  });

  const arrangementData = new FormData();
  const emptyNameLabel =
    type === 'RegularArrangement' ? 'New Regular show' : 'New Trigger show';

  arrangementData.append('arrangement[name]', values.name || emptyNameLabel);
  arrangementData.append('arrangement[template_id]', values.template);
  arrangementData.append('arrangement[type]', type);
  arrangementData.append('pool_id', pool.id);
  arrangementData.append('arrangement[draft]', !id);
  if (customerOverride) {
    arrangementData.append('arrangement[customer_id]', customerOverride);
  }

  const areaDurations =
    Array.isArray(areaDurationsObjectArray) &&
    areaDurationsObjectArray.length > 0;
  const currentMediaHasDurationFields =
    media && Object.values(media).filter(area => area?.duration).length > 0;

  switch (true) {
    // when duration exists send areas object containing durations
    case areaDurations:
      arrangementData.append(
        'arrangement[areas]',
        JSON.stringify({ ...areaDurationsObjectArray }),
      );
      break;
    // when there are no durations in new situation and current media has durations send empty areas object
    case !areaDurations && currentMediaHasDurationFields:
      arrangementData.append('arrangement[areas]', '{}');
      break;
    // in all other cases to not send areas in payload
    default:
      break;
  }

  const promise = dispatch(
    fetchData(
      id ? reverse(Api.ARRANGEMENTS_PATCH, { id }) : Api.ARRANGEMENTS_POST,
      {
        method: id ? 'PATCH' : 'POST',
        body: arrangementData,
      },
      false,
    ),
  );

  return promise;
};

/**
 * Toggle active arrangement (update it in database + update store state accordingly
 * @param {Number} id
 * @param {Boolean} isActive
 * @param {Function} setActive
 */
export const toggleActiveArrangementAction = (
  id,
  isActive,
  setActive,
) => dispatch => {
  const url = isActive
    ? Api.ARRANGEMENTS_DEACTIVATE
    : Api.ARRANGEMENTS_ACTIVATE;

  const promise = dispatch(
    fetchData(reverse(url, { id }), {
      method: 'POST',
    }),
  );
  promise
    .then(json => json.json())
    .then(result => {
      const { arrangement, message } = result;
      const arrangementStatus = arrangement.active;

      setActive(arrangementStatus);

      if (arrangementStatus) {
        dispatch(arrangementActivated(arrangement));
      } else {
        dispatch(arrangementDeactivated(arrangement));
      }
      dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
    });
};


/**
 * Dulicate arrangement
 * @param {Number} original_id
 */
export const duplicateArrangementAction = original_id => () => {
  // eslint-disable-next-line no-console
  console.log('Duplicate arrangement', original_id);
};

/**
 * Toggle select arrangement
 * @param {Boolean} selectable
 */

export const selectArrangement = selectable => dispatch => {
  dispatch(arrangementSelectable(selectable));
};
