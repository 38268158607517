import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import PageFilterContainer from 'components/page-filter-container/PageFilterContainer';
import Container from 'components/container/Container';
import Heading from 'components/typography/heading/Heading';
import OverviewList from 'components/overviewList/OverviewList';
import {
  fetchDevicesAction,
  openDetailDevicesAction,
} from 'store/devices/devicesActions';
import DeviceFilter, {
  filterDeviceList,
} from 'components/devices/filter/DeviceFilter';
import DeckEmpty from 'components/deck/DeckEmpty';
import Status from 'components/status/Status';
import UserRight from 'constants/UserRight.enum';
import { hasSuperAdminRights, hasUserRights } from 'store/auth/hasUserRights';
import Button from 'components/button/Button';
import PoolButton from 'components/pool/button/PoolButton';
import PopupTypes from 'constants/PopupTypes.enum';

import './Overview.scss';
import { RootState } from 'store/rootState';
import { MappedDevices } from '../inputs/inputDevices/InputDevicesOverview.types';
import { OverviewListHeader } from 'components/overviewList/OverviewList.types';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Ellipsis from 'components/typography/ellipsis/Ellipsis';
import { OverviewListItemItem } from 'components/overviewList/OverviewListItem.types';

const DeviceOverview = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { request, signs } = useSelector((state: RootState) => state.devices);
  const hasDeviceEditRights = dispatch(hasUserRights(UserRight.SIGNS_EDIT));
  const hasDeviceViewRights = dispatch(hasUserRights(UserRight.SIGNS_VIEW));
  const isSuperAdmin = dispatch(hasSuperAdminRights());

  const { customerOverride } = useSelector((state: RootState) => state.superAdmin)

  const filterOptions = useSelector(
    (state: RootState) => state.devices.filterOptions,
  );

  const overviewHeaders = [
    { key: '', content: '', width: '25px' },
    { key: 'Name', content: 'Name' },
    { key: 'Serial', content: 'Serial', width: '200px' },
    { key: 'Location', content: 'Location', width: '200px' },
    { key: 'Group', content: 'Group', width: '200px' },
    isSuperAdmin ? { key: 'Customer', content: 'Customer', width: '100px'} : undefined
  ].filter(Boolean) as OverviewListHeader[];

  useEffect(() => {
    dispatch(fetchDevicesAction({
      pool_id: undefined,
      historyLocation: undefined
    }));
  }, [dispatch]);

  const mappedDevices = useMemo(() => {
    return filterDeviceList({
      signs,
      localStorageItem: 'device_filters',
      filterOptions,
      customer: isSuperAdmin ? customerOverride : undefined
    }).map(
      ({
        id,
        name,
        serial_number,
        location_name,
        pool_name,
        customer,
        online_status,
      }) => {
        return {
          key: id.toString(),
          id: id.toString(),
          items: [
            {
              content: (
                <Status
                  isActive={online_status === 'online'}
                  activeText=""
                  inactiveText=""
                />
              ),
              width: '25px',
              key: `${id}_status`,
            },
            { content: name, key: `${id}_name` },
            { content: (
              <Ellipsis text={serial_number} />
            ), width: '200px', key: `${id}_serial` },
            {
              content: location_name || 'Unassigned',
              status: location_name ? 'active' : 'disabled',
              width: '200px',
              key: `${id}_location`,
            },
            {
              content: (
                <span
                  className={classNames('', {
                    'device-overview__list__item--unassigned': !pool_name,
                  })}
                >
                  {pool_name || 'Unassigned'}
                </span>
              ),
              status: pool_name ? 'active' : 'disabled',
              width: '200px',
              key: `${id}_group`,
            },
            isSuperAdmin ? {
              content: (
                <Ellipsis text={customer} />
              ),
              width: '100px',
              key: `${id}_customer`
            } : undefined
          ].filter(Boolean) as OverviewListItemItem[],
          onClick: () => {
            dispatch(openDetailDevicesAction(id, !!pool_name));
          },
        };
      },
    );
  }, [dispatch, signs, filterOptions, customerOverride, isSuperAdmin]);

  if (!hasDeviceViewRights) {
    return (
      <DeckEmpty title="No view rights for devices">
        <p>
          In case you should have view rights please contact your supervisor
          or send an email to{' '}
          <Button
            hasShadow={false}
            scheme="link"
            link={`mailto:${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
            text={`${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
            size="inline"
          />
        </p>
      </DeckEmpty>
    )
  }

  return (
    <>
      <div className="device-overview">
        <PageFilterContainer title="Devices">
          <DeviceFilter localStorageItem="device_filters" />
        </PageFilterContainer>

        <div className="device-overview__content">
          <Container>
            <Heading level={2} stylingLevel={3}>
              All devices
            </Heading>

            <OverviewList
              headers={overviewHeaders}
              content={mappedDevices}
              cardProps={{ highlightOnHover: true, interactive: true }}
              loading={request}
            />
            {!request && signs.length === 0 && (
              <DeckEmpty title="No devices added" />
            )}

            {!request && mappedDevices.length === 0 && (
              <DeckEmpty title="No devices found">
                <p>
                  There are no devices that match your search criteria. Try to
                  change the search criteria or reset all filters.
                </p>
              </DeckEmpty>
            )}
          </Container>
        </div>

        {hasDeviceEditRights && (
          <PoolButton text="Add device" popupType={PopupTypes.DEVICE_ADD} />
        )}
      </div>
    </>
  );
};

export default DeviceOverview;
