import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { reverse } from 'named-urls';
import { useHistory } from 'react-router-dom';
import equal from 'fast-deep-equal';
import PropTypes from 'prop-types';

import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import fetchData from 'store/fetchData';
import { Paths, Api } from 'constants/Routes.enum';
import Input from 'components/form/input/Input';
import {
  mediaAdded,
  mediaDeleted,
  deleteMediaAction,
} from 'store/media/mediaActions';
import {
  infoBarHide,
  setInfoBarShow,
  setInfoBar,
} from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import { popupActionClear } from 'store/popup/popupActions';
import MediaTypeDropdown from 'components/media/type-dropdown/MediaTypeDropdown';
import Icon from 'components/icon/Icon';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRight from 'constants/UserRight.enum';
import RegexOptions from 'constants/RegexOptions';
import SubmitButton from 'components/form/submit-button/SubmitButton';
import MediaPlacementFieldset from 'components/popup/media-preview/MediaPlacementFieldset';
import MediaPreviewDetails from 'components/popup/media-preview/MediaPreviewDetails';
import MediaShowsOverviewFieldset from 'components/popup/media-preview/MediaShowsOverviewFieldset';
import {
  PopupStickyForm,
  PopupStickyHeader,
  PopupStickyMain,
  PopupStickyFooter,
} from 'components/popup/Popup';

import './MediaPreview.scss';

const MediaPreview = props => {
  const {
    id,
    name,
    filename,
    filetype,
    file_size,
    media_file_url,
    media_source,
    created_at,
    source_url,
    media_item_type_id,
    folder_id,
    folder_breadcrumbs,
    shows,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const hasMediaEditRights = dispatch(hasUserRights(UserRight.MEDIA_EDIT));
  const infoBar = useSelector(state => state.info.bar);
  const [isSubmitting, setSubmitting] = useState(false);

  const initialForm = {
    name: name || '',
    url: source_url,
    mediaType: media_item_type_id || '',
    mediaFolderId: folder_id || 0,
  };

  const validationScheme = Yup.object().shape({
    name: Yup.string().required(),
    url: Yup.string().when('mediaType', {
      is: val => !!val,
      then: Yup.string()
        .matches(RegexOptions.URL, 'URL must be a valid URL')
        .required('URL is a required field'),
    }),
  });

  const onSubmit = values => {
    if (equal(values, initialForm)) {
      dispatch(popupActionClear());
      return;
    }
    setSubmitting(true);

    const mediaData = new FormData();
    mediaData.append('media[name]', values.name);

    if (values.url) {
      mediaData.append('media[source_url]', values.url);
    }

    if (values.mediaType) {
      mediaData.append('media[media_item_type_id]', values.mediaType);
    }

    if (values.mediaFolderId >= 0) {
      mediaData.append(
        'media[media_folder_id]',
        values.mediaFolderId === 0 ? '' : values.mediaFolderId,
      );
    }

    const promise = dispatch(
      fetchData(
        reverse(Api.MEDIA_DETAIL, { id }),
        {
          method: 'PATCH',
          body: mediaData,
        },
        false,
      ),
    );

    promise
      .then(json => json.json())
      .then(result => {
        dispatch(mediaDeleted(id));

        const { media } = result;

        if (media) {
          if (
            (!values.mediaFolderId && values.mediaFolderId !== 0) ||
            values.mediaFolderId === folder_id
          ) {
            dispatch(mediaAdded(result.media));
          }
          dispatch(popupActionClear());
        }

        if (values.mediaFolderId !== folder_id) {
          dispatch(
            setInfoBar({
              message: 'The media item has been repositioned',
              action: [
                {
                  handle: () => {
                    dispatch(setInfoBarShow({ show: false }));
                    history.push({
                      pathname:
                        values.mediaFolderId === 0
                          ? Paths.MEDIA
                          : reverse(Paths.MEDIA_FOLDER, {
                              folder_id: values.mediaFolderId,
                            }),
                    });
                  },
                  text: 'Go to folder',
                },
              ],
              timeout: 5000,
              state: 'check',
            }),
          );
        } else {
          dispatch(
            setInfoBar({
              message: result.message,
              timeout: 5000,
              state: 'check',
            }),
          );
        }
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationScheme}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        initialTouched,
        errors,
      }) => (
        <PopupStickyForm onSubmit={handleSubmit}>
          <PopupStickyHeader>
            <fieldset className="fieldset--flatten">
              <FormBlock flatten>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Media name"
                  value={values.name}
                  onChange={handleChange}
                  fontSize="large"
                  error={errors.name}
                  suffix={<Icon name="edit" />}
                  disabled={!hasMediaEditRights}
                />
              </FormBlock>
            </fieldset>
          </PopupStickyHeader>
          <PopupStickyMain>
            {['image', 'video'].indexOf(filetype) >= 0 && (
              <fieldset className="media-preview">
                {['image', 'video'].indexOf(filetype) >= 0 && (
                  <div className="media-preview__image">
                    {filetype === 'image' && (
                      <img
                        src={`${media_file_url || media_source}`}
                        alt={name}
                      />
                    )}

                    {filetype === 'video' && (
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      <video controls>
                        <source src={`${media_file_url || media_source}`} />
                      </video>
                    )}
                  </div>
                )}
              </fieldset>
            )}

            <MediaPreviewDetails
              id={id}
              created_at={created_at}
              file_size={file_size}
              filename={filename}
              breadcrumb={folder_breadcrumbs}
            >
              {filetype === 'html' && (
                <Button
                  text="Open in new tab"
                  size="small"
                  scheme="link"
                  hasShadow={false}
                  handler={() => {
                    dispatch(
                      setInfoBar({
                        message:
                          'Are you sure you want to open this file in a new tab?',
                        action: [
                          {
                            text: 'Open',
                            handle: () =>
                              window
                                .open(media_file_url || media_source, '_blank')
                                .focus(),
                            className: 'infobar__action--link',
                          },
                          {
                            text: 'Cancel',
                            handle: () => dispatch(infoBarHide()),
                            type: 'link',
                          },
                        ],
                        state: 'error',
                      }),
                    );
                  }}
                />
              )}
            </MediaPreviewDetails>

            {!!media_item_type_id && (
              <fieldset>
                <FormBlock>
                  <MediaTypeDropdown
                    label="Type"
                    name="mediaType"
                    id="media_type"
                    onChange={setFieldValue}
                    value={values.mediaType}
                    disabled={!!values.file || !hasMediaEditRights}
                  />
                </FormBlock>

                <FormBlock flatten>
                  <Input
                    type="text"
                    label="URL"
                    name="url"
                    id="url"
                    placeholder="URL"
                    scheme="white"
                    disabled={!!values.file || !hasMediaEditRights}
                    value={values.url}
                    onChange={handleChange}
                    error={errors.url}
                  />
                </FormBlock>
              </fieldset>
            )}

            {hasMediaEditRights && (
              <MediaPlacementFieldset
                name="mediaFolderId"
                setFieldValue={setFieldValue}
                folder_id={folder_id}
              />
            )}

            {shows && shows.length > 0 && (
              <MediaShowsOverviewFieldset shows={shows} />
            )}
          </PopupStickyMain>
          <PopupStickyFooter>
            <FormBlock hasInlineChildren flatten>
              {(hasMediaEditRights && (
                <Button
                  tag="button"
                  type="button"
                  size="medium"
                  text="Delete"
                  scheme="link"
                  hasShadow={false}
                  handler={() => {
                    dispatch(
                      setInfoBar({
                        message:
                          'Are you sure you want to delete this media item?',
                        action: [
                          {
                            text: 'Yes, Delete',
                            color: 'blue',
                            type: 'button',
                            handle: () =>
                              dispatch(deleteMediaAction({ id, shows })),
                          },
                          {
                            text: 'Cancel',
                            type: 'link',
                            handle: () => dispatch(infoBarHide()),
                          },
                        ],
                        state: InfoBarState.ERROR,
                      }),
                    );
                  }}
                  disabled={isSubmitting}
                />
              )) || (
                <Button
                  tag="button"
                  type="submit"
                  size="medium"
                  text="Cancel"
                  scheme="link"
                  hasShadow={false}
                  handler={() => {
                    dispatch(popupActionClear());
                    if (infoBar.show) {
                      dispatch(infoBarHide());
                    }
                  }}
                  disabled={isSubmitting}
                />
              )}

              <SubmitButton
                canSave={initialTouched && hasMediaEditRights}
                disabled={isSubmitting}
              />
            </FormBlock>
          </PopupStickyFooter>
        </PopupStickyForm>
      )}
    </Formik>
  );
};

MediaPreview.propTypes = {
  id: PropTypes.number,
  filename: PropTypes.string,
  filetype: PropTypes.string,
  file_size: PropTypes.string,
  media_file_url: PropTypes.string,
  media_source: PropTypes.string,
  created_at: PropTypes.string,
  source_url: PropTypes.string,
  media_item_type_id: PropTypes.number,
  folder_id: PropTypes.number,
  folder_breadcrumbs: PropTypes.string,
  shows: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      id: PropTypes.number,
      name: PropTypes.string,
      pending_approval: PropTypes.bool,
    }),
  ),
};

MediaPreview.defaultProps = {
  id: '',
  filename: '',
  filetype: '',
  file_size: '',
  media_file_url: '',
  media_source: '',
  created_at: '',
  source_url: '',
  media_item_type_id: null,
  folder_id: '',
  folder_breadcrumbs: '',
  shows: null,
};

export default MediaPreview;
