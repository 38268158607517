import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { reverse } from 'named-urls';

import { Api } from 'constants/Routes.enum';
import fetchData from 'store/fetchData';
import { ArrangementMediaProps } from 'constants/types/arrangmentTypes';
import ArrangementLayoutArea from 'components/arrangement/layout/ArrangementLayoutArea';

import './ArrangementLayout.scss';

const ArrangementLayout = ({
  templateObject,
  templateId,
  gridGap,
  bottomMargin,
  showNumbers,
  className,
  media,
  interActive,
  spotRefs,
}) => {
  const dispatch = useDispatch();
  const [template, setTemplate] = useState(templateObject);
  const borderWidth = 20;

  useEffect(() => {
    const abortController = new AbortController();

    if (templateId) {
      const promise = dispatch(
        fetchData(
          reverse(Api.TEMPLATE, { id: templateId }),
          {},
          true,
          abortController.signal,
        ),
      );

      promise
        .then(json => json.json())
        .then(result => {
          setTemplate(result);
        });
    }

    return () => {
      abortController.abort('Unmounting component: arrangement/layout');
    };
  }, [dispatch, templateId]);

  const totalWidth = template ? template.width + borderWidth * 2 : 0;
  const totalHeight = template ? template.height + borderWidth * 2 : 0;

  // prevent z-index issues with interactive areas by setting background area as first item
  const backgroundAreaFirstInOrder = areas => {
    const updateOrder = areas;

    updateOrder.some(
      (item, index) =>
        item.name === 'Background' &&
        updateOrder.unshift(
          // remove the found item, in-place (by index with splice),
          // returns an array of a single item removed
          updateOrder.splice(index, 1)[0],
        ),
    );

    return updateOrder;
  };

  if (!template) {
    return null;
  }

  return (
    <svg
      viewBox={`0 0 ${totalWidth < 0 ? 0 : totalWidth} ${
        totalHeight < 0 ? 0 : totalHeight
      }`}
      className={classNames('arrangement-layout', className, {
        'arrangement-layout--bottom-margin': bottomMargin,
      })}
    >
      {(template.areas || template.template_areas) &&
        backgroundAreaFirstInOrder(
          template?.areas || template?.template_areas,
        ).map(area => {
          if (!template.height) return <></>;

          const number = area.area_number ?? area.number;

          return (
            <ArrangementLayoutArea
              key={`fragment-${area.x}x${area.y}-${area.width}x${area.height}-${number}`}
              area={area}
              gridGap={gridGap}
              interActive={interActive}
              spotRef={spotRefs && spotRefs[number]}
              media={media && media?.[number]}
              borderWidth={borderWidth}
              showNumbers={showNumbers}
              templateHeight={template.height}
            />
          );
        })}
    </svg>
  );
};

ArrangementLayout.propTypes = {
  templateId: PropTypes.number,
  gridGap: PropTypes.number,
  bottomMargin: PropTypes.bool,
  showNumbers: PropTypes.bool,
  className: PropTypes.string,
  media: ArrangementMediaProps,
  interActive: PropTypes.bool,
  spotRefs: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

ArrangementLayout.defaultProps = {
  templateId: undefined,
  gridGap: 50,
  bottomMargin: false,
  showNumbers: false,
  className: '',
  media: null,
  interActive: false,
  spotRefs: null,
};

export default ArrangementLayout;
