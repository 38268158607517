import React, { FC } from 'react';
import classNames from 'classnames';
import './Deck.scss';
import { DeckProps } from './Deck.types';

const Deck: FC<DeckProps> = ({ columns = 3, children, dataCy }) => {
  return (
    <div
      data-cy={dataCy}
      className={classNames('deck', {
        [`deck--columns-${columns}`]: columns,
      })}
    >
      {children}
    </div>
  );
};

export default Deck;
