import { PoolActionTypes } from './poolActions';

export const poolInitialState = {
  request: false,
  id: undefined,
  name: '',
};

const poolReducer = (state = poolInitialState, action) => {
  switch (action.type) {
    case PoolActionTypes.POOL_REQUESTED:
      return { ...state, request: true };
    case PoolActionTypes.POOL_SUCCEEDED:
      return { ...action.payload.pool, request: false };
    case PoolActionTypes.POOL_ADDED:
      return { ...state, ...action.payload };
    case PoolActionTypes.POOL_UPDATED:
      return { ...state, ...action.payload };
    case PoolActionTypes.POOL_DELETED:
      return state;
    case PoolActionTypes.POOL_FAILED:
      return { ...poolInitialState, request: false };
    case PoolActionTypes.POOL_CLEARED:
      return poolInitialState;
    case PoolActionTypes.POOL_NOT_FOUND:
      return { ...poolInitialState, id: 0 };
    case PoolActionTypes.POOL_DEFAULT:
      return { ...state, default_arrangement: action.payload };
    default:
      return state;
  }
};

export default poolReducer;
