export enum InputSize {
  LARGE = 'large',
}

export enum InputFontSize {
  LARGE = 'large',
}

export enum InputScheme {
  WHITE = 'white',
  LOGIN = 'login',
}
