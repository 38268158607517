import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import equal from 'fast-deep-equal';

import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import { infoBarHide, setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import { popupActionClear } from 'store/popup/popupActions';
import {
  saveMediaFolderAction,
  deleteMediaFolderAction,
} from 'store/media/mediaActions';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRight from 'constants/UserRight.enum';
import Input from 'components/form/input/Input';
import MediaFolderDropdown from 'components/media/folder-dropdown/MediaFolderDropdown';
import { RootState } from 'store/rootState';

const MediaFolder = ({ title, id, parent_id, media_folder_id }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const breadCrumbs = useSelector(state => state.media?.breadcrumbs);
  const media = useSelector(state => state.media);
  const hasMediaFoldersEditRights = dispatch(
    hasUserRights(UserRight.MEDIA_FOLDERS_EDIT),
  );
  const [isSubmitting, setSubmitting] = useState(false);
  const initialForm = {
    title: title || '',
    media_folder_id: parent_id || media_folder_id || 0,
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
  });

  const customerOverride = useSelector(state => state.superAdmin.customerOverride);

  const onSubmit = values => {
    setSubmitting(true);

    if (equal(values, initialForm)) {
      dispatch(popupActionClear());
      setSubmitting(false);
      return;
    }

    dispatch(
      saveMediaFolderAction(
        values,
        id,
        initialForm,
        history,
        media,
        setSubmitting,
        customerOverride
      ),
    );
    dispatch(popupActionClear());
  };

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleChange, values, errors, setFieldValue, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <fieldset>
            <FormBlock flatten>
              <Input
                type="text"
                name="title"
                id="title"
                placeholder="New folder"
                value={values.title}
                error={errors.title}
                onChange={handleChange}
                fontSize="large"
              />
            </FormBlock>
          </fieldset>

          {id && (
            <fieldset>
              <legend>Placement of folder</legend>
              <FormBlock>
                <MediaFolderDropdown
                  name="media_folder_id"
                  label="Where should the folder be located"
                  onChange={setFieldValue}
                  folderId={id}
                  initialValue={media_folder_id}
                />
              </FormBlock>
            </fieldset>
          )}

          <FormBlock hasInlineChildren isEnd>
            {hasMediaFoldersEditRights && !!id ? (
              <Button
                tag="button"
                type="button"
                size="medium"
                text="Remove folder"
                scheme="link"
                hasShadow={false}
                handler={() => {
                  dispatch(
                    setInfoBar({
                      message: 'Are you sure you want to remove this folder?',
                      action: [
                        {
                          text: 'Yes, Delete',
                          type: 'button',
                          color: 'blue',
                          handle: () =>
                            dispatch(
                              deleteMediaFolderAction(id, breadCrumbs, history),
                            ),
                        },
                        {
                          text: 'Cancel',
                          type: 'link',
                          handle: () => dispatch(infoBarHide())
                        }
                      ],
                      state: InfoBarState.ERROR,
                    }),
                  );
                }}
                disabled={isSubmitting}
              />
            ) : (
              <Button
                tag="button"
                type="button"
                size="medium"
                text="Cancel"
                scheme="link"
                hasShadow={false}
                handler={() => {
                  dispatch(popupActionClear());
                }}
                disabled={isSubmitting}
              />
            )}
            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Save and close"
              disabled={isSubmitting}
            />
          </FormBlock>
        </form>
      )}
    </Formik>
  );
};

export default MediaFolder;
