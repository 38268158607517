import React from 'react';
import classNames from 'classnames';

import './Container.scss';
import { ContainerProps } from './Container.types';

const Container: React.FC<ContainerProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={classNames('container', className)} {...props}>
      {children}
    </div>
  );
};

export default Container;
