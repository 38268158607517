import React, { useEffect, createRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, withRouter, matchPath } from 'react-router-dom';
import classNames from 'classnames';

import Container from 'components/container/Container';
import { RootState } from 'store/rootState';
import { SubNavigationProps } from './SubNavigation.types';

import './SubNavigation.scss';

require('intersection-observer');

const SubNavigation: React.FC<SubNavigationProps> = ({
  title,
  routes,
  ...props
}) => {
  const poolRef = createRef<HTMLDivElement>();
  const [isStuck, setStuck] = useState(false);
  const { location } = props;

  const mediaSelectionState = useSelector(
    (state: RootState) => state.media?.selectable,
  );
  const arrangementSelectionState = useSelector(
    (state: RootState) => state.arrangements?.selectable,
  );

  const mappedRoutes = routes.map(route => {
    return {
      ...route,
      Active: !!matchPath(location.pathname, route.Path),
    };
  });

  useEffect(() => {
    if (!poolRef.current) return () => {};
    const el = poolRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        const stuck = entry.intersectionRatio < 1;
        setStuck(stuck);
      },
      {
        threshold: [1],
      },
    );

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [poolRef, setStuck]);

  return (
    <div
      className={classNames('sub-navigation', {
        'sub-navigation--stuck': isStuck,
      })}
      ref={poolRef}
    >
      <Container>
        <ul className="sub-navigation__links">
          {title && <li className="sub-navigation__title">{title}</li>}

          {mappedRoutes.map(
            route =>
              route.ViewRights && (
                <li key={route.Key}>
                  {mediaSelectionState || arrangementSelectionState || route.Active ? (
                    <span
                      data-cy={`poolNavigation${route.Name}`}
                      className={classNames('sub-navigation__link', {
                        'sub-navigation__link--disabled': mediaSelectionState || arrangementSelectionState,
                        'sub-navigation__link--active': route.Active,
                      })}
                    >
                      {route.Name}
                    </span>
                  ) : (
                    <Link
                      to={route.Path}
                      className={classNames('sub-navigation__link')}
                      data-cy={`poolNavigation${route.Name}Active`}
                    >
                      {route.Name}
                    </Link>
                  )}
                </li>
              ),
          )}
        </ul>
      </Container>
    </div>
  );
};

export default withRouter(SubNavigation);
