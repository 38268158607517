import { SuperAdminActionTypes } from "./superAdminActions"

export const superAdminInitialState = {
  customerOverride: ''
}

const superAdminReducer = (state = superAdminInitialState, action: {
  type: string,
  payload: {
    customerOverride: string
  }
}) => {
  switch (action.type) {
    case SuperAdminActionTypes.SUPER_ADMIN_SET_CUSTOMER:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default superAdminReducer