import { Dispatch } from 'redux';

import { SetInfoBar, SetInfoBarShow } from './infoActions.types';

export const InfoActionTypes = {
  INFO_BAR_SET: '@@info/bar/set',
  INFO_BAR_CLEAR: '@@info/bar/clear',
  INFO_BAR_HIDE: '@@info/bar/hide',
  INFO_BAR_SHOW: '@@info/bar/show',
};

export const infoBarSet = ({
  message,
  state,
  timeout,
  action,
  show,
}: SetInfoBar) => ({
  type: InfoActionTypes.INFO_BAR_SET,
  payload: { message, state, timeout, action, show },
});

export const infoBarHide = () => ({
  type: InfoActionTypes.INFO_BAR_HIDE,
});

export const infoBarShow = () => ({
  type: InfoActionTypes.INFO_BAR_SHOW,
});

export const infoBarClear = () => ({
  type: InfoActionTypes.INFO_BAR_CLEAR,
});

export const setInfoBarShow = ({ show }: SetInfoBarShow) => (
  dispatch: Dispatch,
) => {
  dispatch(show ? infoBarShow() : infoBarHide());

  // clears the infoBar after hiding it
  if (!show) {
    setTimeout(() => {
      dispatch(infoBarClear());
    }, 300);
  }
};

export const setInfoBar = ({
  message,
  action,
  timeout,
  state,
  show,
}: SetInfoBar) => (dispatch: Dispatch) => {
  dispatch(
    infoBarSet({
      message,
      state,
      timeout,
      action,
      show,
    }),
  );
  dispatch(infoBarShow());
};
