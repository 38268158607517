import { LocationsActionTypes } from './locationsActions';

export const locationsInitialState = {
  request: false,
  locations: [],
};

const locationsReducer = (state = locationsInitialState, action) => {
  switch (action.type) {
    case LocationsActionTypes.LOCATIONS_REQUESTED:
      return { ...state, request: true };
    case LocationsActionTypes.LOCATIONS_SUCCEEDED:
      return {
        ...state,
        locations: action.payload,
        request: false,
      };
    case LocationsActionTypes.LOCATIONS_FAILED:
      return {
        ...locationsInitialState,
        request: false,
      };
    case LocationsActionTypes.LOCATION_ADDED:
      return {
        ...state,
        locations: [action.payload, ...state.locations],
      };
    case LocationsActionTypes.LOCATION_UPDATED:
      return {
        ...state,
        locations: state.locations.map(location => {
          if (location.id === action.payload.id) {
            return {
              ...action.payload,
            };
          }
          return location;
        }),
      };
    default:
      return state;
  }
};

export default locationsReducer;
