import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { reverse } from 'named-urls';

import { Api } from 'constants/Routes.enum';
import fetchData from 'store/fetchData';
import Dropdown from 'components/form/dropdown/Dropdown';

const LayoutDropdown = ({ poolId, dataCy, ...props }) => {
  const dispatch = useDispatch();
  const [layouts, setLayouts] = useState([]);
  const [loadingLayouts, setLoadingLayouts] = useState(true);

  useEffect(() => {
    setLoadingLayouts(true);

    const promise = dispatch(
      fetchData(reverse(Api.TEMPLATES_BY_POOL, { pool_id: poolId })),
    );

    promise
      .then(json => json.json())
      .then(({ templates }) => {
        const layouts = templates.map(template => {
          return {
            label: template.label,
            value: template.value,
          };
        });
        setLayouts(layouts);
      })
      .catch(err => {
        console.error(err);
        setLayouts([]);
      })
      .finally(() => {
        setLoadingLayouts(false);
      });

    return () => {
      setLoadingLayouts(false);
      setLayouts([]);
    };
  }, [dispatch, poolId]);

  return (
    <Dropdown
      {...props}
      dataCy={dataCy}
      values={layouts}
      isLoading={loadingLayouts}
      hasEmptyOption={false}
      placeholder="Select layout"
    />
  );
};

export default LayoutDropdown;
