import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import equal from 'fast-deep-equal';
import moment from 'moment-timezone';

import { RootState } from 'store/rootState';
import { popupActionClear } from 'store/popup/popupActions';
import { setInfoBarShow } from 'store/info/infoActions';
import { addLocationAction } from 'store/locations/locationsActions';
import {
  PopupStickyFooter,
  PopupStickyForm,
  PopupStickyHeader,
  PopupStickyMain,
} from 'components/popup/Popup';
import Icon from 'components/icon/Icon';
import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import { InputFontSize } from 'components/form/input/Input.enum';
import Dropdown from 'components/form/dropdown/Dropdown';
import Button from 'components/button/Button';
import {
  ButtonScheme,
  ButtonSize,
  ButtonTag,
} from 'components/button/Button.enum';
import ButtonGroup from 'components/button/ButtonGroup';
import SubmitButton from 'components/form/submit-button/SubmitButton';
import UserDropdown from 'components/user/dropdown/UserDropdown';
import { User, UserOption, LocationProps } from './Location.types';

const Location: React.FC<LocationProps> = ({ id, name, timezone, users }) => {
  const dispatch = useDispatch();
  const infoBar = useSelector((state: RootState) => state.info.bar);
  const [isSubmitting, setSubmitting] = useState(false);

  const { customerOverride } = useSelector((state: RootState) => state.superAdmin);

  const timezoneList = useMemo(() => {
    return moment.tz.names().map(name => {
      return {
        label: `${name} (UTC${moment.tz(name).format('Z')})`,
        value: name,
      };
    });
  }, []);

  const [initialForm, setInitialForm] = useState({
    name,
    timezone: timezone || 'UTC',
    users,
  });

  useEffect(() => {
    setInitialForm({
      name,
      timezone,
      users,
    });
  }, [id, name, timezone, users]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is a required field'),
    timezone: Yup.string().required('Timezone is a required field'),
  });

  const onCancel = () => {
    dispatch(popupActionClear());
    if (infoBar.show) {
      dispatch(setInfoBarShow({ show: false }));
    }
  };

  const onSubmit = (values: LocationProps) => {
    if (equal(values, initialForm)) {
      dispatch(popupActionClear());
      dispatch(setInfoBarShow({ show: false }));
      return;
    }

    setSubmitting(true);

    const locationData = new FormData();
    locationData.append('location[name]', values.name);
    locationData.append('location[timezone]', values.timezone);
    if (customerOverride) {
      locationData.append('location[customer_id]', customerOverride);
    }

    if (values.users && values.users.length > 0) {
      values.users.forEach(user => {
        locationData.append('location[user_ids][]', user.id.toString());
      });
    } else {
      locationData.append('location[user_ids][]', '');
    }

    dispatch(addLocationAction({ locationData, id, setSubmitting }));
  };

  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: initialForm,
    validationSchema,
    onSubmit,
  });

  return (
    <PopupStickyForm onSubmit={handleSubmit}>
      <PopupStickyHeader>
        <fieldset className="fieldset--flatten">
          <FormBlock flatten>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Enter the name of the location here"
              value={values.name}
              error={touched.name && errors.name}
              onChange={handleChange}
              fontSize={InputFontSize.LARGE}
              suffix={<Icon name="edit" />}
              disabled={isSubmitting}
            />
          </FormBlock>
        </fieldset>
      </PopupStickyHeader>

      <PopupStickyMain>
        <fieldset>
          <legend>Location details</legend>

          <FormBlock style={{ width: 460 }} flatten>
            <Dropdown
              id="timezone"
              name="timezone"
              label="Timezone"
              values={timezoneList}
              value={values.timezone}
              error={touched.timezone && errors.timezone}
              onChange={setFieldValue}
              hasEmptyOption={false}
            />
          </FormBlock>
        </fieldset>

        <fieldset>
          <legend>User access</legend>

          <FormBlock>
            <UserDropdown
              id="users"
              name="users"
              label="Who needs to access this location"
              value={values.users}
              placeholder="Select users"
              error={touched.users && errors.users}
              width={380}
              onChange={(values: User[]) => {
                setFieldValue(
                  'users',
                  [].slice.call(values).map((option: UserOption) => ({
                    id: option.value,
                    name: option.label,
                  })),
                );
              }}
              listSelected
              multiple
            />
          </FormBlock>
        </fieldset>
      </PopupStickyMain>

      <PopupStickyFooter>
        <FormBlock hasInlineChildren flatten>
          <Button
            tag={ButtonTag.BUTTON}
            size={ButtonSize.MEDIUM}
            text="Cancel"
            scheme={ButtonScheme.LINK}
            hasShadow={false}
            handler={() => {
              onCancel();
            }}
            disabled={isSubmitting}
          />
          <ButtonGroup>
            <SubmitButton canSave disabled={isSubmitting} />
          </ButtonGroup>
        </FormBlock>
      </PopupStickyFooter>
    </PopupStickyForm>
  );
};

export default Location;
