import PropTypes from 'prop-types';
import { MediaTypes } from 'constants/types/mediaTypes';

export const ArrangementMediaItemProps = PropTypes.arrayOf(
  PropTypes.shape({
    active: PropTypes.bool,
    approval_state: PropTypes.oneOf([
      'pending_position',
      'pending_approval',
      'pending_removal',
      'approved',
    ]),
    area_number: PropTypes.number,
    arrangement_id: PropTypes.number,
    arrangement_media_item_id: PropTypes.number,
    created_at: PropTypes.string,
    customer_id: PropTypes.number,
    deleted_at: PropTypes.string,
    filename: PropTypes.string,
    filetype: MediaTypes,
    id: PropTypes.number,
    img_file_content_type: PropTypes.string,
    img_file_file_name: PropTypes.string,
    img_file_file_size: PropTypes.number,
    img_file_updated_at: PropTypes.string,
    media_file_url: PropTypes.string,
    media_source: PropTypes.string,
    media_folder_id: PropTypes.number,
    muted: PropTypes.string,
    name: PropTypes.string,
    pending_approval: PropTypes.bool,
    pending_removal: PropTypes.bool,
    position: PropTypes.number,
    updated_at: PropTypes.string,
    used_count: PropTypes.number,
  }),
);

export const ArrangementMediaItemsProps = PropTypes.shape({
  items: ArrangementMediaItemProps,
});

export const ArrangementMediaProps = PropTypes.objectOf(
  PropTypes.shape({
    [PropTypes.number]: PropTypes.shape({
      duration: PropTypes.shape({
        seconds: PropTypes.number,
        pending_approval: PropTypes.bool,
      }),
      ArrangementMediaItemsProps,
    }),
  }),
);

export const ArrangementPendingChangesProps = PropTypes.shape({
  name: PropTypes.string,
  template_id: PropTypes.number,
});
