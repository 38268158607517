import { History } from 'history'

import { Api } from 'constants/Routes.enum';
import fetchData from 'store/fetchData';
import { setInfoBar } from 'store/info/infoActions';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Pools, RootState } from 'store/rootState';
import HistoryLocationState from 'constants/HistoryState';

export const PoolsActionTypes = {
  POOLS_REQUESTED: '@@pools/requested',
  POOLS_SUCCEEDED: '@@pools/success',
  POOLS_FAILED: '@@pools/error',
  POOLS_CLEARED: '@@pools/cleared',
};

export const poolsRequested = () => ({
  type: PoolsActionTypes.POOLS_REQUESTED,
});

export const poolsSucceeded = (pools: Pools) => ({
  type: PoolsActionTypes.POOLS_SUCCEEDED,
  payload: pools,
});

export const poolsFailed = () => ({
  type: PoolsActionTypes.POOLS_FAILED,
});

export const poolsCleared = () => ({
  type: PoolsActionTypes.POOLS_CLEARED,
});

export const fetchPoolsAction = (filterValues: {
  name?: string;
  location?: string;
  customer?: string;
}, history: History<HistoryLocationState>): ThunkAction<void, RootState, any, AnyAction> => (dispatch, state) => {
  dispatch(poolsRequested());

  const url = Api.POOLS;
  const isFiltering = Object.values(filterValues).some(value => !!value);
  const params = new URLSearchParams();

  if (filterValues.name) {
    params.append('name', filterValues.name);
  }
  if (filterValues.location) {
    params.append('location_id', filterValues.location);
  }
  if (filterValues.customer) {
    params.append('customer_id', filterValues.customer)
  }

  const promise = dispatch(
    fetchData(`${url}${isFiltering ? `?${params.toString()}` : ''}`),
  );
  promise
    .then(json => json.json())
    .then(result => {
      const { state } = history.location;
      if (state && state.groupDeleted) {
        const clearHistoryGroupDeletedState = {
          state: {
            groupDeleted: undefined,
          },
        };
        history.replace(clearHistoryGroupDeletedState);
        dispatch(
          setInfoBar({
            message: 'Group is removed',
            timeout: 5000,
            state: 'check',
          }),
        );
      }
      dispatch(poolsSucceeded(result));
    })
    .catch(() => {
      dispatch(poolsFailed());
    });
};
