import ApprovalStates from 'constants/ApprovalStates.enum';
import { TriggerActionTypes } from './triggersActions';

export const triggersInitialState = {
  request: false,
  triggers: [],
  error: null,
};

const triggersReducer = (state = triggersInitialState, action) => {
  switch (action.type) {
    case TriggerActionTypes.TRIGGERS_REQUESTED:
      return { ...triggersInitialState, request: true };
    case TriggerActionTypes.TRIGGERS_SUCCEEDED:
      return { triggers: [...action.payload], request: false, error: null };
    case TriggerActionTypes.TRIGGERS_FAILED:
      return { ...triggersInitialState, request: false, error: action.payload };
    case TriggerActionTypes.TRIGGER_ADDED:
      return {
        ...state,
        triggers: [action.payload, ...state.triggers],
      };
    case TriggerActionTypes.TRIGGER_EDITED:
      return {
        ...state,
        triggers: [
          action.payload,
          ...state.triggers.filter(trigger => trigger.id !== action.payload.id),
        ],
      };
    case TriggerActionTypes.TRIGGERS_CLEARED:
      return triggersInitialState;
    case TriggerActionTypes.TRIGGER_DELETED:
      return {
        ...state,
        triggers: state.triggers.filter(
          trigger => trigger.id !== action.payload,
        ),
      };

    case TriggerActionTypes.TRIGGER_ACTIVATED:
      return {
        ...state,
        triggers: state.triggers.map(trigger => {
          if (trigger.id === action.payload.id) {
            return {
              ...trigger,
              active: action.payload.active,
            };
          }
          return trigger;
        }),
      };

    case TriggerActionTypes.TRIGGER_APPROVED:
      return {
        ...state,
        triggers: state.triggers.map(trigger => {
          if (trigger.id === action.payload.id) {
            return {
              ...trigger,
              ...action.payload,
              pending_approval:
                action.payload.approval_state !== ApprovalStates.APPROVED,
            };
          }

          return trigger;
        }),
      };
    default:
      return state;
  }
};

export default triggersReducer;
