import filesize from 'filesize';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import LabelValueList from 'components/list/labelValueList/LabelValueList';

const MediaPreviewDetails = ({
  id,
  filename,
  file_size,
  created_at,
  breadcrumb,
  children,
}) => {
  const mediaDetails = () => {
    const list = [];

    if (filename) {
      list.push({
        label: 'Original file name',
        value: filename,
      });
    }
    if (file_size) {
      list.push({
        label: 'Original file name',
        value: filesize(file_size),
      });
    }
    if (breadcrumb !== '') {
      list.push({
        label: 'Folder',
        value: breadcrumb
      });
    }
    if (created_at) {
      list.push({
        label: 'Created at',
        value: moment(created_at).format('DD MMMM YYYY HH:mm'),
      });
    }

    return list;
  };

  return (
    <fieldset>
      <legend>Media details</legend>
      <LabelValueList
        values={mediaDetails()}
        listId={id}
        flatten
        loading={!mediaDetails()}
        loadingItemLength={2}
      />
      {children}
    </fieldset>
  );
};

MediaPreviewDetails.propTypes = {
  id: PropTypes.number,
  filename: PropTypes.string,
  file_size: PropTypes.string,
  created_at: PropTypes.string,
  breadcrumb: PropTypes.string,
  children: PropTypes.node,
};

MediaPreviewDetails.defaultProps = {
  id: null,
  filename: '',
  file_size: '',
  created_at: '',
  breadcrumb: '',
  children: [],
};

export default MediaPreviewDetails;
