import { RRule } from 'rrule';
import { RecurringOptions } from './Recurring.options';

const getRepeatFromRrule = rrule => {
  if (rrule === undefined || rrule === null) return RecurringOptions.NO_REPEAT;

  const rruleOptions = rrule && RRule.fromString(rrule).options;
  const { freq, bymonthday, bysetpos, byweekday } = rruleOptions;

  switch (true) {
    case freq === RRule.DAILY:
      return RecurringOptions.DAILY;
    case freq === RRule.WEEKLY:
      return RecurringOptions.WEEKLY;
    case freq === RRule.MONTHLY &&
      bysetpos !== null &&
      bysetpos.length > 0 &&
      byweekday !== null &&
      byweekday.length > 0:
      return RecurringOptions.MONTHLY_DAY;
    case freq === RRule.MONTHLY && bymonthday !== null && bymonthday.length > 0:
      return RecurringOptions.MONTHLY_NUMBER;
    default:
      return RecurringOptions.NO_REPEAT;
  }
};

export default getRepeatFromRrule;
