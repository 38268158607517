import fetchData from 'store/fetchData';
import { reverse } from 'named-urls';

import PopupTypes from 'constants/PopupTypes.enum';
import UserRight from 'constants/UserRight.enum';
import ApprovalStates from 'constants/ApprovalStates.enum';
import { Api } from 'constants/Routes.enum';

import { infoBarText } from 'components/infoBar/InfoBar.json';
import { ButtonColor } from 'components/button/Button.enum';

import { setInfoBar, setInfoBarShow } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import {
  popupActionClear,
  popupActionSet,
  popupActionUpdate,
} from 'store/popup/popupActions';
import { hasAdminRights, hasUserRights } from 'store/auth/hasUserRights';

export const EventsActionTypes = {
  EVENTS_REQUESTED: '@@events/requested',
  EVENTS_SUCCEEDED: '@@events/success',
  EVENTS_FAILED: '@@events/error',
  EVENTS_ADDED: '@@events/added',
  EVENTS_DELETED: '@@events/deleted',
  EVENTS_CLEARED: '@@events/cleared',
  EVENTS_APPROVED: '@@events/approved',
  EVENTS_ACTIVATED: '@@events/activated',
};

export const eventsRequested = () => ({
  type: EventsActionTypes.EVENTS_REQUESTED,
});

export const eventsSucceeded = events => ({
  type: EventsActionTypes.EVENTS_SUCCEEDED,
  payload: events,
});

export const eventsFailed = () => ({
  type: EventsActionTypes.EVENTS_FAILED,
});

export const eventsAdded = event => ({
  type: EventsActionTypes.EVENTS_ADDED,
  payload: event,
});

export const eventsDeleted = id => ({
  type: EventsActionTypes.EVENTS_DELETED,
  payload: id,
});

export const eventsCleared = () => ({
  type: EventsActionTypes.EVENTS_CLEARED,
});

export const eventsApproved = event => ({
  type: EventsActionTypes.EVENTS_APPROVED,
  payload: event,
});

export const eventsActivated = (id, active) => ({
  type: EventsActionTypes.EVENTS_ACTIVATED,
  payload: {
    id,
    active,
  },
});

export const clearHistoryFromEventState = (history, locationState) => {
  if (locationState) {
    const clearHistoryFromEventState = {
      state: {
        fromEvent: undefined,
        fromEventFormFormValues: undefined,
        newEvent: undefined,
      },
    };
    history.replace(clearHistoryFromEventState);
  }
};

export const approvalEventAction = (id, url) => dispatch => {
  const promise = dispatch(
    fetchData(reverse(url, { id }), {
      method: 'POST',
    }),
  );

  promise
    .then(json => json.json())
    .then(result => {
      if (result.deleted) {
        dispatch(eventsDeleted(id));
        dispatch(popupActionClear());
        dispatch(setInfoBarShow({ show: false }));
        return;
      }

      const { scheduled_event, message } = result;

      if (scheduled_event) {
        dispatch(eventsApproved(scheduled_event));
      }

      const newEvent = {
        ...scheduled_event,
        title: scheduled_event.title,
        startDate: scheduled_event.start_at,
        endDate: scheduled_event.end_at,
        arrangement: scheduled_event.arrangement_id,
        rrule: scheduled_event.rrule,
        event_occurances: scheduled_event.event_occurances,
        pending_approval:
          scheduled_event.approval_state !== ApprovalStates.APPROVED,
      };

      if (scheduled_event.approval_state === ApprovalStates.APPROVED) {
        newEvent.pending_changes = null;
      }

      dispatch(popupActionUpdate(newEvent));
      dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
    });
};

export const deleteEventAction = id => dispatch => {
  // call delete endpoint
  const promise = dispatch(
    fetchData(reverse(Api.EVENTS_DELETE, { id }), { method: 'DELETE' }),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message } = result;
      // media is deleted successfully - clear popup and show message
      dispatch(eventsDeleted(id));
      dispatch(popupActionClear());

      dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
    })
    .catch(error => {
      // event could not be deleted - show error
      dispatch(
        setInfoBar({
          message: error,
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const editEventAction = id => async dispatch => {
  const isAdmin = dispatch(hasAdminRights());
  const hasEventEditRights = dispatch(hasUserRights(UserRight.EVENTS_EDIT));

  const promise = dispatch(fetchData(reverse(Api.EVENTS_DETAIL, { id })));
  promise
    .then(json => json.json())
    .then(({ scheduled_event }) => {
      dispatch(
        popupActionSet(PopupTypes.EVENT, {
          id: scheduled_event.id,
          title: scheduled_event.title,
          startDate: scheduled_event.start_at,
          endDate: scheduled_event.end_at,
          rrule: scheduled_event.rrule,
          arrangement: scheduled_event.arrangement,
          active: scheduled_event.active,
          roundTime: false,
          pending_approval: scheduled_event.pending_approval,
          pending_changes: scheduled_event?.pending_changes,
          priority: scheduled_event.priority,
        }),
      );

      if (scheduled_event.pending_approval) {
        if (isAdmin) {
          dispatch(
            setInfoBar({
              message: infoBarText.approval.message,
              state: InfoBarState.WARNING,
              timeout: 0,
              action: [
                {
                  text: infoBarText.approval.buttons.approve,
                  handle: () => {
                    dispatch(approvalEventAction(id, Api.EVENTS_APPROVE));
                  },
                  color: ButtonColor.GREEN,
                },
                {
                  text: infoBarText.approval.buttons.reject,
                  handle: () => {
                    dispatch(approvalEventAction(id, Api.EVENTS_REJECT));
                  },
                  color: ButtonColor.RED,
                },
              ],
            }),
          );
        } else {
          dispatch(
            setInfoBar({
              message: 'The event is waiting for approval',
              state: InfoBarState.WARNING,
            }),
          );
        }
      } else if (!hasEventEditRights) {
        dispatch(
          setInfoBar({
            message: 'You only have the rights to view this event',
            state: InfoBarState.WARNING,
          }),
        );
      }
    })
    .catch(error => {
      console.error(error);
      dispatch(
        setInfoBar({
          message: error,
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const addEventAction = (
  event,
  id,
  setSubmitting,
  locationState,
  history,
) => dispatch => {
  const isEditing = !!id;

  // create or edit event
  const promise = dispatch(
    fetchData(
      isEditing ? reverse(Api.EVENTS_PATCH, { id }) : Api.EVENTS_POST,
      {
        method: isEditing ? 'PATCH' : 'POST',
        body: event,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message, scheduled_event } = result;
      const scheduledEvent =
        typeof scheduled_event === 'string'
          ? JSON.parse(scheduled_event)
          : scheduled_event;

      // successfully added or edited an event
      dispatch(popupActionClear());
      setSubmitting(false);
      clearHistoryFromEventState(history, locationState);
      dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));

      if (isEditing) {
        // remove existing event from state to avoid duplicates
        dispatch(eventsDeleted(id));
      }
      // add new event to state

      dispatch(
        eventsAdded({
          ...scheduledEvent,
          pending_approval:
            scheduledEvent.approval_state === ApprovalStates.PENDING,
        }),
      );
    })
    .catch(err => {
      console.error(err);
      setSubmitting(false);
      dispatch(
        setInfoBar({
          message: 'Failed to add new event',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const toggleActiveEventAction = (
  id,
  isActive,
  setActive,
  pending_approval,
) => dispatch => {
  if (!pending_approval) {
    const url = isActive ? Api.EVENTS_DEACTIVATE : Api.EVENTS_ACTIVATE;

    const promise = dispatch(
      fetchData(reverse(url, { id }), {
        method: 'POST',
      }),
    );
    promise
      .then(json => json.json())
      .then(result => {
        const { scheduled_event, message } = result;

        setActive(scheduled_event.active);
        dispatch(eventsActivated(id, scheduled_event.active));
        dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
      });
  }
};

let abortController = new AbortController();

export const fetchEventsAction = (
  poolId,
  startDate,
  endDate,
) => async dispatch => {
  abortController.abort('Next event action has been requested'); // Cancel the previous request
  abortController = new AbortController();

  dispatch(eventsRequested());

  const dateParams = new URLSearchParams({
    start_date: startDate,
    end_date: endDate,
  });

  const promise = dispatch(
    fetchData(
      `${reverse(Api.EVENTS_BY_POOL, {
        pool_id: poolId,
      })}?${dateParams.toString()}`,
      {},
      true,
      abortController.signal,
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const events = result.pool.scheduled_events;
      dispatch(eventsSucceeded(events));
    })
    .catch(() => {
      dispatch(eventsFailed());
    });
};
