import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import RichTextEditor from 'components/form/rich-text-editor/RichTextEditor';
import SubmitButton from 'components/form/submit-button/SubmitButton';
import ColorPickerInput from 'components/form/colorPicker/ColorPickerInput';
import MediaItemTypes from 'constants/MediaItemTypes.enum';
import fetchData from 'store/fetchData';
import { Paths, Api } from 'constants/Routes.enum';
import {
  infoBarHide,
  setInfoBar,
  setInfoBarShow,
} from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import {
  addMediaAction,
  deleteMediaAction,
  mediaDeleted,
} from 'store/media/mediaActions';
import UserRight from 'constants/UserRight.enum';
import { hasUserRights } from 'store/auth/hasUserRights';
import equal from 'fast-deep-equal';
import { reverse } from 'named-urls';
import { popupActionClear } from 'store/popup/popupActions';
import MediaPreviewDetails from 'components/popup/media-preview/MediaPreviewDetails';
import MediaPlacementFieldset from 'components/popup/media-preview/MediaPlacementFieldset';
import MediaShowsOverviewFieldset from 'components/popup/media-preview/MediaShowsOverviewFieldset';
import {
  PopupStickyForm,
  PopupStickyHeader,
  PopupStickyMain,
  PopupStickyFooter,
} from 'components/popup/Popup';

const MediaRichText = ({
  name,
  rich_text,
  id,
  shows,
  folder_breadcrumbs,
  folder_id,
  created_at,
}) => {
  const dispatch = useDispatch();
  const authToken = useSelector(state => state.auth.auth_token);
  const { folder_info } = useSelector(state => state.media);
  const hasMediaEditRights = dispatch(hasUserRights(UserRight.MEDIA_EDIT));
  const infoBar = useSelector(state => state.info.bar);
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();

  const [initialValues, setInitialValues] = useState({
    name,
    richText: rich_text.content || '',
    backgroundColor: rich_text.background_color || '#ffffff',
    mediaFolderId: folder_id,
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is a required field'),
    richText: Yup.string().test(
      'cannot-be-none',
      'Rich text is a required field',
      value => {
        if (value === undefined) {
          return false;
        }
        const strippedHtml = value.replace(/<[^>]+>/g, '');
        return strippedHtml.length > 1;
      },
    ),
    backgroundColor: Yup.string().required('Background is a required field'),
  });

  const onSubmit = values => {
    if (equal(values, initialValues)) {
      dispatch(popupActionClear());
      return;
    }
    setSubmitting(true);

    const mediaData = new FormData();
    mediaData.append('media[type]', MediaItemTypes.RICH_TEXT_MEDIA_ITEM);
    mediaData.append('media[name]', values.name);
    mediaData.append('media[content]', values.richText);
    mediaData.append('media[background_color]', values.backgroundColor);

    if (id) {
      mediaData.append(
        'media[media_folder_id]',
        values.mediaFolderId === 0 ? '' : values.mediaFolderId,
      );
    } else if (folder_info) {
      mediaData.append('media[media_folder_id]', folder_info.id);
    }

    const promise = dispatch(
      fetchData(id ? reverse(Api.MEDIA_DETAIL, { id }) : Api.MEDIA_POST, {
        method: id ? 'PATCH' : 'POST',
        headers: {
          Authorization: authToken,
        },
        body: mediaData,
      }),
    );

    promise
      .then(json => json.json())
      .then(result => {
        setSubmitting(false);
        dispatch(mediaDeleted(id));
        if (result.media) {
          if (
            (!values.mediaFolderId && values.mediaFolderId !== 0) ||
            values.mediaFolderId === folder_id
          ) {
            dispatch(addMediaAction(result));
          }
        }

        if (values.mediaFolderId >= 0 && values.mediaFolderId !== folder_id) {
          dispatch(
            setInfoBar({
              message: 'The media item has been repositioned',
              action: [
                {
                  handle: () => {
                    dispatch(setInfoBarShow({ show: false }));
                    history.push({
                      pathname:
                        values.mediaFolderId === 0
                          ? Paths.MEDIA
                          : reverse(Paths.MEDIA_FOLDER, {
                              folder_id: values.mediaFolderId,
                            }),
                    });
                  },
                  text: 'Go to folder',
                },
              ],
              timeout: 5000,
              state: 'check',
            }),
          );
        } else {
          dispatch(
            setInfoBar({
              message: result.message,
              timeout: 5000,
              state: 'check',
            }),
          );
        }
      })
      .catch(() => {
        setSubmitting(false);
        if (id) {
          dispatch(
            setInfoBar({
              message: 'Could not update media item',
              state: InfoBarState.ERROR,
              timeout: 5000,
            }),
          );
        } else {
          dispatch(
            setInfoBar({
              message: 'Could not add media item',
              state: InfoBarState.ERROR,
              timeout: 5000,
            }),
          );
        }
      });

    dispatch(popupActionClear());
  };

  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    initialTouched,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    setInitialValues({
      name,
      richText: rich_text.content || '',
      backgroundColor: rich_text.background_color || '#ffffff',
      mediaFolderId: folder_id,
    });
  }, [name, rich_text, folder_id]);

  return (
    <PopupStickyForm onSubmit={handleSubmit}>
      <PopupStickyHeader>
        <fieldset>
          <Input
            type="text"
            name="name"
            id="name"
            placeholder="Enter a name here"
            fontSize="large"
            value={values.name}
            suffix={<Icon name="edit" />}
            error={touched.name && errors.name}
            onChange={handleChange}
          />
        </fieldset>
      </PopupStickyHeader>
      <PopupStickyMain>
        <fieldset>
          <legend>Rich text</legend>
          <RichTextEditor
            name="richText"
            value={values.richText}
            onChange={setFieldValue}
            error={touched.richText && errors.richText}
            backgroundColor={values.backgroundColor}
          />
        </fieldset>
        <fieldset>
          <legend>Background</legend>
          <FormBlock halfWidth flatten>
            <ColorPickerInput
              name="backgroundColor"
              value={values.backgroundColor}
              label="Choose your background color"
              onChange={setFieldValue}
              error={errors.backgroundColor}
              disabled={!hasMediaEditRights}
            />
          </FormBlock>
        </fieldset>

        {id && (
          <>
            <MediaPreviewDetails
              id={id}
              created_at={created_at}
              breadcrumb={folder_breadcrumbs}
            />
            <MediaPlacementFieldset
              name="mediaFolderId"
              setFieldValue={setFieldValue}
              folder_id={folder_id}
              disabled={!hasMediaEditRights}
            />
          </>
        )}

        {shows && shows.length > 0 && (
          <MediaShowsOverviewFieldset shows={shows} />
        )}
      </PopupStickyMain>

      <PopupStickyFooter>
        <FormBlock hasInlineChildren flatten>
          {(id && hasMediaEditRights && (
            <Button
              tag="button"
              type="button"
              size="medium"
              text="Delete"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(
                  setInfoBar({
                    message: 'Are you sure you want to delete this media item?',
                    action: [
                      {
                        text: 'Yes, Delete',
                        type: 'button',
                        color: 'blue',
                        handle: () =>
                          dispatch(deleteMediaAction({ id, shows })),
                      },
                      {
                        text: 'Cancel',
                        type: 'link',
                        handle: () => dispatch(infoBarHide()),
                      },
                    ],
                    state: InfoBarState.ERROR,
                  }),
                );
              }}
              disabled={isSubmitting}
            />
          )) || (
            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Cancel"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(popupActionClear());
                if (infoBar.show) {
                  dispatch(infoBarHide());
                }
              }}
              disabled={isSubmitting}
            />
          )}

          <SubmitButton
            canSave={initialTouched && !isSubmitting}
            disabled={isSubmitting || !hasMediaEditRights}
          />
        </FormBlock>
      </PopupStickyFooter>
    </PopupStickyForm>
  );
};

MediaRichText.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  rich_text: PropTypes.shape({
    content: PropTypes.string,
    background_color: PropTypes.string,
  }),
  folder_id: PropTypes.number,
  folder_breadcrumbs: PropTypes.string,
  created_at: PropTypes.string,
  shows: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      id: PropTypes.number,
      name: PropTypes.string,
      pending_approval: PropTypes.bool,
    }),
  ),
};

MediaRichText.defaultProps = {
  id: null,
  name: '',
  rich_text: {
    content: '',
    background_color: '#ffffff',
  },
  folder_id: null,
  folder_breadcrumbs: '',
  created_at: '',
  shows: null,
};

export default MediaRichText;
