import React, { Fragment } from 'react';

import Skeleton from 'components/skeleton/Skeleton';
import { SkeletonTag, SkeletonType } from 'components/skeleton/Skeleton.enum';

const CalendarListSkeleton = () => {
  return (
    <div className="calendar calendar__skeleton-list">
      {[...Array(4)].map((_, i) => (
        <Fragment key={i}>
          <Skeleton
            type={SkeletonType.HEADING}
            tag={SkeletonTag.H2}
            styling={{ width: 182, marginBottom: 16 }}
          />

          <div className="calendar__skeleton-list__table">
            {[...Array(i % 2 === 0 ? 1 : 2)].map((_, j) => (
              <div key={j} className="calendar__skeleton-list__item">
                <div className="calendar__skeleton-list__time">
                  <Skeleton
                    type={SkeletonType.TEXT}
                    styling={{ width: 80, height: 12 }}
                  />
                </div>
                <div className="calendar__skeleton-list__title">
                  <Skeleton
                    type={SkeletonType.TEXT}
                    styling={{ width: 186, height: 12 }}
                  />
                </div>
              </div>
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default CalendarListSkeleton;
