import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { CSSTransition } from 'react-transition-group';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import fetchData from 'store/fetchData';
import { popupActionClear } from 'store/popup/popupActions';
import { addMediaAction } from 'store/media/mediaActions';
import { hasUserRights } from 'store/auth/hasUserRights';
import { infoBarHide, setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';

import { Api } from 'constants/Routes.enum';
import UserRights from 'constants/UserRight.enum';
import RegexOptions from 'constants/RegexOptions';
import MediaItemTypes from 'constants/MediaItemTypes.enum';

import Input from 'components/form/input/Input';
import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import MediaTypeDropdown from 'components/media/type-dropdown/MediaTypeDropdown';

const MediaUrl = ({ title }) => {
  const dispatch = useDispatch();
  const authToken = useSelector(state => state.auth.auth_token);
  const { folder_info } = useSelector(state => state.media);

  const infoBar = useSelector(state => state.info.bar);
  const hasMediaEditRights = dispatch(hasUserRights(UserRights.MEDIA_EDIT));
  const [isSubmitting, setSubmitting] = useState(false);
  const initialForm = {
    name: title || '',
    mediaType: '',
    url: '',
  };

  const validationScheme = Yup.object().shape({
    name: Yup.string(),
    mediaType: Yup.string().required('Media Type is a required field'),
    url: Yup.string()
      .matches(RegexOptions.URL, 'URL must be a valid URL')
      .required('URL is a required field'),
  });

  const onSubmit = values => {
    setSubmitting(true);

    const mediaData = new FormData();
    mediaData.append('media[type]', MediaItemTypes.URL_MEDIA_ITEM);
    mediaData.append('media[name]', values.name || values.url);

    // Media should no longer belong to a pool
    // mediaData.append('media[pool_ids]', [pool.id]);

    if (values.mediaType) {
      mediaData.append('media[media_item_type_id]', values.mediaType);

      if (values.url) {
        mediaData.append('media[source_url]', values.url);
      }
    }

    if (folder_info) {
      mediaData.append('media[media_folder_id]', folder_info.id);
    }

    const promise = dispatch(
      fetchData(Api.MEDIA_POST, {
        method: 'POST',
        headers: {
          Authorization: authToken,
        },
        body: mediaData,
      }),
    );

    promise
      .then(json => json.json())
      .then(result => {
        setSubmitting(false);
        dispatch(addMediaAction(result));
      })
      .catch(() => {
        setSubmitting(false);
        dispatch(
          setInfoBar({
            message: 'Could not add media item',
            state: InfoBarState.ERROR,
            timeout: 5000,
          }),
        );
      });
  };

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationScheme}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        initialTouched,
        errors,
        touched,
      }) => (
        <form onSubmit={handleSubmit} className="form">
          <fieldset>
            <legend>Create a link</legend>
            <FormBlock>
              <Input
                type="text"
                name="name"
                id="name"
                label="Name link"
                placeholder="Write the link name here"
                value={values.name}
                error={errors.name}
                onChange={handleChange}
                scheme="white"
                disabled={!hasMediaEditRights}
              />
            </FormBlock>

            <FormBlock flatten={!values.mediaType}>
              <MediaTypeDropdown
                label="Select a url type"
                name="mediaType"
                id="media_type"
                onChange={setFieldValue}
                value={values.mediaType}
                error={touched.mediaType && errors.mediaType}
                disabled={!hasMediaEditRights}
                placeholder="Select a url type"
              />
            </FormBlock>

            <CSSTransition
              in={!!values.mediaType}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <FormBlock flatten>
                <Input
                  type="text"
                  label="Paste the link"
                  name="url"
                  id="url"
                  placeholder="Paste the link here"
                  scheme="white"
                  disabled={!hasMediaEditRights}
                  value={values.url}
                  onChange={handleChange}
                  error={touched.url && errors.url}
                />
              </FormBlock>
            </CSSTransition>
          </fieldset>

          <FormBlock hasInlineChildren isEnd>
            <Button
              tag="button"
              type="button"
              size="medium"
              text="Cancel"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(popupActionClear());
                if (infoBar.show) {
                  dispatch(infoBarHide());
                }
              }}
              disabled={isSubmitting}
            />
            <Button
              tag="button"
              type="submit"
              size="medium"
              text={initialTouched ? 'Save and close' : 'Close'}
              disabled={!hasMediaEditRights || isSubmitting}
            />
          </FormBlock>
        </form>
      )}
    </Formik>
  );
};

MediaUrl.propTypes = {
  title: PropTypes.string,
};

MediaUrl.defaultProps = {
  title: '',
};

export default MediaUrl;
