import { RRule } from 'rrule';

export const RecurringOptions = {
  NO_REPEAT: 'no-repeat',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY_DAY: 'monthly_day',
  MONTHLY_NUMBER: 'monthly_number',
};

export const MonthlyIntervalOptions = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4,
  LAST: -1,
};

export const dayList = [
  { label: 'Monday', value: RRule.MO.weekday },
  { label: 'Tuesday', value: RRule.TU.weekday },
  { label: 'Wednesday', value: RRule.WE.weekday },
  { label: 'Thursday', value: RRule.TH.weekday },
  { label: 'Friday', value: RRule.FR.weekday },
  { label: 'Saturday', value: RRule.SA.weekday },
  { label: 'Sunday', value: RRule.SU.weekday },
];
