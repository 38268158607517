import React from 'react';
import classNames from 'classnames';

import ArrangementLayout from 'components/arrangement/layout/ArrangementLayout';
import Icon from 'components/icon/Icon';
import IconButton from 'components/button/IconButton';
import Status from 'components/status/Status';

import { ButtonSelectShowProps } from './ButtonSelectShow.types';
import './ButtonSelectShow.scss';

const ButtonSelectShow: React.FC<ButtonSelectShowProps> = ({
  disabled = false,
  error,
  onClick,
  pendingApproval = false,
  selected = false,
  arrangement,
  onClearSelection,
}) => {
  return (
    <>
      {selected ? (
        <div
          className={classNames(
            'button-select-show button-select-show--selected',
            {
              'button-select-show--disabled': disabled,
              'button-select-show--selected--changed': pendingApproval,
            },
          )}
        >
          <div className="button-select-show--selected__show-layout-container">
            <ArrangementLayout
              templateId={arrangement?.template?.id ?? arrangement?.template_id}
              className="button-select-show--selected__show-layout"
              templateObject={arrangement?.template}
            />
          </div>

          <div className="button-select-show--selected__text-container">
            <div className="button-select-show--selected__show-name">
              {arrangement && arrangement?.name}
            </div>
            <Status
              isActive={arrangement && arrangement.active}
              needsApproval={arrangement && arrangement.pending_approval}
            />
          </div>
          <IconButton
            disabled={disabled}
            iconSize="x-small"
            iconName="cross"
            className="button-select-show--selected__button-delete"
            onClick={!disabled ? onClearSelection : undefined}
          />
        </div>
      ) : (
        <>
          <button
            className={classNames('button-select-show', {
              'button-select-show--disabled': disabled,
            })}
            type="button"
            disabled={disabled}
            onClick={!disabled ? onClick : undefined}
          >
            <div className="button-select-show__prefix">
              <Icon name="imageSmall" size="small" />
            </div>
            <div className="button-select-show__text">Select a show</div>
            <div className="button-select-show__suffix">
              <Icon name="download" size="small" />
            </div>
            {error && <span className="form__container-error" />}
          </button>
          {error && typeof error === 'string' && (
            <p className="form__error">{error}</p>
          )}
        </>
      )}
    </>
  );
};

export default ButtonSelectShow;
