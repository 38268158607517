import fetchData from 'store/fetchData';
import { Paths, Api } from 'constants/Routes.enum';
import { reverse } from 'named-urls';

import { setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';

import { popupActionClear, popupActionSet } from 'store/popup/popupActions';
import PopupTypes from 'constants/PopupTypes.enum';
import { hasSuperAdminRights, hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRight.enum';

export const DevicesActionTypes = {
  DEVICES_REQUESTED: '@@devices/requested',
  DEVICES_SUCCEEDED: '@@devices/success',
  DEVICES_FAILED: '@@devices/error',
  DEVICES_CLEARED: '@@devices/cleared',
  DEVICES_UPDATED: '@@devices/updated',
  DEVICE_UNASSIGNED: '@@devices/unassigned',
  DEVICE_UNASSIGNED_IN_GROUP: '@@devices/unassigned-in-group',
  DEVICE_ADDED: '@@devices/added',
  DEVICES_FETCH_RESOLUTIONS_SUCCESS: '@@devices/fetch-resolutions/success',
  DEVICES_FETCH_RESOLUTIONS_FAILED: '@@devices/fetch-resolutions/failed',
  DEVICES_FETCH_FILTERS_SUCCESS: '@@devices/fetch-filters/success',
  DEVICES_FETCH_FILTERS_FAILED: '@@devices/fetch-filters/failed',
};

export const devicesRequested = () => ({
  type: DevicesActionTypes.DEVICES_REQUESTED,
});

export const devicesSucceeded = devices => ({
  type: DevicesActionTypes.DEVICES_SUCCEEDED,
  payload: devices,
});

export const devicesFailed = () => ({
  type: DevicesActionTypes.DEVICES_FAILED,
});

export const deviceAdded = sign => ({
  type: DevicesActionTypes.DEVICE_ADDED,
  payload: sign,
});

export const devicesCleared = () => ({
  type: DevicesActionTypes.DEVICES_CLEARED,
});

export const deviceUpdated = sign => ({
  type: DevicesActionTypes.DEVICES_UPDATED,
  payload: sign,
});

export const fetchDeviceResolutionsSucceeded = resolutions => ({
  type: DevicesActionTypes.DEVICES_FETCH_RESOLUTIONS_SUCCESS,
  payload: resolutions,
});

export const fetchDeviceResolutionsFailed = () => ({
  type: DevicesActionTypes.DEVICES_FETCH_RESOLUTIONS_FAILED,
});

export const fetchDeviceFiltersSucceeded = filters => ({
  type: DevicesActionTypes.DEVICES_FETCH_FILTERS_SUCCESS,
  payload: filters,
});

export const fetchDeviceFiltersFailed = () => ({
  type: DevicesActionTypes.DEVICES_FETCH_FILTERS_FAILED,
});

export const deviceUnassigned = (id, location_name, pool_name) => ({
  type: DevicesActionTypes.DEVICE_UNASSIGNED,
  payload: {
    id,
    location_name,
    pool_name,
  },
});

export const deviceUnassignedInGroup = id => ({
  type: DevicesActionTypes.DEVICE_UNASSIGNED_IN_GROUP,
  payload: id,
});

export const fetchDevicesAction = ({
  pool_id,
  historyLocation,
}) => async dispatch => {
  dispatch(devicesRequested());
  const poolParam = pool_id ? `?pool_id=${pool_id}` : '';

  const promise = dispatch(fetchData(`${Api.DEVICES}${poolParam}`));
  promise
    .then(json => json.json())
    .then(result => {
      if (result.signs) {
        dispatch(devicesSucceeded(result));

        if (historyLocation) {
          const { state } = historyLocation;
          if (state && state.groupAdded) {
            const clearHistoryGroupAddedState = {
              state: {
                groupAdded: undefined,
              },
            };
            historyLocation.replace(clearHistoryGroupAddedState);
            dispatch(
              setInfoBar({
                message:
                  'The new group was added. Now add some devices you want to show content on.',
                timeout: 5000,
                state: 'check',
              }),
            );
          }
        }
      } else {
        dispatch(devicesFailed());
      }
    })
    .catch(() => {
      dispatch(devicesFailed());
    });
};

export const editDeviceAction = (
  id,
  values,
  setSubmitting,
) => async dispatch => {
  const deviceData = new FormData();

  deviceData.append('sign[name]', values.name);
  deviceData.append('sign[resolution]', values.resolution);
  deviceData.append('sign[rotation]', values.rotation);
  deviceData.append('sign[mute]', values.mute);

  const promise = dispatch(
    fetchData(
      reverse(Api.DEVICES_EDIT, { id }),
      {
        method: 'PATCH',
        body: deviceData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { sign, message } = result;
      dispatch(popupActionClear());
      dispatch(deviceUpdated(sign));
      dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
    })
    .catch(err => {
      console.error(err);
      setSubmitting(false);
      dispatch(
        setInfoBar({
          message: 'Failed to edit device',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const openEditDevicesAction = id => async dispatch => {
  const hasDevicesEditRights = dispatch(hasUserRights(UserRights.SIGNS_EDIT));
  const promise = dispatch(fetchData(reverse(Api.DEVICES_DETAIL, { id })));

  promise
    .then(json => json.json())
    .then(result => {
      dispatch(
        popupActionSet(PopupTypes.DEVICE_EDIT, {
          id: result.id,
          name: result.name,
          mute: result.mute,
          resolution_id: result.resolution_id,
          rotation: result.rotation,
        }),
      );
    });

  if (!hasDevicesEditRights) {
    dispatch(
      setInfoBar({
        message: 'You only have the rights to view this device',
        state: InfoBarState.WARNING,
      }),
    );
  }
};

export const openDetailDevicesAction = (id, isInPool) => async dispatch => {
  const isSuperAdmin = dispatch(hasSuperAdminRights());
  const promise = dispatch(fetchData(reverse(Api.DEVICES_DETAIL, { id })));
  promise
    .then(json => json.json())
    .then(result => {
      const capitalizeFirstLetter = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      dispatch(
        popupActionSet(PopupTypes.DEVICE_DETAIL, {
          id: result.id,
          deviceValues: [
            { label: 'Name', value: result.name },
            { label: 'Location', value: result.location },
            { label: 'Group', value: result.pool_name },
            isSuperAdmin
              ? { label: 'Customer', value: result.customer }
              : undefined,
            { label: 'Serial', value: result.serial_number },
            {
              label: 'Status',
              value: capitalizeFirstLetter(result.online_status),
            },
            { label: 'Added date', value: result.created_at },
            { label: 'Resolution', value: result.resolution },
            { label: 'Rotation', value: `${result.rotation}°` },
            { label: 'Software version', value: result.version_number },
            { label: 'Available storage', value: result.available_storage },
            { label: 'Total storage', value: result.total_storage },
            { label: 'Muted', value: result.mute ? 'Yes' : 'No' },
          ].filter(Boolean),
          isInPool,
        }),
      );
    })
    .catch(err => {
      console.error(err);
      // dispatch(setInfoBar({ message: err, state: InfoBarState.ERROR, timeout: 5000 }));
    });
};

export const createDeviceAction = (values, setSubmitting) => dispatch => {
  const { serial, name, customer_id } = values;

  const deviceData = new FormData();
  deviceData.append('sign[serial_number]', serial.toUpperCase());
  deviceData.append('sign[name]', name || serial.toUpperCase());
  if (customer_id) {
    deviceData.append('sign[customer_id]', customer_id);
  }

  const promise = dispatch(
    fetchData(
      Api.DEVICES_ADD,
      {
        method: 'POST',
        body: deviceData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message, sign } = result;

      if (result.sign) {
        dispatch(popupActionClear());
        dispatch(deviceAdded(sign));
        dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
      } else {
        dispatch(
          setInfoBar({ message, state: InfoBarState.ERROR, timeout: 5000 }),
        );
      }
    })
    .catch(err => {
      dispatch(
        setInfoBar({ message: err, state: InfoBarState.ERROR, timeout: 5000 }),
      );
    })
    .finally(() => {
      setSubmitting(false);
    });
};

export const addDeviceAction = (
  values,
  history,
  setSubmitting,
) => async dispatch => {
  const { id, pool_id } = values;

  const signData = new FormData();
  signData.append('sign[pool_id]', pool_id);

  const promise = dispatch(
    fetchData(
      reverse(Api.DEVICES_PATCH, { id }),
      {
        method: 'PATCH',
        body: signData,
      },
      false,
    ),
  );
  promise
    .then(json => json.json())
    .then(result => {
      const { message, sign } = result;

      dispatch(popupActionClear());
      setSubmitting(false);
      dispatch(deviceAdded(sign));
      dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
    })
    .catch(err => {
      console.error(err);
      setSubmitting(false);
      dispatch(
        setInfoBar({ message: err, state: InfoBarState.ERROR, timeout: 5000 }),
      );
    });
};

export const unAssignDeviceAction = id => async dispatch => {
  const promise = dispatch(
    fetchData(reverse(Api.DEVICE_UNASSIGN, { id }), { method: 'POST' }),
  );
  promise
    .then(result => {
      if (result) {
        // device is successfully unassigned - clear popup and show message
        const pathName = window.location.pathname;

        if (pathName === Paths.DEVICES) {
          // In case device is unassigned via devices overview, update state by updating location and pool name for unassigned device.
          dispatch(deviceUnassigned(id, null, null));
        } else {
          // In case device is unassigned via devices overview inside a pool, update state by removing device from list
          dispatch(deviceUnassignedInGroup(id));
        }

        dispatch(
          setInfoBar({
            message: 'Device removed from group',
            timeout: 5000,
            state: 'check',
          }),
        );
      }

      dispatch(popupActionClear());
    })
    .catch(err => {
      console.error(err);
      // dispatch(setInfoBar({ message: err, state: InfoBarState.ERROR, timeout: 5000 }));
    });
};

export const fetchDeviceResolutionsAction = () => dispatch => {
  const promise = dispatch(fetchData(reverse(`${Api.DEVICES_OPTIONS}`)));

  promise
    .then(json => json.json())
    .then(result => {
      const resolutions = result.resolution_options.map(option => {
        const key = Object.keys(option)?.[0];
        return { label: option[key], value: Number(key) };
      });
      dispatch(fetchDeviceResolutionsSucceeded(resolutions));
    })
    .catch(() => {
      dispatch(fetchDeviceResolutionsFailed());
      dispatch(
        setInfoBar({
          message: 'Could not fetch device resolutions',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const fetchDeviceFiltersAction = () => dispatch => {
  const promise = dispatch(fetchData(reverse(`${Api.DEVICES_FILTERS}`)));

  promise
    .then(json => json.json())
    .then(result => {
      const { filter_options } = result;
      dispatch(fetchDeviceFiltersSucceeded(filter_options));
    })
    .catch(() => {
      dispatch(fetchDeviceFiltersFailed());
      dispatch(
        setInfoBar({
          message: 'Could not fetch device filters',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};
