import React, { useCallback, useEffect, useMemo } from "react";

import Dropdown from "components/form/dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "store/rootState";
import { superAdminActionSetCustomer } from "store/superAdmin/superAdminActions";
import { fetchCustomersAction } from "store/customers/customerActions";

interface CustomerDropdownProps {
  label?: string;
  width?: number;
  disabled?: boolean;
}

const CustomerDropdown = ({
  label,
  width,
  disabled = false
}: CustomerDropdownProps) => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { customers } = useSelector((state: RootState) => state.customers)
  const { customerOverride } = useSelector((state: RootState) => state.superAdmin)

  const handleChange = useCallback((customer: string) => {
    dispatch(superAdminActionSetCustomer(customer))
  }, [dispatch])

  const options = useMemo(() => {
    const emptyValue = { label: 'All customers', value: '' }
    if (!customers) return [emptyValue]

    const x = customers.map((customer) => ({
      value: customer.id,
      label: customer.name
    }))
    return [
      emptyValue,
      ...x
    ]
  }, [customers])

  useEffect(() => {
    dispatch(fetchCustomersAction())
  }, [dispatch])

  return (
    <Dropdown
      id="super-admin-override"
      className="form__dropdown--small"
      label={label}
      value={customerOverride}
      values={options}
      onChange={(_, value) => handleChange(value)}
      hasEmptyOption={false}
      width={width}
      disabled={disabled}
    />
  )
}

export default CustomerDropdown