export enum Paths {
  HOME = '/',
  LOGIN = '/login',
  REQUEST_PASSWORD = '/request-password',
  RESET_PASSWORD = '/reset',
  ACCOUNT_SETTINGS = '/account-settings',
  TWOFACTOR = '/two_factor',
  MEDIA = '/media',
  MEDIA_FOLDER = '/media/:folder_id',
  DEVICES = '/devices',

  MANAGEMENT = '/management',
  MANAGEMENT_USERS = '/management/users',
  MANAGEMENT_USER_DETAILS = '/management/user-details/:id',
  MANAGEMENT_LOCATIONS = '/management/locations',
  MANAGEMENT_USER_ROLES = '/management/user-roles',

  INPUTS = '/inputs',
  INPUTS_DEVICES = '/inputs/devices',
  INPUTS_SIGNALS = '/inputs/signals',

  POOLS = '/pools',
  POOLS_DETAIL = '/pools/:id',
  POOLS_SCHEDULES = '/pools/:id/schedules',
  POOLS_TRIGGERS = '/pools/:id/triggers',
  POOLS_ARRANGEMENTS = '/pools/:id/arrangements',
  POOLS_MEDIA = '/pools/:id/media',
  POOLS_MEDIA_FOLDER = '/pools/:id/media/:folder_id',
  POOLS_INFO = '/pools/:id/info',
  POOLS_DEVICES = '/pools/:id/devices',
};

export enum Api {
  POOLS = '/pools',
  POOLS_DETAIL = '/pools/:id',
  POOLS_POST = '/pools',
  POOLS_PATCH = '/pools/:id',
  POOLS_DELETE = '/pools/:id',

  LOGS = '/pools/:id/logs',
  LOGGING = '/logging/:id',
  DEVICES = '/signs',
  DEVICES_DETAIL = '/signs/:id',
  DEVICES_ADD = '/signs',
  DEVICES_EDIT = '/signs/:id',
  DEVICES_FILTERS = '/filter/devices',
  DEVICE_UNASSIGN = '/signs/:id/unassign',
  DEVICES_PATCH = '/signs/:id',
  DEVICES_OPTIONS = '/signs/options',

  TOPICS = '/topics/:pool_id',

  TRIGGERS = '/pool_triggers',
  TRIGGERS_POST = '/pool_triggers',
  TRIGGERS_PATCH = '/pool_triggers/:id',
  TRIGGERS_BY_POOL = '/pool_triggers/for_pool/:pool_id',
  TRIGGER_DELETE = '/pool_triggers/:id',
  TRIGGER_DETAIL = '/pool_triggers/:id',
  TRIGGER_ACTIVATE = '/pool_triggers/:id/activate',
  TRIGGER_DEACTIVATE = '/pool_triggers/:id/deactivate',
  TRIGGER_APPROVE = '/pool_triggers/:id/approve',
  TRIGGER_REJECT = '/pool_triggers/:id/reject',

  EVENTS_BY_POOL = '/scheduled_events/pool/:pool_id',
  EVENTS_DETAIL = '/scheduled_events/:id',
  EVENTS_POST = '/scheduled_events',
  EVENTS_PATCH = '/scheduled_events/:id',
  EVENTS_DELETE = '/scheduled_events/:id',
  EVENTS_ACTIVATE = '/scheduled_events/:id/activate',
  EVENTS_DEACTIVATE = '/scheduled_events/:id/deactivate',
  EVENTS_APPROVE = '/scheduled_events/:id/approve',
  EVENTS_REJECT = '/scheduled_events/:id/reject',

  ARRANGEMENTS = '/arrangements',
  ARRANGEMENTS_POST = '/arrangements',
  ARRANGEMENTS_DETAIL = '/arrangements/:id',
  ARRANGEMENTS_PATCH = '/arrangements/:id',
  ARRANGEMENTS_DELETE = '/arrangements/:id',
  ARRANGEMENTS_BY_POOL = '/arrangements/for_pool/:pool_id',
  ARRANGEMENTS_ACTIVATE = '/arrangements/:id/activate',
  ARRANGEMENTS_DEACTIVATE = '/arrangements/:id/deactivate',
  ARRANGEMENTS_APPROVE = '/arrangements/:id/approve',
  ARRANGEMENTS_REJECT = '/arrangements/:id/reject',

  ARRANGEMENT_MEDIA_ITEMS_POST = '/arrangement_media_items',
  ARRANGEMENT_MEDIA_ITEMS_UPDATE_POSITION = '/arrangement_media_items/update_position',
  ARRANGEMENT_MEDIA_ITEMS_DELETE = '/arrangement_media_items/:id',
  ARRANGEMENT_MEDIA_ITEMS_CANCEL = '/arrangements/:id/cancel_update',

  TEMPLATE = '/templates/:id',
  TEMPLATES_BY_POOL = '/templates/for_pool/:pool_id',

  MEDIA = '/media',
  MEDIA_DETAIL = '/media/:id',
  MEDIA_POST = '/media',
  MEDIA_DELETE = '/media/:id',
  MEDIA_BY_POOL = '/media/for_pool/:pool_id',
  MEDIA_TYPES = '/media/types',
  MEDIA_BY_FOLDER = '/media_folders/:folder_id',
  MEDIA_TYPE_VALUES = '/media/type_values',

  MEDIA_FOLDER = '/media_folders',
  MEDIA_FOLDER_PATCH = '/media_folders/:id',
  MEDIA_FOLDER_POST = '/media_folders',
  MEDIA_FOLDER_DELETE = '/media_folders/:id',

  LOCATIONS = '/locations',
  LOCATIONS_OVERVIEW = '/locations/overview',
  LOCATIONS_DETAIL = '/locations/:id',
  LOCATIONS_POST = '/locations',
  LOCATIONS_PATCH = '/locations/:id',

  INPUT_DEVICES = '/input_devices',
  INPUT_DEVICES_POST = '/input_devices',
  INPUT_DEVICES_PATCH = '/input_devices/:id',
  INPUT_DEVICES_DETAIL = '/input_devices/:id',
  INPUT_DEVICES_ACTIVATE_SIGNALS = '/input_devices/:id/activate_signals',
  INPUT_DEVICES_DEACTIVATE_SIGNALS = '/input_devices/:id/deactivate_signals',
  INPUT_DEVICES_DELETE = '/input_devices/:id',

  INPUT_SIGNALS = '/input_signals',
  INPUT_SIGNALS_DETAIL = '/input_signals/:id',
  INPUT_SIGNALS_PATCH = '/input_signals/:id',
  INPUT_SIGNALS_ACTIVATE = '/input_signals/:id/activate',
  INPUT_SIGNALS_DEACTIVATE = '/input_signals/:id/deactivate',

  AUTH_USER = '/auth_user',
  TWOFACTOR_SEND = '/two_factor',
  TWOFACTOR_STATUS = '/two_factor/:id',
  TWOFACTOR_RESEND_EMAIL = '/two_factor?type=login',
  ACCOUNT_SETTINGS_EMAIL_STATUS = '/users/confirmations/email_confirmed',
  ACCOUNT_SETTINGS_TWOFACTOR_STATUS = '/two_factor/status/settings',
  ACCOUNT_SETTINGS_TWOFACTOR_RESEND_EMAIL = '/two_factor?type=settings',

  USERS = '/users',
  USERS_POST = '/users',
  USERS_PATCH = '/users/:id',
  USERS_DETAIL = '/users/:id',

  USER_ROLES = '/user_roles',
  USER_ROLES_PATCH = '/user_roles/:id',
  USER_ROLES_POST = '/user_roles',
  USER_ROLES_DETAIL = '/user_roles/:id',
  USER_ROLES_DELETE = '/user_roles/:id',

  USER_EMAIL = '/users/:id/secure',
  USER_PASSWORD = '/users/:id/secure',
  USERS_PASSWORD = '/users/password',

  CUSTOMERS = '/customers',

  NOTIFICATIONS = '/notifications',
};

export const PublicPaths = [
  Paths.LOGIN,
  Paths.TWOFACTOR,
  Paths.REQUEST_PASSWORD,
  Paths.RESET_PASSWORD,
];

export const PrivatePaths = [
  Paths.ACCOUNT_SETTINGS,
  Paths.MEDIA,
  Paths.DEVICES,
  Paths.MANAGEMENT,
  Paths.INPUTS,
  Paths.POOLS,
  Paths.POOLS_DETAIL,
  Paths.POOLS_SCHEDULES,
  Paths.POOLS_TRIGGERS,
  Paths.POOLS_ARRANGEMENTS,
  Paths.POOLS_MEDIA,
  Paths.POOLS_INFO,
];
