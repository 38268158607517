import React from 'react';
import classNames from 'classnames';

import Skeleton from 'components/skeleton/Skeleton';
import { SkeletonType } from 'components/skeleton/Skeleton.enum';

import './BlankButton.scss';
import { BlankButtonProps } from './BlankButton.types';

/*  BlankButton is a button component like the normal Button component
 *  but without any styling
 *  Use the BlankButton when you need a button without the default button class
 */

const BlankButton: React.FC<BlankButtonProps> = ({
  tag = 'button',
  handler,
  className,
  children,
  elementRef,
  loading = false,
  ...props
}) => {
  return (
    <>
      {loading ? (
        <div className={classNames('blank-button', className)}>
          <Skeleton type={SkeletonType.BUTTON_SQUARE} styling={{ width: 20 }} />
        </div>
      ) : (
        React.createElement(
          tag,
          {
            type: 'button',
            tabIndex: -1,
            onClick: handler || null,
            onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
              (e.key === '13' && handler) || null,
            className: classNames('blank-button', className),
            ref: elementRef,
            ...props,
          },
          children,
        )
      )}
    </>
  );
};

export default BlankButton;
