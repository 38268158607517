export enum InputDevicesActionType {
  INPUT_DEVICES_REQUESTED=  '@@input-devices/requested',
  INPUT_DEVICES_SUCCEEDED=  '@@input-devices/success',
  INPUT_DEVICES_FAILED = '@@input-devices/failed',
  INPUT_DEVICES_CLEARED = '@@input-devices/cleared',
  INPUT_DEVICE_ADDED = '@@input-devices/added',
  INPUT_DEVICE_UPDATED = '@@input-devices/updated',
  INPUT_DEVICE_INPUT_SIGNALS_ACTIVATED =
'@@input-devices/input-signals-activated',
  INPUT_DEVICE_INPUT_SIGNALS_DEACTIVATED =
'@@input-devices/input-signals-deactivated',
  INPUT_DEVICE_DETAILS_REQUESTED = '@@input-devices/details-requested',
  INPUT_DEVICE_DETAILS_SUCCEEDED = '@@input-devices/details-success',
  INPUT_DEVICE_DETAILS_FAILED = '@@input-devices/details-failed',
  INPUT_DEVICE_DELETED = '@@input-devices/deleted',
}