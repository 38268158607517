import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import { Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { AnyAction } from 'redux';
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import { fetchPoolsAction } from 'store/pools/poolsActions';
import LocationDropdown from 'components/location/dropdown/LocationDropdown';
import Heading from 'components/typography/heading/Heading';

import { hasSuperAdminRights } from 'store/auth/hasUserRights';
import { RootState } from 'store/rootState';

import HistoryLocationState from 'constants/HistoryState';

import './PoolFilter.scss';

const PoolFilter = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const initValues = {
    name: '',
    location: '',
    customer: ''
  };
  const [openFilters, setOpenFilters] = useState(false);
  const [formValues, setFormValues] = useState<FormikValues>(initValues);
  const [isInitialized, setIsInitialized] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const history = useHistory<HistoryLocationState>();

  const isSuperAdmin = dispatch(hasSuperAdminRights());
  const { customerOverride } = useSelector((state: RootState) => state.superAdmin)

  const validationScheme = Yup.object().shape({
    name: Yup.string(),
  });

  const filterGroups = useCallback(
    (values: FormikValues) => {
      dispatch(fetchPoolsAction({
        name: values.name,
        location: values.location,
        customer: isSuperAdmin ? customerOverride : undefined
      }, history));

      const groupFilters = {
        values: {
          name: values.name,
          location: values.location,
        },
      };

      localStorage.setItem('group_filters', JSON.stringify(groupFilters));
    },
    [dispatch, history, isSuperAdmin, customerOverride],
  );

  const resetFilter = useCallback(() => {
    localStorage.removeItem('group_filters');
    setFormKey((prevKey) => prevKey + 1);
    setFormValues(initValues);
  }, [setFormKey, setFormValues, initValues]);

  useEffect(() => {
    const formData = localStorage.getItem('group_filters');

    if (formData) {
      const formArray = JSON.parse(formData);
      setOpenFilters(!!formArray.values.location);

      setFormValues(formArray.values);
    }
    setIsInitialized(true);

  }, [dispatch, setIsInitialized, setFormValues, setOpenFilters]);

  useEffect(() => {
    if (isInitialized) {
      filterGroups({
        ...formValues,
        customer: isSuperAdmin ? customerOverride : undefined
      });
    }
  }, [formValues, customerOverride, filterGroups, isInitialized, isSuperAdmin]);

  return (
    <Formik
      key={formKey}
      enableReinitialize
      initialValues={formValues}
      validationSchema={validationScheme}
      onSubmit={filterGroups}
      onChange={filterGroups}
    >
      {({ values, handleChange, setFieldValue, handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          onChange={handleChange}
          className="pool-filter"
        >
          <Heading level={3} noMargin>
            Search
          </Heading>
          <div className="pool-filter__bar">
            <div className="pool-filter__input">
              <Input
                id="filter_name"
                name="name"
                type="text"
                scheme="white"
                placeholder="What are you looking for?"
                value={values.name}
                onChange={handleChange}
              />
            </div>

            <div className="pool-filter__options">
              <Button
                text={openFilters ? 'Less filters' : 'More filters'}
                size="medium"
                scheme="link"
                className="pool-filter__option"
                hasShadow={false}
                onClick={() => setOpenFilters(!openFilters)}
              />
              <Button
                tag="button"
                text="Reset"
                size="medium"
                scheme="link"
                className="pool-filter__option"
                hasShadow={false}
                onClick={resetFilter}
                disabled={values.name === '' && values.location === ''}
              />
              <Button tag="button" type="submit" text="Search" size="medium" />
            </div>
          </div>

          <AnimateHeight duration={300} height={openFilters ? 'auto' : 0}>
            <div className="pool-filter__advanced">
              <Heading level={3}>Filter options</Heading>
              <div className="pool-filter__advanced__stack">
                <LocationDropdown
                  id="filter_location"
                  name="location"
                  label="Locations"
                  width={220}
                  value={values.location}
                  onChange={(name: string, value: string) => {
                    setFieldValue(name, value);
                    handleSubmit();
                  }}
                  multiple={false}
                  showAllLocations={false}
                />
              </div>
            </div>
          </AnimateHeight>
        </form>
      )}
    </Formik>
  );
};

export default PoolFilter;
