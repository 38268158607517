import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import equal from 'fast-deep-equal';

import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import { popupActionClear } from 'store/popup/popupActions';
import { editEmailAction } from 'store/account/accountSettingsActions';
import RegexOptions from 'constants/RegexOptions';
import { RootState } from 'store/rootState';
import { AccountSettingsEmailValues } from './AccountSettingsEmail.types';

const AccountSettingsEmail = () => {
  const dispatch = useDispatch();
  const id = useSelector((state: RootState) => state.auth?.user?.id);
  const [isSubmitting, setSubmitting] = useState(false);
  const initialForm = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('E-mail is a required field')
      .matches(RegexOptions.EMAIL_VALIDATION, 'Please enter a valid e-mail'),
  });

  const onSubmit = (values: AccountSettingsEmailValues) => {
    setSubmitting(true);

    const emailData = new FormData();
    emailData.append('user[email]', values.email);

    if (equal(values, initialForm)) {
      setSubmitting(false);
      dispatch(popupActionClear());
      return;
    }
    dispatch(editEmailAction(emailData, id, setSubmitting));
  };

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <fieldset>
            <FormBlock flatten>
              <Input
                type="text"
                label="E-mail"
                name="email"
                id="email"
                scheme="white"
                value={values.email}
                placeholder="E-mail"
                onChange={handleChange}
                error={touched.email && errors.email}
              />
            </FormBlock>
          </fieldset>

          <FormBlock hasInlineChildren isEnd>
            <Button
              tag="button"
              size="medium"
              text="Cancel"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(popupActionClear());
              }}
              disabled={isSubmitting}
            />
            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Save and close"
              disabled={isSubmitting}
            />
          </FormBlock>
        </form>
      )}
    </Formik>
  );
};

export default AccountSettingsEmail;
