import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { reverse } from 'named-urls';

import PopupTypes from 'constants/PopupTypes.enum';
import { Api } from 'constants/Routes.enum';

import fetchData from 'store/fetchData';
import { setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import {popupActionClear, popupActionSet, popupActionUpdate} from 'store/popup/popupActions';
import { InputSignal, RootState } from 'store/rootState';

import { InputSignalValues } from 'components/popup/inputs/inputSignals/InputSignalEdit.types';
import { InputSignalsActionType } from './inputSignalsActions.enum';

export const inputSignalsRequested = () => ({
  type: InputSignalsActionType.INPUT_SIGNALS_REQUESTED,
});

export const inputSignalsSucceeded = (inputSignals: InputSignal) => ({
  type: InputSignalsActionType.INPUT_SIGNALS_SUCCEEDED,
  payload: inputSignals,
});

export const inputSignalsFailed = () => ({
  type: InputSignalsActionType.INPUT_SIGNALS_FAILED,
});

export const inputSignalDetailsRequested = () => ({
  type: InputSignalsActionType.INPUT_SIGNALS_DETAILS_REQUESTED,
});

export const inputSignalDetailsSuccess = (inputSignal: InputSignal) => ({
  type: InputSignalsActionType.INPUT_SIGNALS_DETAILS_SUCCEEDED,
  payload: inputSignal,
});

export const inputSignalDetailsFailed = () => ({
  type: InputSignalsActionType.INPUT_SIGNALS_DETAILS_FAILED,
});

export const inputSignalsUpdated = (inputSignal: InputSignal) => ({
  type: InputSignalsActionType.INPUT_SIGNALS_UPDATED,
  payload: inputSignal,
});

export const inputSignalsActivated = (inputSignal: InputSignal) => ({
  type: InputSignalsActionType.INPUT_SIGNALS_ACTIVATED,
  payload: inputSignal,
});

export const inputSignalsDeactivated = (inputSignal: InputSignal) => ({
  type: InputSignalsActionType.INPUT_SIGNALS_DEACTIVATED,
  payload: inputSignal,
});

export const fetchInputSignalsAction = (): ThunkAction<
  void,
  RootState,
  undefined,
  AnyAction
> => async dispatch => {
  dispatch(inputSignalsRequested());
  const promise = dispatch(fetchData(Api.INPUT_SIGNALS));
  promise
    .then(json => json.json())
    .then(result => {
      const { input_signals } = result;

      if (input_signals) {
        dispatch(inputSignalsSucceeded(input_signals));
      } else {
        dispatch(inputSignalsFailed());
      }
    })
    .catch(() => {
      dispatch(inputSignalsFailed());
    });
};

export const openInputSignalDetailsAction = (
  id: number,
): ThunkAction<void, RootState, undefined, AnyAction> => async dispatch => {
  dispatch(inputSignalDetailsRequested());

  const promise = dispatch(
    fetchData(reverse(Api.INPUT_SIGNALS_DETAIL, { id })),
  );
  promise
    .then(json => json.json())
    .then(result => {
      const { input_signal } = result;
      dispatch(inputSignalDetailsSuccess(input_signal));
      dispatch(
        popupActionSet(PopupTypes.INPUTS_INPUT_SIGNAL_EDIT, {
          ...input_signal,
        }),
      );
    })
    .catch(err => {
      dispatch(inputSignalDetailsFailed());
      console.error(err);
      // dispatch(setInfoBar({ message: err, state: InfoBarState.ERROR, timeout: 5000 }));
    });
};

export const editInputSignalAction = (
  id: number,
  values: InputSignalValues,
  setSubmitting: Function,
): ThunkAction<void, RootState, undefined, AnyAction> => async dispatch => {
  const inputSignalData = new FormData();

  inputSignalData.append('input_signal[name]', values.name);
  inputSignalData.append('input_signal[status]', values.status);

  const promise = dispatch(
    fetchData(
      reverse(Api.INPUT_SIGNALS_PATCH, { id }),
      {
        method: 'PATCH',
        body: inputSignalData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { input_signal, message } = result;
      dispatch(popupActionClear());
      dispatch(inputSignalsUpdated(input_signal));
      dispatch(
        setInfoBar({ message, state: InfoBarState.CHECK, timeout: 5000 }),
      );
    })
    .catch((err: string) => {
      console.error(err);
      setSubmitting(false);
      dispatch(
        setInfoBar({
          message: 'Failed to edit input signal',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const toggleActiveInputSignalAction = (
  id: number,
  isActive: boolean,
  setActive: Function,
  setSubmitting: Function,
): ThunkAction<void, RootState, undefined, AnyAction> => async dispatch => {
  setSubmitting(true);

  const url = !isActive
    ? Api.INPUT_SIGNALS_ACTIVATE
    : Api.INPUT_SIGNALS_DEACTIVATE;

  const promise = dispatch(
    fetchData(reverse(url, { id }), {
      method: 'POST',
    }),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { input_signal, message } = result;
      const status = input_signal.status === 'active';

      setSubmitting(false);
      dispatch(popupActionUpdate({status : input_signal.status}))
      if (status) {
        setActive(status);
        dispatch(inputSignalsActivated(input_signal));
      } else {
        dispatch(inputSignalsDeactivated(input_signal));
      }
      dispatch(
        setInfoBar({ message, state: InfoBarState.CHECK, timeout: 5000 }),
      );
    });
};
