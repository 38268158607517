import UserRight from 'constants/UserRight.enum';
import { UserRoleRight } from '../UserRoleRights.types';

export const userRoleRights: UserRoleRight[] = [
  {
    id: 'Groups',
    name: 'Groups',
    sections: [
      {
        id: 'Groups-groups',
        name: 'Groups',
        rights: [
          {
            id: 'Groups-groups-viewing',
            name: 'viewing',
            value: UserRight.POOLS_VIEW,
            disabled: true,
          },
          {
            id: 'Groups-groups-editing',
            name: 'editing',
            value: UserRight.POOLS_EDIT,
          },
        ],
      },
      {
        id: 'Groups-schedule-and-events',
        name: 'Schedule and events',
        rights: [
          {
            id: 'Groups-schedule-and-events-viewing',
            name: 'viewing',
            value: UserRight.EVENTS_VIEW,
          },
          {
            id: 'Groups-schedule-and-events-editing',
            name: 'editing',
            value: UserRight.EVENTS_EDIT,
          },
        ],
      },
      {
        id: 'Groups-triggers',
        name: 'Triggers',
        rights: [
          {
            id: 'Groups-triggers-viewing',
            name: 'viewing',
            value: UserRight.TRIGGERS_VIEW,
          },
          {
            id: 'Groups-triggers-editing',
            name: 'editing',
            value: UserRight.TRIGGERS_EDIT,
          },
        ],
      },
      {
        id: 'Groups-shows',
        name: 'Shows',
        rights: [
          {
            id: 'Groups-shows-viewing',
            name: 'viewing',
            value: UserRight.ARRANGEMENTS_VIEW,
          },
          {
            id: 'Groups-shows-editing',
            name: 'editing',
            value: UserRight.ARRANGEMENTS_EDIT,
          },
        ],
      },
      {
        id: 'Groups-devices',
        name: 'Devices',
        rights: [
          {
            id: 'Groups-devices-viewing',
            name: 'viewing',
            value: UserRight.SIGNS_VIEW,
          },
          {
            id: 'Groups-devices-editing',
            name: 'editing',
            value: UserRight.SIGNS_EDIT,
          },
        ],
      },
      {
        id: 'Groups-approval',
        name: 'Approval of group details',
        rights: [
          {
            id: 'Groups-approval-can-approve',
            name: 'can approve',
            value: UserRight.APPROVAL,
          },
        ],
      },
    ],
  },
  {
    id: 'Media',
    name: 'Media',
    sections: [
      {
        id: 'Media-media-items',
        name: 'Media items',
        rights: [
          {
            id: 'Media-media-items-viewing',
            name: 'viewing',
            value: UserRight.MEDIA_VIEW,
          },
          {
            id: 'Media-media-items-editing',
            name: 'editing',
            value: UserRight.MEDIA_EDIT,
          },
        ],
      },
      {
        id: 'Media-media-folders',
        name: 'Media folders',
        rights: [
          {
            id: 'Media-media-folders-viewing',
            name: 'viewing',
            value: UserRight.MEDIA_FOLDERS_VIEW,
          },
          {
            id: 'Media-media-folders-editing',
            name: 'editing',
            value: UserRight.MEDIA_FOLDERS_EDIT,
          },
        ],
      },
    ],
  },
  {
    id: 'Inputs',
    name: 'Inputs',
    sections: [
      {
        id: 'Input-devices',
        name: 'Input devices',
        rights: [
          {
            id: 'Inputs-input-devices-viewing',
            name: 'viewing',
            value: UserRight.INPUT_VIEW,
          },
          {
            id: 'Input-input-devices-editing',
            name: 'editing',
            value: UserRight.INPUT_EDIT,
          },
        ],
      },
    ],
  },
  {
    id: 'Management',
    name: 'Management',
    sections: [
      {
        id: 'Management-management',
        name: 'Management',
        rights: [
          {
            id: 'Management-management-can-configure',
            name: 'can configure',
            value: UserRight.MANAGEMENT,
          },
        ],
      },
    ],
  },
];

export type UserRightConnection = {
  [key in UserRight]: {
    disables?: UserRight[];
    enables?: UserRight[];
  };
};

export const userRightConnections: UserRightConnection = {
  [UserRight.EVENTS_VIEW]: {
    disables: [UserRight.EVENTS_EDIT],
  },
  [UserRight.EVENTS_EDIT]: {
    enables: [
      UserRight.EVENTS_VIEW,
      UserRight.ARRANGEMENTS_VIEW
    ],
  },
  [UserRight.TRIGGERS_VIEW]: {
    disables: [UserRight.TRIGGERS_EDIT],
  },
  [UserRight.TRIGGERS_EDIT]: {
    enables: [
      UserRight.TRIGGERS_VIEW,
      UserRight.ARRANGEMENTS_VIEW
    ],
  },
  [UserRight.ARRANGEMENTS_VIEW]: {
    disables: [
      UserRight.ARRANGEMENTS_EDIT,
      UserRight.EVENTS_EDIT,
      UserRight.TRIGGERS_EDIT
    ],
  },
  [UserRight.ARRANGEMENTS_EDIT]: {
    enables: [
      UserRight.ARRANGEMENTS_VIEW,
      UserRight.MEDIA_VIEW,
      UserRight.MEDIA_FOLDERS_VIEW
    ],
  },
  [UserRight.SIGNS_VIEW]: {
    disables: [UserRight.SIGNS_EDIT],
  },
  [UserRight.SIGNS_EDIT]: {
    enables: [UserRight.SIGNS_VIEW],
  },
  [UserRight.MEDIA_VIEW]: {
    enables: [UserRight.MEDIA_FOLDERS_VIEW],
    disables: [
      UserRight.MEDIA_EDIT,
      UserRight.MEDIA_FOLDERS_VIEW,
      UserRight.MEDIA_FOLDERS_EDIT,
      UserRight.ARRANGEMENTS_EDIT
    ],
  },
  [UserRight.MEDIA_EDIT]: {
    enables: [UserRight.MEDIA_VIEW, UserRight.MEDIA_FOLDERS_VIEW],
  },
  [UserRight.MEDIA_FOLDERS_VIEW]: {
    enables: [UserRight.MEDIA_VIEW],
    disables: [
      UserRight.MEDIA_FOLDERS_EDIT,
      UserRight.MEDIA_VIEW,
      UserRight.MEDIA_EDIT,
      UserRight.ARRANGEMENTS_EDIT
    ],
  },
  [UserRight.MEDIA_FOLDERS_EDIT]: {
    enables: [UserRight.MEDIA_FOLDERS_VIEW, UserRight.MEDIA_VIEW],
  },
  [UserRight.POOLS_VIEW]: {},
  [UserRight.POOLS_EDIT]: {},
  [UserRight.INPUT_VIEW]: {
    disables: [UserRight.INPUT_EDIT],
  },
  [UserRight.INPUT_EDIT]: {
    enables: [UserRight.INPUT_VIEW],
  },
  [UserRight.MANAGEMENT]: {},
  [UserRight.APPROVAL]: {},
};