import React, { FC, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import BlankButton from 'components/button/BlankButton';
import './PopupContext.scss';
import { RootState } from 'store/rootState';
import { PopupContextProps } from './PopupContext.types';

const PopupContext: FC<PopupContextProps> = ({
  button,
  className,
  contentClassName,
  children,
  disabled = false,
}) => {
  const [popupActive, setActive] = useState(false);
  const popupShown = useSelector((state: RootState) => state.popup.type);

  const togglePopup = useCallback(() => {
    if (!disabled) {
      setActive(active => !active);
    }
  }, [disabled]);

  useEffect(() => {
    if (popupShown) {
      setActive(false);
    }
  }, [popupShown]);

  return (
    <div className={classNames('popup-context', className)}>
      <BlankButton handler={togglePopup}>{button}</BlankButton>

      {popupActive && (
        <>
          <div
            className="popup-context__background"
            role="presentation"
            onClick={() => togglePopup()}
          />
          <div
            className={classNames('popup-context__content', contentClassName)}
          >
            {children}
          </div>
        </>
      )}
    </div>
  );
};
export default PopupContext;
