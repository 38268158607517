import React from 'react';
import classNames from 'classnames';

import Text from 'components/typography/text/Text';
import { TextTag } from 'components/typography/text/Text.enum';

import { LabelValueListItem, LabelValueListProps, LabelValueListStyling } from './LabelValueList.types';
import './LabelValueList.scss';

const LabelValueList = ({
  values,
  listId,
  styling = LabelValueListStyling.NONE,
  flatten = false,
  loading = false,
  loadingItemLength = 1,
}: LabelValueListProps) => {
  const listItem = ({ label, value, id, loading }: LabelValueListItem, index: number) => {
    let key;

    switch (true) {
      case !!listId:
        key = `${listId}_${label}_${value}`;
        break;
      case !!id:
        key = `${id}_${label}_${value}`;
        break;
      default:
        key = index;
    }

    return (
      <li
        key={key}
        className={classNames('list__item', {
          'list__item--loading': loading,
        })}
      >
        <>
          {label && (
            <Text
              tag={TextTag.SPAN}
              loading={loading}
              className="list__item-label"
            >
              <b>
                {label}
                {!loading && ': '}
              </b>
            </Text>
          )}
          <Text
            tag={TextTag.SPAN}
            loading={loading}
            className="list__item-value"
          >
            {value}
          </Text>
        </>
      </li>
    );
  };

  return (
    <ul
      className={classNames('label-value-list', {
        'label-value-list--reset': styling === 'none',
        'label-value-list--bullets': styling === 'bullets',
        'label-value-list--flatten': flatten,
      })}
    >
      {loading
        ? [...Array(loadingItemLength)].map((x, index) =>
            listItem({
              label: undefined,
              value: undefined,
              id: undefined,
              loading
            }, index),
          )
        : values &&
          values.map(
            ({ label, value, id }, index) =>
              value !== null &&
              value !== undefined &&
              value !== '' &&
              listItem({ label, value, id, loading }, index),
          )}
    </ul>
  );
};


export default LabelValueList;
