export enum SkeletonTag {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
}

export enum SkeletonType {
    PARAGRAPH = 'paragraph',
    TEXT = 'text',
    HEADING = 'heading',
    BUTTON_SQUARE = 'button_square',
}