import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'components/button/Button';
import { popupActionSet } from 'store/popup/popupActions';
import { setInfoBarShow } from 'store/info/infoActions';
import Icon from 'components/icon/Icon';
import { PoolButtonProps } from './PoolButton.types';
import './PoolButton.scss';

const PoolButton: FC<PoolButtonProps> = ({ text, popupType }) => {
  const dispatch = useDispatch();

  return (
    <div className="pool-button">
      <Button
        shapeEdges="rounded"
        text={<Icon name="plus" size="medium" />}
        hoverText={text}
        hoverTextInverse
        handler={() => {
          dispatch(popupActionSet(popupType, {}));
          dispatch(setInfoBarShow({ show: false }));
        }}
      />
    </div>
  );
};

export default PoolButton;
