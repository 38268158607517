import React from 'react';
import classNames from 'classnames';

import Icon from 'components/icon/Icon';
import Text from 'components/typography/text/Text';
import { TextTag } from 'components/typography/text/Text.enum';

import { IconListIconBackgroundColor } from './IconList.enum';
import { IconListProps, IconListItem } from './IconList.types';
import './IconList.scss';

const IconList: React.FC<IconListProps> = ({
  items,
  icon = 'circle',
  loading,
  loadingItemLength = 1,
  singleLine = false,
  iconBackgroundColor = IconListIconBackgroundColor.WHITE,
  emptyListText,
  iconSize = 'x-small',
}) => {
  const listItem = (item: IconListItem, index: number) => (
    <div
      key={index}
      className={classNames('icon-list__item', {
        [`icon-list__item--loading`]: loading,
        [`icon-list__item--icon-size-${iconSize}`]: iconSize,
      })}
    >
      <Icon
        loading={loading}
        name={item.icon || icon}
        size={iconSize}
        className={classNames('icon-list__item-icon', {
          [`icon-list__item-icon--size-${iconSize}`]: iconSize,
          [`icon-list__item-icon--${iconBackgroundColor}`]: iconBackgroundColor,
        })}
      />
      <Text
        tag={TextTag.SPAN}
        loading={loading}
        className={classNames('icon-list__item-text', {
          'icon-list__item-text--single-line': singleLine,
        })}
      >
        {item.content}
      </Text>
    </div>
  );

  return (
    <div
      className={classNames('icon-list', {
        'icon-list--single-item': items && items.length === 1,
      })}
    >
      {loading &&
        [...Array(loadingItemLength)].map((x, index) => listItem({}, index))}
      {!loading && items && items.length
        ? items.map((item, index) => listItem(item, index))
        : emptyListText && listItem({ content: emptyListText }, 0)}
    </div>
  );
};

export default IconList;
