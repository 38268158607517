import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Truncate from 'react-truncate-markup';

import Card from 'components/card/Card';
import ArrangementLayout from 'components/arrangement/layout/ArrangementLayout';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import { editArrangementAction } from 'store/arrangements/arrangementsActions';
import Status from 'components/status/Status';
import Text from 'components/typography/text/Text';
import Heading from 'components/typography/heading/Heading';

import './ArrangementCard.scss';
import classNames from 'classnames';

const ArrangementCard = ({
  id,
  name,
  type,
  template,
  templateId,
  active,
  pendingApproval,
  selectable,
  selected,
  setSelected,
  disabled,
}) => {
  const dispatch = useDispatch();
  const pool = useSelector(state => state.pool);

  const cardClick = () => {
    if (!disabled) {
      if (selectable) {
        setSelected({
          id,
          name,
          template,
          templateId,
          active,
          pendingApproval,
        });
      } else {
        dispatch(editArrangementAction(id, pool.default_arrangement?.id));
      }
    }
  };

  return (
    <button
      data-cy="arrangementCard"
      type="button"
      tabIndex="0"
      onClick={cardClick}
      onKeyDown={e => e.key === '13' && cardClick}
      className="button__interaction arrangement-card__button"
    >
      <Card
        className={classNames('arrangement-card', {
          'arrangement-card--selectable': selectable && !disabled,
          'arrangement-card--selected': selected,
          'arrangement-card--disabled': disabled,
        })}
        zoomable
      >
        <ArrangementLayout
          templateObject={template}
          templateId={templateId}
          className={
            selectable
              ? 'arrangement-card__layout--center'
              : 'arrangement-card__layout'
          }
        />
        <Text tag="p" color="grey-dark" size="small">
          {type === 'RegularArrangement' ? 'Regular show' : 'Trigger show'}
        </Text>
        <Heading
          level={3}
          className="arrangement-card__title"
          dataCy="arrangementCardTitle"
        >
          <Truncate lines={2}>
            <span>{name}</span>
          </Truncate>
        </Heading>
        <div>
          <div className="card__state">
            <Status
              isActive={active}
              needsApproval={pendingApproval}
              dataCy="arrangementCardStatus"
            />
          </div>
          {!selectable && (
            <Button
              tag="span"
              text={<Icon name="arrow-right" />}
              size="small"
              hasShadow={false}
              className="card__button"
              scheme="card"
              shapeEdges="rounded"
            />
          )}
        </div>
      </Card>
    </button>
  );
};

ArrangementCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.oneOf(['RegularArrangement', 'TriggerArrangement'])
    .isRequired,
  templateId: PropTypes.number,
  active: PropTypes.bool,
  pendingApproval: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
  disabled: PropTypes.bool,
};

ArrangementCard.defaultProps = {
  id: null,
  name: '',
  templateId: null,
  active: false,
  pendingApproval: false,
  selectable: false,
  selected: false,
  setSelected: null,
  disabled: false,
};

export default ArrangementCard;
