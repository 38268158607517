import fetchData from 'store/fetchData';
import { Paths, Api } from 'constants/Routes.enum';
import { reverse } from 'named-urls';
import UserRight from 'constants/UserRight.enum';
import PopupTypes from 'constants/PopupTypes.enum';
import { hasUserRights, hasAdminRights } from 'store/auth/hasUserRights';
import {
  popupActionClear,
  popupActionSet,
  popupActionUpdate,
} from 'store/popup/popupActions';
import { setInfoBar, setInfoBarShow } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';

import { infoBarText } from 'components/infoBar/InfoBar.json';
import { ButtonColor } from 'components/button/Button.enum';
import { clearHistoryFromTriggerState } from 'components/popup/trigger/Trigger';

export const TriggerActionTypes = {
  TRIGGERS_REQUESTED: '@@triggers/requested',
  TRIGGERS_SUCCEEDED: '@@triggers/success',
  TRIGGERS_FAILED: '@@triggers/error',
  TRIGGERS_CLEARED: '@@triggers/cleared',
  TRIGGER_DELETED: '@@triggers/deleted',
  TRIGGER_ADDED: '@@triggers/added',
  TRIGGER_EDITED: '@@triggers/edited',
  TRIGGER_ACTIVATED: '@@trigger/activated',
  TRIGGER_APPROVED: '@@trigger/approved',
};

export const triggersRequested = () => ({
  type: TriggerActionTypes.TRIGGERS_REQUESTED,
});

export const triggersSucceeded = triggers => ({
  type: TriggerActionTypes.TRIGGERS_SUCCEEDED,
  payload: triggers,
});

export const triggersFailed = () => ({
  type: TriggerActionTypes.TRIGGERS_FAILED,
});

export const triggerAdded = trigger => ({
  type: TriggerActionTypes.TRIGGER_ADDED,
  payload: trigger,
});

export const triggerEdited = trigger => ({
  type: TriggerActionTypes.TRIGGER_EDITED,
  payload: trigger,
});

export const triggersCleared = () => ({
  type: TriggerActionTypes.TRIGGERS_CLEARED,
});

export const triggerDeleted = id => ({
  type: TriggerActionTypes.TRIGGER_DELETED,
  payload: id,
});

export const triggerActivated = (id, active) => ({
  type: TriggerActionTypes.TRIGGER_ACTIVATED,
  payload: {
    id,
    active,
  },
});

export const triggerApproved = trigger => ({
  type: TriggerActionTypes.TRIGGER_APPROVED,
  payload: trigger,
});

export const approvalTriggerAction = (id, url) => dispatch => {
  const promise = dispatch(
    fetchData(reverse(url, { id }), {
      method: 'POST',
    }),
  );

  promise
    .then(json => json.json())
    .then(result => {
      if (result.deleted) {
        dispatch(triggerDeleted(id));
        dispatch(popupActionClear());
        dispatch(setInfoBarShow({ show: false }));
        return;
      }

      const { pool_trigger, message } = result;

      if (pool_trigger) {
        dispatch(triggerApproved(pool_trigger));
        dispatch(popupActionUpdate(pool_trigger));
      }

      dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
    });
};

export const addTriggerAction = (
  triggerData,
  id,
  setSubmitting,
  locationState,
  history,
) => async dispatch => {
  const isEditing = !!id;

  // create or edit trigger
  const promise = dispatch(
    fetchData(
      isEditing ? reverse(Api.TRIGGERS_PATCH, { id }) : Api.TRIGGERS_POST,
      {
        method: isEditing ? 'PATCH' : 'POST',
        body: triggerData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message, pool_trigger } = result;

      dispatch(popupActionClear());
      setSubmitting(false);
      clearHistoryFromTriggerState(history, locationState);
      if (message) {
        dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
      }

      if (isEditing) {
        // update trigger state
        dispatch(triggerEdited(pool_trigger));
      } else {
        // add new trigger to state
        dispatch(triggerAdded({ ...pool_trigger, active: false }));
      }
    })
    .catch(err => {
      console.error(err);
      setSubmitting(false);
      dispatch(
        setInfoBar({
          message: 'Failed to add new trigger',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const deleteTriggerAction = id => dispatch => {
  // call delete endpoint
  const promise = dispatch(
    fetchData(reverse(Api.TRIGGER_DELETE, { id }), { method: 'DELETE' }),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message } = result;
      // trigger is deleted successfully - clear popup and show message
      dispatch(triggerDeleted(id));
      dispatch(popupActionClear());

      dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
    })
    .catch(err => {
      // trigger could not be deleted - show error
      dispatch(
        setInfoBar({ message: err, state: InfoBarState.ERROR, timeout: 5000 }),
      );
    });
};

export const editTriggerAction = (id, poolId) => async dispatch => {
  const isAdmin = dispatch(hasAdminRights());
  const hasTriggersEditRights = dispatch(
    hasUserRights(UserRight.TRIGGERS_EDIT),
  );

  const promise = dispatch(
    fetchData(
      reverse(Api.TRIGGER_DETAIL, { id }),
      {
        method: 'GET',
      },
      false,
    ),
  );
  promise
    .then(json => json.json())
    .then(result => {
      const poolTrigger = result.pool_trigger;

      dispatch(
        popupActionSet(PopupTypes.TRIGGER, {
          name: poolTrigger.name,
          id: poolTrigger.id,
          trigger_id: poolTrigger.trigger_id,
          arrangement: poolTrigger.arrangement,
          until_type: poolTrigger.until_type,
          until_value: poolTrigger.until_value,
          active: poolTrigger.active,
          pending_changes: poolTrigger.pending_changes,
          pending_approval: poolTrigger.pending_approval,
          pool_id: poolId,
        }),
      );

      if (poolTrigger.pending_approval) {
        if (isAdmin) {
          dispatch(
            setInfoBar({
              message: infoBarText.approval.message,
              state: InfoBarState.WARNING,
              timeout: 0,
              action: [
                {
                  text: infoBarText.approval.buttons.approve,
                  handle: () => {
                    dispatch(approvalTriggerAction(id, Api.TRIGGER_APPROVE));
                  },
                  color: ButtonColor.GREEN,
                },
                {
                  text: infoBarText.approval.buttons.reject,
                  handle: () => {
                    dispatch(approvalTriggerAction(id, Api.TRIGGER_REJECT));
                  },
                  color: ButtonColor.RED,
                },
              ],
            }),
          );
        } else {
          dispatch(
            setInfoBar({
              message: 'The trigger is waiting for approval',
              state: InfoBarState.WARNING,
            }),
          );
        }
      } else if (!hasTriggersEditRights) {
        dispatch(
          setInfoBar({
            message: 'You only have the rights to view this trigger',
            state: InfoBarState.WARNING,
          }),
        );
      }
    })
    .catch(err => {
      console.error(err);
    });
};

export const toggleActiveTriggerAction = (
  id,
  isActive,
  setActive,
  setSubmitting,
) => dispatch => {
  setSubmitting(true);
  const url = isActive
    ? Paths.API.TRIGGER_DEACTIVATE
    : Paths.API.TRIGGER_ACTIVATE;

  const promise = dispatch(
    fetchData(reverse(url, { id }), {
      method: 'POST',
    }),
  );
  promise
    .then(json => json.json())
    .then(result => {
      const { pool_trigger, message } = result;

      if (pool_trigger) {
        setSubmitting(false);
        setActive(pool_trigger.active);
        dispatch(triggerActivated(id, pool_trigger.active));
        dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
      }
    });
};

export const fetchTriggersAction = pool_id => async dispatch => {
  dispatch(triggersRequested());

  const promise = dispatch(
    fetchData(reverse(Api.TRIGGERS_BY_POOL, { pool_id })),
  );
  promise
    .then(json => json.json())
    .then(({ pool_triggers }) => {
      if (pool_triggers) {
        dispatch(triggersSucceeded(pool_triggers));
      } else {
        dispatch(triggersFailed());
      }
    })
    .catch(() => {
      dispatch(triggersFailed());
    });
};
