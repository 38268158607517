// TODO: Field is Temporarily hide on the media overview need to fix function.
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Upload from 'components/form/upload/Upload';
import fetchData from 'store/fetchData';
import { infoBarHide, setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import { addMediaAction } from 'store/media/mediaActions';
import { Api } from 'constants/Routes.enum';

import './Upload.scss';

const MediaUpload = ({ poolId, folderId, size }) => {
  const dispatch = useDispatch();
  const authToken = useSelector(state => state.auth.auth_token);
  const [file, setFile] = useState(null);

  const onFileDrop = (name, file) => {
    setFile(file);

    const mediaData = new FormData();
    mediaData.append('media[img_file]', file);
    mediaData.append('media[name]', file.name);

    if (poolId) {
      mediaData.append('media[pool_id]', poolId);
    }

    if (folderId) {
      mediaData.append('media[media_folder_id]', folderId);
    }

    const promise = dispatch(
      fetchData(Api.MEDIA_POST, {
        method: 'POST',
        headers: {
          Authorization: authToken,
        },
        body: mediaData,
      }),
    );

    promise
      .then(json => json.json())
      .then(result => {
        const { media, message } = result;
        if (media) {
          dispatch(addMediaAction(result));

          setTimeout(() => {
            setFile(null);
          }, 5000);
        } else if (message) {
          setFile(null);
          if (file?.type === 'text/html') {
            // TODO: When back-end returns correct message return message from back-end instead of hardcoded via the front-end
            dispatch(
              setInfoBar({
                message:
                  'Media item contains invalid content. Correct the contents and try uploading again.',
                state: InfoBarState.ERROR,
                timeout: 5000,
              }),
            );
          } else {
            dispatch(
              setInfoBar({ message, state: InfoBarState.ERROR, timeout: 5000 }),
            );
          }

          setTimeout(() => {
            dispatch(infoBarHide());
          }, 5000);
        }
      })
      .catch(() => {
        dispatch(
          setInfoBar({
            message: 'Could not add media item',
            state: InfoBarState.ERROR,
            timeout: 5000,
          }),
        );
      });
  };

  return (
    <div className="media-upload">
      <Upload
        id="mediaupload"
        name="mediaupload"
        file={file}
        onChange={onFileDrop}
        size={size}
      />
    </div>
  );
};

MediaUpload.propTypes = {
  poolId: PropTypes.number,
  folderId: PropTypes.number,
  size: PropTypes.oneOf(['', 'small', 'medium']),
};

MediaUpload.defaultProps = {
  poolId: undefined,
  folderId: undefined,
  size: '',
};

export default MediaUpload;
