import { Api } from 'constants/Routes.enum';
import { reverse } from 'named-urls';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

import PopupTypes from 'constants/PopupTypes.enum';

import fetchData from 'store/fetchData';
import { setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import {popupActionClear, popupActionSet, popupActionUpdate} from 'store/popup/popupActions';
import {
  InputDevices,
  InputDevice,
  RootState,
  Location,
} from 'store/rootState';
import { InputDeviceAddProps } from 'components/popup/inputs/inputDevices/inputDeviceAdd.types';

import { InputDevicesActionType } from './inputDevicesActions.enum';
import attachedInputSignalsAreAllOnline from "../../../components/popup/inputs/inputDevices/helpers";

export const inputDevicesRequested = () => ({
  type: InputDevicesActionType.INPUT_DEVICES_REQUESTED,
});

export const inputDevicesSucceeded = (inputDevices: InputDevices) => ({
  type: InputDevicesActionType.INPUT_DEVICES_SUCCEEDED,
  payload: inputDevices,
});

export const inputDevicesFailed = () => ({
  type: InputDevicesActionType.INPUT_DEVICES_FAILED,
});

export const inputDeviceAdded = (inputDevice: InputDevice) => ({
  type: InputDevicesActionType.INPUT_DEVICE_ADDED,
  payload: inputDevice,
});

export const inputDeviceUpdated = (inputDevice: InputDevice) => ({
  type: InputDevicesActionType.INPUT_DEVICE_UPDATED,
  payload: inputDevice,
});

export const inputDeviceInputSignalsActivated = (inputDevice: InputDevice) => ({
  type: InputDevicesActionType.INPUT_DEVICE_INPUT_SIGNALS_ACTIVATED,
  payload: inputDevice,
});

export const inputDeviceInputSignalsDeactivated = (
  inputDevice: InputDevice,
) => ({
  type: InputDevicesActionType.INPUT_DEVICE_INPUT_SIGNALS_DEACTIVATED,
  payload: inputDevice,
});

export const inputDeviceDetailsRequested = () => ({
  type: InputDevicesActionType.INPUT_DEVICE_DETAILS_REQUESTED,
});

export const inputDeviceDetailsSuccess = (inputDevice: InputDevice) => ({
  type: InputDevicesActionType.INPUT_DEVICE_DETAILS_SUCCEEDED,
  payload: inputDevice,
});

export const inputDeviceDetailsFailed = () => ({
  type: InputDevicesActionType.INPUT_DEVICE_DETAILS_FAILED,
});

export const inputDeviceDeleted = (id: number) => ({
  type: InputDevicesActionType.INPUT_DEVICE_DELETED,
  payload: {
    id,
  },
});

export const fetchInputDevicesAction = ({
  keyword,
  location_id,
}: {
  keyword?: string;
  location_id?: number;
}): ThunkAction<void, RootState, undefined, AnyAction> => async dispatch => {
  dispatch(inputDevicesRequested());

  const params = new URLSearchParams();

  if (keyword) {
    params.append('name', keyword);
  }
  if (location_id) {
    params.append('location_id', location_id.toString());
  }

  let url = Api.INPUT_DEVICES as string;

  if (params) {
    url = `${Api.INPUT_DEVICES}?${params.toString()}`;
  }

  const promise = dispatch(fetchData(url));
  promise
    .then(json => json.json())
    .then(result => {
      const { input_devices } = result;

      if (input_devices) {
        dispatch(inputDevicesSucceeded(input_devices));
      } else {
        dispatch(inputDevicesFailed());
      }
    })
    .catch(() => {
      dispatch(inputDevicesFailed());
    });
};

export const createInputDeviceAction = (
  values: InputDeviceAddProps,
  setSubmitting: Function,
): ThunkAction<void, RootState, undefined, AnyAction> => dispatch => {
  const inputDeviceData = new FormData();
  inputDeviceData.append(
    'input_device[name]',
    values.name || values.serial_number.toUpperCase(),
  );
  inputDeviceData.append(
    'input_device[serial_number]',
    values.serial_number.toUpperCase(),
  );

  if (values.locations.length > 0) {
    values.locations.forEach((location: Location) => {
      inputDeviceData.append(
        'input_device[location_ids][]',
        location.id.toString(),
      );
    });
  } else {
    inputDeviceData.append('input_device[location_ids][]', '');
  }

  const promise = dispatch(
    fetchData(
      Api.INPUT_DEVICES_POST,
      {
        method: 'POST',
        body: inputDeviceData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message, input_device } = result;
      if (input_device) {
        dispatch(popupActionClear());
        dispatch(inputDeviceAdded(input_device));
        dispatch(
          setInfoBar({ message, state: InfoBarState.CHECK, timeout: 5000 }),
        );
      } else {
        dispatch(
          setInfoBar({ message, state: InfoBarState.ERROR, timeout: 5000 }),
        );
      }
    })
    .catch(err => {
      dispatch(
        setInfoBar({
          message: err.toString(),
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    })
    .finally(() => {
      setSubmitting(false);
    });
};

export const openInputDeviceDetailsAction = (
  id: number,
): ThunkAction<void, RootState, undefined, AnyAction> => async dispatch => {
  dispatch(inputDeviceDetailsRequested());
  const promise = dispatch(
    fetchData(reverse(Api.INPUT_DEVICES_DETAIL, { id })),
  );
  promise
    .then(json => json.json())
    .then(result => {
      const { input_device } = result;

      dispatch(inputDeviceDetailsSuccess(input_device));
      dispatch(
        popupActionSet(PopupTypes.INPUTS_INPUT_DEVICE_EDIT, {
          ...input_device,
        }),
      );
    })
    .catch(err => {
      dispatch(inputDeviceDetailsFailed());
      console.error(err);
      // dispatch(setInfoBar({ message: err, state: InfoBarState.ERROR, timeout: 5000 }));
    });
};

export const editInputDeviceAction = (
  values: InputDevice,
  setSubmitting: Function,
): ThunkAction<void, RootState, undefined, AnyAction> => async dispatch => {
  const inputDeviceData = new FormData();

  inputDeviceData.append(
    'input_device[name]',
    values.name || values.serial_number.toUpperCase(),
  );

  if (values.locations.length > 0) {
    values.locations.forEach((location: Location) => {
      inputDeviceData.append(
        'input_device[location_ids][]',
        location.id.toString(),
      );
    });
  } else {
    inputDeviceData.append('input_device[location_ids][]', '');
  }

  const promise = dispatch(
    fetchData(
      reverse(Api.INPUT_DEVICES_PATCH, { id: values.id }),
      {
        method: 'PATCH',
        body: inputDeviceData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { input_device, message } = result;
      dispatch(popupActionClear());
      dispatch(inputDeviceUpdated(input_device));
      dispatch(
        setInfoBar({ message, state: InfoBarState.CHECK, timeout: 5000 }),
      );
    })
    .catch(err => {
      console.error(err);
      setSubmitting(false);
      dispatch(
        setInfoBar({
          message: 'Failed to edit input device',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const toggleActivateAttachedInputSignalsAction = (
  id: number,
  isActive: boolean,
  setSubmitting: Function,
): ThunkAction<void, RootState, undefined, AnyAction> => dispatch => {
  setSubmitting(true);
  dispatch(inputDeviceDetailsRequested());

  const url = isActive
    ? Api.INPUT_DEVICES_DEACTIVATE_SIGNALS
    : Api.INPUT_DEVICES_ACTIVATE_SIGNALS;

  const promise = dispatch(
    fetchData(reverse(url, { id }), {
      method: 'POST',
    }),
  );
  promise
    .then(json => json.json())
    .then(result => {
      const { input_device, message } = result;
      const status = attachedInputSignalsAreAllOnline(input_device.input_signals);

      setSubmitting(false);
      dispatch(popupActionUpdate({input_signals : input_device.input_signals}))
      if (status) {
        dispatch(inputDeviceInputSignalsActivated(input_device));
      } else {
        dispatch(inputDeviceInputSignalsDeactivated(input_device));
      }
      dispatch(
        setInfoBar({ message, state: InfoBarState.CHECK, timeout: 5000 }),
      );
    });
};

export const deleteInputDeviceAction = (
  id: number,
  showNotification: boolean,
): ThunkAction<void, RootState, undefined, AnyAction> => dispatch => {
  // call delete endpoint
  const promise = dispatch(
    fetchData(reverse(Api.INPUT_DEVICES_DELETE, { id }), {
      method: 'DELETE',
    }),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message } = result;

      // input device is deleted successfully - clear popup and show message
      dispatch(inputDeviceDeleted(id));
      dispatch(popupActionClear());
      if (showNotification) {
        dispatch(
          setInfoBar({ message, state: InfoBarState.CHECK, timeout: 5000 }),
        );
      }
    })
    .catch(err => {
      // input device could not be deleted - show error
      console.error(err);
      dispatch(
        setInfoBar({ message: err, state: InfoBarState.ERROR, timeout: 5000 }),
      );
    });
};
