import classNames from 'classnames';
import { reverse } from 'named-urls';
import React, { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import BlankButton from 'components/button/BlankButton';
import { Paths } from 'constants/Routes.enum';

import { RootState } from 'store/rootState';

import './Breadcrumbs.scss';

interface BreadcrumbsProps {
  renderCurrentFolder?: boolean
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ renderCurrentFolder = false }) => {
  type BreadcrumbsParams = {
    id: string;
    folder_id: string;
  };

  const history = useHistory();
  const { state } = history.location;
  const { id, folder_id } = useParams<BreadcrumbsParams>();
  const storedBreadcrumbs = useSelector(
    (state: RootState) => state.media.breadcrumbs
  );

  const breadcrumbs = useMemo(() => {
    if (!storedBreadcrumbs) return []
    if (renderCurrentFolder) return storedBreadcrumbs

    if (folder_id) {
      return storedBreadcrumbs.filter(item => item.id !== Number(folder_id))
    }

    return storedBreadcrumbs.slice(0, -1)
  }, [storedBreadcrumbs, folder_id, renderCurrentFolder])

  const folderPath = useCallback(
    (folder_id: number | undefined) => {
      switch (true) {
        case id !== undefined: {
          if (folder_id !== undefined) {
            return reverse(Paths.POOLS_MEDIA_FOLDER, {
              id: Number(id),
              folder_id,
            });
          }
          return reverse(Paths.POOLS_MEDIA, { id: Number(id) });
        }
        default: {
          if (folder_id) {
            return reverse(Paths.MEDIA_FOLDER, { folder_id });
          }
          return Paths.MEDIA;
        }
      }
    },
    [id],
  );

  const itemClick = useCallback(
    (id?: number) => {
      const path = folderPath(id);
      history.push({ pathname: path, state });
    },
    [history, state, folderPath],
  );

  return (
    <div className={classNames('breadcrumbs')}>
      <BlankButton
        className="breadcrumb__link"
        tag="button"
        handler={() => itemClick()}
      >
        All media
      </BlankButton>

      {breadcrumbs?.map(
        ({ title, id }) => title && (
          <BlankButton
            className="breadcrumb__link"
            tag="button"
            handler={() => itemClick(id)}
            key={id}
          >
            {title}
          </BlankButton>
        ))}
    </div>
  );
};

export default Breadcrumbs;
