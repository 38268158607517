import React, { FC } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';

import { Paths } from 'constants/Routes.enum';
import useAuthLogout from 'hooks/useAuthLogout';
import IconButton from 'components/button/IconButton';
import Label from 'components/form/label/Label';
import Text from 'components/typography/text/Text';
import PopupContext from 'components/popup-context/PopupContext';
import Divider from 'components/popup-context/divider/Divider';
import { List, ListItem } from 'components/popup-context/list/List';

import { RootState } from 'store/rootState';

import { AccountMenuProps } from './AccountMenu.types';
import './AccountMenu.scss';

const AccountMenu: FC<AccountMenuProps> = ({
  disabled,
}) => {
  const [logoutAction] = useAuthLogout();
  const location = useLocation();
  const user = useSelector((state: RootState) => state?.auth.user);
  const profilePageActive = matchPath(location.pathname, Paths.ACCOUNT_SETTINGS)?.isExact


  return (
    <PopupContext
      className="account-menu"
      contentClassName="account-menu__menu"
      disabled={disabled}
      button={
        <IconButton
          size="extra-small"
          disabled={disabled}
          iconSize="large"
          iconName="account"
          className={classNames('account-menu__icon', {
            'account-menu--active': !!profilePageActive,
            'account-menu__icon--disabled': disabled,
          })}
        />
      }
    >
      <Label label="Logged in as" color="grey" />
      <Text size="x-large" tag="div">
        {user.name}
      </Text>
      <Divider />
      <List flatten>
        <ListItem>
          <IconButton
            tag="a"
            iconName="settings"
            iconSize="small"
            text="Account settings"
            size="extra-small"
            link={Paths.ACCOUNT_SETTINGS}
          />
        </ListItem>
        <ListItem>
          <IconButton
            tag="button"
            iconName="logout"
            iconSize="small"
            text="Log out"
            size="extra-small"
            handler={logoutAction}
          />
        </ListItem>
      </List>
    </PopupContext>
  );
};

export default AccountMenu;
