import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import './List.scss';
import { ListProps, ListItemProps } from './List.types';

export const List: React.FC<PropsWithChildren<ListProps>> = ({ children, flatten = false }) => {
  return (
    <ul
      className={classNames('popup-context__list', {
        'popup-context__list--flatten': flatten,
      })}
    >
      {children}
    </ul>
  );
};

export const ListItem: React.FC<ListItemProps> = ({ children }) => {
  return <li className="popup-context__list-item">{children}</li>;
};
