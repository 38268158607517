import { PopupActionTypes } from './popupActions';

export const popupInitialState = {
  type: null,
  data: null,
  previousState: null,
};

const popupReducer = (state = popupInitialState, action) => {
  console.log(state, action);

  switch (action.type) {
    case PopupActionTypes.POPUP_SET:
      return {
        ...action.payload,
        previousState: state.type
          ? {
              type: state.type,
              data: state.data,
            }
          : null,
      };
    case PopupActionTypes.POPUP_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        previousState: {
          type: state.type,
          data: state.data,
        },
      };

    case PopupActionTypes.POPUP_BACK:
      return {
        ...state.previousState,
        previousState: null,
      };
    case PopupActionTypes.POPUP_CLEAR:
      return {
        ...popupInitialState,
      };
    case PopupActionTypes.POPUP_ARRANGEMENT_AREA_MEDIA_ITEM_DELETED:
      return {
        ...state,
        data: {
          ...state.data,
          media: {
            ...state.data.media,
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default popupReducer;
