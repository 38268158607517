import React from 'react';
import { useDispatch } from 'react-redux';

import Button from 'components/button/Button';
import AddButtonWithContextMenu from 'components/button/AddButtonWithContextMenu';
import Heading from 'components/typography/heading/Heading';
import { List, ListItem } from 'components/popup-context/list/List';
import { popupActionSet } from 'store/popup/popupActions';
import PopupTypes from 'constants/PopupTypes.enum';

const ArrangementAddButton = () => {
  const dispatch = useDispatch();

  return (
    <AddButtonWithContextMenu
      buttonHoverText="Add show"
      dataCy="addArrangementButton"
    >
      <Heading level={3}>Create a show</Heading>
      <List flatten>
        <ListItem>
          <Button
            data-cy="addRegularArrangementButton"
            scheme="link"
            text="Regular show"
            hasShadow={false}
            size="small"
            handler={() =>
              dispatch(
                popupActionSet(PopupTypes.ARRANGEMENT, {
                  type: 'RegularArrangement',
                }),
              )
            }
          />
        </ListItem>
        <ListItem>
          <Button
            data-cy="addTriggerArrangementButton"
            scheme="link"
            text="Trigger show"
            hasShadow={false}
            size="small"
            handler={() =>
              dispatch(
                popupActionSet(PopupTypes.ARRANGEMENT, {
                  type: 'TriggerArrangement',
                }),
              )
            }
          />
        </ListItem>
      </List>
    </AddButtonWithContextMenu>
  );
};

export default ArrangementAddButton;
