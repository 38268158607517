import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OverviewList from 'components/overviewList/OverviewList';
import Status from 'components/status/Status';
import DeckEmpty from 'components/deck/DeckEmpty';
import Ellipsis from 'components/typography/ellipsis/Ellipsis';

import { RootState, InputSignal, InputSignalStatus } from 'store/rootState';
import {
  fetchInputSignalsAction,
  openInputSignalDetailsAction,
} from 'store/inputSignals/inputSignals/inputSignalsActions';

import { MappedInputSignalsItemsType, MappedInputSignalsType } from './InputSignalsOverview.types';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { hasSuperAdminRights } from 'store/auth/hasUserRights';
import { OverviewListHeader } from 'components/overviewList/OverviewList.types';

const InputSignalsOverview = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { request, inputSignals } = useSelector(
    (state: RootState) => state.inputs.inputSignals,
  );
  const { customerOverride } = useSelector((state: RootState) => state.superAdmin);

  const isSuperAdmin = dispatch(hasSuperAdminRights());

  useEffect(() => {
    dispatch(fetchInputSignalsAction());
  }, [dispatch, customerOverride]);

  const mappedInputDevices = useMemo<MappedInputSignalsType[]>(() => {
    let signals = inputSignals ?? [];
    if (isSuperAdmin && customerOverride) {
      signals = signals.filter((signal) => signal.customer_id === Number(customerOverride))
    }
    return signals.map((inputSignal: InputSignal) => {
      const {
        id,
        status,
        name,
        input_device,
        locations,
        type,
        times_used,
        customer
      } = inputSignal;

      return {
        key: id.toString(),
        id: id.toString(),
        items: [
          {
            key: `${id}_status`,
            width: '25px',
            content: (
              <Status
                isActive={status === InputSignalStatus.ACTIVE}
                hasText={false}
                title={status}
              />
            ),
          },
          {
            key: `${id}_name`,
            content: <Ellipsis text={name} />,
            width: isSuperAdmin ? '240px' : '300px',
          },
          {
            key: `${id}_input_device`,
            width: '190px',
            content: input_device ? (
              <Ellipsis text={input_device?.name} />
            ) : (
              'Unassigned'
            ),
            status: input_device ? 'active' : 'disabled',
          },
          {
            key: `${id}_locations`,
            width: '180px',
            content:
              locations.length > 0 ? (
                <Ellipsis
                  text={locations
                    .map(location => location.name)
                    .sort()
                    .join(', ')}
                />
              ) : (
                'Unassigned'
              ),
            status: locations.length > 0 ? 'active' : 'disabled',
          },
          {
            key: `${id}_type`,
            width: '115px',
            content: <Ellipsis text={type} />,
          },
          {
            key: `${id}_times_used`,
            width: '85px',
            content: times_used,
          },
          isSuperAdmin ? {
            key: `${id}_customer`,
            width: '100px',
            content: customer ? (
              <Ellipsis text={customer} />
            ) : undefined
          } : undefined
        ].filter(Boolean) as MappedInputSignalsItemsType[],
        onClick: () => {
          dispatch(openInputSignalDetailsAction(id));
        },
      };
    });
  }, [dispatch, inputSignals, isSuperAdmin, customerOverride]);

  const overviewHeaders = [
    { key: 'status', content: '', width: '25px' },
    { key: 'name', content: 'Name', width: isSuperAdmin ? '240px' : '300px' },
    { key: 'input_device', content: 'Input device', width: '190px' },
    { key: 'locations', content: 'Locations', width: '180px' },
    { key: 'type', content: 'Type', width: '115px' },
    { key: 'times_used', content: 'Times used', width: '85px' },
    isSuperAdmin ? { key: 'customer', content: 'Customer', width: '100px' } : undefined
  ].filter(h => h !== undefined) as OverviewListHeader[];

  return (
    <>
      {!request && inputSignals && inputSignals.length === 0 ? (
        <DeckEmpty title="No input signals found" />
      ) : (
        <OverviewList
          headers={overviewHeaders}
          content={mappedInputDevices}
          loading={request}
          cardProps={{ highlightOnHover: true, interactive: true }}
        />
      )}
    </>
  );
};

export default InputSignalsOverview;
