import Button from 'components/button/Button';
import Heading from 'components/typography/heading/Heading';
import Text from 'components/typography/text/Text';
import React, { PropsWithChildren } from 'react';

import './BigForm.scss';
import { BigFormProps } from './BigForm.types';

export const BigForm = ({
  handleSubmit,
  title,
  fields,
  errorMessages,
  submitButtonText,
  footerText,
  isSubmitting,
  aboveSubmitButtonElements,
  dataCy,
}: BigFormProps) => {
  return (
    <>
      <form onSubmit={handleSubmit} className="form" data-cy={dataCy}>
        {title && (
          <Heading level={1} stylingLevel={2} className="big-form__title">
            {title}
          </Heading>
        )}
        <div className="big-form__fields-container">{fields}</div>
        {errorMessages}
        {aboveSubmitButtonElements}
        <Button
          className="big-form__submit-button"
          tag="button"
          type="submit"
          size="large"
          text={submitButtonText}
          disabled={isSubmitting}
          block
        />
      </form>
      {footerText && (
        <Text
          tag="div"
          size="small"
          color="grey-dark"
          flatten
          className="big-form__footer-text"
        >
          {footerText}
        </Text>
      )}
    </>
  );
};

export const BigFormField = ({ children }: PropsWithChildren) => {
  return <div className="login-page__field">{children}</div>;
};
