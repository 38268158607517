import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Paths, PrivatePaths } from 'constants/Routes.enum';
import { RootState } from 'store/rootState';

const useAuthRouting = () => {
  const history = useHistory();

  const matchPrivateRoutes = useRouteMatch(PrivatePaths);
  const matchLoginPage = useRouteMatch([Paths.LOGIN]);
  const matchRequestPasswordPage = useRouteMatch([Paths.REQUEST_PASSWORD]);
  const matchResetPasswordPage = useRouteMatch([Paths.RESET_PASSWORD]);
  const match2FAPage = useRouteMatch([Paths.TWOFACTOR]);

  const authToken = useSelector((state: RootState) => state.auth?.auth_token);
  const twoFactorRequired = useSelector(
    (state: RootState) => state.auth?.two_factor_required,
  );
  const twoFactorIsSet = useSelector(
    (state: RootState) => state.auth?.two_factor_is_set,
  );

  useEffect(() => {
    // Redirect for Login
    if (
      !authToken &&
      matchLoginPage === null &&
      matchRequestPasswordPage === null &&
      matchResetPasswordPage === null
    ) {
      history.replace(Paths.LOGIN);
    }

    if (authToken) {
      // Redirect for 2FA
      if (twoFactorRequired && !twoFactorIsSet && match2FAPage === null) {
        history.replace(Paths.TWOFACTOR);
      }

      // Redirect for private pages
      if (
        (!twoFactorRequired || (twoFactorRequired && twoFactorIsSet)) &&
        matchPrivateRoutes === null
      ) {
        history.replace(Paths.POOLS);
      }
    }
  }, [
    history,
    authToken,
    matchPrivateRoutes,
    matchLoginPage,
    matchRequestPasswordPage,
    matchResetPasswordPage,
    match2FAPage,
    twoFactorRequired,
    twoFactorIsSet,
  ]);
};

export default useAuthRouting;
