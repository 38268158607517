import { UsersActionTypes } from './usersActions';

export const usersInitialState = {
  request: false,
  users: [],
};

const usersReducer = (state = usersInitialState, action) => {
  switch (action.type) {
    case UsersActionTypes.USERS_REQUESTED:
      return { ...state, request: true };
    case UsersActionTypes.USERS_SUCCEEDED:
      return {
        ...state,
        users: action.payload,
        request: false,
      };
    case UsersActionTypes.USERS_FAILED:
      return {
        ...usersInitialState,
        request: false,
      };
    case UsersActionTypes.USER_ADDED:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    case UsersActionTypes.USERS_UPDATED:
      return {
        ...state,
        users: state.users.map(user => {
          if (user.id === action.payload.id) {
            return {
              ...action.payload,
            };
          }
          return user;
        }),
      };
    default:
      return state;
  }
};

export default usersReducer;
