import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import { popupActionClear } from 'store/popup/popupActions';
import Icon from 'components/icon/Icon';
import { addPoolAction } from 'store/pool/poolActions';
import LocationDropdown from 'components/location/dropdown/LocationDropdown';
import SubmitButton from 'components/form/submit-button/SubmitButton';
import {
  PopupStickyForm,
  PopupStickyHeader,
  PopupStickyMain,
  PopupStickyFooter,
} from 'components/popup/Popup';

const Group = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const initialForm = {
    name: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    location: Yup.number().required('Location is required'),
  });

  const onSubmit = values => {
    setSubmitting(true);
    dispatch(addPoolAction(values, history, setSubmitting));
  };

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        submitCount,
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit,
        initialTouched,
      }) => (
        <PopupStickyForm onSubmit={handleSubmit}>
          <PopupStickyHeader>
            <fieldset>
              <FormBlock flatten>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="New group"
                  value={values.name}
                  error={touched.name && errors.name}
                  onChange={handleChange}
                  fontSize="large"
                  suffix={<Icon name="edit" />}
                />
              </FormBlock>
            </fieldset>
          </PopupStickyHeader>
          <PopupStickyMain>
            <fieldset>
              <FormBlock flatten>
                <LocationDropdown
                  id="group_location"
                  name="location"
                  label="Location"
                  value={values.location}
                  error={
                    (touched.location || submitCount > 0) && errors.location
                  }
                  onChange={setFieldValue}
                />
              </FormBlock>
            </fieldset>
          </PopupStickyMain>
          <PopupStickyFooter>
            <FormBlock hasInlineChildren flatten>
              <Button
                tag="button"
                type="submit"
                size="medium"
                text="Cancel"
                scheme="link"
                hasShadow={false}
                handler={() => {
                  dispatch(popupActionClear());
                }}
                disabled={isSubmitting}
              />
              <SubmitButton canSave={initialTouched} disabled={isSubmitting} />
            </FormBlock>
          </PopupStickyFooter>
        </PopupStickyForm>
      )}
    </Formik>
  );
};

export default Group;
