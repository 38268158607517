import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router';

import Heading from 'components/typography/heading/Heading';
import Container from 'components/container/Container';
import Text from 'components/typography/text/Text';
import { TextColor, TextTag } from 'components/typography/text/Text.enum';
import SubNavigation from 'components/navigation/SubNavigation';
import { Paths } from 'constants/Routes.enum';
import UsersOverview from 'pages/management/users/UsersOverview';
import LocationsOverview from 'pages/management/locations/LocationsOverview';
import UserRolesOverview from 'pages/management/userRoles/UserRolesOverview';
import { RootState } from 'store/rootState';

const Management = () => {
  const { customer } = useSelector((state: RootState) => state.auth);

  const routes = [
    {
      Key: 'locations',
      Path: Paths.MANAGEMENT_LOCATIONS,
      Name: 'Locations',
      ViewRights: true,
    },
    {
      Key: 'users',
      Path: Paths.MANAGEMENT_USERS,
      Name: 'Users',
      ViewRights: true,
    },
    {
      Key: 'user-roles',
      Path: Paths.MANAGEMENT_USER_ROLES,
      Name: 'User roles',
      ViewRights: true,
    },
  ];

  return (
    <div className="detail-page">
      <Container>
        {customer && (
          <Text tag={TextTag.P} color={TextColor.GREYBLACK}>
            {customer}
          </Text>
        )}
        <Heading level={1}>Management</Heading>
      </Container>

      <SubNavigation title="Management" routes={routes} />

      <Container className="detail-page__content">
        <Switch>
          <Route path={Paths.MANAGEMENT_USERS} component={UsersOverview} />
          <Route
            path={Paths.MANAGEMENT_LOCATIONS}
            component={LocationsOverview}
          />
          <Route
            path={Paths.MANAGEMENT_USER_ROLES}
            component={UserRolesOverview}
          />
        </Switch>
      </Container>
    </div>
  );
};

export default Management;
