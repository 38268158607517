import React, { FC } from 'react';
import { ErrorMessage } from 'formik';
import './FormMessage.scss';
import classNames from 'classnames';
import { FormMessageProps } from './FormMessage.types';

const FormMessage: FC<FormMessageProps> = ({
  name = '',
  type,
  errorMessage = null,
  belowField = false,
  dataCy = null,
}) => {
  switch (true) {
    case type === 'formikErrorMessage':
      return (
        <ErrorMessage
          component="div"
          className={classNames('form-message', {
            'form-message--general': !belowField,
            'form-message--below-field': belowField,
          })}
          name={name}
          data-cy={dataCy}
        />
      );
    case errorMessage !== null:
      return (
        <div
          className={classNames('form-message', {
            'form-message--general': errorMessage && !belowField,
            'form-message--below-field': errorMessage && belowField,
          })}
          data-cy={dataCy}
        >
          {errorMessage}
        </div>
      );
    default:
      return null;
  }
};

export default FormMessage;
