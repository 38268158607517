import PropTypes from 'prop-types';

export const MediaTypes = PropTypes.oneOf([
  'video',
  'image',
  'html',
  'rss',
  'jackpot',
  'ticker_tape',
  'other',
  'rich_text',
]);

export const MediaItemTypes = PropTypes.oneOf([
  null,
  'html',
  'rtsp',
  'rss',
  'ticker_tape',
  'jackpot',
]);
