import { reverse } from 'named-urls';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { useParams } from 'react-router-dom';

import Button from 'components/button/Button';
import Container from 'components/container/Container';
import LabelValueList from 'components/list/labelValueList/LabelValueList';
import { LabelValueListStyling } from 'components/list/labelValueList/LabelValueList.types';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import SubNavigation from 'components/navigation/SubNavigation';
import Heading from 'components/typography/heading/Heading';
import Text from 'components/typography/text/Text';

import { Paths } from 'constants/Routes.enum';
import UserRight from 'constants/UserRight.enum';

import { hasUserRights } from 'store/auth/hasUserRights';
import { fetchPool } from 'store/pool/poolActions';
import { RootState } from 'store/rootState';

import PoolsArrangements from 'pages/pools/arrangements/Arrangements';
import PoolsDevices from 'pages/pools/devices/Devices';
import PoolsInfo from 'pages/pools/info/Info';
import PoolsMedia from 'pages/pools/media/Media';
import PoolsSchedules from 'pages/pools/schedules/Schedules';
import PoolsTrigger from 'pages/pools/triggers/Triggers';

import './Detail.scss';

const PoolsDetail = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id?: string }>();
  const pool = useSelector((state: RootState) => state.pool);

  const hasMediaViewRights = dispatch(hasUserRights(UserRight.MEDIA_VIEW));
  const hasArrangementsViewRights = dispatch(
    hasUserRights(UserRight.ARRANGEMENTS_VIEW),
  );
  const hasEventsViewRights = dispatch(hasUserRights(UserRight.EVENTS_VIEW));
  const hasDevicesViewRights = dispatch(hasUserRights(UserRight.SIGNS_VIEW));
  const hasTriggersViewRights = dispatch(
    hasUserRights(UserRight.TRIGGERS_VIEW),
  );

  useEffect(() => {
    const paramsId = Number(params.id);
    if (pool.id === undefined || paramsId !== pool.id) {
      dispatch(fetchPool(paramsId));
    }

    // TODO: Fix exhaustive-deps warning
    // eslint-disable-next-line
  }, [dispatch]);

  const list = [
    {
      id: 1,
      value: (
        <>
          <Button
            hasShadow={false}
            scheme="link"
            link={Paths.POOLS}
            text="Return to the group overview."
            size="inline"
          />
        </>
      ),
    },
    {
      id: 2,
      value: (
        <>
          Are you stuck? Contact your supervisor or send an email to{' '}
          <Button
            hasShadow={false}
            scheme="link"
            link={`mailto:${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
            text={`${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
            size="inline"
          />
        </>
      ),
    },
  ];

  const routes = [
    {
      Key: 'schedules',
      Path: reverse(Paths.POOLS_SCHEDULES, { id: pool.id }),
      Name: 'Schedules',
      ViewRights: hasEventsViewRights,
    },
    {
      Key: 'triggers',
      Path: reverse(Paths.POOLS_TRIGGERS, { id: pool.id }),
      Name: 'Triggers',
      ViewRights: hasTriggersViewRights,
    },
    {
      Key: 'arrangements',
      Path: reverse(Paths.POOLS_ARRANGEMENTS, { id: pool.id }),
      Name: 'Shows',
      ViewRights: hasArrangementsViewRights,
    },
    {
      Key: 'media',
      Path: reverse(Paths.POOLS_MEDIA, { id: pool.id }),
      Name: 'Media',
      ViewRights: hasMediaViewRights,
    },
    {
      Key: 'devices',
      Path: reverse(Paths.POOLS_DEVICES, { id: pool.id }),
      Name: 'Devices',
      ViewRights: hasDevicesViewRights,
    },
    {
      Key: 'details',
      Path: reverse(Paths.POOLS_INFO, { id: pool.id }),
      Name: 'Details',
      ViewRights: true,
    },
  ];

  return (
    <div className="detail-page">
      {(!pool.request && (
        <>
          {pool.id !== 0 && (
            <>
              <Container>
                <Text tag="p" color="grey-dark">
                  {pool.location?.name}
                </Text>
                <Heading dataCy="poolDetailsHeaderTitle" level={1}>
                  {pool.name}
                </Heading>
              </Container>

              <SubNavigation routes={routes} title={pool.name} />

              <Container className="detail-page__content">
                <Switch>
                  <Route
                    path={Paths.POOLS_SCHEDULES}
                    component={PoolsSchedules}
                  />
                  <Route path={Paths.POOLS_TRIGGERS} component={PoolsTrigger} />
                  <Route
                    path={Paths.POOLS_ARRANGEMENTS}
                    component={PoolsArrangements}
                  />
                  <Route
                    path={[Paths.POOLS_MEDIA_FOLDER, Paths.POOLS_MEDIA]}
                    component={PoolsMedia}
                  />
                  <Route path={Paths.POOLS_DEVICES} component={PoolsDevices} />
                  <Route path={Paths.POOLS_INFO} component={PoolsInfo} />
                </Switch>
              </Container>
            </>
          )}

          {pool.id === 0 && (
            <Container>
              <h1>Group cannot be found</h1>
              <p>The group you are looking for cannot be found.</p>
              <h2>What to do next?</h2>
              <LabelValueList values={list} styling={LabelValueListStyling.BULLETS} />
            </Container>
          )}
        </>
      )) || (
        <Container>
          <LoadingIndicator title="Loading group..." />
        </Container>
      )}
    </div>
  );
};

export default PoolsDetail;
