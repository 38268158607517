import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { matchPath, useHistory } from 'react-router-dom';

import Login from 'pages/auth/Login';
import RequestPassword from 'pages/auth/RequestPassword';
import ResetPassword from 'pages/auth/ResetPassword';
import PoolsDetail from 'pages/pools/detail/Detail';
import Overview from 'pages/pools/overview/Overview';

import AccountSettings from 'pages/account/AccountSettings';
import TwoFactor from 'pages/auth/TwoFactor';
import DeviceOverview from 'pages/devices/Overview';
import Inputs from 'pages/inputs/Inputs';
import Management from 'pages/management/Management';
import UserDetails from 'pages/management/users/UserDetails';
import MediaOverview from 'pages/media/Overview';

import PrivateBody from 'components/body/private/Private';
import PublicBody from 'components/body/public/Public';
import InfoBar from 'components/infoBar/InfoBar';
import Notifications from 'components/notifications/Notifications';
import { NotificationLocation } from 'components/notifications/Notifications.enum';
import Popup from 'components/popup/Popup';

import { Paths, PrivatePaths, PublicPaths } from 'constants/Routes.enum';
import UserRights from 'constants/UserRight.enum';

import { hasUserRights } from 'store/auth/hasUserRights';
import useAuthRouting from 'hooks/useAuthRouting';

const App = () => {
  useAuthRouting();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const accountTwoFactorPopupIsActive = useSelector(
    state => state.accountSettings?.two_factor_popup_is_active,
  );
  const accountVerifyEmailPopupIsActive = useSelector(
    state => state.accountSettings?.verify_email_popup_is_active,
  );
  const history = useHistory();

  const accountSettingPopupActive = useMemo(() => {
    const matchesPath = matchPath(
      history.location?.pathname,
      Paths.ACCOUNT_SETTINGS,
    )?.isExact;
    return (
      matchesPath &&
      (accountTwoFactorPopupIsActive || accountVerifyEmailPopupIsActive)
    );
  }, [
    history.location,
    accountTwoFactorPopupIsActive,
    accountVerifyEmailPopupIsActive,
  ]);

  const redirectFromPoolDetail = useCallback(() => {
    const hasEventsViewRights =
      auth.user?.rights && dispatch(hasUserRights(UserRights.EVENTS_VIEW));
    const hasTriggersViewRights =
      auth.user?.rights && dispatch(hasUserRights(UserRights.TRIGGERS_VIEW));
    const hasArrangementsViewRights =
      auth.user?.rights &&
      dispatch(hasUserRights(UserRights.ARRANGEMENTS_VIEW));
    const hasMediaViewRights =
      auth.user?.rights && dispatch(hasUserRights(UserRights.MEDIA_VIEW));
    const hasDevicesViewRights =
      auth.user?.rights && dispatch(hasUserRights(UserRights.SIGNS_VIEW));

    const rights = {
      hasEventsViewRights,
      hasTriggersViewRights,
      hasArrangementsViewRights,
      hasMediaViewRights,
      hasDevicesViewRights,
    };
    switch (true) {
      case rights.hasEventsViewRights:
        return Paths.POOLS_SCHEDULES;
      case rights.hasTriggersViewRights:
        return Paths.POOLS_TRIGGERS;
      case rights.hasArrangementsViewRights:
        return Paths.POOLS_ARRANGEMENTS;
      case rights.hasMediaViewRights:
        return Paths.POOLS_MEDIA;
      case rights.hasDevicesViewRights:
        return Paths.POOLS_DEVICES;
      default:
        return Paths.POOLS_INFO;
    }
  }, [dispatch, auth.user]);

  const privateRoutes = () => (
    <PrivateBody>
      <Switch>
        <Route path={Paths.POOLS} exact component={Overview} />
        <Redirect
          path={Paths.POOLS_DETAIL}
          exact
          to={redirectFromPoolDetail()}
        />
        <Route path={Paths.POOLS_DETAIL} component={PoolsDetail} />
        <Route
          path={[Paths.MEDIA_FOLDER, Paths.MEDIA]}
          component={MediaOverview}
        />
        <Route path={Paths.DEVICES} component={DeviceOverview} />

        <Redirect
          path={Paths.MANAGEMENT}
          exact
          to={Paths.MANAGEMENT_LOCATIONS}
        />
        <Route
          path={[
            Paths.MANAGEMENT_USERS,
            Paths.MANAGEMENT_LOCATIONS,
            Paths.MANAGEMENT_USER_ROLES,
          ]}
          component={Management}
        />

        <Redirect path={Paths.INPUTS} exact to={Paths.INPUTS_DEVICES} />
        <Route
          path={[Paths.INPUTS_DEVICES, Paths.INPUTS_SIGNALS]}
          component={Inputs}
        />

        <Route path={[Paths.MANAGEMENT_USER_DETAILS]} component={UserDetails} />

        <Route
          path={Paths.ACCOUNT_SETTINGS}
          exact
          component={AccountSettings}
        />
      </Switch>

      <InfoBar />

      {accountSettingPopupActive ? (
        <Popup hasBackground={false} size="small" />
      ) : (
        <Popup />
      )}
      {Notifications({ location: NotificationLocation.SIGNED_IN })}
    </PrivateBody>
  );

  const publicRoutes = () => (
    <PublicBody>
      <Route path={Paths.TWOFACTOR} exact component={TwoFactor} />
      <Route path={Paths.LOGIN} component={Login} />
      <Route path={Paths.REQUEST_PASSWORD} exact component={RequestPassword} />
      <Route path={Paths.RESET_PASSWORD} exact component={ResetPassword} />
      <Popup position="center" />

      <InfoBar />
      {Notifications({ location: NotificationLocation.SIGNED_OUT })}
    </PublicBody>
  );

  return (
    <Switch>
      <Route path={PublicPaths} component={publicRoutes} />
      <Route path={PrivatePaths} component={privateRoutes} />
    </Switch>
  );
};

export default App;
