import React, { Fragment } from 'react';
import classNames from 'classnames';

import BlankButton from 'components/button/BlankButton';
import Card from 'components/card/Card';
import Text from 'components/typography/text/Text';
import { TextTag } from 'components/typography/text/Text.enum';

import { OverviewListItemProps } from './OverviewListItem.types';
import {
  OverviewListItemStatus,
  OverviewListItemTag,
} from './OverviewListItem.enum';

import './OverviewListItem.scss';

const OverviewListItem: React.FC<OverviewListItemProps> = ({
  columns,
  dataCyListItem,
  cardProps,
  id,
  onClick,
  loading = false,
  tag = OverviewListItemTag.BUTTON,
  className,
  status = OverviewListItemStatus.DEFAULT,
}) => {
  const listItemContent = () => (
    <Card
      className={classNames('overview-list-item', className, {
        'overview-list-item--loading': loading,
        'overview-list-item--loaded': !loading && tag !== 'div',
        [`overview-list-item--status-${status}`]: status,
      })}
      highlightOnHover={false}
      interactive={false}
      {...cardProps}
    >
      {columns.map(column => (
        <Fragment key={column.key || `${id}_${column.content}`}>
          <Text
            tag={TextTag.SPAN}
            style={{
              flex: column.width ? `0 0 ${column.width}` : undefined,
              textAlign: column.align,
              maxWidth: column.width ? column.width : undefined,
            }}
            className={classNames('overview-list-item__column', {
              [`overview-list-item__column--status-${column.status}`]: column.status,
            })}
            loading={loading}
          >
            {column.content}
          </Text>
        </Fragment>
      ))}
    </Card>
  );

  return (
    <>
      {loading || tag === OverviewListItemTag.DIV ? (
        listItemContent()
      ) : (
        <BlankButton key={id} handler={onClick} data-cy={dataCyListItem}>
          {listItemContent()}
        </BlankButton>
      )}
    </>
  );
};

export default OverviewListItem;
