import React from 'react';
import classNames from 'classnames';

import Label from 'components/form/label/Label';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment-timezone';
import defaultTimezone from 'constants/DefaultTimezone';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import Icon from 'components/icon/Icon';
import Theme from './Theme';

import 'react-datepicker/dist/react-datepicker.css';
import './DateTime.scss';
import { DateTimeInputProps } from './DateTime.types';
import { RootState } from 'store/rootState';
import MomentUtils from '@date-io/moment';

const TimeInput = ({
  label,
  value = new Date(),
  placeholder,
  id,
  name,
  onChange,
  isChanged = false,
  disabled = false,
  error,
}: DateTimeInputProps) => {
  const timeZone = useSelector((state: RootState) => state.pool?.location?.timezone_info);

  return (
    <Label idFor={id} label={label}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <ThemeProvider theme={Theme}>
          <KeyboardTimePicker
            autoOk
            placeholder={placeholder}
            ampm={false}
            variant="inline"
            value={value}
            minutesStep={5}
            onChange={date =>
              onChange(name, moment.tz(date, timeZone || defaultTimezone))
            }
            className={classNames('form__datetime', 'form__datetime--time', {
              'form__datetime--changed': isChanged,
              'form__datetime--disabled': disabled,
            })}
            disabled={disabled}
            helperText={null}
            error={!!error}
            keyboardIcon={
              <Icon
                name="time"
                className={classNames('form__datetime__icon', {
                  'form__datetime__icon--disabled': disabled,
                })}
              />
            }
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Label>
  );
};

export default TimeInput;
