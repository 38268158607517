export const PopupActionTypes = {
  POPUP_SET: '@@popup/set',
  POPUP_UPDATE: '@@popup/update',
  POPUP_CLEAR: '@@popup/clear',
  POPUP_BACK: '@@popup/back',
  POPUP_ARRANGEMENT_AREA_MEDIA_ITEM_DELETED:
    '@@popup/arrangement/mediaItem/deleted',
};

export const popupActionSet = (type, data) => ({
  type: PopupActionTypes.POPUP_SET,
  payload: {
    type,
    data,
  },
});

export const popupActionUpdate = data => ({
  type: PopupActionTypes.POPUP_UPDATE,
  payload: data,
});

export const popupActionClear = () => ({
  type: PopupActionTypes.POPUP_CLEAR,
});

export const popupActionBack = () => ({
  type: PopupActionTypes.POPUP_BACK,
});

export const popupArrangementAreaMediaItemDeleted = spots => ({
  type: PopupActionTypes.POPUP_ARRANGEMENT_AREA_MEDIA_ITEM_DELETED,
  payload: spots,
});
