import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { reverse } from 'named-urls';

import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import ButtonGroup from 'components/button/ButtonGroup';
import Checkbox from 'components/form/checkbox/Checkbox';
import LayoutDropdown from 'components/layout/dropdown/LayoutDropdown';
import Icon from 'components/icon/Icon';
import ArrangementMedia from 'components/arrangement/media/ArrangementMedia';
import Status from 'components/status/Status';
import ArrangementLayout from 'components/arrangement/layout/ArrangementLayout';
import Text from 'components/typography/text/Text';
import {
  PopupStickyForm,
  PopupStickyFooter,
  PopupStickyMain,
  PopupStickyHeader,
} from 'components/popup/Popup';
import PopupLabels from 'components/popup-labels/PopupLabels';
import DeckEmpty from 'components/deck/DeckEmpty';

import UserRights from 'constants/UserRight.enum';
import { Api } from 'constants/Routes.enum';
import RegexOptions from 'constants/RegexOptions';
import {
  ArrangementMediaProps,
  ArrangementPendingChangesProps,
} from 'constants/types/arrangmentTypes';
import ApprovalStates from 'constants/ApprovalStates.enum';

import { hasAdminRights, hasUserRights } from 'store/auth/hasUserRights';
import { popupActionClear } from 'store/popup/popupActions';
import { infoBarHide, setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import {
  fetchArrangementsAction,
  deleteArrangementAction,
  toggleActiveArrangementAction,
  upsertArrangementAction,
  arrangementApproved,
  duplicateArrangementAction,
} from 'store/arrangements/arrangementsActions';
import { setPoolDefaultArrangement } from 'store/pool/poolActions';
import fetchData from 'store/fetchData';

import './Arrangement.scss';

const Arrangement = ({
  id,
  name,
  type,
  media,
  template_id,
  isDefault,
  active,
  pending_approval,
  pending_changes,
}) => {
  const dispatch = useDispatch();
  const pool = useSelector(state => state.pool);
  const infoBar = useSelector(state => state.info.bar);
  const history = useHistory();
  const { state } = history.location;
  const isAdmin = dispatch(hasAdminRights());
  const hasArrangementEditRights = dispatch(
    hasUserRights(UserRights.ARRANGEMENTS_EDIT),
  );
  const [isActive, setActive] = useState(active);
  const [isSubmitting, setSubmitting] = useState(false);
  const [templateId, setTemplateId] = useState(template_id);
  const [spots, setSpots] = useState([]);
  const popup = useSelector(state => state.popup);
  const customerOverride = useSelector(state => state.superAdmin.customerOverride);

  const getSpotsAreaDetails = (spots, media, fromArrangementFormFormValues) => {
    if (!spots || spots.length === 0) {
      return null;
    }

    const spotAreaDetails = spots?.reduce((result, spot) => {
      const spotNumber = spot.area_number || spot.number;
      const spotHasItems = media && media[spotNumber];
      const spotImageItemLength =
        (spotHasItems &&
          media[spotNumber]?.items?.filter(
            item => item.filetype === 'image' && !item.pending_removal,
          )?.length) ||
        0;

      if (spotHasItems && spotImageItemLength > 1) {
        const duration =
          (media && media[spotNumber].duration?.seconds) ||
          (fromArrangementFormFormValues &&
            fromArrangementFormFormValues[`duration${spotNumber}`]) ||
          '';
        result.push({ [`duration${spotNumber}`]: duration });
      }
      return result;
    }, []);

    if (spotAreaDetails && spotAreaDetails.length) {
      return Object.assign({}, ...spotAreaDetails);
    }
    return null;
  };

  const [initialForm, setInitialForm] = useState({
    name: name || '',
    template: templateId || null,
    isDefault: isDefault || false,
  });

  const [dimension, setDimension] = useState({ height: 0, width: 0 });
  const [loading, setLoading] = useState(false);

  const mediaItemsChanged = media => {
    let hasChangeMedia = false;

    if (!media || !Object.entries(media)) {
      return hasChangeMedia;
    }

    const mediaItemsArray = [].concat(...Object.values(media));

    hasChangeMedia =
      mediaItemsArray.filter(
        item => item.approval_state !== ApprovalStates.APPROVED,
      ).length > 0;

    return hasChangeMedia;
  };

  const durationValidationScheme = (spots, media) => {
    if (Array.isArray(spots) && spots.length) {
      const fields = spots.reduce((result, spot) => {
        const spotNumber = spot.area_number || spot.number;
        const spotHasItems = media && media[spotNumber];
        const spotImageItemLength =
          (spotHasItems &&
            media[spotNumber]?.items?.filter(
              item => item.filetype === 'image' && !item.pending_removal,
            )?.length) ||
          0;

        if (spotHasItems && spotImageItemLength > 1) {
          result.push({
            [`duration${spotNumber}`]: Yup.string()
              .matches(
                RegexOptions.NON_NEGATIVE_NUMBER,
                'Please enter a positive amount of seconds',
              )
              .typeError('Time between items is a required field')
              .required('Time between items is a required field'),
          });
        }
        return result;
      }, []);
      if (fields) {
        return Object.assign({}, ...fields);
      }
    }
    return null;
  };

  const validationSchema = Yup.object().shape({
    ...durationValidationScheme(spots, media),
    name: Yup.string()
      .nullable()
      .required('Name is a required field'),
    template: Yup.number()
      .nullable()
      .required('Layout is a required field'),
  });

  const clearHistoryFromArrangementState = () => {
    if (state && state.fromArrangement) {
      const clearHistoryFromArrangementState = {
        state: {
          fromArrangement: undefined,
        },
      };
      history.push(clearHistoryFromArrangementState);
    }
  };

  const closePopup = () => {
    dispatch(popupActionClear());
    if (infoBar.show) {
      dispatch(infoBarHide());
    }
  };

  const onCancel = id => {
    if (state?.newArrangement) {
      dispatch(deleteArrangementAction(id, false, history));
    } else {
      clearHistoryFromArrangementState();
      closePopup();
    }
  };

  const onSubmit = values => {
    if (!hasArrangementEditRights) {
      dispatch(popupActionClear());
      if (infoBar.show) {
        dispatch(infoBarHide());
      }
      return;
    }

    setSubmitting(true);
    const promise = dispatch(
      upsertArrangementAction(values, type, id, pool, media, customerOverride),
    );

    promise
      .then(json => json.json())
      .then(result => {
        const { message, arrangement } = result;
        const arrangementsOrderStorage = localStorage.getItem(
          'arrangement_order',
        );
        clearHistoryFromArrangementState();
        dispatch(
          fetchArrangementsAction({
            pool_id: pool.id,
            sort:
              arrangementsOrderStorage && JSON.parse(arrangementsOrderStorage),
          }),
        );

        if (id) {
          dispatch(arrangementApproved(result));
        }

        if (
          (values.isDefault && !isDefault) ||
          id === pool.default_arrangement?.id
        ) {
          dispatch(setPoolDefaultArrangement(pool.id, arrangement));
        }

        if (message && arrangement) {
          dispatch(popupActionClear());
          dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
        }

        setSubmitting(false);
      });
  };

  const namePlaceholder = (id, type) => {
    const text = type === 'RegularArrangement' ? 'Regular' : 'Trigger';

    if (id) {
      return `${text} show`;
    }
    return `New ${text} show`;
  };

  const spotRefs = useMemo(() => {
    return spots?.reduce((acc, value) => {
      acc[value.area_number || value.number] = React.createRef();
      return acc;
    }, {});
  }, [spots]);

  useEffect(() => {
    const abortController = new AbortController();

    if ((pending_approval && pending_changes?.template_id) || templateId) {
      setLoading(true);

      const promise = dispatch(
        fetchData(
          reverse(Api.TEMPLATE, {
            id:
              (pending_approval && pending_changes?.template_id) || templateId,
          }),
          {},
          true,
          abortController.signal,
        ),
      );

      promise
        .then(json => json.json())
        .then(({ template_areas, areas, height, width }) => {
          setDimension({ height, width });
          setSpots(template_areas ?? areas);
        })
        .catch(e => {
          console.log(e);
          setSpots([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {
      abortController.abort('Unmounting component: popup/arrangement');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, templateId]);

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    handleSubmit,
    initialTouched,
    touched,
  } = useFormik({
    initialValues: initialForm,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    setInitialForm(() => {
      const spotsAreaDetails = getSpotsAreaDetails(
        spots,
        popup?.data?.media,
        state?.fromArrangementFormFormValues,
      );

      return {
        ...spotsAreaDetails,
        template: state?.fromArrangementFormFormValues?.template || templateId,
        name: state?.fromArrangementFormFormValues?.name || values.name,
        isDefault: isDefault || false,
      };
    });
    // TODO: Fix exhaustive-deps warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pending_changes, spots, popup]);

  useEffect(() => {
    setInitialForm({
      template: template_id,
      name,
    });
  }, [name, template_id]);

  return (
    <PopupStickyForm onSubmit={handleSubmit}>
      <PopupStickyHeader>
        <fieldset className="fieldset--flatten">
          {pool && pool.location && (
            <PopupLabels
              labels={[
                pool.name,
                pool.location.name,
                type === 'RegularArrangement' ? 'Regular show' : 'Trigger show',
              ]}
            />
          )}

          <FormBlock flatten>
            <Input
              data-cy="name"
              type="text"
              name="name"
              id="name"
              placeholder={namePlaceholder(id, type)}
              value={
                (pending_approval && pending_changes?.name) || values.name || ''
              }
              error={touched.name && errors.name}
              onChange={handleChange}
              fontSize="large"
              suffix={<Icon name="edit" />}
              isChanged={
                pending_approval &&
                (!!pending_changes?.name ||
                  (pending_changes === undefined && !mediaItemsChanged(media)))
              }
              disabled={pending_approval || !hasArrangementEditRights}
            />
          </FormBlock>
        </fieldset>
      </PopupStickyHeader>
      <PopupStickyMain padding={false}>
        <FormBlock flatten isStart ignoreSplit>
          <div className="arrangement__left-column">
            <>
              {id && (
                <fieldset className="fieldset--small">
                  <Status
                    isActive={isActive}
                    needsApproval={pending_approval}
                  />
                </fieldset>
              )}
              <fieldset>
                <legend>Layout</legend>
                <FormBlock>
                  <LayoutDropdown
                    dataCy="layout"
                    poolId={pool.id}
                    label="Select a layout"
                    name="template"
                    id="template"
                    value={
                      (pending_approval && pending_changes?.template_id) ||
                      values.template
                    }
                    error={touched.template && errors.template}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                      setTemplateId(value);
                    }}
                    isChanged={
                      pending_approval &&
                      (!!pending_changes?.template ||
                        (pending_changes === undefined &&
                          !mediaItemsChanged(media)))
                    }
                    disabled={pending_approval || !hasArrangementEditRights}
                  />
                </FormBlock>

                {values.template && (
                  <ArrangementLayout
                    templateId={
                      (pending_approval && pending_changes?.template_id) ||
                      values.template
                    }
                    bottomMargin
                    showNumbers
                    media={media}
                    interActive
                    active
                    spotRefs={spotRefs}
                  />
                )}
              </fieldset>
            </>
          </div>
          <div className="arrangement__right-column">
            {!values.template && (
              <DeckEmpty title="Select a layout to start editing">
                <Text tag="p">
                  Once you select a layout for this show, you can add media to
                  its area’s. Don’t forget to activate the show.
                </Text>
              </DeckEmpty>
            )}

            <ArrangementMedia
              media={media}
              arrangementId={id}
              arrangementType={type}
              templateId={
                (pending_approval && pending_changes?.template_id) || templateId
              }
              formValues={values}
              pending_approval={pending_approval}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              spots={spots}
              dimension={dimension}
              loading={loading}
              spotRefs={spotRefs}
            />

            {id && type === 'RegularArrangement' && (
              <fieldset>
                <legend>Default show</legend>
                <Text color="grey-black">
                  {isDefault
                    ? `This show is set as default. To set another show as default, select
                    the 'Set as default show' option in that show.`
                    : `Setting this show as ‘default show’ will result into displaying
                    this show every time there are no shows planned or triggers
                    activated. Setting this show as default show will override the
                    current default show.`}
                </Text>
                <FormBlock flatten>
                  <Checkbox
                    id="isDefault"
                    name="isDefault"
                    checked={values.isDefault ?? false}
                    onChange={setFieldValue}
                    label="Set as default show"
                    disabled={
                      !isAdmin ||
                      !!isDefault ||
                      pending_approval ||
                      !isActive ||
                      !hasArrangementEditRights
                    }
                  />
                </FormBlock>
              </fieldset>
            )}
          </div>
        </FormBlock>
      </PopupStickyMain>
      <PopupStickyFooter>
        <FormBlock hasInlineChildren flatten>
          {(id &&
            !isDefault &&
            hasArrangementEditRights &&
            !state?.newArrangement && (
              <Button
                tag="a"
                size="medium"
                text="Delete"
                scheme="link"
                hasShadow={false}
                handler={() => {
                  if (hasArrangementEditRights) {
                    dispatch(
                      setInfoBar({
                        message: 'Are you sure you want to delete this show?',
                        action: [
                          {
                            text: 'Yes, Delete',
                            handle: () =>
                              dispatch(deleteArrangementAction(id, true)),
                          },
                        ],
                        state: InfoBarState.ERROR,
                      }),
                    );
                  }
                }}
                disabled={!hasArrangementEditRights || isSubmitting}
              />
            )) || (
            <Button
              tag="button"
              size="medium"
              text="Cancel"
              scheme="link"
              hasShadow={false}
              handler={() => {
                onCancel(id);
              }}
              disabled={isSubmitting}
            />
          )}
          <ButtonGroup>
            {id && (
              <Button
                tag="button"
                size="medium"
                scheme="link"
                text="Duplicate"
                hasShadow={false}
                handler={() => {
                  if (hasArrangementEditRights) {
                    dispatch(duplicateArrangementAction(id));
                  }
                }}
                disabled={!hasArrangementEditRights || isSubmitting}
              />
            )}

            {id && (
              <Button
                tag="button"
                size="medium"
                scheme="link"
                text={isActive ? 'Deactivate' : 'Activate'}
                hasShadow={false}
                handler={() => {
                  if (
                    !pending_approval &&
                    !isDefault &&
                    hasArrangementEditRights
                  )
                    dispatch(
                      toggleActiveArrangementAction(id, isActive, setActive),
                    );
                }}
                disabled={
                  pending_approval ||
                  isDefault ||
                  !hasArrangementEditRights ||
                  isSubmitting
                }
              />
            )}

            {!pending_approval && initialTouched && hasArrangementEditRights ? (
              <Button
                dataCy="arrangementSubmitButton"
                tag="button"
                type="submit"
                size="medium"
                text="Save and close"
                disabled={isSubmitting}
              />
            ) : (
              <Button
                tag="button"
                size="medium"
                text="Close"
                handler={() => {
                  closePopup();
                }}
              />
            )}
          </ButtonGroup>
        </FormBlock>
      </PopupStickyFooter>
    </PopupStickyForm>
  );
};

Arrangement.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.oneOf(['RegularArrangement', 'TriggerArrangement'])
    .isRequired,
  media: ArrangementMediaProps,
  template_id: PropTypes.number,
  isDefault: PropTypes.bool,
  active: PropTypes.bool,
  pending_approval: PropTypes.bool,
  pending_changes: ArrangementPendingChangesProps,
};

Arrangement.defaultProps = {
  id: null,
  name: null,
  media: null,
  template_id: null,
  isDefault: false,
  active: false,
  pending_approval: false,
  pending_changes: null,
};

export default Arrangement;
