import {ComponentStatus, InputSignalLatestData, InputSignalStatus, Location} from 'store/rootState';

export enum TriggerStatus {
  ONLINE = 'online',
  OFFLINE = 'offline'
}

export interface Trigger {
  status: TriggerStatus;
  id: number;
  name?: string;
  until_value: number;
  until_type: string;
  until_topic?: {
    public_name?: string;
    name?: string;
  };
  public_name?: string;
  trigger_public_name: string;
  arrangement_name: string;
  arrangement?: any;
  active?: boolean;
  pending_approval?: boolean;
}

export interface InputSignalEditProps {
  id: number;
  name: string;
  locations: Location[];
  triggers: Trigger[];
  input_device?: { id: string, name: string };
  pool_ids?: number[];
  type: string;
  status: InputSignalStatus | ComponentStatus;
  latest_data: InputSignalLatestData;
}

export interface InputSignalValues {
  id: number;
  name: string;
  locations: Location[];
  triggers: Trigger[];
  input_device?: { id: string, name: string };
  type: string;
  status: InputSignalStatus | ComponentStatus;
}
