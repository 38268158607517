import {
  MediaItemSortAttribute,
  MediaItemSortOrder,
  MediaValueTypeFontValue,
  MediaValueTypeTickerTapeScrollDirectionValue,
} from './Media.enum';

export interface MediaFilter {
  name: string;
  filetype: string;
}

export interface MediaFolderInfo {
  id: number;
  customer_id: number;
  media_folder_id?: number;
  title: string;
  ancestry: any;
  created_at: Date;
  updated_at: Date;
}

export interface MediaBreadcrumbs {
  id: number;
  customer_id: number;
  media_folder_id?: number;
  title: string;
  ancestry?: any;
  created_at: Date;
  updated_at: Date;
}

export interface MediaValueTypeJackpotTopic {
  label: string;
  value: number;
}

export interface MediaValueTypeJackpotCurrency {
  label: string;
  value: string;
}

export interface MediaValueTypeJackpotSeparatorAndDecimal {
  label: string;
  value: string;
}

export interface MediaValueTypeFont {
  label: MediaValueTypeFontValue;
  value: MediaValueTypeFontValue;
}

export interface MediaValueTypeJackpotFormattingOption {
  label: string;
  value: string;
}

export interface MediaValueTypeJackpot {
  topics: MediaValueTypeJackpotTopic[];
  currencies: MediaValueTypeJackpotCurrency[];
  separatorAndDecimals: MediaValueTypeJackpotSeparatorAndDecimal[];
  fonts: MediaValueTypeFont[];
  formattingOptions: MediaValueTypeJackpotFormattingOption[];
}

export interface MediaValueTypeTickerTapeScrollDirection {
  label: string;
  value: MediaValueTypeTickerTapeScrollDirectionValue;
}

export interface MediaValueTypeTickerTape {
  fonts: MediaValueTypeFont[];
  scrollDirection: MediaValueTypeTickerTapeScrollDirection[];
}

export interface MediaValueTypes {
  jackpot?: MediaValueTypeJackpot;
  tickerTape?: MediaValueTypeTickerTape;
}

export interface MediaMedia {
  id: number;
  name: string;
  deleted_at?: Date;
  created_at: Date;
  updated_at?: Date;
  filename: string;
  filetype: string;
  used_count: number;
  source_url?: string;
  // TODO: REFACTOR - source_type seems to be the same as filetype
  // See components/media/files/Files.tsx
  source_type?: string;
  customer_id?: number;
  media_file_file_name: string;
  // TODO: REFACTOR - media_file seems to be the same as media_source
  // See components/media/files/Files.tsx
  media_file_url?: string;
  media_file_content_type?: string;
  media_file_file_size?: number;
  media_file_updated_at?: Date;
  media_folder_id?: number;
  media_source?: string;
  media_item_type: string;
  media_item_type_id?: number;
  jackpot?: {
    topic_id: number;
    keyword: string;
    currency: string;
    separator_and_decimals: string;
    font: string;
    formatting: string;
    color: string;
  };
  ticker_tape?: {
    text: number;
    color: string;
    speed: string;
    direction: MediaValueTypeTickerTapeScrollDirectionValue;
    font: MediaValueTypeFontValue;
  };
}

export interface MediaSort {
  attribute: `${MediaItemSortAttribute}`;
  order: `${MediaItemSortOrder}`;
}

export enum MediaItemType {
  AUDIO = 'audio',
  IMAGE = 'image',
  HTML = 'html',
  HTML_LINK = 'html_link',
  RICH_TEXT = 'rich_text',
  RSS = 'rss',
  RTSP = 'rtsp',
  VIDEO = 'video',
  JACKPOT = 'jackpot',
  TICKER_TAPE = 'ticker_tape',
  YOUTUBE = 'youtube'
}

export interface Media {
  filter: MediaFilter;
  folders: MediaFolderInfo[];
  media: MediaMedia[];
  request: boolean;
  folder_info?: MediaFolderInfo;
  selectable: boolean;
  sort: MediaSort;
  breadcrumbs?: MediaBreadcrumbs[];
  value_types?: MediaValueTypes;
}

export interface AddMediaAction {
  message: string;
  media: MediaMedia;
}

export interface DeleteMediaActionShows {
  active: boolean;
  id: number;
  name: string;
  pending_approval: boolean;
}

export interface DeleteMediaAction {
  id: number;
  shows?: DeleteMediaActionShows;
}

export interface MediaItemSort {
  attribute: `${MediaItemSortAttribute}`;
  order: `${MediaItemSortOrder}`;
}

export interface FetchMediaAction {
  folder_id?: string;
  pool_id?: number;
}
