import React, { useCallback } from 'react';
import classNames from 'classnames';

import './OverviewList.scss';
import OverviewListItem from './OverviewListItem';
import { OverviewListProps } from './OverviewList.types';

const OverviewList: React.FC<OverviewListProps> = ({
  className,
  headers,
  content,
  cardProps,
  loading = false,
  loadingLength,
  hideHeaders = false,
  dataCyListItem,
}) => {
  const loadingState = useCallback(() => {
    const keyArray = [...Array(loadingLength || 5)];

    return keyArray.map((_, key) => (
      <OverviewListItem
        key={key.toString()}
        id={key.toString()}
        columns={headers}
        loading
      />
    ));
  }, [loadingLength, headers]);

  return (
    <section className={classNames('overview-list', className)}>
      {!hideHeaders && (
        <header className="overview-list__header">
          {headers?.map(header => (
            <span
              key={header.key}
              className="overview-list__header-column"
              style={{
                flex: header.width ? `0 0 ${header.width}` : undefined,
                textAlign: header.align ? header.align : undefined,
                maxWidth: header.width ? header.width : undefined,
              }}
            >
              {header.content}
            </span>
          ))}
        </header>
      )}

      <div className="overview-list__list">
        {loading
          ? loadingState()
          : content?.map(({ id, key, items, onClick, status, tag }) => (
            <OverviewListItem
              key={key}
              id={id}
              columns={items}
              onClick={onClick}
              dataCyListItem={dataCyListItem}
              cardProps={cardProps}
              status={status}
              tag={tag}
            />
          ))}
      </div>
    </section>
  );
};

export default OverviewList;
