import { reverse } from 'named-urls';

import { Api } from 'constants/Routes.enum';

import { setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import fetchData from 'store/fetchData';

export const UserActionTypes = {
  USER_REQUESTED: '@@user/requested',
  USER_SUCCEEDED: '@@user/success',
  USER_FAILED: '@@user/error',
  USER_NOT_FOUND: '@@user/not-found',
  USER_UPDATED: '@@user/updated',
};

export const userRequested = () => ({
  type: UserActionTypes.USER_REQUESTED,
});

export const userSucceeded = user => ({
  type: UserActionTypes.USER_SUCCEEDED,
  payload: user,
});

export const userFailed = () => ({
  type: UserActionTypes.USER_FAILED,
});

export const userNotFound = () => ({
  type: UserActionTypes.USER_NOT_FOUND,
});

export const userUpdated = user => ({
  type: UserActionTypes.USER_UPDATED,
  payload: user,
});

export const fetchUserAction = id => async dispatch => {
  dispatch(userRequested());

  const promise = dispatch(fetchData(reverse(Api.USERS_DETAIL, { id })));
  if (promise) {
    promise
      .then(json => json.json())
      .then(result => {
        const { error, message, user } = result;

        if (!error && !message) {
          dispatch(userSucceeded(user));
        } else {
          dispatch(userNotFound());
        }
      })
      .catch(() => {
        dispatch(userFailed());
        dispatch(
          setInfoBar({
            message: 'Could not fetch user',
            state: InfoBarState.ERROR,
            timeout: 5000,
          }),
        );
      });
  }
};
