import React, { useRef, useEffect, useState, useCallback, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { Calendar as Cal, EventApi, View } from '@fullcalendar/core';
import ReactDOMServer from 'react-dom/server';

import Icon from 'components/icon/Icon';
import PriorityOptions from 'components/form/priority/Priority.enum';

import defaultTimezone from 'constants/DefaultTimezone';
import PopupTypes from 'constants/PopupTypes.enum';
import UserRight from 'constants/UserRight.enum';
import HistoryLocationState from "constants/HistoryState";

import { hasUserRights } from 'store/auth/hasUserRights';
import { editEventAction, fetchEventsAction } from 'store/events/eventsActions';
import { popupActionSet } from 'store/popup/popupActions';
import { RootState } from 'store/rootState';

import CalendarListSkeleton from './CalendarListSkeleton';
import CalendarSkeletonCalendar from './CalendarSkeletonCalendar';
import calendarOptions from './CalendarOptions';

import './Calendar.scss';

interface ParamTypes {
  id: string;
}

const Calendar: FC = () => {
  const { state } = useLocation<HistoryLocationState>();
  const dispatch = useDispatch();
  const params = useParams<ParamTypes>();

  const { events, jumpToDate } = useSelector((state: RootState) => state.events);
  const eventsLoading = useSelector((state: RootState) => state.events.request);
  const pool = useSelector((state: RootState) => state.pool);

  const hasScheduledEventsEditRights = dispatch(
    hasUserRights(UserRight.EVENTS_EDIT),
  );

  const calendarRef = useRef<HTMLDivElement>(null);
  const [calendar, setCalendar] = useState<Cal | null>(null);
  const [calendarView, setCalendarView] = useState(() => {
    const inStorage = localStorage.getItem('calendar_view');
    return inStorage || 'timeGridWeek';
  });

  const timeZone = useSelector((state: RootState) => state.pool?.location?.timezone_info);
  const getTime = useCallback((time: string | Date = new Date()) => {
      return moment.tz(time, timeZone || defaultTimezone);
    },
    [timeZone],
  );
  const [time, setTime] = useState<string>(getTime().format());

  // open event popup with startdate set
  const dateClick = useCallback(
    ({ dateStr }: { dateStr: string }) => {
      // @ts-ignore
      if (hasScheduledEventsEditRights) {
        dispatch(
          popupActionSet(PopupTypes.EVENT, {
            startDate: moment.tz(dateStr, timeZone || defaultTimezone),
            roundTime: true,
          }),
        );
      }
    },
    [timeZone, dispatch, hasScheduledEventsEditRights],
  );

  // open event popup with event information
  const eventClick = useCallback(
    ({ event }: { event: EventApi }) => {
      if (event.groupId) {
        dispatch(editEventAction(Number(event.groupId)));
      }
    },
    [dispatch],
  );

  // check if popups need to be openen based on location state
  useEffect(() => {
    if (state && state.fromEvent !== undefined) {
      dispatch(editEventAction(state.fromEvent));
    }
    if (state?.fromEventFormFormValues !== undefined) {
      dispatch(popupActionSet(PopupTypes.EVENT, state.fromEventFormFormValues))
    }
    if (state && state.newEvent !== undefined) {
      dispatch(popupActionSet(PopupTypes.EVENT, {}));
    }
  }, [state, dispatch]);

  // Set up calendar on mount
  useEffect(() => {
    if (!calendarRef.current) return

    const fetchEvents = ({ currentStart, currentEnd }: View) => {
      // Only fetch events once pool.id equals id from querystring
      if (pool.id === Number(params.id)) {
        dispatch(fetchEventsAction(pool.id, currentStart, currentEnd));
      }
    };

    const customButtons = {
      listWeek: {
        text: 'list',
        click: () => setCalendarView('listWeek'),
      },
      timeGridWeek: {
        text: 'calendar',
        click: () => setCalendarView('timeGridWeek'),
      },
    };

    const eventRender = ({ el }: { el: HTMLElement }) => {
      const title = el.querySelector('.fc-title, .fc-list-item-title') as HTMLElement;
      if (title) {
        title.innerHTML = title.innerText;
      }
    };

    const cal = new Cal(calendarRef.current, {
      ...calendarOptions,
      dateClick: info => dateClick(info),
      eventClick: info => eventClick(info),
      datesRender: ({ view }) => fetchEvents(view),
      eventRender,
      customButtons,
      timeZone,
      now: getTime().format(),
    });

    setCalendar(cal);
  }, [
    dispatch,
    calendarRef,
    setCalendar,
    dateClick,
    eventClick,
    getTime,
    timeZone,
    params.id,
    pool.id,
  ]);

  useEffect(() => {
    if (calendar && jumpToDate) {
      calendar.gotoDate(jumpToDate)
    }
  }, [calendar, jumpToDate]);

  useEffect(() => {
    if (calendar) {
      calendar.changeView(calendarView);
      localStorage.setItem('calendar_view', calendarView);
    }
  }, [calendar, calendarView]);

  // render the calendar
  useEffect(() => {
    // Initalized calendar have a .fc class so
    if (calendar && !calendarRef.current?.classList.contains('fc')) {
      calendar.render();
    }
  }, [calendar]);

  useEffect(() => {
    // update the current time twice every minute
    const interval = setInterval(() => {
      setTime(getTime().format());
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, eventClick, dateClick, pool.id, params.id, timeZone, getTime]);

  // set the current time as a css variable for the 'now indicator'
  useEffect(() => {
    if (!calendarRef.current) return

    const calendarElement = calendarRef.current;
    const minutes = Number(getTime(time).format('mm'));

    if (minutes < 10 || minutes > 50) {
      calendarElement.classList.add('calendar--overlapping-time');
    } else {
      calendarElement.classList.remove('calendar--overlapping-time');
    }

    calendarElement.style.setProperty(
      '--time',
      `'${getTime(time).format('HH:mm')}'`,
    );
  }, [calendarRef, time, timeZone, getTime]);

  // add events to calendar when 'view' changes
  useEffect(() => {
    if (calendar && calendar?.state?.dateProfile?.currentRange) {
      const eventsArray: Array<{
        id: string
        groupId: number
        title: string
        start: string
        end: string
        classNames: string[]
      }> = [];
      if (events && events.length > 0) {
        events.forEach((event) => {
          event?.event_occurances.forEach(occurrence => {
            const hasIcons =
              event.rrule ||
              event.event_occurances.length > 1 ||
              event.priority >= PriorityOptions.PRIORITY_MEDIUM;

            const eventTitle = (
              <>
                <span className="calendar__event__title">{event.title}</span>
                {hasIcons && (
                  <div className="calendar__event__icons">
                    {(!!event.rrule || event.event_occurances.length > 1) && (
                      <Icon name="repeat" />
                    )}
                    {event.priority === PriorityOptions.PRIORITY_MEDIUM && (
                      <Icon name="priorityMedium" />
                    )}
                    {event.priority === PriorityOptions.PRIORITY_HIGH && (
                      <Icon name="priorityHigh" />
                    )}
                  </div>
                )}
              </>
            );

            const renderedTitle = ReactDOMServer.renderToStaticMarkup(
              eventTitle,
            );

            const eventObject = {
              id: `${event.id}-${occurrence.id}`,
              groupId: event.id,
              title: renderedTitle,
              start: moment
                .tz(occurrence.start_at, timeZone || defaultTimezone)
                .format(),
              end: moment
                .tz(occurrence.end_at, timeZone || defaultTimezone)
                .format(),
              classNames: [
                'calendar__event',

                event.rrule ||
                event.event_occurances.length > 1 ||
                event.priority >= PriorityOptions.PRIORITY_MEDIUM
                  ? 'calendar__event--has-icons'
                  : '',
                !event.active ? 'calendar__event--inactive' : '',
                +new Date(occurrence.end_at) < Date.now()
                  ? 'calendar__event--past'
                  : '',
                event.pending_approval ? 'calendar__event--pending' : '',
              ],
            };

            eventsArray.push(eventObject);
          })
        });
      }

      // fill each day with at least one event
      // otherwise the day will not show up in the listview
      const emptyArray = [];
      const { start, end } = calendar?.state?.dateProfile?.currentRange;
      const current = getTime(start);

      while (current < moment(end)) {
        const startOfDay = current.clone().startOf('day');
        const endOfDay = current.clone().endOf('day');

        if (
          !eventsArray.some(e => {
            const eventStart = getTime(e.start);
            const eventEnd = getTime(e.end);

            return (
              (eventStart >= startOfDay && eventStart <= endOfDay) ||
              (eventEnd >= startOfDay && eventEnd <= endOfDay) ||
              (eventStart < startOfDay && eventEnd > startOfDay)
            );
          })
        ) {
          const eventObject = {
            id: `empty_${current.day()}`,
            title: 'There are no planned events for this day',
            start: startOfDay.format(),
            end: endOfDay.format(),
            classNames: ['calendar__event--empty'],
          };
          emptyArray.push(eventObject);
        }

        current.add(1, 'day');
      }

      const emptySource = calendar.getEventSourceById('emptyArray');
      if (emptySource) {
        emptySource.remove();
      }
      calendar.addEventSource({
        id: 'eventArray',
        events: eventsArray,
      });
      calendar.addEventSource({
        id: 'emptyArray',
        events: emptyArray,
      });
    }

    return () => {
      if (calendar) {
        const sources = calendar.getEventSources();
        sources.forEach(source => {
          source.remove();
        });
      }
    };
  }, [timeZone, calendar, events, getTime]);

  return (
    <>
      <div
        className={classNames('calendar__container', {
          'calendar__container--loading': eventsLoading,
        })}
      >
        <div className={classNames('calendar')} ref={calendarRef} />

        {eventsLoading && calendarView === 'listWeek' && (
          <CalendarListSkeleton />
        )}
        {eventsLoading && calendarView === 'timeGridWeek' && (
          <CalendarSkeletonCalendar />
        )}
      </div>
    </>
  );
};

export default Calendar;
