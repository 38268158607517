import React from 'react';

import Skeleton from 'components/skeleton/Skeleton';
import { SkeletonTag, SkeletonType } from 'components/skeleton/Skeleton.enum';
import Card from 'components/card/Card';

const CalendarSkeletonItem = ({ column, row }: {
  column: number;
  row: number;
}) => {
  const top = row * 121 + 40;
  const left = column * (100 / 7);

  return (
    <div
      style={{ left: `${left}%`, top }}
      className="calendar__skeleton-calendar__event"
    >
      <Card highlightOnHover={false} interactive={false} zoomable={false}>
        <Skeleton type={SkeletonType.TEXT} styling={{ height: 9 }} />
        <Skeleton
          type={SkeletonType.TEXT}
          styling={{ height: 9, width: 35, marginTop: 9 }}
        />
        <div className="calendar__skeleton-calendar__event__button">
          <Skeleton type={SkeletonType.BUTTON_SQUARE} />
        </div>
      </Card>
    </div>
  );
};

const CalendarSkeleton = () => {
  return (
    <div className="calendar calendar__skeleton-calendar">
      <div className="calendar__skeleton-calendar__head">
        {[...Array(7)].map((_, v) => (
          <div key={v}>
            <Skeleton
              type={SkeletonType.HEADING}
              tag={SkeletonTag.H4}
              styling={{ width: 63 }}
            />
          </div>
        ))}
      </div>

      <div className="calendar__skeleton-calendar__body">
        <div className="calendar__skeleton-calendar__columns">
          {[...Array(7)].map((_, v) => (
            <div key={v} className="calendar__skeleton-calendar__column" />
          ))}
        </div>

        <div className="calendar__skeleton-calendar__rows">
          {[...Array(7)].map((_, v) => (
            <div key={v} className="calendar__skeleton-calendar__row" />
          ))}
        </div>

        <div className="calendar__skeleton-calendar__times">
          {[...Array(6)].map((_, v) => (
            <div key={v} className="calendar__skeleton-calendar__time">
              <Skeleton
                type={SkeletonType.HEADING}
                tag={SkeletonTag.H4}
                styling={{ width: 32 }}
              />
            </div>
          ))}
        </div>

        <div className="calendar__skeleton-calendar__events">
          <CalendarSkeletonItem column={0} row={3} />
          <CalendarSkeletonItem column={1} row={1} />
          <CalendarSkeletonItem column={2} row={2} />
          <CalendarSkeletonItem column={3} row={0} />
          <CalendarSkeletonItem column={4} row={3} />
          <CalendarSkeletonItem column={5} row={3} />
          <CalendarSkeletonItem column={6} row={1} />
        </div>
      </div>
    </div>
  );
};

export default CalendarSkeleton;
