import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import authReducer from './auth/authReducer';
import poolsReducer from './pools/poolsReducer';
import poolReducer from './pool/poolReducer';
import popupReducer from './popup/popupReducer';
import infoReducer from './info/infoReducer';
import mediaReducer from './media/mediaReducer';
import eventsReducer from './events/eventsReducer';
import devicesReducer from './devices/devicesReducer';
import arrangementsReducer from './arrangements/arrangementsReducer';
import triggersReducer from './triggers/triggersReducer';
import { loadState, saveState } from './localStorage';
import accountSettingsReducer from './account/accountSettingsReducer';
import usersReducer from './users/usersReducer';
import userReducer from './user/userReducer';
import locationsReducer from './locations/locationsReducer';
import userRolesReducer from './userRoles/userRolesReducer';
import inputDevicesReducer from './inputSignals/inputDevices/inputDevicesReducer';
import inputSignalsReducer from './inputSignals/inputSignals/inputSignalsReducer';
import superAdminReducer from './superAdmin/superAdminReducers';
import customerReducer from './customers/customerReducer';

const inputsReducers = combineReducers({
  inputDevices: inputDevicesReducer,
  inputSignals: inputSignalsReducer,
});

export const rootReducer = combineReducers({
  auth: authReducer,
  info: infoReducer,
  pools: poolsReducer,
  pool: poolReducer,
  events: eventsReducer,
  popup: popupReducer,
  media: mediaReducer,
  arrangements: arrangementsReducer,
  devices: devicesReducer,
  triggers: triggersReducer,
  accountSettings: accountSettingsReducer,
  users: usersReducer,
  user: userReducer,
  locations: locationsReducer,
  userRoles: userRolesReducer,
  inputs: inputsReducers,
  superAdmin: superAdminReducer,
  customers: customerReducer
});

const persistedStorage = loadState();
const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});
const store = createStore(
  rootReducer,
  persistedStorage,
  composeEnhancers(applyMiddleware(thunk)),
);

store.subscribe(() => {
  const state = store.getState();
  saveState({
    auth: {
      ...state.auth,
      error: null,
    },
    superAdmin: {
      customerOverride: state.superAdmin?.customerOverride
    },
    customers: {
      // @ts-ignore
      customers: state.customers.customers
    },
    media: {
      // @ts-ignore
      filter: state.media?.filter,
      // @ts-ignore
      sort: state.media?.sort,
    },
  });
});

export default store;
