import React, { FC } from 'react';

import Button from 'components/button/Button';
import { SubmitButtonProps } from './SubmitButton.types';

// TODO: Remove submit button and replace it by Button component on the places where it is used. It now also can behave like a close button and triggers a submit, which is not desirable.

const SubmitButton: FC<SubmitButtonProps> = ({
  canSave = false,
  disabled = false,
  dataCy,
}) => {
  return (
    <Button
      data-cy={dataCy}
      tag="button"
      type="submit"
      size="medium"
      text={canSave ? 'Save and close' : 'Close'}
      disabled={disabled}
    />
  );
};

export default SubmitButton;
