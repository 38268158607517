import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Truncate from "react-truncate-markup";

import Button from "components/button/Button";
import {
  ButtonScheme,
  ButtonShapeEdges,
  ButtonSize,
  ButtonTag,
} from "components/button/Button.enum";
import Card from "components/card/Card";
import Icon from "components/icon/Icon";
import MediaCardImage from "components/media/card-image/MediaCardImage";

import { editMediaAction } from "store/media/mediaActions";
import { MediaItemType } from "store/media/Media.types";

import "./MediaCard.scss";

interface MediaCardProps {
  id: number;
  title: string;
  image?: string;
  type?: MediaItemType | string;
  itemType?: MediaItemType | string;
  selectable?: boolean;
  selected?: boolean;
  setSelected?: (id: number, type: MediaItemType) => void;
  disabled?: boolean;
}

const MediaCard = ({
  id,
  title = "",
  image,
  type = "other",
  itemType,
  selectable = false,
  selected = false,
  setSelected,
  disabled = false,
}: MediaCardProps) => {
  const [isTruncated, setTruncated] = useState(false);
  const dispatch = useDispatch();
  const [imageNotLoaded, setImageNotLoaded] = useState(true);

  const cardClick = () => {
    if (!disabled) {
      if (selectable && setSelected) {
        setSelected(id, (itemType || type) as MediaItemType);
      } else {
        dispatch(editMediaAction(id));
      }
    }
  };

  return (
    <button
      data-cy="mediaCardItem"
      type="button"
      tabIndex={0}
      onClick={cardClick}
      onKeyDown={(e) => e.key === "13" && cardClick}
      className={classNames({ button__interaction: !selectable })}
      title={isTruncated ? title : ""}
    >
      <Card
        className={classNames("media-card", {
          "media-card--selectable": selectable && !disabled,
          "media-card--selected": selected,
          "media-card--disabled": disabled,
          "media-card--with-image": type === "image" && imageNotLoaded,
        })}
        zoomable={!disabled}
        interactive={!disabled}
        highlightOnHover={!disabled}
      >
        <MediaCardImage
          {...{ type, itemType, title, image, setImageNotLoaded }}
        />

        <div className="media-card__content">
          <h3
            className={classNames("media-card__title", {
              "media-card__title--url":
                title.startsWith("http") || title.startsWith("rstp"),
            })}
          >
            {/* @ts-ignore why is children prop missing? */}
            <Truncate
              lines={2}
              ellipsis={`...${
                title.length > 7
                  ? title.substring(title.length - 7, title.length)
                  : ""
              }`}
              onTruncate={(truncated) => setTruncated(truncated)}
            >
              <span>{title}</span>
            </Truncate>
          </h3>
        </div>

        <Button
          tag={ButtonTag.SPAN}
          text={<Icon name="arrow-right" />}
          size={ButtonSize.SMALL}
          hasShadow={false}
          className="card__button"
          scheme={ButtonScheme.CARD}
          shapeEdges={ButtonShapeEdges.ROUNDED}
          onClick={(e) => {
            e.stopPropagation();
            !disabled && dispatch(editMediaAction(id));
          }}
        />
      </Card>
    </button>
  );
};

export default MediaCard;
