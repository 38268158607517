import React, { useState } from 'react';
import { ColorResult } from 'react-color';

import Input from 'components/form/input/Input';
import ColorPicker from 'components/form/colorPicker/ColorPicker';

import { ColorPickerInputProps } from './ColorPicker.types';

import './ColorPickerInput.scss';

const ColorPickerInput = ({
  label,
  name,
  id,
  placeholder,
  value,
  error,
  onChange,
  disabled,
}: ColorPickerInputProps) => {
  const [state, setState] = useState<{
    displayColorPicker: boolean;
    color: string;
  }>({
    displayColorPicker: false,
    color: value || '#000000',
  });

  const handleClick = () => {
    setState(prevState => {
      return { ...state, displayColorPicker: !prevState.displayColorPicker };
    });
  };

  const handleClose = () => {
    setState({ ...state, displayColorPicker: false });
  };

  const handleColorChange = (color: ColorResult | string) => {
    let hexColor = typeof color === 'string' ? color : color.hex;

    setState({ ...state, color: hexColor });

    onChange(name, hexColor as string);
  };

  return (
    <Input
      type="text"
      label={label}
      name={name}
      id={id}
      placeholder={placeholder}
      scheme="white"
      value={state.color as string}
      containerClassName="color-picker-input__container"
      className="color-picker-input"
      onChange={e => handleColorChange(e.target.value)}
      // @ts-ignore should come from HTMLInputElement
      maxLength={7}
      error={error}
      disabled={disabled}
    >
      <ColorPicker
        handleColorChange={handleColorChange}
        handleClose={handleClose}
        handleClick={handleClick}
        className="color-picker-input__picker"
        popoverClassName="color-picker-input__picker-popover"
        buttonClassName="color-picker-input__picker-button"
        color={state.color}
        disabled={disabled}
        displayColorPicker={state.displayColorPicker}
      />
    </Input>
  );
};

export default ColorPickerInput;
