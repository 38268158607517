import { Paths, Api } from 'constants/Routes.enum';
import { infoBarSet, setInfoBar, setInfoBarShow } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';

export const requestChangePasswordAction = (
  email,
  setSubmitting,
  setSubmittedSuccessfully,
) => async dispatch => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_API}${Api.USERS_PASSWORD}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          email,
        },
      }),
    },
  );

  if (response.status === 200) {
    response.json().then(response => {
      const { message } = response;
      dispatch(infoBarSet({ message, timeout: 0 }));
      dispatch(setInfoBarShow({ show: true }));
      setSubmittedSuccessfully(true);
    });
  } else {
    response.json().then(response => {
      const { error } = response;

      dispatch(
        setInfoBar({
          message: error,
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
      setSubmitting(false);
    });
  }
};

export const resetPasswordAction = (
  token,
  password,
  confirmPassword,
  history,
) => async dispatch => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_API}${Api.USERS_PASSWORD}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          reset_password_token: token,
          password,
          confirm_password: confirmPassword,
        },
      }),
    },
  );

  if (response.status === 200) {
    response.json().then(response => {
      const { message } = response;

      const location = {
        pathname: Paths.LOGIN,
        state: {
          passwordReset: true,
        },
      };
      history.replace(location);

      dispatch(setInfoBar({ message, timeout: 5000, state: 'check' }));
    });
  } else {
    response.json().then(response => {
      const { error } = response;

      dispatch(
        setInfoBar({
          message: error,
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
  }
};
