import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from 'components/container/Container';
import Heading from 'components/typography/heading/Heading';
import Card from 'components/card/Card';
import Label from 'components/form/label/Label';
import Text from 'components/typography/text/Text';
import Button from 'components/button/Button';
import IconButton from 'components/button/IconButton';

import './AccountSettings.scss';
import useAuthLogout from 'hooks/useAuthLogout';
import {
  editPasswordTwoFactorAction,
  editEmailTwoFactorAction,
} from 'store/account/accountSettingsActions';
import { RootState } from 'store/rootState';

const AccountSettings = () => {
  const [logoutAction] = useAuthLogout();
  const user = useSelector((state: RootState) => state?.auth?.user);
  const accountSettingsTwoFactorIsSet = useSelector(
    (state: RootState) => state?.accountSettings?.two_factor_is_set,
  );
  const dispatch = useDispatch();

  const editEmailClick = () => {
    return dispatch(editEmailTwoFactorAction(accountSettingsTwoFactorIsSet));
  };

  const editPasswordClick = () => {
    return dispatch(editPasswordTwoFactorAction(accountSettingsTwoFactorIsSet));
  };

  return (
    <Container>
      <Heading className="profile-page__title" level={1}>
        Account settings
      </Heading>
      <div className="profile-page__card-layout">
        <Card
          className="profile-page__general-card"
          highlightOnHover={false}
          interactive={false}
          shadow
        >
          <div className="profile-page__general-card-layout">
            <div className="profile-page__general-card-name">
              <Label label="Name" color="grey" />
              <Text size="x-large" tag="div">
                {user.name}
              </Text>
            </div>
            <div className="profile-page__general-card-email">
              <Label label="E-mail" color="grey" />
              <Text size="x-large" tag="div">
                {user.email}
              </Text>
              <Button
                tag="a"
                size="medium"
                text="edit"
                scheme="link"
                hasShadow={false}
                handler={() => editEmailClick()}
              />
            </div>
            <div className="profile-page__general-card-username">
              <Label label="Username" color="grey" />
              <Text size="x-large" tag="div">
                {user.username}
              </Text>
            </div>
            <div className="profile-page__general-card-password">
              <Label label="Password" color="grey" />
              <Text size="x-large" tag="div">
                &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
              </Text>
              <Button
                tag="a"
                size="medium"
                text="edit"
                scheme="link"
                hasShadow={false}
                handler={() => editPasswordClick()}
              />
            </div>
          </div>
        </Card>
        <Card
          className="profile-page__permissions-card"
          highlightOnHover={false}
          interactive={false}
          shadow
        >
          <Label
            label={
              user?.roles && user?.roles.length > 1 ? 'User roles' : 'User role'
            }
            color="grey"
          />
          <Text size="x-large" tag="div">
            {user?.roles &&
              user?.roles
                // @ts-ignore
                .map(role => role.name)
                .sort()
                .join(', ')}
          </Text>
        </Card>
        <Card
          className="profile-page__logout-card"
          highlightOnHover={false}
          interactive={false}
          shadow
        >
          <Label label="Want to log out?" color="grey" />
          <IconButton
            tag="button"
            iconName="logout"
            iconSize="medium"
            text="Log out"
            size="large"
            handler={logoutAction}
          />
        </Card>
      </div>
    </Container>
  );
};

export default AccountSettings;
