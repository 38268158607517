import React, { FC } from 'react';
import classNames from 'classnames';

import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import { IconButtonProps } from './IconButton.types';

import './IconButton.scss';

const IconButton: FC<IconButtonProps> = ({
  iconName,
  iconSize,
  className,
  iconColor,
  iconPosition = 'before-text',
  loading = false,
  size,
  text,
  disabled,
  ...props
}) => {
  return (
    <Button
      loading={loading}
      scheme="link"
      hasShadow={false}
      className={classNames('icon-button', className, {
        'icon-button--without-text': !text,
        [`icon-button--size-${size}`]: size,
        [`icon-button--icon-${iconPosition}`]: iconPosition,
      })}
      text={text}
      disabled={disabled}
      {...props}
    >
      <Icon
        size={iconSize}
        name={iconName}
        color={!disabled ? iconColor : undefined}
        loading={loading}
        className={classNames('icon-button__icon', {
          [`icon-button__icon--${iconPosition}-margin`]:
            text !== '' && text !== undefined,
        })}
      />
    </Button>
  );
};

export default IconButton;
