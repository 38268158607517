import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button from 'components/button/Button';
import IconButton from 'components/button/IconButton';
import Card from 'components/card/Card';
import Container from 'components/container/Container';
import Label from 'components/form/label/Label';
import Icon from 'components/icon/Icon';
import LabelValueList from 'components/list/labelValueList/LabelValueList';
import { LabelValueListStyling } from 'components/list/labelValueList/LabelValueList.types';
import PageFilterContainer from 'components/page-filter-container/PageFilterContainer';
import { List, ListItem } from 'components/popup-context/list/List';
import Status from 'components/status/Status';
import { StatusTextColor } from 'components/status/Status.enum';
import Heading from 'components/typography/heading/Heading';
import Text from 'components/typography/text/Text';
import { TextSize, TextTag } from 'components/typography/text/Text.enum';

import { Paths } from 'constants/Routes.enum';

import { RootState } from 'store/rootState';
import { fetchUserAction } from 'store/user/userActions';
import { openUserAction } from 'store/users/usersActions';

import './UserDetails.scss';

const UserDetails: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const user = useSelector((state: RootState) => state.user);

  const sendPasswordLink = false;
  const deactivateUserLink = false;
  const deleteUserLink = false;
  const quickActions = true;

  const list = [
    {
      id: 1,
      value: (
        <Button
          hasShadow={false}
          scheme="link"
          link={Paths.MANAGEMENT_USERS}
          text="Return to the user overview."
          size="inline"
        />
      ),
    },
    {
      id: 2,
      value: (
        <>
          Are you stuck? Contact your supervisor or send an email to{' '}
          <Button
            hasShadow={false}
            scheme="link"
            link={`mailto:${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
            text={`${process.env.REACT_APP_SITE_MAIL_ADDRESS}`}
            size="inline"
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    const paramsId = Number(params.id);
    if (user.id === undefined || paramsId !== user.id) {
      dispatch(fetchUserAction(paramsId));
    }

    // TODO: Fix exhaustive-deps warning
    // eslint-disable-next-line
  }, [dispatch]);

  const inactiveText = useMemo(() => {
    switch (user.status) {
      case 'expired':
        return 'Account not verified by the user';
      case 'pending_email':
        return 'Changed email not yet verified by the user';
      default:
        return 'Account not yet activated by the user';
    }
  }, [user.status]);

  return (
    <>
      {user.id === 0 ? (
        <div className="detail-page">
          <Container>
            <Heading level={1}>User cannot be found</Heading>
            <p>The user you are looking for cannot be found.</p>
            <Heading level={2}>What to do next?</Heading>
            <LabelValueList values={list} styling={LabelValueListStyling.BULLETS} />
          </Container>
        </div>
      ) : (
        <>
          <PageFilterContainer noPadding>
            <IconButton
              tag="a"
              iconName="arrow-left"
              iconSize="small"
              text="User overview"
              size="extra-small"
              className="user-details__back-link"
              link={Paths.MANAGEMENT_USERS}
              loading={user.request}
            />
            <div className="user-details__main-header">
              <Icon
                name="user"
                size="x-large"
                className="user-details__main-header__icon"
              />
              <Heading
                level={1}
                className="user-details__main-header__header"
                loading={user.request}
              >
                {user.firstname} {user.lastname}
              </Heading>
              <Status
                loading={user.request}
                className="user-details__main-header__status"
                isActive={user.status === 'activated'}
                expired={user.status === 'expired'}
                activeText={`Active since ${moment(user.created_at).format(
                  'DD MMMM YYYY',
                )}`}
                textColor={
                  user.status === 'expired' ? StatusTextColor.RED : undefined
                }
                inactiveText={inactiveText}
              />
            </div>
          </PageFilterContainer>

          <Container>
            <div className="user-details__layout">
              <div className="user-details__layout-column">
                <Card
                  className="user-details__card"
                  highlightOnHover={false}
                  interactive={false}
                  shadow
                >
                  <Heading level={3} noMargin>
                    User information
                  </Heading>
                  <hr className="user-details__divider" />
                  <div className="user-details__field">
                    <Label
                      label="Name"
                      loading={user.request}
                      loadingWidth={100}
                      color="grey"
                    />
                    <Text
                      size={TextSize.XLARGE}
                      tag={TextTag.DIV}
                      loading={user.request}
                    >
                      {user.firstname} {user.lastname}
                    </Text>
                  </div>
                  <div className="user-details__field">
                    <Label
                      label="E-mail"
                      loading={user.request}
                      loadingWidth={100}
                      color="grey"
                    />
                    <Text
                      size={TextSize.XLARGE}
                      tag={TextTag.DIV}
                      loading={user.request}
                    >
                      {user.email}
                    </Text>
                  </div>
                </Card>

                <Card
                  className="user-details__card"
                  highlightOnHover={false}
                  interactive={false}
                  shadow
                >
                  <Heading level={3} noMargin>
                    User roles
                  </Heading>
                  <hr className="user-details__divider" />
                  <div className="user-details__field">
                    <Label
                      label={user.roles.length > 0 ? 'User roles' : ''}
                      loading={user.request}
                      loadingWidth={100}
                      color="grey"
                    />
                    <Text
                      size={
                        user.roles.length > 0
                          ? TextSize.XLARGE
                          : TextSize.NORMAL
                      }
                      tag={TextTag.DIV}
                      loading={user.request}
                    >
                      {user.roles.length > 0
                        ? user.roles
                          .map(role => role.name)
                          .sort()
                          .join(', ')
                        : 'This user has no user roles.'}
                    </Text>
                  </div>
                </Card>

                <Card
                  className="user-details__card"
                  highlightOnHover={false}
                  interactive={false}
                  shadow
                >
                  <Heading level={3} noMargin>
                    Locations
                  </Heading>
                  <hr className="user-details__divider" />
                  <div className="user-details__field">
                    <Label
                      label={user.locations.length > 0 ? 'Locations' : ''}
                      loading={user.request}
                      loadingWidth={100}
                      color="grey"
                    />
                    <Text
                      size={
                        user.locations.length > 0
                          ? TextSize.XLARGE
                          : TextSize.NORMAL
                      }
                      tag={TextTag.DIV}
                      loading={user.request}
                    >
                      {user.locations.length > 0
                        ? user.locations
                          // @ts-ignore
                          .map(loc => loc.name)
                          .sort()
                          .join(', ')
                        : 'This user has no locations.'}
                    </Text>
                  </div>
                </Card>
              </div>
              <div className="user-details__layout-column">
                {quickActions && (
                  <Card
                    className="user-details__card"
                    highlightOnHover={false}
                    interactive={false}
                    shadow
                  >
                    <Heading level={3}>Quick actions</Heading>
                    <List>
                      {sendPasswordLink && (
                        <ListItem>
                          <IconButton
                            tag="button"
                            iconName="lock"
                            iconSize="small"
                            text="Send password reset mail"
                            size="extra-small"
                            handler={() => { }}
                            loading={user.request}
                          />
                        </ListItem>
                      )}
                      {deactivateUserLink && (
                        <ListItem>
                          <IconButton
                            tag="button"
                            iconName="access"
                            iconSize="small"
                            text="Deactivate user"
                            size="extra-small"
                            handler={() => { }}
                            loading={user.request}
                          />
                        </ListItem>
                      )}
                      {deleteUserLink && (
                        <ListItem>
                          <IconButton
                            tag="button"
                            scheme="delete-link"
                            iconName="delete"
                            iconSize="small"
                            text="Delete"
                            size="extra-small"
                            handler={() => { }}
                            loading={user.request}
                          />
                        </ListItem>
                      )}
                    </List>
                    <IconButton
                      className="user-details__edit-button"
                      block
                      scheme="default"
                      size="medium"
                      hasShadow
                      text="Edit user"
                      iconSize="small"
                      iconName="edit"
                      iconColor="white"
                      iconPosition="after-text"
                      tag="button"
                      handler={() => dispatch(openUserAction(user.id))}
                      loading={user.request}
                    />
                  </Card>
                )}
              </div>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default UserDetails;
