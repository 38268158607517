import { reverse } from 'named-urls';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Container from 'components/container/Container';
import OverviewList from 'components/overviewList/OverviewList';
import { OverviewListContent, OverviewListHeader } from 'components/overviewList/OverviewList.types';
import { OverviewListItemStatus } from 'components/overviewList/OverviewListItem.enum';
import { OverviewListItemItem } from 'components/overviewList/OverviewListItem.types';
import PageFilterContainer from 'components/page-filter-container/PageFilterContainer';
import PoolButton from 'components/pool/button/PoolButton';
import PoolFilter from 'components/pool/filter/PoolFilter';
import Ellipsis from 'components/typography/ellipsis/Ellipsis';
import Heading from 'components/typography/heading/Heading';

import PopupTypes from 'constants/PopupTypes.enum';
import { Paths } from 'constants/Routes.enum';
import UserRight from 'constants/UserRight.enum';

import { arrangementCleared } from 'store/arrangements/arrangementsActions';
import { hasSuperAdminRights, hasUserRights } from 'store/auth/hasUserRights';
import { devicesCleared } from 'store/devices/devicesActions';
import { poolCleared } from 'store/pool/poolActions';
import { RootState } from 'store/rootState';
import { triggersCleared } from 'store/triggers/triggersActions';

import './Overview.scss';

const PoolsOverview = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const history = useHistory();
  const hasPoolsEditRights = dispatch(hasUserRights(UserRight.POOLS_EDIT));
  const isSuperAdmin = dispatch(hasSuperAdminRights());
  const { request, pools } = useSelector((state: RootState) => state.pools);

  const overviewHeaders = [
    { key: 'Groups', content: 'Groups' },
    { key: 'Location', content: 'Location', width: isSuperAdmin ? '20%' : '25%' },
    isSuperAdmin ? { key: 'Customer', content: 'Customer', width: '10%' } : undefined,
    { key: 'Devices', content: 'Devices', width: '10%' },
    { key: 'Notification', content: 'Notification', width: '25%' },
  ].filter(Boolean) as OverviewListHeader[];

  useEffect(() => {
    // Clear all data from pools
    dispatch(poolCleared());
    dispatch(triggersCleared());
    dispatch(devicesCleared());
    dispatch(arrangementCleared());
  }, [dispatch]);

  const mappedPools = useMemo<Array<OverviewListContent>>(() => {
    if (!pools) return []

    return pools.map(({ id, name, location, customer, approval_requested, sign_information }) => ({
      key: `pool_${id}`,
      id,
      items: [
        { content: name, key: `${id}_status` },
        {
          content: location,
          key: `${id}_location`,
          width: isSuperAdmin ? '20%' : '25%'
        },
        isSuperAdmin
          ? {
            content: <Ellipsis text={customer} />,
            key: `${id}_customer`,
            width: '10%'
          }
          : undefined,
        {
          content: sign_information.count,
          key: `${id}_devices`,
          width: '10%',
        },
        {
          content: approval_requested ? 'Approval needed' : <span />,
          key: `${id}_notification`,
          width: '25%',
        },
      ].filter(Boolean) as OverviewListItemItem[],
      status: OverviewListItemStatus.ACTIVE,
      onClick: () => {
        history.push({ pathname: reverse(Paths.POOLS_DETAIL, { id }) });
      },
    }))
  }, [pools, history, isSuperAdmin])


  return (
    <div className="pools-overview">
      <PageFilterContainer title="Groups">
        <PoolFilter />
      </PageFilterContainer>

      <div className="pools-overview__content">
        <Container>
          <Heading level={2} stylingLevel={3}>
            All groups
          </Heading>
          <OverviewList
            dataCyListItem="poolsOverviewListItem"
            headers={overviewHeaders}
            content={mappedPools}
            loading={request}
            cardProps={{
              highlightOnHover: true,
              interactive: true,
            }}
          />
        </Container>

        {hasPoolsEditRights && (
          <PoolButton text="Add group" popupType={PopupTypes.GROUP} />
        )}
      </div>
    </div>
  );
};

export default PoolsOverview;
