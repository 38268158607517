import React, { useEffect, useState, useRef } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import RichTextEditorColorPicker from './RichTextEditorColorPicker';
import { RichTextEditorProps } from './RichTextEditor.types';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichTextEditor.scss';

const RichTextEditor = ({ name, value, onChange, backgroundColor, error }: RichTextEditorProps) => {
  const [editorContent, setContent] = useState(value);
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(value).contentBlocks, null),
    ) || EditorState.createEmpty(),
  );

  const editorTimer = useRef<number>();

  useEffect(() => {
    editorTimer.current = window.setTimeout(() => {
      onChange(name, editorContent);
    }, 2000);

    return () => {
      clearTimeout(editorTimer.current);
    };
  }, [onChange, name, editorContent]);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);

    // Get the raw content from the editor
    const rawContent = convertToRaw(editorState.getCurrentContent());
    // See if any of the blocks are iframes and store them in an array
    const iframeEntities = Object.keys(rawContent.entityMap).filter(
      key => rawContent.entityMap[key].type === 'EMBEDDED_LINK',
    );

    // The content that we send to the backend
    let content = draftToHtml(rawContent);

    // if there was an iframe in the content
    // set the new filtered content in the editor
    if (iframeEntities.length > 0) {
      // remove any content block that is an iframe
      const filteredBlocks = rawContent.blocks.filter(block => {
        return !block.entityRanges.some(
          entity =>
            !iframeEntities || iframeEntities.includes(entity.key.toString()),
        );
      });

      // these content blocks are not quite the same as the filtered block
      // but are needed to fill the editor again
      const contentBlocks = editorState.getCurrentContent().getBlocksAsArray();

      setEditorState(
        EditorState.createWithContent(
          // fill content with new content blocks
          ContentState.createFromBlockArray(
            contentBlocks.filter(block =>
              filteredBlocks.some(
                // TODO: no key?
                filteredBlock => filteredBlock.key === block.getKey(),
              ),
            ),
          ),
        ),
      );

      // update content with filtered blocks
      content = draftToHtml({
        ...rawContent,
        blocks: filteredBlocks,
      });
    }

    setContent(content);
  };

  return (
    <>
      <div className="rich-text-editor" style={{ background: backgroundColor }}>
        <Editor
          editorState={editorState}
          toolbarClassName="rich-text-editor__toolbar"
          wrapperClassName="rich-text-editor__wrapper"
          editorClassName="rich-text-editor__editor"
          editorStyle={{ fontFamily: 'Arial' }}
          placeholder="Begin typing..."
          onEditorStateChange={onEditorStateChange}
          onBlur={() => onChange(name, editorContent)}
          toolbar={{
            options: [
              'fontFamily',
              'inline',
              'blockType',
              'colorPicker',
              'list',
              'textAlign',
              'history',
            ],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['bold', 'italic', 'underline', 'strikethrough'],
              bold: {
                icon: null,
                className: 'rich-text-editor__icon rich-text-editor__icon-bold',
              },
              italic: {
                icon: null,
                className:
                  'rich-text-editor__icon rich-text-editor__icon-italic',
              },
              underline: {
                icon: null,
                className:
                  'rich-text-editor__icon rich-text-editor__icon-underline',
              },
              strikethrough: {
                icon: null,
                className:
                  'rich-text-editor__icon rich-text-editor__icon-strikethrough',
              },
            },
            blockType: {
              inDropdown: true,
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
              className: 'rich-text-editor__toolbar__block-type',
              component: undefined,
              dropdownClassName: undefined,
            },
            fontFamily: {
              options: [
                'Arial',
                'Arial Black',
                'Bookman',
                'Comic Sans MS',
                'Courier',
                'Courier New',
                'Garamond',
                'Georgia',
                'Impact',
                'Palatino',
                'Tahoma',
                'Times',
                'Times New Roman',
                'Trebuchet MS',
                'Verdana',
              ],
              className: 'rich-text-editor__toolbar__font-family',
              component: undefined,
              dropdownClassName: undefined,
            },
            list: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['unordered', 'ordered'],
              unordered: {
                icon: null,
                className:
                  'rich-text-editor__icon rich-text-editor__icon-unordered-list',
              },
              ordered: {
                icon: null,
                className:
                  'rich-text-editor__icon rich-text-editor__icon-ordered-list',
              },
            },
            textAlign: {
              inDropdown: true,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['left', 'center', 'right', 'justify'],
              left: { icon: '/icons/icon_editor_align-left.svg' },
              center: { icon: '/icons/icon_editor_align-center.svg' },
              right: { icon: '/icons/icon_editor_align-right.svg' },
              justify: { icon: '/icons/icon_editor_align-justify.svg' },
            },
            history: {
              undo: {
                icon: null,
                className: 'rich-text-editor__icon rich-text-editor__icon-undo',
              },
              redo: {
                icon: null,
                className: 'rich-text-editor__icon rich-text-editor__icon-redo',
              },
            },
            colorPicker: { component: RichTextEditorColorPicker },
          }}
        />
      </div>
      {!!error && <p className="form__error">{error}</p>}
    </>
  );
};

export default RichTextEditor;
