import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Dropdown from 'components/form/dropdown/Dropdown';
import DropdownMultiple from 'components/form/dropdown-multiple/DropdownMultiple';
import { fetchUserRolesAction } from 'store/userRoles/userRolesActions';

const useUserRoleDropdown = () => {
  const dispatch = useDispatch();
  const { request, userRoles } = useSelector(state => state.userRoles);
  const [userRoleList, setUserRoleList] = useState([]);

  useEffect(() => {
    dispatch(fetchUserRolesAction());

    return () => {
      setUserRoleList([]);
    };
  }, [dispatch]);

  useEffect(() => {
    setUserRoleList(
      userRoles
        .map(userRole => {
          return {
            label: userRole.name,
            value: userRole.id,
          };
        })
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }),
    );
  }, [userRoles]);

  return useMemo(() => ({ request, userRoleList }), [request, userRoleList]);
};

const UserRoleDropdown = ({ multiple, ...props }) => {
  const { userRoleList, loadingUserRoles } = useUserRoleDropdown();
  return (
    <>
      {multiple ? (
        <DropdownMultiple
          {...props}
          values={userRoleList}
          isLoading={loadingUserRoles}
          sortOptions
        />
      ) : (
        <Dropdown
          {...props}
          values={userRoleList}
          isLoading={loadingUserRoles}
        />
      )}
    </>
  );
};

UserRoleDropdown.propTypes = {
  multiple: PropTypes.bool,
};

UserRoleDropdown.defaultProps = {
  multiple: false,
};

export default UserRoleDropdown;
